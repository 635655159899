import { Card, Button, Stack, Typography, Skeleton } from '@mui/material';
import { useContext } from 'react';
import { cartBgColor } from '../../../../styles/variables';
import { useTranslation } from 'react-i18next';

import EmptyBundleIllustration from '../../../../assets/EmptyBundleIllustration';
import { useNavigate } from 'react-router-dom';
import { MyGiftShiftContext } from '../../../../providers/my-giftshift-provider';
import { LanguageContext } from '../../../../providers/language-selector-provider';
import { useInView } from 'react-intersection-observer';

export const NoBundleCard = (props: { hash: string; onChangeTab: (tabValue: string) => void }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loadingData } = useContext(MyGiftShiftContext);
  const { isEnglish } = useContext(LanguageContext);
  const sectionId = 'my-bundle';
  const { ref: inViewRef } = useInView({
    initialInView: props.hash === sectionId,
    onChange: (isInView) => {
      if (isInView) {
        props.onChangeTab(sectionId);
      }
    },
    threshold: 1,
  });

  const handleCreateBundle = () => {
    navigate(isEnglish ? '/donate' : '/doneren');
  };

  return loadingData ? (
    <Skeleton
      id={sectionId}
      variant="rounded"
      width={'100%'}
      sx={{
        scrollMarginTop: { mobile: '172px', tablet: '192px', laptop: '161px' },
        scrollMarginBlockStart: { mobile: '172px', tablet: '192px', laptop: '161px' },
        height: { mobile: '819px', tablet: '691px', laptop: '703px' },
      }}
      ref={inViewRef}
    />
  ) : (
    <Card
      id={sectionId}
      role="tabpanel"
      sx={{
        overflow: 'initial',
        backgroundColor: cartBgColor,
        scrollMarginTop: { mobile: '172px', tablet: '192px', laptop: '161px' },
        scrollMarginBlockStart: { mobile: '172px', tablet: '192px', laptop: '161px' },
        boxShadow: 'none',
        padding: { mobile: 3, laptop: 4 },
        borderRadius: { mobile: '10px', laptop: '12px' },
      }}
      ref={inViewRef}
    >
      <Stack gap={3}>
        <Typography variant="h2">{t('myBundle')}</Typography>
        <Stack gap={3} alignItems={'center'} width="100%" paddingBottom={{ mobile: 4, laptop: 5 }}>
          <EmptyBundleIllustration />
          <Typography variant="paragraphL" textAlign={'center'}>
            {t('noMonthlyBundle')}
          </Typography>
          <Button variant="contained" size="large" onClick={handleCreateBundle}>
            {t('createBundle')}
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
};
export default NoBundleCard;
