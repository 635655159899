import Widget1 from './Widget1';
import Widget2 from './Widget2';
import Widget3 from './Widget3';
import { TWidget, type WidgetProps } from './types';

export const Widget = ({ variant, ...props }: WidgetProps) => {
  const renderWidget = () => {
    switch (variant) {
      case TWidget.Type1:
        return <Widget1 {...props} />;
      case TWidget.Type2:
        return <Widget2 {...props} />;
      case TWidget.Type3:
        return <Widget3 {...props} />;
      default:
        return <Widget1 {...props} />;
    }
  };
  return <>{renderWidget()}</>;
};

export default Widget;
