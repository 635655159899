import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  IconButton,
  Typography,
  type SelectChangeEvent,
} from '@mui/material';
import { Stack } from '@mui/system';
import Dropdown from '../../../../components/dropdown/Dropdown';
import LoadingButton from '../../../../components/loading-button/LoadingButton';
import { LanguageContext } from '../../../../providers/language-selector-provider';
import { MyGiftShiftContext } from '../../../../providers/my-giftshift-provider';
import { AuthenticationContext } from '../../../../providers/authentication-provider';
import { CloseIcon } from '../../../../assets/icons';
import { textColor } from '../../../../styles/variables';
import EmptyBundleIllustration from '../../../../assets/EmptyBundleIllustration';

export const RenderCountryField = ({ updateRenderStates }: { updateRenderStates: () => void }) => {
  const { t } = useTranslation();
  const { handleUpdateCountry, currentBundle, handleRemoveBundle } = useContext(MyGiftShiftContext);
  const { allCountries } = useContext(LanguageContext);
  const {
    userDetails: { country },
  } = useContext(AuthenticationContext);
  const mappedCountries = allCountries?.map((country) => ({
    id: country.alias,
    label: country.country,
    logo: country.flag,
  }));
  const [addressState, setAddressState] = useState(country);
  const [openCountryDialog, setOpenCountryDialog] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSuccess = () => {
    updateRenderStates();
  };

  const handleSave = () => {
    currentBundle.length && country !== addressState
      ? setOpenCountryDialog(true)
      : handleUpdateCountry(addressState, onSuccess);
  };

  const onRemoveBundle = async () => {
    setLoading(true);
    await handleRemoveBundle()
      .then((response) => {
        if (response) {
          handleUpdateCountry(addressState, onSuccess);
          setOpenCountryDialog(false);
        }
      })
      .finally(() => setLoading(false));
  };

  const changeCountryDialogStyles = {
    '& .MuiDialog-paper': {
      padding: { mobile: '76px 20px', tablet: 10 },
      borderRadius: { mobile: '20px 20px 0 0 ', tablet: '16px' },
      gap: 4,
      margin: { mobile: 0, tablet: 9 },
      width: '100%',
      alignItems: 'center',
    },
    '& .MuiDialog-container': {
      alignItems: { mobile: 'flex-end', tablet: 'center' },
    },
  };

  return (
    <Stack gap={2}>
      <Dropdown
        options={mappedCountries || []}
        defaultValue={country || ''}
        name="country"
        onChange={(e) => {
          const { target } = e as SelectChangeEvent<string>;
          setAddressState(target.value);
        }}
        value={addressState}
        variant="outlined"
      />

      <Dialog
        open={openCountryDialog}
        onClose={() => setOpenCountryDialog(false)}
        aria-labelledby="confirm-change-country-dialog-title"
        aria-describedby="confirm-change-country-dialog-description"
        maxWidth="tablet"
        fullWidth
        sx={changeCountryDialogStyles}
      >
        <EmptyBundleIllustration />
        <Stack gap={1}>
          <Typography variant="h3" id="confirm-remove-bundle-dialog-title" textAlign={'center'}>
            {t('areYouSure')}
          </Typography>
          <Typography
            variant="paragraphL"
            color={textColor}
            id="confirm-remove-bundle-dialog-description"
            textAlign={'center'}
          >
            {t('changePreferredCountryWarning')}
          </Typography>
        </Stack>
        <IconButton
          aria-label="close"
          onClick={() => setOpenCountryDialog(false)}
          sx={{
            position: 'absolute',
            padding: 2,
            right: 16,
            top: 16,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <DialogActions sx={{ padding: 0, flexDirection: 'column', gap: 4 }}>
          <LoadingButton
            onClick={() => onRemoveBundle()}
            size="large"
            variant="contained"
            color="error"
            loading={loading}
            disabled={loading}
          >
            {t('removeBundle')}
          </LoadingButton>
          <Button
            size="large"
            onClick={() => setOpenCountryDialog(false)}
            variant="text"
            color="primary"
          >
            {t('cancel')}
          </Button>
        </DialogActions>
      </Dialog>

      <Box width="fit-content">
        <LoadingButton
          variant="contained"
          color="primary"
          sx={{ width: 'fit-content' }}
          type="submit"
          onClick={handleSave}
        >
          {t('save')}
        </LoadingButton>
      </Box>
    </Stack>
  );
};
