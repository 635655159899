import { Button, Skeleton, Stack, Typography } from '@mui/material';
import { useStripe } from '@stripe/react-stripe-js';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Navbar from '../../components/navbar/Navbar';
import { LanguageContext } from '../../providers/language-selector-provider';
import { primaryColor, white } from '../../styles/variables';
import { getStripeUserMessageKey } from './utils';

const PaymentFailedPage = () => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const [searchParams] = useSearchParams();
  const clientSecret = searchParams.get('payment_intent_client_secret');
  const [reason, setReason] = useState<string | undefined>();
  const { isEnglish } = useContext(LanguageContext);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const getPaymentIntent = async () => {
    if (clientSecret) {
      window.history.pushState({}, document.title, window.location.pathname);
      try {
        setIsLoading(true);
        const stripeResponse = await stripe?.retrievePaymentIntent(clientSecret);

        if (stripeResponse?.error || !stripeResponse?.paymentIntent.last_payment_error) {
          throw new Error();
        }

        const message = getStripeUserMessageKey(
          stripeResponse?.paymentIntent?.last_payment_error?.code
        );

        setReason(message);
      } catch {
        setReason(t('pleaseTryAgain'));
      } finally {
        setIsLoading(false);
      }
    } else {
      setReason(t('pleaseTryAgain'));
    }
  };

  useEffect(() => {
    getPaymentIntent();
  }, [stripe]);

  return (
    <>
      <Navbar />
      <Stack
        gap={5}
        width={'100%'}
        py={{ mobile: '124px', tablet: '118px', laptop: '94px' }}
        alignItems={'center'}
        minHeight={{
          mobile: 'calc(100vh - 56px)',
          tablet: 'calc(100vh - 88px)',
          laptop: 'calc(100vh - 113px)',
        }}
        sx={{ backgroundColor: primaryColor }}
        px={{ mobile: '20px', tablet: 0 }}
      >
        <Stack sx={{ width: '100%', maxWidth: '592px', gap: 1 }}>
          <Typography
            sx={{
              textAlign: 'center',
              fontSize: '136.8px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '150%',
            }}
          >
            😵‍💫
          </Typography>
          <Typography variant="h1" sx={{ color: white, textAlign: 'center' }}>
            {t('somethingWentWrong', {
              interpolation: { skipOnVariables: false },
            })}
          </Typography>
          {isLoading ? (
            <Skeleton variant="rounded" height={100} width="100%" />
          ) : reason ? (
            <Stack>
              {t(reason)
                ?.split('.')
                .map((el) => {
                  if (el) {
                    return (
                      <Typography
                        variant="paragraphL"
                        sx={{ color: white, textAlign: 'center' }}
                        key={el}
                      >
                        {el}.
                      </Typography>
                    );
                  }
                })}
            </Stack>
          ) : null}
          <Stack sx={{ flexDirection: 'row', justifyContent: 'center', mt: '32px' }}>
            <Button
              variant="contained"
              color="secondary"
              size="large"
              onClick={() => {
                navigate(isEnglish ? '/donate' : '/doneren');
              }}
              sx={{ max: '200px' }}
            >
              <Typography variant="buttonL">{t('tryAgain')}</Typography>
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};

export default PaymentFailedPage;
