import type { Theme } from '@mui/material';
import {
  Alert,
  Box,
  Dialog,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { backgroundColor, errorColor, shadowLG, textColor } from '../../styles/variables';

import { Form, Formik } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
  AlertTriangleIcon,
  EyeClosedIcon,
  EyeIcon,
  FacebookIcon,
  GoogleIcon,
} from '../../assets/icons';
import InputWithLabel from '../../components/inputWithLabel/InputWithLabel';
import LoadingButton from '../../components/loading-button/LoadingButton';
import SocialButton from '../../components/social-button/SocialButton';
import { AuthenticationContext } from '../../providers/authentication-provider';
import { LanguageContext } from '../../providers/language-selector-provider';
import { NotificationsContext } from '../../providers/notifications-provider';
import ForgotPasswordPage from '../ForgotPasswordPage/ForgotPasswordPage';
import { loginSchema } from '../MyGiftShiftPage/MyGiftShift.utils';
import { handleRedirectAfterLogin } from '../utils';

// types
import type { AxiosError } from 'axios';
import type { TLogin } from '../../models/User';

const LoginPage = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false);
  const [accountNotFoundError, setAccountNotFoundError] = useState(false);
  const { handleSocialAuth, handleLoginUser, loadingAuthentication, socialAccountExists } =
    useContext(AuthenticationContext);
  const { isEnglish } = useContext(LanguageContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setNotification } = useContext(NotificationsContext);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClose = () => {
    navigate('/');
  };

  const handleLoginError = (er: AxiosError) => {
    if (er?.response?.status === 401) {
      setAccountNotFoundError(true);
    } else {
      setNotification({
        type: 'error',
        message: t('somethingWentWrong', { message: t('pleaseTryAgain') }),
      });
    }
  };

  const isLandscape = useMediaQuery(
    (theme: Theme) => `${theme.breakpoints.down('laptop')} and (orientation: landscape)`
  );

  const loginBoxStyles = {
    bgcolor: 'background.paper',
    borderRadius: { mobile: '0px', tablet: '16px' },
    px: { mobile: '20px', tablet: '90px', laptop: '104px' },
    py: { mobile: '40px', tablet: '66px', laptop: '80px' },
    borderBottomLeftRadius: { mobile: 0, tablet: '16px' },
    borderBottomRightRadius: { mobile: 0, tablet: '16px' },
    boxShadow: shadowLG,
    width: { mobile: '100%', tablet: '508px', laptop: '592px' },
    maxWidth: '784px',
    outline: 0,
    height: { mobile: '100svh', tablet: 'fit-content' },
    display: 'flex',
    justifyContent: 'center',
  };

  const handleLoginFormSubmit = (values: TLogin) => {
    handleLoginUser(values, () => handleRedirectAfterLogin({ isEnglish }), handleLoginError);
  };

  return (
    <Dialog
      open={true}
      onClose={handleClose}
      aria-labelledby="login-modal-title"
      aria-describedby="login-modal-description"
      fullScreen
      PaperProps={{
        sx: { background: backgroundColor, alignItems: 'center', justifyContent: 'center' },
      }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box sx={loginBoxStyles}>
          <Stack
            gap={3}
            width="100%"
            height="100%"
            maxWidth="430px"
            pb={isLandscape ? 0 : 4}
            overflow={{ mobile: 'auto', tablet: 'initial' }}
          >
            <Stack>
              <Typography variant="h2" mb={'4px'} id="login-modal-title">
                {t('login')}
              </Typography>
              <Grid container gap={0.5} alignItems={'center'}>
                <Typography variant="paragraphM" color={'textSecondary'}>
                  {t('dontHaveAnAccount')}
                </Typography>
                <Typography
                  variant="paragraphM"
                  color="primary.main"
                  onClick={() => navigate(isEnglish ? '/register' : '/registreren')}
                  sx={{ cursor: 'pointer' }}
                >
                  {t('register')}
                </Typography>
              </Grid>
            </Stack>

            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validationSchema={loginSchema(t)}
              onSubmit={handleLoginFormSubmit}
              validateOnChange={false}
            >
              {({ handleChange, errors, values, setErrors, handleBlur }) => (
                <Form>
                  <Stack width={'100%'} gap={3}>
                    <InputWithLabel
                      fullWidth
                      labelName={t('emailAddress')}
                      name="email"
                      value={values.email}
                      error={!!errors.email}
                      errorMessage={errors.email}
                      onChange={(e) => {
                        setErrors({});
                        setAccountNotFoundError(false);
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                    />

                    <InputWithLabel
                      fullWidth
                      labelName={t('password')}
                      type={showPassword ? 'text' : 'password'}
                      onChange={(e) => {
                        setErrors({});
                        setAccountNotFoundError(false);
                        handleChange(e);
                      }}
                      onBlur={handleBlur}
                      name="password"
                      value={values.password}
                      error={!!errors.password}
                      errorMessage={errors.password}
                      extraLabel={
                        <Typography
                          variant="paragraphM"
                          color="primary.main"
                          sx={{ cursor: 'pointer' }}
                          onClick={() => setOpenForgotPasswordModal(true)}
                          data-testid="ForgotPasswordButton"
                        >
                          {t('forgotPassword')}
                        </Typography>
                      }
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowPassword}
                            onMouseDown={handleMouseDownPassword}
                            edge="end"
                            sx={{ mr: 0 }}
                          >
                            {showPassword ? <EyeClosedIcon /> : <EyeIcon />}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    <ForgotPasswordPage
                      open={openForgotPasswordModal}
                      onClose={() => setOpenForgotPasswordModal(false)}
                    />

                    <Box>
                      <LoadingButton
                        size="large"
                        variant="contained"
                        data-testid="LoginButton"
                        type="submit"
                        fullWidth
                        loading={loadingAuthentication}
                        disabled={!!Object.keys(errors).length || loadingAuthentication}
                        color="primary"
                      >
                        {t('login')}
                      </LoadingButton>
                    </Box>

                    {accountNotFoundError ? (
                      <Alert icon={<AlertTriangleIcon color={errorColor} />} severity={'error'}>
                        {t('incorrectPassword')}
                      </Alert>
                    ) : null}

                    {socialAccountExists ? (
                      <Alert icon={<AlertTriangleIcon color={errorColor} />} severity={'error'}>
                        {t('incorrectPassword')} {t('socialAccountExists')}
                      </Alert>
                    ) : null}
                  </Stack>
                </Form>
              )}
            </Formik>

            {/* <CheckboxLabeled label={t("rememberMe")} /> */}

            <Divider
              sx={{
                '.MuiDivider-wrapper': {
                  padding: '0 20px',
                },
              }}
            >
              <Typography variant="paragraphS" color={textColor}>
                {t('orLoginWith')}
              </Typography>
            </Divider>

            <Grid container spacing={2}>
              <Grid item mobile={6}>
                <SocialButton
                  icon={<FacebookIcon />}
                  label={'Facebook'}
                  onClick={() => handleSocialAuth('facebook')}
                  justifyContent="center"
                />
              </Grid>
              <Grid item mobile={6}>
                <SocialButton
                  icon={<GoogleIcon />}
                  label={'Google'}
                  onClick={() => handleSocialAuth('google')}
                  justifyContent="center"
                />
              </Grid>
            </Grid>
          </Stack>
        </Box>
      </Box>
    </Dialog>
  );
};

export default LoginPage;
