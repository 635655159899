import { useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, FormControl, RadioGroup } from '@mui/material';
import RadioButtonLabeled from '../../../../components/radio-button-labeled/RadioButtonLabeled';
import { MyGiftShiftContext } from '../../../../providers/my-giftshift-provider';
import { AuthenticationContext } from '../../../../providers/authentication-provider';

export const RenderGenderField = ({ updateRenderStates }: { updateRenderStates: () => void }) => {
  const { handleUpdateGender } = useContext(MyGiftShiftContext);
  const {
    userDetails: { gender },
  } = useContext(AuthenticationContext);
  const [genderValue, setGenderValue] = useState(gender);
  const { t } = useTranslation();

  const onSuccess = () => {
    updateRenderStates();
  };

  return (
    <FormControl>
      <RadioGroup
        aria-labelledby="gender-select"
        name="gender-select"
        value={genderValue}
        onChange={(e) => setGenderValue(e.target.value)}
      >
        <RadioButtonLabeled value="f" label={t('female')} />
        <RadioButtonLabeled value="m" label={t('male')} />
        <RadioButtonLabeled value="o" label={t('other')} />
        <RadioButtonLabeled value="x" label={t('idRatherNotSay')} />
      </RadioGroup>
      <Button
        variant="contained"
        color="primary"
        sx={{ width: 'fit-content' }}
        onClick={() => handleUpdateGender(genderValue, onSuccess)}
      >
        {t('save')}
      </Button>
    </FormControl>
  );
};
