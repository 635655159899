import { Alert, Card, Divider, FormControl, RadioGroup, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { checkCouponValidity } from '../../../../api/coupons';
import { AlertTriangleIcon } from '../../../../assets/icons';
import Callout from '../../../../components/callout/Callout';
import CharityAmountDivider from '../../../../components/charity-amount-divider/CharityAmountDivider';
import CouponInput from '../../../../components/coupon-input/CouponInput';
import RadioButtonLabeled from '../../../../components/radio-button-labeled/RadioButtonLabeled';
import { type TDonationFrequency } from '../../../../models/Donation';
import { AuthenticationContext } from '../../../../providers/authentication-provider';
import { DonationFlowContext } from '../../../../providers/donation-flow-provider';
import { darkBlue, errorColor, shadowXL } from '../../../../styles/variables';
import { removeCausesDuplicates } from '../../DonationFlow.utils';
import FrequencyAndAmountStepSkeleton from './FrequencyAndAmountStepSkeleton';

export const FrequencyAndAmountStep = () => {
  const { t } = useTranslation();
  const { authenticatedUser } = useContext(AuthenticationContext);
  const {
    handleChooseFrequencyType,
    donationFrequency,
    selectedCharities,
    onCharityAmountSelected,
    totalAmount,
    handleRemoveCharity,
    currentBundle,
    loadingData,
    handlePreviousStep,
    validCoupon,
    setValidCoupon,
  } = useContext(DonationFlowContext);
  const [touchedFields, setTouchedFields] = useState(false);
  const [isLoadingCouponChecking, setIsLoadingCouponChecking] = useState(false);
  const [isCouponValid, setIsCouponValid] = useState<boolean | undefined>(
    validCoupon?.code ? true : undefined
  );

  useEffect(() => {
    if (!selectedCharities.length) {
      handlePreviousStep();
    }
  }, []);

  const isMonthlyDonor = localStorage.getItem('monthlyDonor');

  const isInvalid =
    totalAmount < 5 &&
    touchedFields &&
    !selectedCharities.some((el) => parseInt(el.donationAmount as string) < 0);

  const handleCouponValidityCheck = async (coupon: string) => {
    setIsLoadingCouponChecking(true);
    try {
      const { data } = await checkCouponValidity(coupon);

      setIsCouponValid(true);
      setValidCoupon({ code: coupon, value: data.amount });
      localStorage.setItem('coupon', JSON.stringify({ code: coupon, value: data.amount }));
    } catch {
      setIsCouponValid(false);
    } finally {
      setIsLoadingCouponChecking(false);
    }
  };

  return loadingData ? (
    <FrequencyAndAmountStepSkeleton />
  ) : (
    <Stack
      gap={{ mobile: 7, tablet: 8, laptop: 11 }}
      px={{ mobile: '20px', tablet: '40px', laptop: 0 }}
      mx={{ mobile: '0', laptop: '32px' }}
      width={{ mobile: '100%', laptop: '70%' }}
      maxWidth={'1216px'}
    >
      {(currentBundle.length === 0 && isMonthlyDonor) || !isMonthlyDonor ? (
        <Stack gap={2} pt={2}>
          <Typography variant="h3">{t('howOftenDonate')}</Typography>
          <FormControl>
            <RadioGroup
              aria-label="donation-frequency"
              aria-labelledby="donation-frequency"
              name="donation-frequency"
              value={donationFrequency}
              onChange={(event) => {
                handleChooseFrequencyType(
                  (event.target as HTMLInputElement).value as TDonationFrequency
                );
              }}
            >
              <RadioButtonLabeled value="one-time" label={t('oneTime')} />
              <RadioButtonLabeled
                value="monthly"
                label={
                  <Stack flexDirection={'row'} alignItems={'center'} gap={1}>
                    <Typography variant="paragraphM">{t('monthly')}</Typography>
                    <Typography variant="paragraphXS">{`(${t('cancellableAnyTime')})`}</Typography>
                  </Stack>
                }
              />
            </RadioGroup>
          </FormControl>
          {donationFrequency === 'monthly' ? <Callout content={t('monthlyDonationExtra')} /> : null}
        </Stack>
      ) : null}

      <Stack gap={4}>
        <Typography variant="h3" sx={{ opacity: donationFrequency ? 1 : 0.2 }}>
          {`${isMonthlyDonor ? `${t('welcomeBack', { name: authenticatedUser?.name })} ` : ''}${t(
            'donationAmountQuestion'
          )}`}
        </Typography>

        {donationFrequency ? (
          <>
            {donationFrequency !== 'monthly' ? (
              <CouponInput
                validCode={validCoupon?.code}
                isLoading={isLoadingCouponChecking}
                isValid={isCouponValid}
                onCouponSubmit={handleCouponValidityCheck}
              />
            ) : null}
            <Card sx={{ p: { mobile: 3, tablet: 5 }, borderRadius: '16px', boxShadow: shadowXL }}>
              <Stack gap={3}>
                {removeCausesDuplicates(selectedCharities).map((charity) => (
                  <Stack key={charity.id} gap={4} alignItems={'center'}>
                    <CharityAmountDivider
                      value={charity.donationAmount}
                      charity={charity}
                      inputValueLabel={t('otherwise')}
                      onChange={(val) => {
                        onCharityAmountSelected(val, charity);

                        if (!touchedFields) {
                          setTouchedFields(true);
                        }
                      }}
                      onRemoveCharity={handleRemoveCharity}
                    />
                    <Divider sx={{ width: '100%' }} />
                  </Stack>
                ))}
                <Stack>
                  <Stack
                    flexDirection={'row'}
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    marginBottom="16px"
                  >
                    <Typography variant="paragraphM">{`${t('yourTotalDonation')}:`}</Typography>
                    <Typography variant="h2" color={isInvalid ? errorColor : darkBlue}>
                      {`€ ${totalAmount}`}
                    </Typography>
                  </Stack>
                  {validCoupon ? (
                    <Stack
                      flexDirection={'row'}
                      justifyContent={'space-between'}
                      alignItems={'center'}
                      marginBottom="16px"
                    >
                      <Typography variant="paragraphM">{t('spentCouponAmount')}</Typography>
                      <Typography variant="h2">{`€ ${validCoupon.value}`}</Typography>
                    </Stack>
                  ) : null}
                </Stack>
                {isInvalid ? (
                  <Alert icon={<AlertTriangleIcon color={errorColor} />} severity={'error'}>
                    {t('minDonationAmountError')}
                  </Alert>
                ) : null}
              </Stack>
            </Card>
          </>
        ) : null}
      </Stack>
    </Stack>
  );
};

export default FrequencyAndAmountStep;
