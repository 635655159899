import { AxiosError } from 'axios';
import { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  activateAccount,
  clearHttpOnlyCookies,
  getSocialAuthLink,
  loginUser,
  registerUser,
  socialLogin,
} from '../api/authentication';
import { resetUserPassword, submitNewUserPassword } from '../api/reset-password';
import { getUserDetails, updateUserDetails } from '../api/user-details';
import { resetStorage } from '../common/utils';
import {
  containsCapitalLetter,
  containsSpecialCharacter,
  hasMinimumChars,
  passwordRequirementsInitialValues,
} from '../pages/RegisterPage/RegisterPage.utils';
import { NotificationsContext } from './notifications-provider';

// types
import type { FC } from 'react';
import type { TRegisterUser } from '../api/authentication';
import type { TAuthenticatedUser, TLogin, TResetPassword } from '../models/User';
import type { TPasswordRequirements } from '../pages/RegisterPage/types';

type TUserDetails = {
  name: string;
  birthday: string;
  country: string;
  gender: string;
  newsletter: boolean;
};

const userDetailsInitialValues = {
  name: '',
  birthday: '',
  country: '',
  gender: '',
  newsletter: false,
};

export interface IAuthenticationContext {
  nameValue: string;
  passwordValue: string;
  handleChangeEmail: (val: string) => void;
  handleChangeName: (val: string) => void;
  handleChangePassword: (val: string) => void;
  passwordRequirements: TPasswordRequirements;
  invalidPassword: boolean;
  emailAlreadyExists: boolean;
  invalidEmailError: boolean;
  invalidNameError: boolean;
  handleRegisterAccount: (values: TRegisterUser) => void;
  handleLoginUser: (values: TLogin, onSuccess: () => void, onError: (er: any) => void) => void;
  loadingAuthentication: boolean;
  checkYourEmailState: boolean;
  handleActivateAccount: (code: string, callBack: () => void, onError: () => void) => void;
  authenticatedUser: TAuthenticatedUser | undefined;
  setCheckYourEmailState: (val: boolean) => void;
  resetFields: () => void;
  handleLogout: () => void;
  handleCheckPasswordRequirements: (val: string) => void;
  updateAuthenticatedUserName: (val: string) => void;
  handleSubmitNewPassword: (
    code: string | null,
    values: TResetPassword,
    onSuccess: () => void,
    onError: () => void
  ) => void;
  showResetLinkModal: boolean;
  loadingSubmitForgotPassword: boolean;
  setShowResetLinkModal: (val: boolean) => void;
  handleResetPassword: (values: { email: string }) => void;
  emailError: boolean;
  handleSocialLogin: (onSuccess: () => void, onError: () => void) => Promise<void>;
  handleSocialAuth: (socialType: 'facebook' | 'google') => void;
  handleSocialAccountExists: () => void;
  socialAccountExists: boolean;
  unconfirmedEmail: boolean;
  resetErrors: () => void;
  isManualLogout: boolean;
  userDetails: TUserDetails;
  setUserDetails: (userDetails: TUserDetails) => void;
}

export const AuthenticationContext = createContext<IAuthenticationContext>({
  nameValue: '',
  passwordValue: '',
  handleChangeEmail: () => {},
  handleChangeName: () => {},
  handleChangePassword: () => {},
  passwordRequirements: passwordRequirementsInitialValues,
  invalidPassword: false,
  emailAlreadyExists: false,
  invalidEmailError: false,
  invalidNameError: false,
  handleRegisterAccount: () => {},
  handleLoginUser: () => {},
  loadingAuthentication: false,
  checkYourEmailState: false,
  handleActivateAccount: () => {},
  authenticatedUser: undefined,
  setCheckYourEmailState: () => {},
  resetFields: () => {},
  handleLogout: () => {},
  handleCheckPasswordRequirements: () => {},
  updateAuthenticatedUserName: () => {},
  handleSubmitNewPassword: () => {},
  showResetLinkModal: false,
  loadingSubmitForgotPassword: false,
  setShowResetLinkModal: () => {},
  handleResetPassword: () => {},
  emailError: false,
  handleSocialLogin: async () => {},
  handleSocialAuth: () => {},
  handleSocialAccountExists: () => {},
  socialAccountExists: false,
  unconfirmedEmail: false,
  resetErrors: () => {},
  isManualLogout: false,
  userDetails: userDetailsInitialValues,
  setUserDetails: () => {},
});

export const AuthenticationProvider: FC<{ children?: React.ReactNode }> = (props) => {
  //helpers
  const userName = localStorage.getItem('username') || undefined;
  const userEmail = localStorage.getItem('email') || undefined;
  const userSocialAccount = localStorage.getItem('socialAccount');
  const userIsAdmin = localStorage.getItem('isAdmin');

  // state

  const [nameValue, setNameValue] = useState('');
  const [passwordValue, setPasswordValue] = useState('');
  const [checkYourEmailState, setCheckYourEmailState] = useState(false);
  const [authenticatedUser, setAuthenticatedUser] = useState<TAuthenticatedUser | undefined>(
    userName
      ? {
          email: userEmail,
          name: userName,
          socialAccount: userSocialAccount ? JSON.parse(userSocialAccount) : undefined,
          isAdmin: userIsAdmin ? JSON.parse(userIsAdmin) : undefined,
        }
      : undefined
  );

  const [passwordRequirements, setPasswordRequirements] = useState<TPasswordRequirements>(
    passwordRequirementsInitialValues
  );

  const [emailAlreadyExists, setEmailAlreadyExists] = useState(false);
  const [invalidEmailError, setInvalidEmailError] = useState(false);
  const [invalidNameError, setInvalidNameError] = useState(false);
  const [loadingAuthentication, setLoadingAuthentication] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [loadingSubmitForgotPassword, setLoadingSubmitForgotPassword] = useState(false);
  const [showResetLinkModal, setShowResetLinkModal] = useState(false);
  const [socialAccountExists, setSocialAccountExists] = useState(false);
  const [unconfirmedEmail, setUnconfirmedEmail] = useState(false);
  const [isManualLogout, setIsManualLogout] = useState(false);
  const [userDetails, setUserDetails] = useState<TUserDetails>(userDetailsInitialValues);

  const { setNotification } = useContext(NotificationsContext);
  const { t } = useTranslation();

  const updateAuthenticatedUserName = (value: string) => {
    setAuthenticatedUser((prevState) => {
      if (prevState) {
        localStorage.setItem('username', value);
        return {
          ...prevState,
          name: value,
        };
      }
      return undefined;
    });
  };

  // stateUpdates
  const handleChangeEmail = () => {
    setEmailAlreadyExists(false);
    setInvalidEmailError(false);
  };

  const handleChangeName = (val: string) => {
    setInvalidNameError(false);
    setNameValue(val);
  };

  const handleChangePassword = (val: string) => {
    setPasswordValue(val.replace(/\s/g, ''));
    handleCheckPasswordRequirements(val);
  };

  const handleCheckPasswordRequirements = (val: string) => {
    const requirements: TPasswordRequirements = {
      ...passwordRequirements,
      minimum8Chars: hasMinimumChars(val.replace(/\s/g, '')),
      oneCapitalLetter: containsCapitalLetter(val.replace(/\s/g, '')),
      oneSpecialCharacter: containsSpecialCharacter(val.replace(/\s/g, '')),
    };
    setPasswordRequirements(requirements);
  };

  // handlers

  const handleRegisterAccount = async (values: TRegisterUser) => {
    setLoadingAuthentication(true);
    setInvalidEmailError(false);
    setInvalidNameError(false);

    try {
      const response = await registerUser({
        email: values.email.toLowerCase(),
        name: values.name,
        password: values.password,
        newsletter: values.newsletter,
      });
      if (response) {
        setLoadingAuthentication(false);
        setCheckYourEmailState(true);
        resetFields();
      }
    } catch (error: any) {
      setLoadingAuthentication(false);

      if (error?.response?.status === 409) {
        setEmailAlreadyExists(true);
      } else if (error.response.status === 406) {
        handleSocialAccountExists();
      } else {
        setNotification({
          type: 'error',
          message: t('somethingWentWrong', { message: t('pleaseTryAgain') }),
        });
      }
    }
  };

  const fetchUserDetails = async () => {
    try {
      const { data } = await getUserDetails();

      if (!data.country) {
        await updateUserDetails({ country: 'nl' });
      }

      setUserDetails({
        ...data,
        country: data.country || 'nl',
      });
    } catch {
      setNotification({
        type: 'error',
        message: t('somethingWentWrong', { message: t('pleaseTryAgain') }),
      });
    }
  };

  const handleLoginUser = async (
    values: TLogin,
    onSuccess: () => void,
    onError: (err: any) => void
  ) => {
    setLoadingAuthentication(true);
    try {
      const { data } = await loginUser({
        email: values.email.toLowerCase(),
        password: values.password,
      });

      localStorage.setItem('access_token', data.access);
      localStorage.setItem('refreshToken', data.refresh);
      document.cookie = `access_token=${data.access};domain=.giftshift.org; path=/`;
      document.cookie = `refresh_token=${data.refresh};domain=.giftshift.org; path=/`;
      localStorage.setItem('username', data.user);
      localStorage.setItem('email', data.email);

      if (data.socialAccount) {
        localStorage.setItem('socialAccount', data.socialAccount);
      }

      if (data.isAdmin) {
        localStorage.setItem('isAdmin', data.isAdmin);
      }

      setAuthenticatedUser({
        name: data.user,
        email: data.email,
        socialAccount: data.socialAccount || false,
        isAdmin: data.isAdmin || false,
      });
      resetFields();
      onSuccess();
      setIsManualLogout(false);
    } catch (error) {
      onError(error);
    } finally {
      setLoadingAuthentication(false);
    }
  };

  const handleActivateAccount = async (
    code: string,
    onSuccess: () => void,
    onError: () => void
  ) => {
    try {
      const response = await activateAccount(code);
      if (response) {
        onSuccess();
      }
    } catch (error) {
      onError();
    }
  };

  const handleLogout = async () => {
    try {
      await clearHttpOnlyCookies();
    } finally {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('username');
      localStorage.removeItem('email');
      localStorage.removeItem('socialAccount');
      localStorage.removeItem('isAdmin');
      resetStorage();
      setAuthenticatedUser(undefined);
      resetFields();
      setIsManualLogout(true);
    }
  };

  const handleSubmitNewPassword = async (
    code: string | null,
    values: TResetPassword,
    onSuccess: () => void,
    onError: () => void
  ) => {
    try {
      if (code) {
        await handleLogout();
        await submitNewUserPassword(code, { password: values.password });
        onSuccess();
      }
    } catch (error) {
      onError();
    }
  };

  const handleResetPassword = async (values: { email: string }) => {
    setLoadingSubmitForgotPassword(true);
    try {
      await resetUserPassword({ email: values.email.toLowerCase() });

      setShowResetLinkModal(true);
    } catch (error) {
      if (error instanceof AxiosError) {
        switch (error.response?.status) {
          case 406:
            handleSocialAccountExists();
            break;
          case 409:
            setUnconfirmedEmail(true);
            break;
          default:
            setEmailError(true);
            break;
        }
      }
    } finally {
      setLoadingSubmitForgotPassword(false);
    }
  };

  const handleSocialAuth = async (socialType: 'facebook' | 'google') => {
    try {
      const response = await getSocialAuthLink(socialType);
      window.location.href = response.data.url;
      setIsManualLogout(false);
    } catch {
      setNotification({
        type: 'error',
        message: t('somethingWentWrong', { message: t('pleaseTryAgain') }),
      });
    }
  };

  const handleSocialLogin = async (onSuccess: () => void, onError: () => void) => {
    try {
      const response = await socialLogin();
      localStorage.setItem('access_token', response.data.access);
      localStorage.setItem('refreshToken', response.data.refresh);
      document.cookie = `access_token=${response.data.access};domain=.giftshift.org; path=/`;
      document.cookie = `refresh_token=${response.data.refresh};domain=.giftshift.org; path=/`;
      localStorage.setItem('username', response.data.name);
      localStorage.setItem('email', response.data.email);
      if (response.data.socialAccount) {
        localStorage.setItem('socialAccount', response.data.socialAccount);
      }
      if (response.data.isAdmin) {
        localStorage.setItem('isAdmin', response.data.isAdmin);
      }
      setAuthenticatedUser({
        name: response.data.name,
        email: response.data.email,
        socialAccount: response.data.socialAccount || false,
        isAdmin: response.data.isAdmin || false,
      });
      onSuccess();
    } catch {
      onError();
    }
  };

  useEffect(() => {
    if (authenticatedUser) {
      fetchUserDetails();
    }
  }, [authenticatedUser]);

  // utils
  const invalidPassword =
    !passwordRequirements.minimum8Chars ||
    !passwordRequirements.oneCapitalLetter ||
    !passwordRequirements.oneSpecialCharacter;

  const resetFields = () => {
    setNameValue('');
    setPasswordValue('');
    setEmailAlreadyExists(false);
    setInvalidEmailError(false);
    setInvalidNameError(false);
    setLoadingAuthentication(false);
  };

  const handleSocialAccountExists = () => {
    setSocialAccountExists(true);
  };

  const resetErrors = () => {
    setEmailError(false);
    setSocialAccountExists(false);
    setUnconfirmedEmail(false);
  };

  return (
    <AuthenticationContext.Provider
      value={{
        nameValue,
        passwordValue,
        passwordRequirements,
        handleChangePassword,
        handleChangeName,
        handleChangeEmail,
        invalidPassword,
        emailAlreadyExists,
        invalidEmailError,
        invalidNameError,
        handleRegisterAccount,
        handleLoginUser,
        loadingAuthentication,
        checkYourEmailState,
        handleActivateAccount,
        authenticatedUser,
        setCheckYourEmailState: (val: boolean) => setCheckYourEmailState(val),
        resetFields,
        handleLogout,
        handleCheckPasswordRequirements,
        updateAuthenticatedUserName,
        handleSubmitNewPassword,
        showResetLinkModal,
        loadingSubmitForgotPassword,
        handleResetPassword,
        setShowResetLinkModal,
        emailError,
        handleSocialLogin,
        handleSocialAuth,
        handleSocialAccountExists,
        socialAccountExists,
        unconfirmedEmail,
        resetErrors,
        isManualLogout,
        userDetails,
        setUserDetails,
      }}
    >
      {props.children}
    </AuthenticationContext.Provider>
  );
};
