import { Stack, Typography } from '@mui/material';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import CharitiesSelector from '../../../../components/charities-selector/CharitiesSelector';
import { DonationFlowContext } from '../../../../providers/donation-flow-provider';
import SelectCharitiesSkeleton from './SelectCharitiesSkeleton';
import { sortSelectedCharities } from '../../DonationFlow.utils';
import type { TCharity } from '../../../../models/Charities';
import { DonationFromWidgetContext } from '../../../../providers/donation-from-widget-provider';
import CharitiesSearchbar from './CharitiesSearchbar';
import CharitiesFilter from './CharitiesFilter';

export const SelectCharitiesStep = () => {
  const { t } = useTranslation();
  const {
    allCharities: { charities },
    selectedCharities,
    handleSelectCharity,
    handleRemoveCharity,
    loadingData,
  } = useContext(DonationFlowContext);
  const { preselectedCharitiesFromWidget } = useContext(DonationFromWidgetContext);
  const [allCharitiesSorted, setAllCharitiesSorted] = useState<TCharity[] | undefined>();
  const [filteredCharities, setFilteredCharities] = useState<TCharity[]>(charities);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [selectedFilters, setSelectedFilters] = useState<string[]>([]);

  const handleFilterSearchCharities = useCallback(
    (searchTerm: string, filters: string[]) => {
      const searchedCharities = charities.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      );

      if (filters.length > 0) {
        const matchedCharities = searchedCharities.filter((charity) => {
          return (
            charity.categories &&
            charity.categories.some((category) => filters.includes(category.name))
          );
        });
        setFilteredCharities(matchedCharities);
      } else {
        setFilteredCharities(searchedCharities);
      }
    },
    [searchTerm, selectedFilters]
  );

  useEffect(() => {
    handleFilterSearchCharities(searchTerm, selectedFilters);
  }, [searchTerm, selectedFilters]);

  useEffect(() => {
    if (preselectedCharitiesFromWidget?.length && charities) {
      const charitiesSorted = sortSelectedCharities(charities, selectedCharities);
      setAllCharitiesSorted(charitiesSorted);
    }
  }, [selectedCharities.length && preselectedCharitiesFromWidget?.length, charities]);

  useEffect(() => {
    setFilteredCharities(charities);
  }, [charities]);

  return loadingData ? (
    <SelectCharitiesSkeleton />
  ) : (
    <Stack
      width={{ mobile: '100%', laptop: '70%' }}
      maxWidth={'1216px'}
      mx={{ mobile: '0', laptop: '32px' }}
      px={{ mobile: '20px', tablet: '40px', laptop: 0 }}
    >
      <Typography variant="h3" pt={2} pb={4}>
        {t('whichCharitiesToAdd')}
      </Typography>
      <Stack direction="row" gap={{ mobile: 1, tablet: 3 }}>
        <CharitiesSearchbar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <CharitiesFilter
          selectedFilters={selectedFilters}
          setSelectedFilters={setSelectedFilters}
        />
      </Stack>
      <CharitiesSelector
        selectedCharities={selectedCharities}
        charities={allCharitiesSorted?.length ? allCharitiesSorted : filteredCharities}
        onRemoveFromSelection={handleRemoveCharity}
        onSelectCharity={handleSelectCharity}
        selectionText={t('addButton')}
        removeSelectionText={t('remove')}
        logoOnly={false}
      />
    </Stack>
  );
};

export default SelectCharitiesStep;
