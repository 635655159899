import { Stack, Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { MyGiftShiftContext } from '../../../../providers/my-giftshift-provider';
import { useContext, useState } from 'react';
import { NotificationsContext } from '../../../../providers/notifications-provider';
import LoadingButton from '../../../../components/loading-button/LoadingButton';
import { textColor } from '../../../../styles/variables';

export const RenderPasswordField = ({
  updateRenderStates,
  isSocialAccount,
}: {
  updateRenderStates: () => void;
  isSocialAccount: boolean | undefined;
}) => {
  const { handleSendResetPasswordLink } = useContext(MyGiftShiftContext);
  const { setNotification } = useContext(NotificationsContext);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const onSuccess = () => {
    updateRenderStates();
    setLoading(false);
    setNotification({ message: t('youHaveReceivedAnEmail'), type: 'success' });
  };

  const handleSave = () => {
    setLoading(true);
    handleSendResetPasswordLink(onSuccess);
  };

  return (
    <Stack gap="8px">
      <Typography variant="paragraphM" color={textColor}>
        {!isSocialAccount ? t('youCanChangeYourPasswordViaEmail') : t('youHaveSetNoPassword')}
      </Typography>

      {!isSocialAccount && (
        <Box width="fit-content">
          <LoadingButton
            variant="contained"
            disabled={loading}
            loading={loading}
            color="primary"
            sx={{ width: 'fit-content' }}
            onClick={() => handleSave()}
          >
            {t('sendResetEmail')}
          </LoadingButton>
        </Box>
      )}
    </Stack>
  );
};
