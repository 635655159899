export const AnalysisAndPerformanceIcon = () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="21.7" cy="34.3" r="21.7" fill="#FFBCBC" />
    <path
      d="M49.75 43.75L40.6875 34.6875M28.9167 12.5C34.6696 12.5 39.3333 17.1637 39.3333 22.9167M45.5833 22.9167C45.5833 32.1214 38.1214 39.5833 28.9167 39.5833C19.7119 39.5833 12.25 32.1214 12.25 22.9167C12.25 13.7119 19.7119 6.25 28.9167 6.25C38.1214 6.25 45.5833 13.7119 45.5833 22.9167Z"
      stroke="#000CFF"
      strokeWidth="4.17"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export default AnalysisAndPerformanceIcon;
