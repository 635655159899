import { Box, Button, CircularProgress, type ButtonProps } from '@mui/material';

type LoadingButtonProps = ButtonProps & {
  loading?: boolean;
};

export const LoadingButton = ({ loading = false, ...props }: LoadingButtonProps) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Button {...props} />
      {loading && (
        <CircularProgress
          size={24}
          color={props.color}
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            marginTop: '-12px',
            marginLeft: '-12px',
          }}
        />
      )}
    </Box>
  );
};

export default LoadingButton;
