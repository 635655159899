import { Box, Stack, Typography } from '@mui/material';
import { disabledColor, successColor } from '../../styles/variables';
import { CheckIcon } from '../../assets/icons';

type CountrySelectorItemProps = {
  itemId: string;
  itemLogo: string;
  itemLabel: string;
  cca2?: string;
  selectedCountry?: string;
  selectedLanguage?: string;
  borderRight?: boolean;
  handleItemClick: (value: string) => void;
};

const CountrySelectorItem = (props: CountrySelectorItemProps) => {
  const {
    itemId,
    itemLogo,
    itemLabel,
    borderRight = false,
    handleItemClick,
    selectedLanguage,
    selectedCountry,
  } = props;

  const countrySelectorItemStyles = {
    alignItems: 'center',
    gap: '10px',
    minWidth: '230px',
    minHeight: '47px',
    padding: '0 15px',
    borderBottom: `1px solid transparent`,
    borderTop: `1px solid transparent`,
    borderRight: `1px solid ${borderRight ? disabledColor : 'transparent'}`,

    '&:hover': {
      borderColor: `${borderRight ? disabledColor : 'transparent'}`,
      borderRightColor: 'transparent',
      cursor: 'pointer',
    },

    '&:last-of-type:hover': {
      borderBottomColor: 'transparent',
    },
  };

  return (
    <Stack
      direction="row"
      key={itemId}
      sx={countrySelectorItemStyles}
      onClick={() => handleItemClick(itemId)}
      data-testid={`selector-item-${itemId}`}
    >
      <Box maxWidth="30px" height="20px" lineHeight="0">
        <img
          loading="lazy"
          style={{ width: '100%', borderRadius: '3.75px', height: '100%' }}
          src={itemLogo}
          alt={`${itemLogo}-logo`}
        />
      </Box>

      <Typography>{itemLabel}</Typography>

      {itemId === selectedLanguage || itemId === selectedCountry ? (
        <Box ml="auto" lineHeight="0">
          <CheckIcon color={successColor} />
        </Box>
      ) : null}
    </Stack>
  );
};

export default CountrySelectorItem;
