import { Card, Divider, Grid, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { cartBgColor, darkBlue, desktopM, errorColor } from '../../styles/variables';

// types
import type { ReactNode } from 'react';
import type { TSelectedCharity } from '../../models/Charities';
import type { TDonationFrequency } from '../../models/Donation';

interface CheckoutCardProps {
  title: string;
  itemsList: TSelectedCharity[];
  totalAmount: number;
  extraInfo?: ReactNode;
  titleTestId?: string;
  couponValue: number | undefined;
  donationFrequency: TDonationFrequency;
}

export const CheckoutCard = ({
  title,
  itemsList,
  totalAmount,
  extraInfo,
  titleTestId,
  couponValue,
  donationFrequency,
}: CheckoutCardProps) => {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        p: { mobile: 1.5, tablet: 3 },
        backgroundColor: cartBgColor,
        borderRadius: '10px',
        boxShadow: 'none',
      }}
    >
      <Stack gap={1.5}>
        <Typography variant="h4" data-testid={titleTestId}>
          {title}
        </Typography>

        <Grid container spacing={{ mobile: 1, tablet: 1 }}>
          {itemsList.map((charity) => (
            <Grid
              key={charity.id}
              item
              mobile={6}
              mobileS={4}
              tablet={12}
              container
              alignItems={'center'}
              justifyContent={{ mobile: 'flex-start', tablet: 'space-between' }}
              gap={{ mobile: 1, tablet: 2 }}
              flexWrap={'nowrap'}
            >
              <Grid item height={'100%'}>
                <Card
                  sx={{
                    width: { mobile: '50px', tablet: '68px' },

                    borderRadius: { mobile: '4px', tablet: '5px' },
                    boxShadow: desktopM,
                    padding: '0',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img src={charity.logo} alt={`${charity.name}-logo`} width={'100%'} />
                </Card>
              </Grid>
              <Grid item>
                <Typography variant="paragraphS">{`€ ${charity.donationAmount}`}</Typography>{' '}
              </Grid>
            </Grid>
          ))}
        </Grid>

        <Divider sx={{ borderStyle: 'dashed', borderColor: darkBlue }} />
        <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Typography variant="h6">{t(couponValue ? 'subtotal' : 'total')}</Typography>
          <Typography
            variant="h6"
            fontWeight={700}
          >{`€ ${totalAmount.toFixed(2).replace('.', ',')}`}</Typography>
        </Stack>
        {couponValue && donationFrequency !== 'monthly' ? (
          <>
            <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography variant="h6">{t('code')}</Typography>
              <Typography
                variant="h6"
                fontWeight={700}
              >{`- € ${couponValue.toFixed(2).replace('.', ',')}`}</Typography>
            </Stack>

            <Divider sx={{ borderStyle: 'dashed', borderColor: darkBlue }} />

            <Stack flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
              <Typography variant="h4">{t('total')}</Typography>
              <Typography
                variant="h4"
                fontWeight={700}
                color={totalAmount - couponValue < 0 ? errorColor : darkBlue}
              >{`€ ${(totalAmount - couponValue).toFixed(2).replace('.', ',')}`}</Typography>
            </Stack>
          </>
        ) : null}
        {extraInfo}
      </Stack>
    </Card>
  );
};

export default CheckoutCard;
