import { Box, Button, Popover, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { ChevronDownIcon } from '../../assets/icons';
import { LanguageContext } from '../../providers/language-selector-provider';
import { handleChangeRoute } from '../../providers/utils';
import { desktopM, disabledColor } from '../../styles/variables';
import CountrySelectorItem from './CountrySelectorItem';

const CountrySelector = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedCountryLogo, setSelectedCountryLogo] = useState<string | undefined>(
    '/country-flags/nl.png'
  );
  const {
    languageOptions,
    allCountries,
    selectedCountry,
    setSelectedCountry,
    selectedLanguage,
    setSelectedLanguage,
  } = useContext(LanguageContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const pathname = location.pathname;

  /* Utils */
  const navigateToPathByLanguage = (language: string, enPath: string, nlPath: string) =>
    language === 'en' ? navigate(enPath) : navigate(nlPath);

  const matchPathToNavigate = (path: string, language: string) => {
    const params = [...searchParams];

    switch (path) {
      case '/my-giftshift':
      case '/mijn-giftshift':
        navigateToPathByLanguage(language, '/my-giftshift', '/mijn-giftshift');
        break;
      case '/thank-you':
      case '/bedankt': {
        const [iDealKeyParam, iDealValueParam] = params[0];
        navigateToPathByLanguage(
          language,
          `/thank-you?${iDealKeyParam}=${iDealValueParam}`,
          `/bedankt?${iDealKeyParam}=${iDealValueParam}`
        );
        break;
      }
      case '/donate':
      case '/doneren':
        navigateToPathByLanguage(language, '/donate', '/doneren');
        break;
      case '/register':
      case '/registreren':
        navigateToPathByLanguage(language, '/register', '/registreren');
        break;
    }
  };

  /* Handlers */
  const handleOnLanguageChange = (value: string) => {
    matchPathToNavigate(pathname, value);
    setSelectedLanguage('');
    handleChangeRoute(selectedCountry, value);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) =>
    setAnchorEl(event.currentTarget);

  const handleClosePopover = () => setAnchorEl(null);

  const handleSelectCountry = (countryId: string) => {
    setSelectedCountry('');
    //Preferred country overwrites the newly selected country
    handleChangeRoute(countryId, selectedLanguage);
  };

  const handleSelectLanguage = (languageId: string) => {
    setSelectedLanguage(languageId);
    handleOnLanguageChange(languageId);
  };

  const openPopover = Boolean(anchorEl);
  const popoverId = openPopover ? 'country-popover' : undefined;

  const popoverStyles = {
    '.MuiPopover-paper': {
      boxShadow: desktopM,
      borderRadius: '8px',
    },
  };

  useEffect(() => {
    const countryLogoString = `/country-flags/${selectedCountry}.png`;
    setSelectedCountryLogo(countryLogoString);
  }, [selectedCountry]);

  return (
    <>
      <Button
        aria-describedby={popoverId}
        onClick={handleOpenPopover}
        variant="text"
        sx={{ '&:focus': { borderColor: 'transparent' } }}
        disableRipple
      >
        <Box width="30px" height="20px" mr="8px">
          <img
            loading="lazy"
            width="100%"
            height="100%"
            style={{ borderRadius: '3.75px' }}
            src={selectedCountryLogo}
            alt={`${selectedCountryLogo}-logo`}
          />
        </Box>
        <ChevronDownIcon />
      </Button>
      <Popover
        id={popoverId}
        open={openPopover}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        sx={popoverStyles}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Stack direction="row">
          <Stack>
            <Typography variant="h5" p="15px" borderRight={`1px solid ${disabledColor}`}>
              Country
            </Typography>
            <Stack>
              {allCountries?.map((country) => (
                <CountrySelectorItem
                  key={country.alias}
                  itemLabel={country.country}
                  itemLogo={country.flag}
                  itemId={country.alias}
                  borderRight={true}
                  handleItemClick={handleSelectCountry}
                  selectedCountry={selectedCountry}
                />
              ))}
            </Stack>
          </Stack>

          <Stack pl="10px">
            <Typography variant="h5" p="15px">
              Language
            </Typography>
            {languageOptions.map((language) => {
              if (language) {
                return (
                  <CountrySelectorItem
                    key={language.id}
                    itemLabel={language.label}
                    itemLogo={language.logo}
                    itemId={language.id}
                    handleItemClick={handleSelectLanguage}
                    selectedLanguage={selectedLanguage}
                    cca2={language.cca2}
                  />
                );
              }
            })}
          </Stack>
        </Stack>
      </Popover>
    </>
  );
};

export default CountrySelector;
