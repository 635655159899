import { Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MyGiftShiftContext } from '../../../../../providers/my-giftshift-provider';
import BundleCardGridItem from '../BundleCardGridItem';
import ConfirmPauseDialog from '../confirmation-dialogs/ConfirmPauseDialog';

export const FrequencySection = () => {
  const { t } = useTranslation();
  const { bundleInfo } = useContext(MyGiftShiftContext);

  const [openConfirmPauseDialog, setOpenConfirmPauseDialog] = useState(false);

  return (
    <>
      <BundleCardGridItem
        title={t('frequency')}
        content={<Typography variant="paragraphM">{t('monthly')}</Typography>}
        actionLabel={bundleInfo.status === 'active' ? t('pause') : undefined}
        handleAction={() => {
          setOpenConfirmPauseDialog(true);
        }}
      />
      <ConfirmPauseDialog
        open={openConfirmPauseDialog}
        onClose={() => {
          setOpenConfirmPauseDialog(false);
        }}
      />
    </>
  );
};

export default FrequencySection;
