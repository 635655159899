import type { CountryCharities, TCharity, TSelectedCharity } from '../../models/Charities';
import { darkBlue, textColor } from '../../styles/variables';

export const renderCharitiesSelectionInfo = (
  charities: TSelectedCharity[],
  andLabel: string,
  charitiesLabel: string
) => {
  switch (charities.length) {
    case 0: {
      return '';
    }
    case 1: {
      return <strong style={{ color: darkBlue }}>{charities[0].name}</strong>;
    }
    case 2: {
      return (
        <>
          <strong style={{ color: darkBlue }}>{charities[0].name} </strong>
          {` ${andLabel} `}
          <strong style={{ color: darkBlue }}>{charities[1].name} </strong>
        </>
      );
    }
    default: {
      return <strong style={{ color: darkBlue }}>{`${charities.length} ${charitiesLabel}`}</strong>;
    }
  }
};

export const renderAllCharitiesNames = (charities: TSelectedCharity[], andLabel: string) => {
  switch (charities.length) {
    case 0: {
      return '';
    }
    case 1: {
      return <strong style={{ color: textColor }}>{charities[0].name}</strong>;
    }
    case 2: {
      return (
        <>
          <strong style={{ color: textColor }}>{charities[0].name} </strong>
          {` ${andLabel} `}
          <strong style={{ color: textColor }}>{charities[1].name} </strong>
        </>
      );
    }
    default: {
      return (
        <>
          <strong style={{ color: textColor }}>{charities[0].name}</strong>
          {charities.slice(1, -1).map((charity) => (
            <strong key={charity.id} style={{ color: textColor }}>
              , {charity.name}
            </strong>
          ))}
          {` ${andLabel} `}
          <strong style={{ color: textColor }}>{charities[charities.length - 1].name} </strong>
        </>
      );
    }
  }
};

export const DonationSteps = {
  SelectCharities: 1,
  FrequencyAndAmount: 2,
  Authentication: 3,
  SummaryAndPayment: 4,
  CheckExistingBundleStep: 5,
};

export const removeCausesDuplicates = (array: TSelectedCharity[]) => {
  const uniqueIds: string[] = [];

  const currentSelectionFirst = [...array].sort((a: TSelectedCharity) =>
    a.isFromCurrentBundle ? 1 : -1
  );

  const unique = currentSelectionFirst.filter((element) => {
    const isDuplicate = uniqueIds.includes(element.id);

    if (!isDuplicate) {
      uniqueIds.push(element.id);
      return true;
    }

    return false;
  });
  return unique;
};

export const formatNextDebitDate = (date: string, lng: string) => {
  const nextPayment = new Date(date);
  const nextDay = nextPayment.getDate();
  const nextMonth = nextPayment.getMonth();
  const nextYear = nextPayment.getFullYear();

  const nextDonationDate = new Date(Date.UTC(nextYear, nextMonth, nextDay));

  const formattingOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  };
  return nextDonationDate.toLocaleDateString(lng, formattingOptions);
};

export const getCharitiesFromIds = (
  all: TCharity[],
  ids: string[] | undefined,
  amounts?: string[] | undefined
) => {
  const preselectedValues: TSelectedCharity[] = [];
  const charitiesWithAmounts: Record<string, string> = {};

  ids?.forEach((id, index) => {
    charitiesWithAmounts[`${id}`] = amounts?.[index] || '';
  });

  if (ids) {
    all.forEach((item) => {
      if (ids.includes(item.id)) {
        preselectedValues.push({ ...item, donationAmount: charitiesWithAmounts[item.id] });
      }
    });
  }
  return preselectedValues;
};

export const mapCharities = (listFromResponse: CountryCharities) => {
  const charitiesWithLogos = listFromResponse?.charities.map((item) => ({
    ...item,
    logo: item.s3Url,
  }));

  return { charities: charitiesWithLogos, country: listFromResponse.country };
};

export const mapCurrentBundle = (listFromResponse: TSelectedCharity[]) =>
  listFromResponse.map((item) => ({
    ...item,
    donationAmount: item.donationAmount?.toString(),
    isFromCurrentBundle: true,
  }));

export const sortSelectedCharities = (
  allCharitiesArray: TCharity[],
  selectedCharitiesArray: TSelectedCharity[]
) => {
  const charitiesSorted = [
    ...allCharitiesArray.sort((a) => {
      const selectedCharity = selectedCharitiesArray.find((charity) => charity.id === a.id);

      return selectedCharity ? -1 : 1;
    }),
  ];

  return charitiesSorted;
};
