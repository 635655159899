import { axiosPrivate } from '../common/axiosPrivate';
import { axiosPublic } from '../common/axiosPublic';
import { clearBrowserCookies } from '../common/utils';

export interface TRegisterUser {
  name: string;
  email: string;
  password: string;
  newsletter: boolean;
}
interface TLoginUser {
  email: string;
  password: string;
}

export const registerUser = async (body: TRegisterUser) =>
  await axiosPublic.post('/auth/register/', body);

export const loginUser = async (body: TLoginUser) => await axiosPublic.post('/auth/login/', body);

export const activateAccount = async (activationCode: string) =>
  await axiosPublic.post(`/auth/${activationCode}`);

export const getSocialAuthLink = async (socialType: 'google' | 'facebook') =>
  await axiosPublic.get(`/social-auth/${socialType}-redirect/`);

export const socialLogin = async () =>
  await axiosPublic.get('/social-auth/retrieve-social-tokens/');

export const clearHttpOnlyCookies = async () => {
  await axiosPrivate.post('/auth/logout/').finally(() => {
    clearBrowserCookies();
  });
};
