import { Grid, Box } from '@mui/material';
import { useContext, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SidebarImg from '../../assets/RegisterSideBarImg.png';

import LogoLight from '../../assets/LogoLightSVG';
import { AuthenticationContext } from '../../providers/authentication-provider';
import EmailReceivedContent from './EmailReceivedContent';
import RegistrationContent from './RegistrationContent';
import { LanguageContext } from '../../providers/language-selector-provider';
import { config } from '../../api/config';

export const RegisterPage = () => {
  const { checkYourEmailState, setCheckYourEmailState, resetFields } =
    useContext(AuthenticationContext);
  const { isEnglish } = useContext(LanguageContext);

  useEffect(() => {
    return () => {
      resetFields();
      setCheckYourEmailState(false);
    };
  }, []);

  return (
    <Grid
      container
      sx={{
        height: { mobile: '100%', laptop: '100vh' },
        overflow: { mobile: 'scroll', laptop: 'hidden' },
      }}
    >
      <Grid item mobile={0} laptop={6} display={{ mobile: 'none', laptop: 'inline-block' }}>
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
          }}
        >
          <img
            src={SidebarImg}
            alt="sidebar-graphic"
            width="100%"
            style={{ objectFit: 'cover', objectPosition: 'top', height: '100vh' }}
          />
          <Box sx={{ position: 'absolute', top: '56px', left: '58px' }}>
            <Link to={isEnglish ? `${config.FE_APP_URL}/en/` : `${config.FE_APP_URL}`}>
              <LogoLight />
            </Link>
          </Box>
        </Box>
      </Grid>
      {checkYourEmailState ? <EmailReceivedContent /> : <RegistrationContent />}
    </Grid>
  );
};

export default RegisterPage;
