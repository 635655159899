import type { SelectChangeEvent } from '@mui/material';
import { Box, Button, Card, Divider, Grid, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import {
  darkBlue,
  desktopL,
  disabledColor,
  primaryColor,
  textColor,
  white,
} from '../../styles/variables';
import Widget from '../widgets/Widget';

import { config } from '../../api/config';
import { type TSelectedCharity } from '../../models/Charities';
import { LanguageContext } from '../../providers/language-selector-provider';
import Dropdown from '../dropdown/Dropdown';
import LanguageSelectorItem from '../language-selector/LanguageSelectorItem';
import LoadingButton from '../loading-button/LoadingButton';
import { TWidget } from '../widgets/types';
import {
  hasChangedCharities,
  isFieldChanged,
  openColorPickerDefaultValues,
  tranformImageForPreview,
} from './helpers';
import ButtonSection from './sections/ButtonSection';
import CharitiesSection from './sections/CharitiesSection';
import HeadingsSection from './sections/HeadingsSection';
import ImagesSection from './sections/ImagesSection';
import type {
  TWidgetText,
  TColorPickerOpen,
  WidgetConfiguratorProps,
  TWidgetTextErrors,
} from './types';
import { TextContentSection } from './sections/TextContentSection';

export const WidgetConfigurator = ({
  allCharities = [],
  logos = [],
  partners,
  selectedPartner,
  currentWidgetData,
  setPartner,
  onSaveWidgetData,
  onCopy,
  isLoadingSaveData,
}: WidgetConfiguratorProps) => {
  const [displayedCharities, setDisplayedCharities] = useState<TSelectedCharity[]>();
  const [variant, setVariant] = useState<number>(1);
  const [buttonBgColor, setButtonBgColor] = useState<string>(primaryColor);
  const [buttonTextColor, setButtonTextColor] = useState<string>(white);
  const [headingColor, setHeadingColor] = useState<string>(darkBlue);
  const [subHeadingColor, setSubHeadingColor] = useState<string>(textColor);
  const [buttonBorderRadius, setButtonBorderRadius] = useState<number>(6);
  const [image, setImage] = useState<File | Blob | string | undefined>(undefined);
  const [previewImage, setPreviewImage] = useState<File | Blob | string>();
  const { languageOptions, selectedCountry, selectedLanguage } = useContext(LanguageContext);
  const [language, setLanguage] = useState(selectedLanguage);
  const [changedFields, setChangedFields] = useState<boolean>(false);
  const [isColorPickerOpen, setIsColorPickerOpen] = useState<TColorPickerOpen>(
    openColorPickerDefaultValues
  );
  const [widgetTextState, setWidgetTextState] = useState<TWidgetText>({
    headingText: '',
    subHeadingText: '',
    buttonText: '',
  });

  const [widgetTextErrors, setWidgetTextErrors] = useState<TWidgetTextErrors>({
    headingText: false,
    subHeadingText: false,
    buttonText: false,
  });

  const resetWidgetTextErrors = () => {
    setWidgetTextErrors({
      headingText: false,
      subHeadingText: false,
      buttonText: false,
    });
  };

  const formatSrc = () => {
    return `${config.FE_APP_URL}/${selectedCountry}/${selectedLanguage}/widget?pid=${selectedPartner}`;
  };

  useEffect(() => {
    if (currentWidgetData) {
      const {
        variant,
        buttonBgColor,
        buttonTextColor,
        headingColor,
        subHeadingColor,
        buttonBorderRadius,
        image,
        charities,
        headingText,
        subHeadingText,
        buttonText,
      } = currentWidgetData;

      setDisplayedCharities(charities);
      setVariant(variant);
      setButtonBgColor(buttonBgColor);
      setButtonTextColor(buttonTextColor);
      setHeadingColor(headingColor);
      setSubHeadingColor(subHeadingColor);
      setButtonBorderRadius(buttonBorderRadius);
      setImage(image);
      setWidgetTextState({ headingText, subHeadingText, buttonText });
      setChangedFields(false);
    } else {
      setChangedFields(true);
    }
  }, [currentWidgetData]);

  const generateWidgetCode = () => `
  <script>
    window.addEventListener('message', (e) => {
        const iframe = document.querySelector('#gs-widget')
        const message = e.data;
        if (message.height) {
          iframe.height = message.height + 'px';
          iframe.style.minHeight = message.height + 'px'
      }
    }, false);
  </script>
  <iframe
    id="gs-widget"
    title="GiftShiftWidget" 
    src="${formatSrc()}"
    style="border: 0" 
    width="100%" height="100%">
  </iframe>`;

  const handleCopyToClipboard = async () => {
    const code = generateWidgetCode();
    await navigator.clipboard.writeText(code);
    onCopy && onCopy();
  };

  const handleSave = async () => {
    if (!widgetTextState.buttonText) {
      setWidgetTextErrors((prevState) => {
        return { ...prevState, buttonText: true };
      });
      return;
    }
    const formDataImage = new FormData();
    if (image && previewImage) {
      const imageFile = image as File;
      formDataImage.append(
        'image',
        imageFile,
        imageFile.name.replace(/\s+/g, '').replace(/\s*[\\[{(].*?[)}\]]\s*/g, '')
      );
    }

    onSaveWidgetData(
      {
        ...widgetTextState,
        variant,
        buttonBgColor,
        buttonBorderRadius,
        buttonTextColor,
        headingColor,
        subHeadingColor,
        charities: displayedCharities?.map((item) => item.id) || [],
        partnerId: selectedPartner,
        language,
      },
      image && previewImage ? formDataImage : undefined
    ).then(() => setPreviewImage(undefined));
  };

  const type3HasImage = (variant === TWidget.Type3 && image) || variant !== TWidget.Type3;

  const hasCharities =
    ((variant === TWidget.Type2 || variant === TWidget.Type3) && !!displayedCharities?.length) ||
    variant === TWidget.Type1;

  const disableSaveButton = isLoadingSaveData || !changedFields || !hasCharities || !type3HasImage;

  return (
    <Stack gap={5} alignItems={'center'}>
      <Grid container spacing={5}>
        <Grid
          item
          container
          mobile={12}
          laptop={6}
          justifyContent={{ mobile: 'center', laptop: 'flex-start' }}
        >
          <Stack gap={3} width={'100%'} maxWidth={'800px'}>
            <Typography variant="h2">Configure partner widget</Typography>
            <Card
              sx={{
                margin: '4px auto 0px',
                borderRadius: '12px',
                width: '100%',
                maxWidth: '800px',
                padding: '24px 20px',
                alignSelf: 'stretch',
                display: 'flex',
                flexDirection: 'column',
                gap: 3,
                backgroundColor: white,
                boxShadow: desktopL,
                overflow: 'unset',
              }}
            >
              <Typography variant="h1" fontWeight={400}>
                Widget Configurator
              </Typography>
              <Divider />
              <Dropdown
                labelValue="Partner"
                labelColor={darkBlue}
                labelVariant="h2"
                options={[{ id: '', label: 'None', name: 'None' }, ...partners]}
                onChange={(e) => {
                  setPartner(e.target.value as string);
                  resetWidgetTextErrors();
                }}
                variant="outlined"
              />
              <Divider />
              <Dropdown
                labelValue="Widget type"
                labelColor={darkBlue}
                labelVariant="h2"
                optionsFontWeight="600"
                value={variant}
                options={[
                  { label: 'Widget 1', id: TWidget.Type1 },
                  { label: 'Widget 2', id: TWidget.Type2 },
                  { label: 'Widget 3', id: TWidget.Type3 },
                ]}
                onChange={(e) => {
                  if (e.target.value !== TWidget.Type3) {
                    setImage(undefined);
                  } else {
                    setImage(currentWidgetData?.image);
                  }

                  if (isFieldChanged(currentWidgetData?.variant, e.target.value as number)) {
                    setChangedFields(true);
                  }

                  setVariant(e.target.value as number);
                  resetWidgetTextErrors();
                }}
                variant="outlined"
              />
              <Divider />
              <TextContentSection
                widgetTextState={widgetTextState}
                setWidgetTextState={setWidgetTextState}
                widgetTextErrors={widgetTextErrors}
                setWidgetTextErrors={setWidgetTextErrors}
                setChangedFields={setChangedFields}
              />
              <Divider />
              <HeadingsSection
                headingColor={headingColor}
                subheadingColor={subHeadingColor}
                isColorPickerOpen={isColorPickerOpen}
                setColorPickerOpen={setIsColorPickerOpen}
                setHeadingColor={(val) => {
                  if (isFieldChanged(currentWidgetData?.headingColor, val)) {
                    setChangedFields(true);
                  }
                  setHeadingColor(val);
                }}
                setSubheadingColor={(val) => {
                  if (isFieldChanged(currentWidgetData?.subHeadingColor, val)) {
                    setChangedFields(true);
                  }
                  setSubHeadingColor(val);
                }}
              />
              <Divider />
              <ButtonSection
                buttonBgColor={buttonBgColor}
                buttonBorderRadius={buttonBorderRadius}
                buttonTextColor={buttonTextColor}
                isColorPickerOpen={isColorPickerOpen}
                setColorPickerOpen={setIsColorPickerOpen}
                setButtonBgColor={(val) => {
                  if (isFieldChanged(currentWidgetData?.buttonBgColor, val)) {
                    setChangedFields(true);
                  }
                  setButtonBgColor(val);
                }}
                setButtonBorderRadius={(val) => {
                  if (isFieldChanged(currentWidgetData?.buttonBorderRadius, val)) {
                    setChangedFields(true);
                  }
                  setButtonBorderRadius(val);
                }}
                setButtonTextColor={(val) => {
                  if (isFieldChanged(currentWidgetData?.buttonTextColor, val)) {
                    setChangedFields(true);
                  }

                  setButtonTextColor(val);
                }}
              />
              <Grid container justifyContent="space-between" alignItems="center">
                <Typography variant="h3">Language</Typography>
                <LanguageSelectorItem
                  lightMode={true}
                  onChange={(e) => {
                    const {
                      target: { value },
                    } = e as SelectChangeEvent<string>;
                    setLanguage(value);

                    if (isFieldChanged(language, value)) {
                      setChangedFields(true);
                    }
                  }}
                  selectedLanguage={language}
                  languageOptions={languageOptions}
                  matchLanguageLogo={(value: string) =>
                    languageOptions.find((item) => item?.id === value)?.logo
                  }
                  labelColor={darkBlue}
                />
              </Grid>
              {variant !== TWidget.Type1 ? (
                <>
                  <Divider />
                  <CharitiesSection
                    variant={variant}
                    displayedCharities={displayedCharities}
                    allCharities={allCharities}
                    setDisplayedCharities={(newCharities) => {
                      setChangedFields(
                        hasChangedCharities(currentWidgetData?.charities, newCharities)
                      );

                      setDisplayedCharities(newCharities);
                    }}
                  />
                </>
              ) : null}
              {variant === TWidget.Type3 && (
                <>
                  <Divider />
                  <ImagesSection
                    image={image}
                    previewImage={previewImage}
                    onChangeImage={(val) => {
                      setChangedFields(true);
                      setImage(val);
                      if (val) {
                        tranformImageForPreview(val, setPreviewImage);
                      }
                    }}
                  />
                  <Divider />
                </>
              )}
              <LoadingButton
                size="large"
                variant="contained"
                onClick={handleSave}
                loading={isLoadingSaveData}
                disabled={disableSaveButton}
                fullWidth
              >
                Save data and generate link
              </LoadingButton>
            </Card>
          </Stack>
        </Grid>
        <Grid
          item
          mobile={12}
          laptop={6}
          container
          justifyContent={{ mobile: 'center', laptop: 'flex-end' }}
        >
          <Stack
            gap={3}
            width={'100%'}
            sx={{
              maxWidth: {
                mobile: '100%',
                tablet: '800px',
              },
            }}
          >
            <Typography variant="h2">Preview</Typography>
            <Widget
              variant={variant}
              image={image}
              previewImage={previewImage}
              buttonBgColor={buttonBgColor}
              buttonBorderRadius={buttonBorderRadius || undefined}
              buttonTextColor={buttonTextColor}
              headingColor={headingColor}
              subHeadingColor={subHeadingColor}
              charities={displayedCharities}
              logos={logos}
              partnerId={selectedPartner?.toString()}
              language={language}
              headingText={widgetTextState.headingText}
              subHeadingText={widgetTextState.subHeadingText}
              buttonText={widgetTextState.buttonText}
              selectedCountry={selectedCountry}
              selectedLanguage={selectedLanguage}
            />
          </Stack>
          <Card
            sx={{
              p: 3,
              borderRadius: '12px',
              boxShadow: desktopL,
              marginTop: '40px',
              maxWidth: {
                mobile: '100%',
                tablet: '800px',
              },
            }}
          >
            <Stack gap={4}>
              <Typography variant="h2">Code</Typography>
              {selectedPartner && (
                <Stack gap={1}>
                  <Typography variant="h5">How to:</Typography>
                  <Typography variant="paragraphL">
                    Copy the code below and insert it in partner's HTML code. (Only works if it is
                    saved first)
                  </Typography>
                </Stack>
              )}
              {selectedPartner ? (
                <Box sx={{ background: disabledColor, p: 3, borderRadius: '12px' }}>
                  <Stack gap={2}>
                    <Typography sx={{ wordBreak: 'break-all' }}>
                      <code>{generateWidgetCode()}</code>
                    </Typography>
                    <Grid container justifyContent={'flex-end'}>
                      <Button color="info" variant="outlined" onClick={handleCopyToClipboard}>
                        Copy
                      </Button>
                    </Grid>
                  </Stack>
                </Box>
              ) : (
                <Typography>
                  Please select a partner first. After that, save changes before sending this code
                  to the partner.
                </Typography>
              )}
            </Stack>
          </Card>
        </Grid>

        <Grid item mobile={12} container justifyContent={'center'}>
          <Stack
            gap={3}
            width={'100%'}
            sx={{
              maxWidth: {
                mobile: '100%',
                tablet: '800px',
                laptop: '100%',
              },
            }}
          >
            <Card sx={{ p: 3, borderRadius: '12px', boxShadow: desktopL }}>
              <Stack gap={4}>
                <Typography variant="h2">Current widget </Typography>
                <Stack gap={1}>
                  <Typography variant="paragraphL">
                    Here is a preview of the widget that this partner has.
                  </Typography>
                </Stack>
                {currentWidgetData && (
                  <Widget
                    {...currentWidgetData}
                    logos={logos}
                    selectedCountry={selectedCountry}
                    selectedLanguage={selectedLanguage}
                  />
                )}
              </Stack>
            </Card>
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default WidgetConfigurator;
