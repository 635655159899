import {
  Card,
  Divider,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  type Theme,
} from '@mui/material';
import { Fragment, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../../../components/loading-button/LoadingButton';
import { LanguageContext } from '../../../../providers/language-selector-provider';
import { MyGiftShiftContext } from '../../../../providers/my-giftshift-provider';
import { desktopXXL, white } from '../../../../styles/variables';
import { formatCharitiesOverview, formatDateToLocaleString } from '../../MyGiftShift.utils';
import InfoCardDark from './components/InfoCardDark';
import OverviewRowGridItem from './components/OverviewRowGridItem';
import { useInView } from 'react-intersection-observer';

export const DonationsOverviewCard = (props: {
  hash: string;
  onChangeTab: (tabValue: string) => void;
}) => {
  const {
    loadingData,
    donationsOverviewList,
    donationOverviewDetails,
    donationsOverviewTotalCount,

    handleLoadMoreDonations,
  } = useContext(MyGiftShiftContext);
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('tablet'));
  const { selectedLanguage } = useContext(LanguageContext);
  const [loadingLoadMore, setLoadingLoadMore] = useState(false);
  const sectionId = 'overview';
  const { ref: inViewRef } = useInView({
    initialInView: props.hash === sectionId,
    onChange: (isInView) => {
      if (isInView) {
        props.onChangeTab(sectionId);
      }
    },
    threshold: 0.3,
  });

  const onLoadMoreData = () => {
    setLoadingLoadMore(true);
    handleLoadMoreDonations(donationsOverviewList.length).then(() => {
      setLoadingLoadMore(false);
    });
  };

  return loadingData ? (
    <Skeleton
      id={sectionId}
      variant="rounded"
      width={'100%'}
      sx={{
        scrollMarginTop: { mobile: '172px', tablet: '192px', laptop: '161px' },
        scrollMarginBlockStart: { mobile: '172px', tablet: '192px', laptop: '161px' },
        height: { mobile: '695px', tablet: '514px', laptop: '621px' },
      }}
      ref={inViewRef}
    />
  ) : (
    <Card
      id={sectionId}
      role="tabpanel"
      sx={{
        backgroundColor: white,
        boxShadow: desktopXXL,
        borderRadius: '12px',
        overflow: 'initial',
        padding: { mobile: 3, laptop: 4 },
        scrollMarginTop: { mobile: '172px', tablet: '192px', laptop: '161px' },
        scrollMarginBlockStart: { mobile: '172px', tablet: '192px', laptop: '161px' },
      }}
      ref={inViewRef}
    >
      <Stack gap={{ mobile: '12px', tablet: 2, laptop: 3 }}>
        <Typography variant="h2">{t('myDonationOverview')}</Typography>
        <Stack flexDirection={{ mobile: 'column', tablet: 'row' }} gap={2}>
          <InfoCardDark
            label={t('totalDonated')}
            value={`€${donationOverviewDetails?.totalDonatedAmount}`}
          />
          <InfoCardDark
            label={t('totalDonatedTo', { charity: donationOverviewDetails?.maxDonationCharity })}
            value={`€${donationOverviewDetails?.maxTotalDoantionForCharity}`}
          />
          <InfoCardDark
            label={t('lastDonation')}
            value={donationOverviewDetails?.lastDonationDate || ''}
          />
        </Stack>
        {!isMobile ? (
          <>
            <Divider />
            <OverviewRowGridItem
              date={t('date')}
              content={
                <Typography variant="h6" lineHeight={'150%'}>
                  {t('details')}
                </Typography>
              }
              total={t('total')}
            />
          </>
        ) : null}
        <Divider />
        {donationsOverviewList.map((donation) => (
          <Fragment key={donation.id}>
            <OverviewRowGridItem
              date={formatDateToLocaleString(donation.date, selectedLanguage)}
              content={
                <Typography variant="paragraphM">
                  {formatCharitiesOverview(donation.charities)}
                </Typography>
              }
              total={`€ ${donation.totalAmount}`}
              status={donation.status}
            />
            <Divider key={`${donation.id}-divider`} />
          </Fragment>
        ))}
        {donationsOverviewTotalCount > donationsOverviewList.length && (
          <LoadingButton
            variant="outlined"
            fullWidth
            disabled={loadingLoadMore}
            loading={loadingLoadMore}
            onClick={onLoadMoreData}
          >
            {t('loadMore')}
          </LoadingButton>
        )}
      </Stack>
    </Card>
  );
};
