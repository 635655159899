import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';

// types
import type { ReactNode } from 'react';

type Dialog = {
  isOpen: boolean;
  handleCloseModal: () => void;
  title: ReactNode;
  content: ReactNode;
  actions: ReactNode;
};

const DialogComponent = ({ isOpen, handleCloseModal, title, content, actions }: Dialog) => {
  return (
    <Dialog open={isOpen} onClose={handleCloseModal}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>{content}</DialogContent>
      <DialogActions>{actions}</DialogActions>
    </Dialog>
  );
};

export default DialogComponent;
