import { useEffect, useContext } from 'react';
import { Stack, Typography, CircularProgress } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { updateSuccessfulPayment } from '../../api/payments';
import { NotificationsContext } from '../../providers/notifications-provider';
import { getLocalAccessToken } from '../../common/utils';
import { LanguageContext } from '../../providers/language-selector-provider';

export const UpdatePaymentMethodPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { setNotification } = useContext(NotificationsContext);
  const { isEnglish } = useContext(LanguageContext);

  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('sessionId');

  const token = getLocalAccessToken();

  const updatePaymentMethod = async (id: string) => {
    await updateSuccessfulPayment(id, token)
      .then(() => {
        setNotification({ type: 'success', message: t('paymentMethodSuccess') });
        window.location.href = isEnglish ? '/my-giftshift' : '/mijn-giftshift';
      })
      .catch(() => {
        setNotification({
          type: 'error',
          message: t('somethingWentWrong', { message: t('pleaseTryAgain') }),
        });
        window.location.href = '/';
      });
  };

  useEffect(() => {
    if (!sessionId) {
      navigate('/');
    } else {
      updatePaymentMethod(sessionId);
    }
  }, [sessionId]);

  return (
    <Stack width={'100%'} height="100vh" justifyContent={'center'} alignItems={'center'} gap={2}>
      <>
        <CircularProgress />
        <Typography>{`${t('verifyingLink')}...`}</Typography>
      </>
    </Stack>
  );
};

export default UpdatePaymentMethodPage;
