import { type Theme } from '@mui/material/styles';

export const MuiAvatarStyles = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      width: '56px',
      height: '56px',
      [theme.breakpoints.up('tablet')]: {
        width: '64px',
        height: '64px',
      },
      [theme.breakpoints.up('laptop')]: {
        width: '80px',
        height: '80px',
      },
    }),
  },
};
