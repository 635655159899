import { Stack, Typography } from '@mui/material';
import { type ReactNode } from 'react';

export const OptionLabel = ({
  title,
  subtitle,
}: {
  title: string;
  subtitle?: string | ReactNode;
}) => {
  return (
    <Stack gap={1}>
      <Typography variant="h4">{title}</Typography>
      <Typography variant="paragraphM" color={'textSecondary'}>
        {subtitle}
      </Typography>
    </Stack>
  );
};

export default OptionLabel;
