import { Typography } from '@mui/material';
import { useContext } from 'react';
import BundleCardGridItem from '../BundleCardGridItem';

import { useTranslation } from 'react-i18next';
import { MyGiftShiftContext } from '../../../../../providers/my-giftshift-provider';
import IBANNumberEditState from '../edit-states/IBANNumberEditState';

interface IBANNumberSectionProps {
  showEditState: boolean;
  updateEditState: () => void;
}

export const IBANNumberSection = ({
  showEditState = false,
  updateEditState,
}: IBANNumberSectionProps) => {
  const { t } = useTranslation();
  const { bundleInfo } = useContext(MyGiftShiftContext);

  return (
    <BundleCardGridItem
      title={t('ibanNumber')}
      content={
        showEditState ? (
          <IBANNumberEditState />
        ) : (
          <Typography variant="paragraphM">*{bundleInfo.ibanLast4}</Typography>
        )
      }
      actionLabel={
        bundleInfo.status !== 'inactive' ? (showEditState ? t('cancel') : t('modify')) : undefined
      }
      handleAction={updateEditState}
    />
  );
};

export default IBANNumberSection;
