import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { getTranslations } from './api/country-and-language';
import { getCookie } from './common/utils';

// types
import type { BasenameURL } from './models/Basename';

const backendOptions = {
  loadPath: '{{lng}}/{{ns}}',
  request: (options: any, url: string, payload: any, callback: any) => {
    try {
      const [lng] = url.split('/');

      getTranslations(lng).then((data) => {
        const translations = data.translation;

        callback(null, {
          data: JSON.stringify(translations),
          status: 200,
        });
      });
    } catch {
      callback(null, {
        status: 500,
        data: null,
      });
    }
  },
};

const routesCookie = getCookie('routes');

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    backend: backendOptions,
    lng: routesCookie ? (JSON.parse(routesCookie) as BasenameURL).country : 'nl',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
