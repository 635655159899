import { Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { LanguageContext } from '../../../../../providers/language-selector-provider';
import { MyGiftShiftContext } from '../../../../../providers/my-giftshift-provider';
import { formatDateToLocaleString } from '../../../MyGiftShift.utils';
import BundleCardGridItem from '../BundleCardGridItem';
import DonationDateEditState from '../edit-states/DonationDateEditState';

interface NextDonationDateSectionProps {
  showEditState: boolean;
  updateEditState: () => void;
}

export const NextDonationDateSection = ({
  showEditState = false,
  updateEditState,
}: NextDonationDateSectionProps) => {
  const { t } = useTranslation();
  const { selectedLanguage } = useContext(LanguageContext);
  const { bundleInfo } = useContext(MyGiftShiftContext);

  return (
    <BundleCardGridItem
      title={t('nextDonationDate')}
      content={
        showEditState ? (
          <DonationDateEditState updateEditState={updateEditState} />
        ) : (
          <Stack spacing={3}>
            {bundleInfo.status === 'pending' && (
              <Typography variant="paragraphM">
                <Trans i18nKey={'nextDonationDatePendingMessage'} />
              </Typography>
            )}
            <Typography variant="paragraphM">
              <Trans
                i18nKey={'nextDonationDebitDate'}
                values={{
                  date: formatDateToLocaleString(bundleInfo.nextDonationDate, selectedLanguage),
                }}
              />
            </Typography>
          </Stack>
        )
      }
      actionLabel={
        bundleInfo.status !== 'inactive' ? (showEditState ? t('cancel') : t('modify')) : undefined
      }
      handleAction={updateEditState}
    />
  );
};

export default NextDonationDateSection;
