import { Box, Stack } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputWithLabel from '../../../../components/inputWithLabel/InputWithLabel';
import LoadingButton from '../../../../components/loading-button/LoadingButton';
import { MyGiftShiftContext } from '../../../../providers/my-giftshift-provider';
import { AuthenticationContext } from '../../../../providers/authentication-provider';

export const RenderDateOfBirthField = ({
  updateRenderStates,
}: {
  updateRenderStates: () => void;
}) => {
  const { handleUpdateBirthday } = useContext(MyGiftShiftContext);
  const {
    userDetails: { birthday },
  } = useContext(AuthenticationContext);
  const [dateOfBirth, setDateOfBirth] = useState(birthday);
  const [invalidDateError, setInvalidDateError] = useState(false);
  const [loading, setLoading] = useState(false);

  const { t } = useTranslation();

  const today = new Date();
  const [max] = today.toISOString().split('T');

  const onSuccess = () => {
    setLoading(false);
    updateRenderStates();
  };

  const handleDateChange = (value: string) => {
    setInvalidDateError(false);
    setDateOfBirth(value);
  };

  const handleSave = () => {
    setLoading(true);
    handleUpdateBirthday(dateOfBirth, onSuccess);
  };

  return (
    <Stack gap="8px">
      <Box>
        <InputWithLabel
          type="date"
          value={dateOfBirth}
          onChange={(e) => {
            handleDateChange(e.target.value);
          }}
          inputProps={{ max }}
          error={invalidDateError}
          errorMessage={t('theEnteredDateIsInvalid')}
        />
      </Box>
      <Box width="fit-content">
        <LoadingButton
          size="medium"
          loading={loading}
          disabled={loading}
          variant="contained"
          color="primary"
          sx={{ width: 'fit-content' }}
          onClick={() => {
            handleSave();
          }}
        >
          {t('save')}
        </LoadingButton>
      </Box>
    </Stack>
  );
};
