import { axiosPrivate } from '../common/axiosPrivate';
import { type TCharityToUpdate } from '../models/Charities';

export const getCurrentBundle = async () => await axiosPrivate.get('/bundles/');

export const changeBundle = async (charities: TCharityToUpdate[]) =>
  await axiosPrivate.patch('/modify-subscription-charities/', { charities });

export const pauseSubscription = async () =>
  await axiosPrivate.patch('/update-subscription/', { command: 'pause' });

export const stopSubscription = async () =>
  await axiosPrivate.patch('/update-subscription/', { command: 'stop' });

export const unpauseSubscription = async (newDebitDate: string) =>
  await axiosPrivate.patch('/update-subscription/', {
    command: 'unpause',
    newDebitTime: newDebitDate,
  });

export const updateNextDebitDate = async (newDebitDate: string) =>
  await axiosPrivate.patch('/update-subscription/', {
    command: 'changeTime',
    newDebitTime: newDebitDate,
  });
