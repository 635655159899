import { axiosPublic } from '../common/axiosPublic';
import en from '../multi-language/en';

// types
import type { Country } from '../models/Basename';

export const getAllCountries = async () => await axiosPublic.get<Country[]>('/countries/');

export const getAvailableLanguages = async () => await axiosPublic.get('/languages/');

export const getTranslations = async (country: string) =>
  axiosPublic
    .get(`/translations/?country=${country}`)
    .then((response) => {
      if (!Object.keys(response.data.translation).length) {
        throw new Error();
      }

      return response.data;
    })
    .catch(() => {
      return en;
    });
