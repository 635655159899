import { changeLanguage } from 'i18next';
import { getAllCountries, getAvailableLanguages } from '../api/country-and-language';
import { getCookie } from '../common/utils';

// types
import type { BasenameURL, Country } from '../models/Basename';

type Language = {
  setCountry: (country: string) => string;
  handleLanguageChange: (language: string) => { id: string; cca2: string };
};

const DEFAULT_PATHS = { country: 'nl', language: 'nl' };

export const isValidBasename = (path: string) => path?.length === 2;

export const getBasename = (): [country: string, language: string, restPathname: string[]] => {
  const { location } = window;
  const [country, language, ...restPathname] = location.pathname.split('/').slice(1);

  return [country, language, restPathname];
};

const getDefaultPaths = (authenticatedUserCountry: string | undefined) => {
  const routes = getCookie('routes');
  const pathsFromCookie: BasenameURL | undefined = routes && JSON.parse(routes);

  return {
    country:
      authenticatedUserCountry ||
      getCookie('ViewerCountry')?.toLowerCase() ||
      pathsFromCookie?.country ||
      DEFAULT_PATHS.language,
    language: pathsFromCookie?.language || DEFAULT_PATHS.language,
  };
};

export const setBasename = (setters: Language, authenticatedUserCountry: string) => {
  const { setCountry, handleLanguageChange } = setters;
  const [country, language, restPathname] = getBasename();

  let stateUrl: BasenameURL;

  if (!isValidBasename(country) || !isValidBasename(language)) {
    stateUrl = getDefaultPaths(authenticatedUserCountry);
  } else {
    stateUrl = { country, language };
  }

  document.cookie = `routes=${JSON.stringify(stateUrl)}; path=/`;

  const definedCountry = setCountry(stateUrl.country);
  const definedLanguage = handleLanguageChange(stateUrl.language);

  changeLanguage(definedLanguage.cca2);

  window.history.replaceState(
    null,
    '',
    `/${definedCountry}/${definedLanguage.id}/${restPathname[restPathname.length - 1] || location.pathname}${location.search}`
  );
};

export const handleGetFlags = async (
  onSuccess: (countries: Country[], languages: string[]) => void,
  onError: () => void
) => {
  try {
    const { data: countries } = await getAllCountries();
    const { data: languages } = await getAvailableLanguages();

    onSuccess(countries, languages);
  } catch {
    onError();
  }
};

export const handleChangeRoute = (country: string, language: string) => {
  const { history, location } = window;
  const route = location.pathname.split('/').pop();

  history.replaceState(
    null,
    '',
    `/${country}/${language}/${route}${location.search}${location.hash}`
  );
};

export const handleRedirectUserPreferredCountry = (
  setSelectedCountry: (country: string) => void,
  route: string,
  setNotification?: () => void
) => {
  setSelectedCountry('');
  window.history.pushState(null, '', route);
  localStorage.removeItem('selectedValues');
  setNotification && setNotification();
};

export const isFulfilled = <T>(
  input: PromiseSettledResult<T>
): input is PromiseFulfilledResult<T> => input.status === 'fulfilled';
