import { Dialog, DialogActions, IconButton, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EmptyBundleIllustration from '../../../../../assets/EmptyBundleIllustration';
import { CloseIcon } from '../../../../../assets/icons';
import LoadingButton from '../../../../../components/loading-button/LoadingButton';
import { MyGiftShiftContext } from '../../../../../providers/my-giftshift-provider';

interface ConfirmPauseDialogProps {
  open: boolean;
  onClose: () => void;
}

export const ConfirmPauseDialog = ({ open, onClose }: ConfirmPauseDialogProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { handlePauseBundle } = useContext(MyGiftShiftContext);

  const handlePauseDonation = async () => {
    setLoading(true);
    const response = await handlePauseBundle();
    if (response) {
      setLoading(false);
      onClose();
    }
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-pause-dialog-title"
      aria-describedby="confirm-pause-dialog-description"
      maxWidth="tablet"
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          padding: { mobile: '76px 20px', tablet: 10 },
          borderRadius: { mobile: '20px 20px 0 0 ', tablet: '16px' },
          gap: 4,
          margin: { mobile: 0, tablet: 9 },
          width: '100%',
          alignItems: 'center',
        },
        '& .MuiDialog-container': {
          alignItems: { mobile: 'flex-end', tablet: 'center' },
        },
      }}
    >
      <EmptyBundleIllustration />
      <Typography variant="h3" id="confirm-pause-dialog-title" textAlign={'center'}>
        {t('areYouSure')}
      </Typography>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          padding: 2,
          right: 16,
          top: 16,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogActions
        sx={{ justifyContent: { mobile: 'center', laptop: 'flex-start' }, padding: 0 }}
      >
        <LoadingButton
          onClick={handlePauseDonation}
          size="large"
          variant="contained"
          color="error"
          loading={loading}
          disabled={loading}
        >
          {t('pauseDonation')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmPauseDialog;
