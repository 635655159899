import { axiosPublic } from '../common/axiosPublic';
import { config } from './config';

interface TResetPassword {
  email: string;
}

interface TSubmitNewPassword {
  password: string;
}

export const resetUserPassword = async (body: TResetPassword) =>
  await axiosPublic.post(`${config.BASE_URL}/auth/reset-password/`, body);

export const submitNewUserPassword = async (activationCode: string, body: TSubmitNewPassword) =>
  await axiosPublic.post(`/auth/reset-password/${activationCode}`, body);
