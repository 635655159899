import { DonationSteps } from './DonationFlowPage/DonationFlow.utils';

type RedirectAfterLoginParams = {
  isEnglish: boolean;
};

export const handleRedirectAfterLogin = ({ isEnglish }: RedirectAfterLoginParams) => {
  const hasDonationFlowStarted = localStorage.getItem('selectedValues');
  if (hasDonationFlowStarted) {
    const donationFrequency = localStorage.getItem('donationFrequency');

    const step =
      donationFrequency === 'one-time'
        ? DonationSteps.SummaryAndPayment
        : DonationSteps.CheckExistingBundleStep;

    window.location.href = `${isEnglish ? '/donate' : '/doneren'}?step=${step}`;
  } else {
    window.location.href = '/my-giftshift';
  }
};
