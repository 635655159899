import { Stack, Typography, useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { backgroundColor, errorColor, textColor } from '../../styles/variables';

// types
import type { Theme } from '@mui/material';

type CouponReadOnly = {
  validCode: string;
  onRemoveCoupon: () => void;
};

const CouponReadOnly = ({ validCode, onRemoveCoupon }: CouponReadOnly) => {
  const { t } = useTranslation();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('tablet'));

  return (
    <Stack
      sx={{
        backgroundColor: `${backgroundColor}80`,
        borderRadius: '10px',
        padding: 2,
        gap: '2px',
      }}
    >
      <Typography
        variant="paragraphL"
        color={textColor}
        align={`${isMobile ? 'center' : 'left'}`}
      >{`${t('yourCode')}:`}</Typography>
      <Stack direction={`${isMobile ? 'column' : 'row'}`} alignItems="center" gap="10px">
        <Typography variant="h4">{validCode}</Typography>
        <Typography
          variant="paragraphL"
          sx={{ color: errorColor, cursor: 'pointer', ':hover': { color: `${errorColor}cc` } }}
          onClick={onRemoveCoupon}
        >
          {t('removeCode')}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default CouponReadOnly;
