import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import EmailIllustration from '../../../../assets/EmailIllustration';

export const CheckEmailForActivation = () => {
  const { t } = useTranslation();
  return (
    <Stack alignItems={'center'} gap={3}>
      <Box sx={{ width: { mobile: '129px', tablet: '141px', laptop: '173px' } }}>
        <EmailIllustration width="100%" />
      </Box>
      <Stack gap={0.5} alignItems="center" maxWidth={'426px'}>
        <Typography maxWidth={'415px'} variant="h2" textAlign={'center'}>
          {t('youHaveReceivedAnEmail')}
        </Typography>
        <Typography color="textSecondary" variant="paragraphM" textAlign={'center'}>
          {t('confirmRegistrationToContinue')}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default CheckEmailForActivation;
