import { Delete } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useState } from 'react';
import { darkBlue, shadowSM } from '../../styles/variables';
import DialogComponent from '../dialog/DialogComponent';
import LoadingButton from '../loading-button/LoadingButton';

// type
import type { Theme, TypographyProps } from '@mui/material';
import type { GeneratedCoupon } from '../../api/coupons';

const CouponItem = ({
  coupon,
  handleDeleteCoupon,
  isDeletingCoupon,
}: {
  coupon: GeneratedCoupon;
  handleDeleteCoupon: (couponId: string) => Promise<void>;
  isDeletingCoupon: boolean;
}) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('tablet'));
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const formatDate = (date: Date) => {
    const couponDate = date.toISOString().split('T');

    return couponDate[0];
  };

  const couponDetails = [
    { label: 'Amount', value: coupon.amount, prefix: '$' },
    { label: 'Client', value: coupon.client },
    { label: 'Campaign', value: coupon.campaign },
  ];

  const dateStyles: TypographyProps = {
    variant: isMobile ? 'paragraphL' : 'paragraphXS',
    fontWeight: 800,
    textAlign: 'center',
  };

  return (
    <Card
      sx={{
        border: coupon.isRedeemed ? '' : `1px solid ${darkBlue}`,
        borderRadius: 3,
        boxShadow: shadowSM,
      }}
    >
      <DialogComponent
        isOpen={openDeleteModal}
        handleCloseModal={() => {
          setOpenDeleteModal(false);
        }}
        title={<>Delete #{coupon.id}</>}
        content={
          <Stack direction="row" gap={1} alignItems="center">
            <Typography>Are you sure you want to delete</Typography>
            <Typography variant="h6">#{coupon.id}?</Typography>
          </Stack>
        }
        actions={
          <>
            <Button
              onClick={() => {
                setOpenDeleteModal(false);
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              color="error"
              onClick={() => handleDeleteCoupon(coupon.id).finally(() => setOpenDeleteModal(false))}
              loading={isDeletingCoupon}
              disabled={isDeletingCoupon}
            >
              Delete
            </LoadingButton>
          </>
        }
      />
      <CardContent>
        <Stack
          direction="row"
          alignItems="center"
          gap={2}
          sx={{ py: coupon.isRedeemed ? '6px' : '0' }}
        >
          <Typography variant="paragraphS" textAlign="center" fontWeight="800">
            {coupon.id}
          </Typography>
          {!coupon.isRedeemed ? (
            <IconButton
              size="small"
              onClick={() => {
                setOpenDeleteModal(true);
              }}
            >
              <Delete color="error" />
            </IconButton>
          ) : (
            ''
          )}
        </Stack>
        <Stack direction="row" sx={{ width: '100%' }}>
          <Stack sx={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
            <Typography variant="paragraphM" textAlign="center">
              From
            </Typography>
            <Typography {...dateStyles}>{`${formatDate(new Date(coupon.startDate))}`}</Typography>
          </Stack>
          <Typography variant="paragraphM" alignSelf="center">
            -
          </Typography>
          <Stack sx={{ display: 'flex', justifyContent: 'center', flex: 1 }}>
            <Typography variant="paragraphM" textAlign="center">
              To
            </Typography>
            <Typography {...dateStyles}>{`${formatDate(new Date(coupon.endDate))}`}</Typography>
          </Stack>
        </Stack>
        {couponDetails.map(({ label, value, prefix }) => (
          <Stack key={label} direction="column">
            <Typography variant="paragraphM">{label}: </Typography>
            <Typography variant="paragraphS" fontWeight={700}>
              {prefix}
              {value}
            </Typography>
          </Stack>
        ))}
      </CardContent>
    </Card>
  );
};

export default CouponItem;
