import { AxiosHeaders } from 'axios';

// types
import type { InternalAxiosRequestConfig } from 'axios';

export const getLocalAccessToken = () => localStorage.getItem('access_token') || undefined;

export const getLocalRefreshToken = () => localStorage.getItem('refreshToken') || undefined;

export const clearBrowserCookies = () => {
  const cookies = document.cookie.split(';');

  cookies.forEach((cookie) => {
    const cookieName = cookie.split('=')[0];

    document.cookie = cookieName + `=; expires=Thu, 01 Jan 1970 00:00:00 GMT;`;
  });

  document.cookie =
    'access_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.giftshift.org; path=/';
  document.cookie =
    'refresh_token=; expires=Thu, 01 Jan 1970 00:00:00 GMT; domain=.giftshift.org; path=/';
};

export const getCookie = (cookieName: string) => {
  const cookieArray = decodeURIComponent(document.cookie).split(';');
  return cookieArray
    .find((cookie) => cookie.trim().split('=')[0].includes(cookieName))
    ?.split('=')[1];
};

export const resetStorage = () => {
  const items = [
    'selectedValues',
    'donationFrequency',
    'monthlyDonor',
    'nextDonationDate',
    'widgetData',
    'pid',
    'coupon',
  ];

  items.forEach((el) => localStorage.removeItem(el));
};

const cookieKeys = [
  { cookieName: 'access_token', headerName: 'authorization' },
  { cookieName: 'csrftoken', headerName: 'X-CSRFToken' },
];

export const addValuesFromCookieToHeader = (config: InternalAxiosRequestConfig) => {
  const accessToken = getLocalAccessToken();

  const headersValue = cookieKeys.map((value) => ({
    [value.headerName]: getCookie(value.cookieName) || localStorage.getItem(value.cookieName),
  }));

  if (accessToken) {
    config.headers = new AxiosHeaders(Object.assign({}, ...headersValue));
  }

  return config;
};

export const ALL_LANGUAGES = [
  {
    alias: 'ad',
    language: 'Catalan',
  },
  {
    alias: 'ae',
    language: 'Arabic',
  },
  {
    alias: 'af',
    language: 'Dari',
  },
  {
    alias: 'ag',
    language: 'English',
  },
  {
    alias: 'ai',
    language: 'English',
  },
  {
    alias: 'al',
    language: 'Albanian',
  },
  {
    alias: 'am',
    language: 'Armenian',
  },
  {
    alias: 'ao',
    language: 'Portuguese',
  },
  {
    alias: 'aq',
    language: 'Dutch',
  },
  {
    alias: 'ar',
    language: 'Guaraní',
  },
  {
    alias: 'as',
    language: 'English',
  },
  {
    alias: 'at',
    language: 'German',
  },
  {
    alias: 'au',
    language: 'English',
  },
  {
    alias: 'aw',
    language: 'Dutch',
  },
  {
    alias: 'ax',
    language: 'Swedish',
  },
  {
    alias: 'az',
    language: 'Azerbaijani',
  },
  {
    alias: 'ba',
    language: 'Bosnian',
  },
  {
    alias: 'bb',
    language: 'English',
  },
  {
    alias: 'bd',
    language: 'Bengali',
  },
  {
    alias: 'be',
    language: 'German',
  },
  {
    alias: 'bf',
    language: 'French',
  },
  {
    alias: 'bg',
    language: 'Bulgarian',
  },
  {
    alias: 'bh',
    language: 'Arabic',
  },
  {
    alias: 'bi',
    language: 'French',
  },
  {
    alias: 'bj',
    language: 'French',
  },
  {
    alias: 'bl',
    language: 'French',
  },
  {
    alias: 'bm',
    language: 'English',
  },
  {
    alias: 'bn',
    language: 'Malay',
  },
  {
    alias: 'bo',
    language: 'Aymara',
  },
  {
    alias: 'bq',
    language: 'English',
  },
  {
    alias: 'br',
    language: 'Portuguese',
  },
  {
    alias: 'bs',
    language: 'English',
  },
  {
    alias: 'bt',
    language: 'Dzongkha',
  },
  {
    alias: 'bv',
    language: 'Norwegian',
  },
  {
    alias: 'bw',
    language: 'English',
  },
  {
    alias: 'by',
    language: 'Belarusian',
  },
  {
    alias: 'bz',
    language: 'Belizean Creole',
  },
  {
    alias: 'ca',
    language: 'English',
  },
  {
    alias: 'cc',
    language: 'English',
  },
  {
    alias: 'cd',
    language: 'French',
  },
  {
    alias: 'cf',
    language: 'French',
  },
  {
    alias: 'cg',
    language: 'French',
  },
  {
    alias: 'ch',
    language: 'French',
  },
  {
    alias: 'ci',
    language: 'French',
  },
  {
    alias: 'ck',
    language: 'English',
  },
  {
    alias: 'cl',
    language: 'Spanish',
  },
  {
    alias: 'cm',
    language: 'English',
  },
  {
    alias: 'cn',
    language: 'Chinese',
  },
  {
    alias: 'co',
    language: 'Spanish',
  },
  {
    alias: 'cr',
    language: 'Spanish',
  },
  {
    alias: 'cu',
    language: 'Spanish',
  },
  {
    alias: 'cv',
    language: 'Portuguese',
  },
  {
    alias: 'cw',
    language: 'English',
  },
  {
    alias: 'cx',
    language: 'English',
  },
  {
    alias: 'cy',
    language: 'Greek',
  },
  {
    alias: 'cz',
    language: 'Czech',
  },
  {
    alias: 'de',
    language: 'German',
  },
  {
    alias: 'dj',
    language: 'Arabic',
  },
  {
    alias: 'dk',
    language: 'Danish',
  },
  {
    alias: 'dm',
    language: 'English',
  },
  {
    alias: 'do',
    language: 'Spanish',
  },
  {
    alias: 'dz',
    language: 'Arabic',
  },
  {
    alias: 'ec',
    language: 'Spanish',
  },
  {
    alias: 'ee',
    language: 'Estonian',
  },
  {
    alias: 'eg',
    language: 'Arabic',
  },
  {
    alias: 'eh',
    language: 'Berber',
  },
  {
    alias: 'en',
    language: 'English',
  },
  {
    alias: 'er',
    language: 'Arabic',
  },
  {
    alias: 'es',
    language: 'Spanish',
  },
  {
    alias: 'et',
    language: 'Amharic',
  },
  {
    alias: 'fi',
    language: 'Finnish',
  },
  {
    alias: 'fj',
    language: 'English',
  },
  {
    alias: 'fk',
    language: 'English',
  },
  {
    alias: 'fm',
    language: 'English',
  },
  {
    alias: 'fo',
    language: 'Danish',
  },
  {
    alias: 'fr',
    language: 'French',
  },
  {
    alias: 'ga',
    language: 'French',
  },
  {
    alias: 'gb',
    language: 'English',
  },
  {
    alias: 'gd',
    language: 'English',
  },
  {
    alias: 'ge',
    language: 'Georgian',
  },
  {
    alias: 'gf',
    language: 'French',
  },
  {
    alias: 'gg',
    language: 'English',
  },
  {
    alias: 'gh',
    language: 'English',
  },
  {
    alias: 'gi',
    language: 'English',
  },
  {
    alias: 'gl',
    language: 'Greenlandic',
  },
  {
    alias: 'gm',
    language: 'English',
  },
  {
    alias: 'gn',
    language: 'French',
  },
  {
    alias: 'gp',
    language: 'French',
  },
  {
    alias: 'gq',
    language: 'French',
  },
  {
    alias: 'gr',
    language: 'Greek',
  },
  {
    alias: 'gs',
    language: 'English',
  },
  {
    alias: 'gt',
    language: 'Spanish',
  },
  {
    alias: 'gu',
    language: 'Chamorro',
  },
  {
    alias: 'gw',
    language: 'Portuguese',
  },
  {
    alias: 'gy',
    language: 'English',
  },
  {
    alias: 'hk',
    language: 'English',
  },
  {
    alias: 'hm',
    language: 'English',
  },
  {
    alias: 'hn',
    language: 'Spanish',
  },
  {
    alias: 'hr',
    language: 'Croatian',
  },
  {
    alias: 'ht',
    language: 'French',
  },
  {
    alias: 'hu',
    language: 'Hungarian',
  },
  {
    alias: 'id',
    language: 'Indonesian',
  },
  {
    alias: 'ie',
    language: 'English',
  },
  {
    alias: 'il',
    language: 'Arabic',
  },
  {
    alias: 'im',
    language: 'English',
  },
  {
    alias: 'in',
    language: 'English',
  },
  {
    alias: 'io',
    language: 'English',
  },
  {
    alias: 'iq',
    language: 'Arabic',
  },
  {
    alias: 'ir',
    language: 'Persian (Farsi)',
  },
  {
    alias: 'is',
    language: 'Icelandic',
  },
  {
    alias: 'it',
    language: 'Italian',
  },
  {
    alias: 'je',
    language: 'English',
  },
  {
    alias: 'jm',
    language: 'English',
  },
  {
    alias: 'jo',
    language: 'Arabic',
  },
  {
    alias: 'jp',
    language: 'Japanese',
  },
  {
    alias: 'ke',
    language: 'English',
  },
  {
    alias: 'kg',
    language: 'Kyrgyz',
  },
  {
    alias: 'kh',
    language: 'Khmer',
  },
  {
    alias: 'ki',
    language: 'English',
  },
  {
    alias: 'km',
    language: 'Arabic',
  },
  {
    alias: 'kn',
    language: 'English',
  },
  {
    alias: 'kp',
    language: 'Korean',
  },
  {
    alias: 'kr',
    language: 'Korean',
  },
  {
    alias: 'kw',
    language: 'Arabic',
  },
  {
    alias: 'ky',
    language: 'English',
  },
  {
    alias: 'kz',
    language: 'Kazakh',
  },
  {
    alias: 'la',
    language: 'Lao',
  },
  {
    alias: 'lb',
    language: 'Arabic',
  },
  {
    alias: 'lc',
    language: 'English',
  },
  {
    alias: 'li',
    language: 'German',
  },
  {
    alias: 'lk',
    language: 'Sinhala',
  },
  {
    alias: 'lr',
    language: 'English',
  },
  {
    alias: 'ls',
    language: 'English',
  },
  {
    alias: 'lt',
    language: 'Lithuanian',
  },
  {
    alias: 'lu',
    language: 'German',
  },
  {
    alias: 'lv',
    language: 'Latvian',
  },
  {
    alias: 'ly',
    language: 'Arabic',
  },
  {
    alias: 'ma',
    language: 'Arabic',
  },
  {
    alias: 'mc',
    language: 'French',
  },
  {
    alias: 'md',
    language: 'Romanian',
  },
  {
    alias: 'me',
    language: 'Montenegrin',
  },
  {
    alias: 'mf',
    language: 'French',
  },
  {
    alias: 'mg',
    language: 'French',
  },
  {
    alias: 'mh',
    language: 'English',
  },
  {
    alias: 'mk',
    language: 'Macedonian',
  },
  {
    alias: 'ml',
    language: 'French',
  },
  {
    alias: 'mm',
    language: 'Burmese',
  },
  {
    alias: 'mn',
    language: 'Mongolian',
  },
  {
    alias: 'mo',
    language: 'Portuguese',
  },
  {
    alias: 'mp',
    language: 'Carolinian',
  },
  {
    alias: 'mq',
    language: 'French',
  },
  {
    alias: 'mr',
    language: 'Arabic',
  },
  {
    alias: 'ms',
    language: 'English',
  },
  {
    alias: 'mt',
    language: 'English',
  },
  {
    alias: 'mu',
    language: 'English',
  },
  {
    alias: 'mv',
    language: 'Maldivian',
  },
  {
    alias: 'mw',
    language: 'English',
  },
  {
    alias: 'mx',
    language: 'Spanish',
  },
  {
    alias: 'my',
    language: 'English',
  },
  {
    alias: 'mz',
    language: 'Portuguese',
  },
  {
    alias: 'na',
    language: 'Afrikaans',
  },
  {
    alias: 'nc',
    language: 'French',
  },
  {
    alias: 'ne',
    language: 'French',
  },
  {
    alias: 'nf',
    language: 'English',
  },
  {
    alias: 'ng',
    language: 'English',
  },
  {
    alias: 'ni',
    language: 'Spanish',
  },
  {
    alias: 'nl',
    language: 'Dutch',
  },
  {
    alias: 'no',
    language: 'Norwegian Nynorsk',
  },
  {
    alias: 'np',
    language: 'Nepali',
  },
  {
    alias: 'nr',
    language: 'English',
  },
  {
    alias: 'nu',
    language: 'English',
  },
  {
    alias: 'nz',
    language: 'English',
  },
  {
    alias: 'om',
    language: 'Arabic',
  },
  {
    alias: 'pa',
    language: 'Spanish',
  },
  {
    alias: 'pe',
    language: 'Aymara',
  },
  {
    alias: 'pf',
    language: 'French',
  },
  {
    alias: 'pg',
    language: 'English',
  },
  {
    alias: 'ph',
    language: 'English',
  },
  {
    alias: 'pk',
    language: 'English',
  },
  {
    alias: 'pl',
    language: 'Polish',
  },
  {
    alias: 'pm',
    language: 'French',
  },
  {
    alias: 'pn',
    language: 'English',
  },
  {
    alias: 'pr',
    language: 'English',
  },
  {
    alias: 'ps',
    language: 'Arabic',
  },
  {
    alias: 'pt',
    language: 'Portuguese',
  },
  {
    alias: 'pw',
    language: 'English',
  },
  {
    alias: 'py',
    language: 'Guaraní',
  },
  {
    alias: 'qa',
    language: 'Arabic',
  },
  {
    alias: 're',
    language: 'French',
  },
  {
    alias: 'ro',
    language: 'Romanian',
  },
  {
    alias: 'rs',
    language: 'Serbian',
  },
  {
    alias: 'ru',
    language: 'Russian',
  },
  {
    alias: 'rw',
    language: 'English',
  },
  {
    alias: 'sa',
    language: 'Arabic',
  },
  {
    alias: 'sb',
    language: 'English',
  },
  {
    alias: 'sc',
    language: 'Seychellois Creole',
  },
  {
    alias: 'sd',
    language: 'Arabic',
  },
  {
    alias: 'se',
    language: 'Swedish',
  },
  {
    alias: 'sg',
    language: 'English',
  },
  {
    alias: 'sh',
    language: 'English',
  },
  {
    alias: 'si',
    language: 'Slovene',
  },
  {
    alias: 'sj',
    language: 'Norwegian',
  },
  {
    alias: 'sk',
    language: 'Slovak',
  },
  {
    alias: 'sl',
    language: 'English',
  },
  {
    alias: 'sm',
    language: 'Italian',
  },
  {
    alias: 'sn',
    language: 'French',
  },
  {
    alias: 'so',
    language: 'Arabic',
  },
  {
    alias: 'sr',
    language: 'Dutch',
  },
  {
    alias: 'ss',
    language: 'English',
  },
  {
    alias: 'st',
    language: 'Portuguese',
  },
  {
    alias: 'sv',
    language: 'Spanish',
  },
  {
    alias: 'sx',
    language: 'English',
  },
  {
    alias: 'sy',
    language: 'Arabic',
  },
  {
    alias: 'sz',
    language: 'English',
  },
  {
    alias: 'tc',
    language: 'English',
  },
  {
    alias: 'td',
    language: 'Arabic',
  },
  {
    alias: 'tf',
    language: 'French',
  },
  {
    alias: 'tg',
    language: 'French',
  },
  {
    alias: 'th',
    language: 'Thai',
  },
  {
    alias: 'tj',
    language: 'Russian',
  },
  {
    alias: 'tk',
    language: 'English',
  },
  {
    alias: 'tl',
    language: 'Portuguese',
  },
  {
    alias: 'tm',
    language: 'Russian',
  },
  {
    alias: 'tn',
    language: 'Arabic',
  },
  {
    alias: 'to',
    language: 'English',
  },
  {
    alias: 'tr',
    language: 'Turkish',
  },
  {
    alias: 'tt',
    language: 'English',
  },
  {
    alias: 'tv',
    language: 'English',
  },
  {
    alias: 'tw',
    language: 'Chinese',
  },
  {
    alias: 'tz',
    language: 'English',
  },
  {
    alias: 'ua',
    language: 'Ukrainian',
  },
  {
    alias: 'ug',
    language: 'English',
  },
  {
    alias: 'um',
    language: 'English',
  },
  {
    alias: 'us',
    language: 'English',
  },
  {
    alias: 'uy',
    language: 'Spanish',
  },
  {
    alias: 'uz',
    language: 'Russian',
  },
  {
    alias: 'va',
    language: 'Italian',
  },
  {
    alias: 'vc',
    language: 'English',
  },
  {
    alias: 've',
    language: 'Spanish',
  },
  {
    alias: 'vg',
    language: 'English',
  },
  {
    alias: 'vi',
    language: 'English',
  },
  {
    alias: 'vn',
    language: 'Vietnamese',
  },
  {
    alias: 'vu',
    language: 'Bislama',
  },
  {
    alias: 'wf',
    language: 'French',
  },
  {
    alias: 'ws',
    language: 'English',
  },
  {
    alias: 'xk',
    language: 'Albanian',
  },
  {
    alias: 'ye',
    language: 'Arabic',
  },
  {
    alias: 'yt',
    language: 'French',
  },
  {
    alias: 'za',
    language: 'Afrikaans',
  },
  {
    alias: 'zm',
    language: 'English',
  },
  {
    alias: 'zw',
    language: 'Chibarwe',
  },
];
