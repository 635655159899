import { type TCharityLogo, type TSelectedCharity } from '../../../models/Charities';

export const TWidget = {
  Type1: 1,
  Type2: 2,
  Type3: 3,
};

export interface WidgetProps {
  variant?: string | number | undefined;
  buttonBgColor: string | undefined;
  buttonTextColor: string | undefined;
  buttonBorderRadius: number | undefined;
  headingColor: string | undefined;
  subHeadingColor: string | undefined;
  logos?: TCharityLogo[];
  image?: File | Blob | string | undefined;
  previewImage?: File | Blob | string | undefined;
  verticalImage?: File | Blob | undefined;
  charities?: TSelectedCharity[];
  partnerId?: string;
  onClick?: () => void;
  ref?: React.Ref<any>;
  language: string | undefined;
  headingText: string;
  subHeadingText: string;
  buttonText: string;
  selectedCountry: string;
  selectedLanguage: string;
}

export type AllWidgetsProps = WidgetProps & { partnerId: string; name: string | undefined };
