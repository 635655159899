import { Drawer, Box, Typography, Button, Stack, IconButton, Divider, Badge } from '@mui/material';
import { CloseIcon, LogoutIcon } from '../../assets/icons';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { darkBlue, errorColor } from '../../styles/variables';
import { useContext } from 'react';
import { AuthenticationContext } from '../../providers/authentication-provider';
import { PublicContext } from '../../providers/public-provider';
import { LanguageContext } from '../../providers/language-selector-provider';

interface UserMenuDrawerProps {
  open: boolean;
  onClose: () => void;
}

export const UserMenuDrawer = ({ open, onClose }: UserMenuDrawerProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { authenticatedUser, handleLogout } = useContext(AuthenticationContext);
  const { currentBundle } = useContext(PublicContext);
  const { isEnglish } = useContext(LanguageContext);

  const handleDonate = () => {
    navigate(isEnglish ? '/donate' : '/doneren');
  };

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '100%',
          backgroundColor: 'background.paper',
          px: { mobile: '36px', tablet: '130px' },
          py: { mobile: 5 },
        },
      }}
    >
      <IconButton sx={{ position: 'absolute', top: '32px', right: '28px' }} onClick={onClose}>
        <CloseIcon />
      </IconButton>
      <Box sx={{ width: '100%', margin: 'auto' }}>
        <Stack gap={4} justifyItems={'center'}>
          <Stack gap={1}>
            <Typography variant="h2">{authenticatedUser?.name}</Typography>
            <Typography variant="paragraphM">{authenticatedUser?.email}</Typography>
          </Stack>
          <Divider />
          <Stack gap={4}>
            <Link
              to={isEnglish ? '/my-giftshift#my-bundle' : '/mijn-giftshift#my-bundle'}
              style={{ textDecoration: 'none' }}
            >
              <Typography variant={'h3'} color={darkBlue} onClick={onClose}>
                {t('myBundle')}
              </Typography>
            </Link>
            <Link
              to={isEnglish ? '/my-giftshift#my-details' : '/mijn-giftshift#my-details'}
              style={{ textDecoration: 'none' }}
            >
              <Typography variant={'h3'} color={darkBlue} onClick={onClose}>
                {t('myDetails')}
              </Typography>
            </Link>
            <Link
              to={isEnglish ? '/my-giftshift#overview' : '/mijn-giftshift#overview'}
              style={{ textDecoration: 'none' }}
            >
              <Typography variant={'h3'} color={darkBlue} onClick={onClose}>
                {t('donationOverview')}
              </Typography>
            </Link>
          </Stack>
          <Stack gap={2}>
            <Badge badgeContent={currentBundle.length || undefined} color="error">
              <Button
                fullWidth
                variant="contained"
                size="large"
                onClick={handleDonate}
                data-testid="DonateButton"
              >
                {t('donate')}
              </Button>
            </Badge>
            <Button
              variant="outlined"
              color="error"
              onClick={handleLogout}
              endIcon={<LogoutIcon color={errorColor} />}
              data-testid="LogoutButton"
              size="large"
            >
              {t('logout')}
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default UserMenuDrawer;
