import { Grid, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InactiveCircleSvg from '../../../../../assets/svg/InactiveCircleSvg';
import SuccessCircleSvg from '../../../../../assets/svg/SuccessCircleSvg';
import { MyGiftShiftContext } from '../../../../../providers/my-giftshift-provider';
import BundleCardGridItem from '../BundleCardGridItem';
import ConfirmPauseDialog from '../confirmation-dialogs/ConfirmPauseDialog';
import StatusPausedEditState from '../edit-states/StatusPausedEditState';
import PendingCircleSvg from '../../../../../assets/svg/PendingCircleSvg';

interface StatusSectionProps {
  showEditState: boolean;
  updateEditState: () => void;
}

export const StatusSection = ({ showEditState = false, updateEditState }: StatusSectionProps) => {
  const { t } = useTranslation();
  const [openConfirmPauseDialog, setOpenConfirmPauseDialog] = useState(false);
  const { bundleInfo } = useContext(MyGiftShiftContext);

  return (
    <>
      <BundleCardGridItem
        title={t('status')}
        content={
          showEditState ? (
            <StatusPausedEditState updateEditState={updateEditState} />
          ) : (
            <Grid container alignItems={'center'} gap={1}>
              {bundleInfo.status === 'active' ? (
                <SuccessCircleSvg />
              ) : bundleInfo.status === 'pending' ? (
                <PendingCircleSvg />
              ) : (
                <InactiveCircleSvg />
              )}
              <Typography variant="paragraphM">
                {bundleInfo.status === 'active'
                  ? t('active')
                  : bundleInfo.status === 'pending'
                    ? t('pending')
                    : t('paused')}
              </Typography>
            </Grid>
          )
        }
        actionLabel={
          bundleInfo.status !== 'paused' ? t('pause') : showEditState ? t('cancel') : t('resume')
        }
        handleAction={
          bundleInfo.status !== 'paused'
            ? () => {
                setOpenConfirmPauseDialog(true);
              }
            : updateEditState
        }
      />
      <ConfirmPauseDialog
        open={openConfirmPauseDialog}
        onClose={() => {
          setOpenConfirmPauseDialog(false);
        }}
      />
    </>
  );
};

export default StatusSection;
