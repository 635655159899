import { Chip, Grid, Stack } from '@mui/material';

import type { TSelectedCharity } from '../../../models/Charities';

import { darkBlue } from '../../../styles/variables';
import DropdownAutocomplete from '../../dropdown/DropdownAutocomplete';
import { TWidget } from '../../widgets/types';

type CharitiesSectionProps = {
  variant: number;
  allCharities: TSelectedCharity[];
  displayedCharities?: TSelectedCharity[];
  setDisplayedCharities: (val: TSelectedCharity[]) => void;
};

export const CharitiesSection = ({
  allCharities,
  displayedCharities,
  variant,
  setDisplayedCharities,
}: CharitiesSectionProps) => {
  return (
    <Stack>
      <DropdownAutocomplete
        multiple
        autoComplete={false}
        limitTags={0}
        showLogos={false}
        labelValue={`Charities Shown ${variant === TWidget.Type2 ? '(max 9)' : '(max 5)'}`}
        labelVariant="h2"
        labelColor={darkBlue}
        defaultValue={[]}
        value={displayedCharities?.map((item) => ({ ...item, label: item.name }))}
        options={allCharities?.map((item) => ({ ...item, label: item.name }))}
        renderInput={() => <> </>}
        onChange={(_, values) => {
          if (values) {
            const allValuesWithoutLabel = (values as TSelectedCharity[]).map(
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              ({ label, ...rest }) => rest
            );

            const limitedValues =
              variant === TWidget.Type2
                ? allValuesWithoutLabel.slice(-9)
                : allValuesWithoutLabel.slice(-5);
            setDisplayedCharities(limitedValues);
          }
        }}
        renderTags={() => null}
        isOptionEqualToValue={(option, value) => option.id === value.id}
      />
      <Grid container gap={1} py={2}>
        {displayedCharities?.map((selectedCharity) => (
          <Chip label={selectedCharity.name} key={selectedCharity.id} />
        ))}
      </Grid>
    </Stack>
  );
};

export default CharitiesSection;
