import { createTheme } from '@mui/material/styles';
import { type CSSProperties } from 'react';
import { MuiAlertStyles } from './mui-components/alert';
import { MuiAutocompleteStyles } from './mui-components/autocomplete';
import { MuiAvatarStyles } from './mui-components/avatar';
import { MuiButtonStyles } from './mui-components/button';
import { MuiCheckboxStyles } from './mui-components/checkbox';
import { MuiChipStyles } from './mui-components/chip';
import { MuiCircularProgressStyles } from './mui-components/circular-progress';
import { MuiDividerStyles } from './mui-components/divider';
import { MuiInputStyles } from './mui-components/input';
import { MuiLinearProgressStyles } from './mui-components/linear-progress';
import { MuiMenuItemStyles } from './mui-components/menu-item';
import { MuiOutlinedInputStyles } from './mui-components/outlined-input';
import { MuiSelectStyles } from './mui-components/select';
import { MuiSliderStyles } from './mui-components/slider';
import { MuiTabsStyles, MuiTabStyles } from './mui-components/tabs';
import { TypographyStyles } from './mui-components/typography';

declare module '@mui/material/styles' {
  interface TypographyVariants {
    paragraphL: CSSProperties;
    paragraphM: CSSProperties;
    paragraphS: CSSProperties;
    paragraphXS: CSSProperties;
    menuItem: CSSProperties;
    subtitle: CSSProperties;
    buttonL: CSSProperties;
    buttonM: CSSProperties;
    buttonS: CSSProperties;
    quote: CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    paragraphL: CSSProperties;
    paragraphM: CSSProperties;
    paragraphS: CSSProperties;
    paragraphXS: CSSProperties;
    menuItem: CSSProperties;
    subtitle: CSSProperties;
    buttonL: CSSProperties;
    buttonM: CSSProperties;
    buttonS: CSSProperties;
    quote: CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    paragraphL: true;
    paragraphM: true;
    paragraphS: true;
    paragraphXS: true;
    menuItem: true;
    subtitle: true;
    buttonL: true;
    buttonM: true;
    buttonS: true;
    subtitle1: false;
    subtitle2: false;
    body1: false;
    body2: false;
    quote: true;
  }
}

declare module '@mui/material/styles' {
  interface BreakpointOverrides {
    xs: false; // removes the `xs` breakpoint
    sm: false;
    md: false;
    lg: false;
    xl: false;
    mobile: true; // adds the `mobile` breakpoint
    mobileS: true;
    tablet: true;
    laptop: true;
    desktop: true;
  }
}

// Augment the palette to include an ochre color
declare module '@mui/material/styles' {
  interface Palette {
    light: Palette['primary'];
  }

  interface PaletteOptions {
    light?: PaletteOptions['primary'];
  }
}

// Update the Button's color options to include an ochre option
declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    light: true;
  }
}

declare module '@mui/material/CircularProgress' {
  interface CircularProgressPropsColorOverrides {
    light: true;
  }
}

const BREAKPOINTS = {
  breakpoints: {
    values: {
      mobile: 0,
      mobileS: 400,
      tablet: 768,
      laptop: 1200,
      desktop: 1440,
    },
  },
};

export const font = '"Sora", sans-serif';

export let theme = createTheme(BREAKPOINTS);
theme = createTheme(theme, {
  palette: {
    primary: {
      light: '#000cff96',
      main: '#000CFF',
      dark: '#000cffbf',
      contrastText: '#FFFFFF',
    },
    secondary: {
      main: '#FFBCBC',
      light: '#ffbcbc96',
      dark: '#ffbcbcbf',
      contrastText: '#000347',
    },
    light: {
      main: '#FFF',
      contrastText: '#000347',
    },
    error: {
      main: '#FF4545',
      contrastText: '#FFF',
    },
    warning: {
      main: '#FFB800',
      contrastText: '#000347',
    },
    info: {
      main: '#000347',
      contrastText: '#FFF',
    },
    success: {
      main: '#71DE82',
      contraxtText: '#FFF',
    },
    common: {
      black: '#000',
      white: '#FFF',
    },
    text: {
      primary: '#000347',
      secondary: '#5E6083',
    },
    divider: {},
    grey: { 400: '#E7E8F1B2', 500: '#E7E8F1', 600: '#C4C6DC', 700: '#BBBCCD' },
    background: {
      paper: '#FFF',
      default: '#FFF',
    },
  },
  typography: {
    fontFamily: font,
    allVariants: {
      fontFamily: font,
    },
    ...TypographyStyles(theme),
  },

  components: {
    MuiButton: {
      ...MuiButtonStyles,
    },
    MuiOutlinedInput: {
      ...MuiOutlinedInputStyles,
    },
    MuiInput: {
      ...MuiInputStyles,
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: font,
        },
      },
      defaultProps: {
        variantMapping: {
          paragraphL: 'p',
          paragraphM: 'p',
          paragraphS: 'p',
          paragraphXS: 'p',
        },
      },
    },
    MuiAvatar: {
      ...MuiAvatarStyles,
    },
    MuiCheckbox: {
      ...MuiCheckboxStyles,
    },
    MuiSlider: {
      ...MuiSliderStyles,
    },
    MuiAutocomplete: {
      ...MuiAutocompleteStyles,
    },
    MuiMenuItem: {
      ...MuiMenuItemStyles,
    },
    MuiSelect: {
      ...MuiSelectStyles,
    },
    MuiDivider: {
      ...MuiDividerStyles,
    },
    MuiTab: {
      ...MuiTabStyles,
    },
    MuiTabs: {
      ...MuiTabsStyles,
    },
    MuiAlert: {
      ...MuiAlertStyles,
    },
    MuiLinearProgress: {
      ...MuiLinearProgressStyles,
    },
    MuiCircularProgress: {
      ...MuiCircularProgressStyles,
    },
    MuiSkeleton: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
        },
      },
    },
    MuiChip: {
      ...MuiChipStyles,
    },
  },
});
