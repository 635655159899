import { Box } from '@mui/material';
import { useContext } from 'react';
import SidebarImg from '../../assets/SidebarImage.jpg';

import { DonationFromWidgetContext } from '../../providers/donation-from-widget-provider';

export const DonationSideContainer = () => {
  const { verticalImage } = useContext(DonationFromWidgetContext);
  return (
    <Box
      sx={{
        width: '30%',
        display: { mobile: 'none', laptop: 'flex' },
        position: 'relative',
      }}
    >
      <img
        src={verticalImage || SidebarImg}
        alt="sidebar-graphic"
        height="100%"
        width="100%"
        style={{ objectFit: 'cover', objectPosition: 'top' }}
      />
    </Box>
  );
};

export default DonationSideContainer;
