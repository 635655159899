import { Box, Button, Card, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { primaryColor, shadowSM, white } from '../../styles/variables';

import LinkIcon from '@mui/icons-material/Link';
import { config } from '../../api/config';
import { updateSuccessfulPayment } from '../../api/payments';
import { FacebookIcon } from '../../assets/icons';
import LinkedInIcon from '../../assets/icons/social-media/LinkedinIcon';
import WhatsappIcon from '../../assets/icons/social-media/WhatsappIcon';
import { getLocalAccessToken, resetStorage } from '../../common/utils';
import Navbar from '../../components/navbar/Navbar';
import { AuthenticationContext } from '../../providers/authentication-provider';
import { LanguageContext } from '../../providers/language-selector-provider';
import { NotificationsContext } from '../../providers/notifications-provider';
import { formatNextDebitDate } from '../DonationFlowPage/DonationFlow.utils';
import { openShareMenu } from '../MyGiftShiftPage/MyGiftShift.utils';
import ThankYouPageSkeleton from './ThankYouPageSkeleton';
import { getThankYouMessage } from './utils';

//type
import type { CountryCharities } from '../../models/Charities';

interface TCharity {
  id: string;
  name: string;
  logo: string | undefined;
}

export const ThankYouPage = () => {
  const navigate = useNavigate();
  const { selectedLanguage, isEnglish } = useContext(LanguageContext);
  const { authenticatedUser } = useContext(AuthenticationContext);

  const [charities, setCharities] = useState<TCharity[]>([]);
  const [nextDonationDate, setNextDonationDate] = useState<string | undefined>(undefined);
  const [loadingData, setLoadingData] = useState(true);

  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get('sessionId');
  const changeBundle = searchParams.get('change-bundle');
  const idealMethod = searchParams.get('ideal-method');
  const redirectStatus = searchParams.get('redirect_status');
  const emptyPayment = searchParams.get('empty-payment');

  const { setNotification } = useContext(NotificationsContext);
  const [isPendingPayment, setIsPendingPayment] = useState(false);

  const token = getLocalAccessToken();

  useEffect(() => {
    if (redirectStatus === 'failed') {
      const paymentIntentClientSecret = searchParams.get('payment_intent_client_secret');

      navigate(`/payment-error?payment_intent_client_secret=${paymentIntentClientSecret}`);

      return;
    }
    if (!sessionId && !changeBundle && !idealMethod && !emptyPayment) {
      resetStorage();
      navigate('/');
    } else {
      if (sessionId) {
        resetStorage();
        updateSuccessfulPayment(sessionId, token)
          .then((response) => {
            setCharities(response.data.charities);
            if (response.data.paymentStatus === 'pending') {
              setIsPendingPayment(true);
            }
            if (response.data.nextDonationDate) {
              setNextDonationDate(
                formatNextDebitDate(response.data.nextDonationDate, selectedLanguage)
              );
            }

            setLoadingData(false);
          })
          .catch(() => {
            navigate('/');
          });
      } else {
        const selectedCharities = localStorage.getItem('selectedValues');
        const nextDebitDate = localStorage.getItem('nextDonationDate');

        if (selectedCharities) {
          setCharities((JSON.parse(selectedCharities) as CountryCharities).charities);
        }
        if (nextDebitDate && !idealMethod) {
          setNextDonationDate(formatNextDebitDate(nextDebitDate, selectedLanguage));
        }
        setLoadingData(false);
        resetStorage();
      }
    }
  }, [sessionId, navigate, selectedLanguage, changeBundle]);

  const { t } = useTranslation();

  const charitiesListText = () => {
    const charitiesNames = charities?.map((charity) => charity.name);
    switch (charitiesNames?.length) {
      case 0:
      case undefined: {
        return '';
      }
      case 1: {
        return charitiesNames[0];
      }
      case 2: {
        return `${charitiesNames[0]} ${t('and')} ${charitiesNames[1]}`;
      }
      case 3: {
        return `${charitiesNames[0]}, ${charitiesNames[1]} ${t('and')} ${charitiesNames[2]}`;
      }

      default: {
        return `${charitiesNames?.length} ${t('charities')}`;
      }
    }
  };

  return (
    <>
      <Navbar />
      <Stack
        gap={5}
        width={'100%'}
        py={{ mobile: '124px', tablet: '118px', laptop: '94px' }}
        alignItems={'center'}
        minHeight={{
          mobile: 'calc(100vh - 56px)',
          tablet: 'calc(100vh - 88px)',
          laptop: 'calc(100vh - 113px)',
        }}
        sx={{ backgroundColor: primaryColor }}
        px={{ mobile: '20px', tablet: 0 }}
      >
        {loadingData ? (
          <ThankYouPageSkeleton />
        ) : (
          <>
            <Stack gap={2} flexDirection={'row'} flexWrap={'wrap'} justifyContent={'center'}>
              {charities?.map((charity) => (
                <Card
                  key={charity.id}
                  sx={{
                    width: '167px',
                    height: '125px',
                    borderRadius: '14px',
                    boxShadow: shadowSM,
                    padding: '16px 24px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <img src={charity.logo} alt="logo" width={'100%'} />
                </Card>
              ))}
            </Stack>
            <Stack gap={1} maxWidth={{ mobile: '100%', tablet: '508px', laptop: '592px' }}>
              <Typography variant="h1" color={white} textAlign={'center'}>
                {t('thankYouForContribution')}
              </Typography>
              <Stack spacing={isPendingPayment ? 3 : 0} width="100%">
                <Typography variant="paragraphL" textAlign={'center'} color={white}>
                  {`${
                    charities?.length === 1
                      ? t('happyWithYourSupportOneCharity', { charity: charitiesListText() })
                      : t('happyWithYourSupport', { charities: charitiesListText() })
                  }`}
                </Typography>
                <Typography variant="paragraphL" textAlign={'center'} color={white}>
                  {getThankYouMessage(isPendingPayment, nextDonationDate, t)}
                </Typography>
              </Stack>
            </Stack>
            <Stack gap={2}>
              <Typography variant="paragraphS" color={white} textAlign={'center'}>
                {t('evenMoreImpactBySharing')}
              </Typography>
              <Stack flexDirection={'row'} gap={1.5} justifyContent={'center'}>
                <Link
                  to={`https://www.facebook.com/sharer.php?u=${config.FE_APP_URL}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box
                    sx={{
                      p: '10px 16px',
                      borderRadius: '8px',
                      backgroundColor: white,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <FacebookIcon />
                  </Box>
                </Link>
                <Link
                  to={`https://www.linkedin.com/sharing/share-offsite/?url=${config.FE_APP_URL}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box
                    sx={{
                      p: '10px 16px',
                      borderRadius: '8px',
                      backgroundColor: white,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <LinkedInIcon />
                  </Box>
                </Link>
                <Link
                  to={`https://wa.me/?text=${config.FE_APP_URL}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Box
                    sx={{
                      p: '10px 16px',
                      borderRadius: '8px',
                      backgroundColor: white,
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <WhatsappIcon />
                  </Box>
                </Link>
                <Box
                  onClick={() => {
                    openShareMenu(
                      config.FE_APP_URL,
                      t('shareText'),
                      () => {
                        setNotification({
                          message: t('copyClipboard'),
                          type: 'info',
                        });
                      },
                      () => {
                        setNotification({
                          message: t('somethingWentWrong', { message: t('pleaseTryAgain') }),
                          type: 'error',
                        });
                      }
                    );
                  }}
                  sx={{
                    p: '10px 16px',
                    borderRadius: '8px',
                    backgroundColor: white,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                  }}
                >
                  <LinkIcon style={{ transform: 'rotate(-45deg)' }} />
                </Box>
              </Stack>
              {authenticatedUser ? (
                <Stack alignItems={'center'} marginTop={{ mobile: 1, tablet: 3 }}>
                  <Button
                    color="secondary"
                    variant="contained"
                    size="large"
                    onClick={() => {
                      navigate(isEnglish ? '/my-giftshift' : '/mijn-giftshift');
                    }}
                  >
                    {t('toMyGiftShift')}
                  </Button>
                </Stack>
              ) : null}
            </Stack>
          </>
        )}
      </Stack>
    </>
  );
};

export default ThankYouPage;
