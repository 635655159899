import { type AlertProps } from '@mui/material';
import { type Theme } from '@mui/material/styles';
import { font } from '../custom-theme';

export const MuiAlertStyles = {
  styleOverrides: {
    icon: ({ theme }: { theme: Theme }) => ({
      opacity: 1,
      marginRight: '8px',
      padding: '2px',
      '& svg': {
        width: '14px',
        height: '14px',
      },

      [theme.breakpoints.up('tablet')]: {
        marginRight: '10px',
        '& svg': {
          width: '16px',
          height: '16px',
        },
      },
      [theme.breakpoints.up('laptop')]: {
        marginRight: '12px',
        '& svg': {
          width: '18px',
          height: '18px',
        },
      },
    }),
    root: ({ ownerState, theme }: { theme: Theme; ownerState: AlertProps }) => ({
      fontFamily: font,
      borderRadius: '8px',
      padding: '6px 8px',
      alignItems: 'center',
      ...(ownerState.severity === 'error' && {
        color: theme.palette.error.main,
        backgroundColor: theme.palette.secondary.light,
      }),
      [theme.breakpoints.up('tablet')]: {
        padding: '8px 10px',
      },
      [theme.breakpoints.up('laptop')]: {
        padding: '10px 12px',
      },
    }),
    message: () => ({
      padding: '0',
    }),
  },
};
