import { axiosPrivate } from '../common/axiosPrivate';
import { axiosPublic } from '../common/axiosPublic';

export type CreateCoupons = {
  startDate: string;
  endDate: string;
  amount: number;
  numberCoupons: number;
  prefix: string;
  client: string;
  campaign: string;
};

export type GeneratedCoupon = Omit<CreateCoupons, 'numberCoupons' | 'prefix'> & {
  id: string;
  isRedeemed: boolean;
};

export const checkCouponValidity = async (coupon: string) =>
  await axiosPublic.post('/validate-coupon/', {
    couponId: coupon,
  });

export const generateCoupons = async (body: CreateCoupons) =>
  await axiosPrivate.post('/coupons/', body);

export const getAllCoupons = async () => await axiosPrivate.get<GeneratedCoupon[]>('/coupons/');

export const deleteCoupon = async (couponId: string) =>
  await axiosPrivate.delete('/coupons/', { data: { couponId } });
