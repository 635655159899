import { CircularProgress, Stack } from '@mui/material';

const LoadingComponent = () => {
  return (
    <Stack width={'100%'} height="100vh" justifyContent={'center'} alignItems={'center'} gap={2}>
      <CircularProgress />
    </Stack>
  );
};

export default LoadingComponent;
