import { type TFunction } from 'i18next';
import { object, string } from 'yup';
import { type TPasswordRequirements } from './types';

export const passwordRequirementsInitialValues: TPasswordRequirements = {
  minimum8Chars: false,
  oneCapitalLetter: false,
  oneSpecialCharacter: false,
};

// eslint-disable-next-line no-useless-escape
const SPECIAL_CHAR_FORMAT = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
const PASSWORD_MINIMUM_LENGTH = 8;

export const containsSpecialCharacter = (str: string) => SPECIAL_CHAR_FORMAT.test(str);

export const containsCapitalLetter = (str: string) => /[A-Z]/.test(str);

export const hasMinimumChars = (str: string) => str.length >= PASSWORD_MINIMUM_LENGTH;

export const validateEmail = (email: string) => {
  return email.match(
    // eslint-disable-next-line no-useless-escape
    /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  );
};

export const validateName = (name: string) => {
  return name.match(/^[a-zA-Z0-9\-\s]+$/) !== null && name.length >= 3;
};

export const registerSchema = (t: TFunction) =>
  object().shape({
    name: string()
      .min(3, t('nameMustBeAtLeastAndNoSymbols'))
      .matches(/^[a-zA-Z0-9\-\s]+$/, t('nameMustBeAtLeastAndNoSymbols')),
    email: string().email(t('invalidEmailAddress')),
    password: string(),
  });
