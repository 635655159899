import LinkIcon from '@mui/icons-material/Link';
import { Card, Grid, Skeleton, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { config } from '../../../../api/config';
import Illu2 from '../../../../assets/Illu2';
import { FacebookIcon, LinkedInIcon, WhatsappIcon } from '../../../../assets/icons';
import SocialButton from '../../../../components/social-button/SocialButton';
import { MyGiftShiftContext } from '../../../../providers/my-giftshift-provider';
import { NotificationsContext } from '../../../../providers/notifications-provider';
import { primaryColor } from '../../../../styles/variables';
import { openShareMenu } from '../../MyGiftShift.utils';

const ShareButtonsCard = () => {
  const { t } = useTranslation();
  const { setNotification } = useContext(NotificationsContext);
  const { loadingData } = useContext(MyGiftShiftContext);

  return loadingData ? (
    <Skeleton
      variant="rounded"
      width={'100%'}
      sx={{ height: { mobile: '570px', tablet: '457px', laptop: '475px' } }}
    />
  ) : (
    <Card
      sx={{
        backgroundColor: primaryColor,
        borderRadius: '12px',
        overflow: 'initial',
        px: 4,
        py: 7,
        scrollMarginTop: { mobile: '172px', tablet: '192px', laptop: '161px' },
        scrollMarginBlockStart: { mobile: '172px', tablet: '192px', laptop: '161px' },
        boxShadow: 'none',
      }}
    >
      <Stack gap={3} alignItems={'center'}>
        <Illu2 />
        <Typography variant="h4" textAlign={'center'} color="white">
          {t('share')}
        </Typography>
        <Grid container spacing={2} style={{ width: '100%' }}>
          <Grid item mobile={12} tablet={6}>
            <Link
              to={`https://wa.me/?text=${t('shareText')} ${config.FE_APP_URL}`}
              rel="noopener noreferrer"
              target="_blank"
              style={{ textDecoration: 'none' }}
            >
              <SocialButton
                icon={<WhatsappIcon />}
                label={t('shareVia', { socialMediaName: 'WhatsApp' })}
              />
            </Link>
          </Grid>
          <Grid item mobile={12} tablet={6}>
            <Link
              to={`https://www.facebook.com/sharer.php?u=${config.FE_APP_URL}`}
              rel="noopener noreferrer"
              target="_blank"
              style={{ textDecoration: 'none' }}
            >
              <SocialButton
                icon={<FacebookIcon />}
                label={t('shareOn', { socialMediaName: 'Facebook' })}
              />
            </Link>
          </Grid>
          <Grid item mobile={12} tablet={6}>
            <Link
              to={`https://www.linkedin.com/sharing/share-offsite/?url=${config.FE_APP_URL}`}
              rel="noopener noreferrer"
              target="_blank"
              style={{ textDecoration: 'none' }}
            >
              <SocialButton
                icon={<LinkedInIcon />}
                label={t('shareOn', { socialMediaName: 'LinkedIn' })}
              />
            </Link>
          </Grid>
          <Grid item mobile={12} tablet={6}>
            <SocialButton
              icon={<LinkIcon style={{ transform: 'rotate(-45deg)' }} />}
              label={t('shareLink')}
              onClick={() => {
                openShareMenu(
                  config.FE_APP_URL,
                  t('shareText'),
                  () => {
                    setNotification({
                      message: t('copyClipboard'),
                      type: 'info',
                    });
                  },
                  () => {
                    setNotification({
                      message: t('somethingWentWrong', { message: t('pleaseTryAgain') }),
                      type: 'error',
                    });
                  }
                );
              }}
            />
          </Grid>
        </Grid>
      </Stack>
    </Card>
  );
};

export default ShareButtonsCard;
