export const en = {
  translation: {
    // a
    aboutUs: 'About us',
    accept: 'Accept',
    active: 'Active',
    addButton: 'Add',
    addition: 'Addition',
    additionalOneTimeDonation: 'An additional one-time donation',
    additionToMonthlyDonation:
      'In addition to my monthly donation, make an extra one-time donation to the ',
    address: 'Address',
    adjust: 'Adjust',
    adjustBundle: 'Adjust bundle',
    adjustYourSearch:
      'Adjust your search or come back later if we have connected multiple charities.',
    alreadyHaveAnAccount: 'Do you already have an account?',
    analysisAndPerformance: 'Analysis and performance',
    analysisAndPerformanceContent:
      'With your registration you also help us to reach people better. We try to look closely at the behavior of our users so that we can make the donation pie bigger. Of course we do not share your data to earn money from you, but to serve you better.',
    and: 'and',
    anonymously: 'Anonymously',
    apply: 'Apply',
    areYouSure: 'Are you sure?',
    aroundDateOfTheMonth: 'Around the {{day}}th of the month',

    // b
    bankAccountUnusable:
      'The bank account provided cannot be used. A different bank account must be used.',
    bankAccountVerificationFailed:
      'The bank account cannot be verified, either because the microdeposit amounts provided do not match the actual amounts, or because verification has failed too many times.',
    bundle: 'Bundle',
    buttonTextError: 'Button text is required and should contain maximum 20 characters.',

    // c
    cancel: 'Cancel',
    cancellableAnyTime: 'Cancellable at any time',
    card: 'Credit or debit card',
    cardDeclined: 'The card has been declined.',
    city: 'City',
    changeBundleWithNewSelection: 'You change your bundle to your new selection from',
    changeDonationExtraInfo:
      'The current IBAN number is <strong>*{{iban}}</strong>. Your new donation will be debited on <strong>{{nextDonationDate}}</strong>.',
    changePreferredCountryWarning:
      'Changing your preferred country will remove your current active bundle.',
    charities: 'charities',
    charitiesChosenThisMonth: 'We have chosen the following charities this month.',
    clear: 'Clear',
    code: 'Code',
    commission:
      '2.5% of the donation is used for the maintenance and development of the platform. You will find more explanation <0>here</0>.',
    confirmRegistrationToContinue:
      'Confirm your registration to continue. Please note, it may take a while before you receive the email or it may end up in your spam folder.',
    continueAnonymously: 'Continue anonymously',
    continue: 'Continue',
    continueWith: 'Continue with',
    continueWithoutRegistration: 'Continue without registration',
    confirmPassword: 'Confirm password',
    cookies: 'Cookies',
    cookieMessage:
      'GiftShift uses necessary cookies to ensure that our site works properly. By continuing to browse this site, you agree to the use of these cookies. For more information about the cookies we use and how you can manage them, see our',
    cookieStatement: 'Cookie Statement',
    country: 'Country',
    createBundle: 'Create bundle',
    copyClipboard: 'Copied to the clipboard',
    couponCode: 'Do you have a code? Enter it here.',

    // d
    data: 'data',
    dateOfBirth: 'Date of birth',
    decline: 'Decline',
    deleteAccount: 'Delete account',
    date: 'Date',
    details: 'Details',
    differentPreferredCountry:
      'Please select charities from selected preferred country ({{country}})',
    dutch: 'Dutch',
    donate: 'Donate',
    donationAmountQuestion: 'What do you want to donate?',
    donationOverview: 'Donation overview',
    dontHaveAnAccount: "Don't have an account?",

    // e
    emailAddress: 'E-mail address',
    emailExists: 'This e-mail address already exists',
    ensureNextDebitOn:
      'Your first donation will be debited within 7 days. The next donation will take place around {{date}}.',
    evenMoreImpactBySharing: 'You can make even more impact by sharing:',
    english: 'English',
    expiredCard: 'The card has expired. Check the expiration date or use a different card.',
    extra: 'Extra',
    extraOneTimeInfo:
      'Even though you already donate monthly, you can choose to make an extra one-off donation to a charity that could use it right now.',

    // f
    female: 'Female',
    forgotPassword: 'Forgot password?',
    forgotYourPassword: 'Forgot your password',
    forward: 'Forward {{value}}',
    frequency: 'Frequency',
    fieldShouldContainMaximum50characters: 'field should contain maximum 50 characters.',
    fieldShouldContainNoSpecialCharacters: "field shouldn't contain any special characters.",
    filter: 'Filter',
    findTheBestCharities:
      'Find the best charities that suit you and create your own donation bundle.',

    // g
    gender: 'Gender',

    // h
    happyWithYourSupport: '{{charities}} are very happy with your support.',
    happyWithYourSupportOneCharity: '{{charity}} is very happy with your support.',
    haveAtLeast8Chars: 'Have at least 8 characters',
    haveOneCapitalLetter: 'Have at least one capital letter',
    haveSpecialCharacter: 'Have at least one special character',
    headingTextError: 'Heading text should contain maximum 150 characters.',
    healthAndHumanRights: 'The combination of health and human rights is important to me.',
    hi: 'Hi',
    home: 'Home',
    houseNumber: 'House number',
    howNiceOfYouToDonate: 'How nice of you to donate again!',
    howOftenDonate: 'How often do you want to donate?',

    // i
    ibanNumber: 'IBAN Number of Credit Card',
    ideal: 'iDeal',
    idRatherNotSay: "I'd rather not say",
    ifYouDeleteYourAccount:
      'If you delete your account, you will lose your entire donation history and you will no longer be able to log in.',
    impactAndOverview: 'Impact and overview',
    impactAndOverviewContent:
      'In My GiftShift you will find your donations and impact. With your registration you always have an overview of your contribution.',
    inactive: 'Inactive',
    incorrectInput: 'The input in incorrect',
    incorrectPassword:
      'The entered password is incorrect or the specified account does not exist in our system.',
    insufficientFunds:
      'Your credit card was declined because of insufficient funds. Try paying with a debit card instead.',
    invalidCoupon: 'Error message (already redeemed, invalid or expired)',
    invalidEmailAddress: 'The entered e-mail address is invalid or it does not exist.',
    invalidPostCode: 'The entered zip code is invalid.',
    invalidHouseNumber: 'The entered house number is invalid.',
    invalidStreetValue: 'The entered street value is invalid.',
    invalidCityValue: 'The entered city value is invalid.',

    // j

    // k
    keepAnEye: 'Keep an eye on GiftShift for updates.',
    keepBundle: 'Keep bundle',
    keepAccount: 'Keep account',

    // l
    lastDonation: 'Last donation',
    loadMore: 'Load more',
    login: 'Login',
    logout: 'Log out',

    // m
    male: 'Male',
    makeAdditionalDonation: 'Do an extra one-time donation',
    makeFirstDonation: 'You are now going to make your first donation. 🎉',
    makeVerificationPayment: 'Modify',
    mergeBundles: 'Merge bundles',
    minDonationAmountError:
      'The minimum donation amount is €5. Every little bit helps, but because of the costs that a payment entails, we had to choose this option.',
    modify: 'Modify',
    monthly: 'Monthly',
    monthlyDonationExtra:
      'This is the most efficient form of donation. In this way we have to incur fewer costs for the transactions and continuity is better guaranteed. Thank you in advance! ❤️',
    myBundle: 'My bundle',
    myDetails: 'My details',
    myDonationOverview: 'My donation overview',
    myGiftShift: 'My GiftShift',

    // n
    name: 'Name',
    nameMustBeAtLeastAndNoSymbols:
      "Name must be at least 3 characters long and shouldn't contain symbols.",
    nextDonationDate: 'Next donation date',
    nextDonationDebitDate: 'Your next donation will be debited on <strong>{{date}}</strong>.',
    nextDonationDatePendingMessage:
      'Your first donation will be debited shortly. We are currently still waiting for approval from your bank.',
    nextStep: 'Next step',
    no: 'No',
    noCharitiesFound: 'No charities found',
    noMonthlyBundle: 'You have not set up a monthly bundle yet.',
    noPreviousDonations: 'You have not made any previous donations through GiftShift',
    noRegistration: 'No registration',

    //o
    okay: 'Okay',
    oneTime: 'One-time',
    orLoginWith: 'or login with',
    otherwise: 'Otherwise',
    other: 'Other',
    overwriteBundle: 'Overwrite bundle',

    // p
    password: 'Password',
    passwordsDoNotMatch: 'Passwords do not match!',
    paymentMethodCustomerDecline:
      "You haven't authorized the payment. Please try again or choose another payment method so we can attempt to process the donation for you.",
    paymentMethodNotAvailable:
      'The payment processor for the provided payment method is temporarily unavailable. Please try a different payment method or retry later with the same payment method.',
    paymentMethodSuccess: 'Payment method successfully updated!',
    postCode: 'Postal code',
    pause: 'Pause',
    paused: 'Paused',
    pauseDonation: 'Pause donation',
    pauseOrAdjust: 'You can also pause or adjust your bundle as you wish.',
    pending: 'Pending',
    pendingPaymentNote:
      'Please note: It may take a while before the first donation can be debited. Keep an eye on ‘My GiftShift’ for updates.',
    pleaseTryAgain: 'Please try again.',
    poweredBy: 'Powered by',
    previous: 'Previous',
    preferredCountry: 'Preferred country',
    privacyPolicy: 'Privacy policy',
    processingError: 'An error occurred while processing the card.',

    // q

    // r
    receiveNewsletter: 'Receive newsletter',
    register: 'Register',
    registerDisclaimer:
      "By logging in I agree to the Terms of Service and Privacy Policy. I also agree to receive e-mails and communications related to GiftShift's services and offerings.",
    registerWithEmail: 'Register with e-mail address',
    required: 'Required {{field}}',
    rememberMe: 'Remember me',
    remove: 'Remove',
    removeBundle: 'Remove bundle',
    removeCode: 'Remove code',
    reset: 'Reset',
    resetPassword: 'Reset password',
    resume: 'Resume',

    // s
    save: 'Save',
    search: 'Search',
    securityAndPrivacy: 'Security & privacy',
    securityAndPrivacyContent:
      'Security and privacy: A donation platform that prioritises user-friendliness also values the security and privacy of its users. Your personal information is handled securely and you can rest assured that your donations are processed securely.',
    sendResetEmail: 'Send a reset email',
    selected: '',
    selectPaymentMethod: 'Select your payment method',
    sepa_debit: 'SEPA direct debit',
    setUpNewPassword: 'Set up a new password',
    share: 'Make an even bigger impact and share GiftShift with friends',
    shareOn: 'Share on {{socialMediaName}}',
    shareLink: 'Share with a link',
    shareVia: 'Share via {{socialMediaName}}',
    shareText: 'Donate to several charities at once via GiftShift.',
    shareYourEmailAddress: 'Do you want to share your email address with your chosen charities?',
    socialAccountExists:
      'It is also possible that you have previously used the Google or Facebook login.',
    somethingWentWrong: 'Oops! Something went wrong. {{message}}',
    spentCouponAmount: 'Voucher amount to be spent',
    subscribe: 'Subscribe',
    subtotal: 'Subtotal',
    subheadingTextError: 'Subheading text should contain maximum 250 characters.',
    successfullySent: 'Successfully sent {{data}}',
    stayInformedByEmail: 'I would like to subscribe to the newsletter',
    stayInformedShareBundle:
      'This way you stay informed of all the activities of your charities and the impact they make with your donation',
    status: 'Status',
    street: 'Street',

    // t
    taxBenefit: 'Tax benefit',
    taxBenefitContent:
      'In My GiftShift you will find overviews that you can submit for your income tax. This way you can reclaim part of your donation.',
    termsAndConditions: 'Terms and conditions',
    thankYouForContribution: 'Thank you for your contribution!',
    theEnteredDateIsInvalid: 'The submitted date is invalid.',
    thisIsOneTimeContribution: 'This is a one-time contribution.',
    thisIsMonthlyContribution: 'This is a monthly contribution.',
    toAdjustIbanNumber:
      'The current IBAN number is <strong>{{ibanNumber}}</strong>. Do you want to adjust it?',
    toMyGiftShift: 'To My GiftShift',
    total: 'Total',
    totalDonated: 'Total donated',
    totalDonatedTo: 'Total donated to {{charity}}',
    tryAgain: 'Try again',

    // u
    unsubscribe: 'Unsubscribe',
    unconfirmedEmail:
      "This email address is not yet registered in our system. It's possible you haven't confirmed your account. Please check your email for the confirmation mail and click the button to confirm your account. If you can't find the email, please contact us for assistance.",
    usability: 'Usability',
    usabilityContent:
      'Personalized settings, faster donations, access to donation history, better communication and easy donation management. It ensures an optimized and hassle-free experience for the users, motivating them to use the platform more often and contribute to charitable causes.',
    useLinkToResetPassword:
      'Use this to set your new password. Please note, it may take a while before you receive the email or it may end up in your spam folder.',

    // v
    verifyingLink: 'Verifying link',
    viewAllCharities: 'View all charities',

    // w
    welcomeBack: 'Welcome back {{name}}!',
    welcomeTo: 'Welcome to {{name}}',
    willBeMergedWithSelection: 'will be merged with your selection from ',
    whatDoYouCareAbout: 'What do you care about?',
    whenContinueDonating: 'When do you want to continue donating?',
    whichCharitiesToAdd: 'Which charities would you like to add to your bundle?',
    whichCharitiesSupportMonth: 'Which charities are you supporting this month?',
    whyRegister: 'Why register?',
    whyRegisterParagraph:
      'Do you find the hassle of registering? We get that. Nevertheless, we would like to explain to you why we recommend this. Of course it is nice for us to know who our user is. But there are also advantages for you:',

    // x

    // y
    yes: 'Yes',
    youAreCurrentlySubscribed: 'You are currently subscribed.',
    youAreCurrentlyUnsubscribed: 'You are currently unsubscribed.',
    youAlreadyHaveBundle:
      'You already have a monthly bundle with GiftShift. What do you want to do?',
    yourCode: 'Your code',
    youHaveReceivedAnEmail: 'You have received an e-mail',
    youHaveSelected: 'You have selected',
    youHaveMadeAGreatChoice:
      'You have made a great choice for some charities. There are still a number of steps to take before your donation can be made.',
    yourCurrentBundle: 'Your current bundle from ',
    yourPasswordMust: 'Your password must:',
    yourTotalDonation: 'Your total donation is',
    youCanChangeYourPasswordViaEmail: 'You can change your password via email.',
    youHaveSetNoPassword:
      'You have not set a password because you are logged in via Google/Facebook.',
    yourPrivacy: 'Your privacy',
    //z
  },
};

export default en;
