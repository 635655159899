import { useContext } from 'react';
import { RouterProvider } from 'react-router-dom';
import './App.css';
import LoadingComponent from './components/loading/LoadingComponent';
import { LanguageContext } from './providers/language-selector-provider';
import router from './router';

function App() {
  const { selectedCountry, selectedLanguage } = useContext(LanguageContext);

  if (!selectedCountry || !selectedLanguage) {
    return <LoadingComponent />;
  }

  return (
    <div className="App">
      <RouterProvider router={router(`/${selectedCountry}/${selectedLanguage}`)} />
    </div>
  );
}

export default App;
