import { Box, Grid, Typography } from '@mui/material';
import { SketchPicker } from 'react-color';

import { disabledColor } from '../../../styles/variables';
import { openColorPickerDefaultValues } from '../helpers';
import type { TColorPickerOpen } from '../types';

type HeadingsSectionProps = {
  headingColor: string;
  subheadingColor: string;
  isColorPickerOpen: TColorPickerOpen;
  setColorPickerOpen: (val: TColorPickerOpen) => void;
  setHeadingColor: (val: string) => void;
  setSubheadingColor: (val: string) => void;
};

export const HeadingsSection = ({
  headingColor,
  subheadingColor,
  isColorPickerOpen,
  setColorPickerOpen,
  setHeadingColor,
  setSubheadingColor,
}: HeadingsSectionProps) => {
  return (
    <>
      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <Grid item>
          <Typography variant="h3">Heading Color</Typography>
        </Grid>
        <Grid container item width={'fit-content'} alignItems="center" justifyContent={'flex-end'}>
          <Box
            sx={{
              width: '56px',
              height: '56px',
              borderRadius: '16px',
              border: `1px solid ${disabledColor}`,
              backgroundColor: headingColor,
              boxShadow: '0px 0px 0px 6px #FFF inset',
              cursor: 'pointer',
            }}
            onClick={() => {
              setColorPickerOpen({
                ...openColorPickerDefaultValues,
                heading: !isColorPickerOpen.heading,
              });
            }}
          />
          {isColorPickerOpen.heading && (
            <Box sx={{ position: 'absolute', zIndex: 2 }}>
              <Box
                sx={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px' }}
                onClick={() =>
                  setColorPickerOpen({
                    ...openColorPickerDefaultValues,
                    heading: !isColorPickerOpen.heading,
                  })
                }
              />
              <SketchPicker
                color={headingColor}
                onChange={(color) => setHeadingColor(color.hex)}
                styles={{ default: { picker: { position: 'absolute', top: 20, right: 0 } } }}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <Grid item>
          <Typography variant="h3">Sub Heading Color</Typography>
        </Grid>
        <Grid container item width={'fit-content'} alignItems="center" justifyContent={'flex-end'}>
          <Box
            sx={{
              width: '56px',
              height: '56px',
              borderRadius: '16px',
              border: `1px solid ${disabledColor}`,
              backgroundColor: subheadingColor,
              boxShadow: '0px 0px 0px 6px #FFF inset',
              cursor: 'pointer',
            }}
            onClick={() => {
              setColorPickerOpen({
                ...openColorPickerDefaultValues,
                subHeading: !isColorPickerOpen.subHeading,
              });
            }}
          />
          {isColorPickerOpen.subHeading && (
            <Box sx={{ position: 'absolute', zIndex: 2 }}>
              <Box
                sx={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px' }}
                onClick={() =>
                  setColorPickerOpen({
                    ...openColorPickerDefaultValues,
                    subHeading: !isColorPickerOpen.subHeading,
                  })
                }
              />
              <SketchPicker
                color={subheadingColor}
                onChange={(color) => setSubheadingColor(color.hex)}
                styles={{ default: { picker: { position: 'absolute', top: 20, right: 0 } } }}
              />
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default HeadingsSection;
