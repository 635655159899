import { FormControl, Grid, RadioGroup, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { getPaymentMethods } from '../../../../api/payments';
import CouponReadOnly from '../../../../components/coupon-input/CouponReadOnly';
import { AuthenticationContext } from '../../../../providers/authentication-provider';
import { DonationFlowContext } from '../../../../providers/donation-flow-provider';
import { LanguageContext } from '../../../../providers/language-selector-provider';
import { NotificationsContext } from '../../../../providers/notifications-provider';
import { handleRedirectUserPreferredCountry } from '../../../../providers/utils';
import { darkBlue, textColor } from '../../../../styles/variables';
import { formatDateToLocaleString } from '../../../MyGiftShiftPage/MyGiftShift.utils';
import { DonationSteps } from '../../DonationFlow.utils';
import CheckExistingBundleSkeleton from '../check-existing-bundle-step/CheckExistingBundleSkeleton';
import DonationCheckoutCard from './DonationCheckoutCard';
import { getPaymentComponents } from './helpers';

//types
import type { TPaymentMethod } from '../../../../models/Donation';
import type { PaymentMethod } from '../../../../models/Payments';

export const SummaryAndPaymentStep = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { selectedCountry, selectedLanguage, setSelectedCountry, isEnglish } =
    useContext(LanguageContext);
  const {
    paymentMethod: { paymentMethod },
    handleChoosePaymentMethod,
    donationFrequency,
    currentBundle,
    bundleInfo,
    loadingData,
    selectedCharities,
    validCoupon,
    setValidCoupon,
  } = useContext(DonationFlowContext);
  const { authenticatedUser, userDetails } = useContext(AuthenticationContext);
  const { setNotification } = useContext(NotificationsContext);
  const [isLoadingPaymentMethods, setIsLoadingPaymentMethods] = useState(false);
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([]);

  const handleGetPaymentMethods = async () => {
    setIsLoadingPaymentMethods(true);
    try {
      const { data } = await getPaymentMethods(selectedCountry);

      setPaymentMethods(
        data.map((el) => {
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const { country, ...rest } = el;

          return { ...rest, donationFrequency };
        })
      );
    } catch {
      setNotification({
        type: 'error',
        message: t('somethingWentWrong', { message: t('pleaseTryAgain') }),
      });
    } finally {
      setIsLoadingPaymentMethods(false);
    }
  };

  useEffect(() => {
    if (!selectedCharities.length && !currentBundle.length) {
      navigate(`?step=${DonationSteps.SelectCharities}`);
    } else if (
      selectedCharities.some((el) => !el.donationAmount || parseInt(el.donationAmount) <= 0)
    ) {
      navigate(`?step=${DonationSteps.FrequencyAndAmount}`);
    } else if (
      authenticatedUser &&
      donationFrequency === 'monthly' &&
      userDetails.country !== selectedCountry
    ) {
      handleRedirectUserPreferredCountry(
        setSelectedCountry,
        `${isEnglish ? '/donate' : '/doneren'}?step=${DonationSteps.SelectCharities}`,
        () => {
          setNotification({
            message: t('differentPreferredCountry', { country: userDetails.country.toUpperCase() }),
            type: 'error',
          });
        }
      );
    }

    handleGetPaymentMethods();
  }, []);

  const isAlreadyMonthlyDonor =
    currentBundle.length > 0 &&
    bundleInfo &&
    (bundleInfo.status === 'active' || bundleInfo.status === 'pending') &&
    donationFrequency === 'monthly';

  const getMonthlyDonorGreeting = isAlreadyMonthlyDonor ? (
    <Typography
      variant="paragraphM"
      color={textColor}
      sx={{
        '& strong': {
          color: darkBlue,
        },
      }}
    >
      <Trans
        i18nKey={'changeDonationExtraInfo'}
        values={{
          iban: bundleInfo.ibanLast4,
          nextDonationDate: formatDateToLocaleString(
            bundleInfo.nextDonationDate,
            selectedLanguage,
            false,
            true
          ),
        }}
      />
    </Typography>
  ) : (
    <Typography variant="paragraphM" color={textColor}>
      {t('makeFirstDonation')}
    </Typography>
  );

  const getGreeting =
    donationFrequency === 'one-time' ? (
      <Typography variant="paragraphM" color={textColor}>
        {t('howNiceOfYouToDonate')}
      </Typography>
    ) : (
      getMonthlyDonorGreeting
    );

  return loadingData || isLoadingPaymentMethods || !paymentMethods.length ? (
    <CheckExistingBundleSkeleton />
  ) : (
    <Stack
      px={{ mobile: '20px', tablet: '40px', laptop: 0 }}
      mx={{ mobile: '0', laptop: '32px' }}
      width={{ mobile: '100%', laptop: '70%' }}
      maxWidth={'1216px'}
      pt={2}
    >
      <Grid container flexDirection={{ mobile: 'row', tablet: 'row-reverse' }} spacing={4}>
        <Grid item mobile={12} tablet={5}>
          <DonationCheckoutCard couponValue={validCoupon?.value} />
        </Grid>
        <Grid item mobile={12} tablet={7}>
          <Stack gap={3}>
            {authenticatedUser ? (
              <Stack gap={1}>
                <Typography variant="h3">{`${t('hi')} ${authenticatedUser?.name}`}</Typography>
                {getGreeting}
              </Stack>
            ) : null}

            {validCoupon && donationFrequency !== 'monthly' ? (
              <CouponReadOnly
                validCode={validCoupon.code}
                onRemoveCoupon={() => {
                  setValidCoupon(undefined);
                  localStorage.removeItem('coupon');
                }}
              />
            ) : null}

            {!isAlreadyMonthlyDonor ? (
              <>
                <Typography variant="h4">{t('selectPaymentMethod')}</Typography>
                <FormControl>
                  <RadioGroup
                    aria-labelledby="payment-method"
                    name="payment-method"
                    value={paymentMethod}
                    onChange={(event) => {
                      handleChoosePaymentMethod({
                        paymentMethod: event.target.value as TPaymentMethod['paymentMethod'],
                      });
                    }}
                  >
                    <Stack gap={2}>
                      {getPaymentComponents(
                        paymentMethods,
                        paymentMethod,
                        handleChoosePaymentMethod,
                        t
                      )}
                    </Stack>
                  </RadioGroup>
                </FormControl>
              </>
            ) : null}
          </Stack>
        </Grid>
      </Grid>
    </Stack>
  );
};

export default SummaryAndPaymentStep;
