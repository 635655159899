export const CheckedSuccesIcon = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="14" height="14" rx="7" fill="#71DE82" />
    <path
      d="M10.3438 4.65625C10.5469 4.84375 10.5469 5.17188 10.3438 5.35938L6.34375 9.35938C6.15625 9.5625 5.82812 9.5625 5.64062 9.35938L3.64062 7.35938C3.4375 7.17188 3.4375 6.84375 3.64062 6.65625C3.82812 6.45312 4.15625 6.45312 4.34375 6.65625L5.98438 8.29688L9.64062 4.65625C9.82812 4.45312 10.1562 4.45312 10.3438 4.65625Z"
      fill="white"
    />
  </svg>
);

export default CheckedSuccesIcon;
