import { Button, Dialog, DialogContent, IconButton, Slide, Stack, Typography } from '@mui/material';
import { type TransitionProps } from '@mui/material/transitions';
import { type ReactElement, type Ref, forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import LogoDark from '../../assets/LogoDarkSVG';
import { CloseIcon } from '../../assets/icons';
import { desktopL, disabledColor, textColor } from '../../styles/variables';
import ImageMolecule from '../molecules/ImageMolecule';

interface PartnerDialogProps {
  isOpen: boolean;
  partnerLogo: string;
  onContinue: () => void;
}

const Transition = forwardRef(
  (
    props: TransitionProps & {
      children: ReactElement;
    },
    ref: Ref<HTMLDivElement>
  ) => {
    return <Slide direction="up" ref={ref} {...props} />;
  }
);

const PartnerDialog = ({ isOpen, partnerLogo, onContinue }: PartnerDialogProps) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      sx={{
        boxShadow: desktopL,
        '& .MuiDialog-paper': {
          borderRadius: { mobile: '16px 16px 0px 0px', tablet: '16px' },
          position: { mobile: 'absolute', tablet: 'relative' },
          bottom: { mobile: 0 },
          margin: { mobile: 0, tablet: 4 },
          height: { mobile: '90%', tablet: 'unset' },
          width: { mobile: '100%', tablet: 'inherit' },
          maxWidth: 'tablet',
        },
      }}
      TransitionComponent={Transition}
    >
      <DialogContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          padding: 0,
        }}
      >
        <Stack sx={{ alignItems: 'flex-end', padding: { mobile: '16px', tablet: '20px 16px' } }}>
          <IconButton
            onClick={() => {
              onContinue();
            }}
          >
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack sx={{ gap: '32px', p: { mobile: '20px', tablet: '0px 80px 80px 80px' } }}>
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              gap: '29px',
            }}
          >
            <ImageMolecule
              image={partnerLogo}
              width="99px"
              height="40px"
              backgroundSize="contain"
            />
            <CloseIcon color={disabledColor} />
            <LogoDark width="82" height="41" />
          </Stack>
          <Stack gap={1}>
            <Typography variant="h3">{t('welcomeTo', { name: 'GiftShift!' })}</Typography>
            <Typography variant="paragraphL" sx={{ color: textColor }}>
              {t('youHaveMadeAGreatChoice')}
            </Typography>
          </Stack>
          <Stack width="fit-content">
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={() => {
                onContinue();
              }}
              sx={{ height: { mobile: '55px' } }}
            >
              {t('continue')}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default PartnerDialog;
