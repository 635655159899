import { Typography } from '@mui/material';
import { useContext } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CheckoutCard from '../../../../components/checkout-card/CheckoutCard';
import { DonationFlowContext } from '../../../../providers/donation-flow-provider';
import { LanguageContext } from '../../../../providers/language-selector-provider';
import { primaryColor } from '../../../../styles/variables';
import { removeCausesDuplicates } from '../../DonationFlow.utils';
import { config } from '../../../../api/config';

export const DonationCheckoutCard = ({ couponValue }: { couponValue: number | undefined }) => {
  const { t } = useTranslation();
  const { selectedCharities, totalAmount, donationFrequency } = useContext(DonationFlowContext);
  const { isEnglish } = useContext(LanguageContext);

  return (
    <CheckoutCard
      title={t('myBundle')}
      itemsList={removeCausesDuplicates(selectedCharities)}
      totalAmount={totalAmount}
      extraInfo={
        <>
          <Typography variant="paragraphS" color="textSecondary">
            {donationFrequency === 'one-time'
              ? t('thisIsOneTimeContribution')
              : t('thisIsMonthlyContribution')}
          </Typography>
        </>
      }
      titleTestId="MyBundle"
      couponValue={couponValue}
      donationFrequency={donationFrequency}
    />
  );
};

export default DonationCheckoutCard;
