import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getAllCharities } from '../api/charities';
import { config } from '../api/config';
import {
  createSubscription,
  initiatePayment,
  postEmptyPayment,
  postPayment,
} from '../api/payments';
import { changeBundle, getCurrentBundle } from '../api/subscription';
import { getLocalAccessToken, resetStorage } from '../common/utils';
import {
  DonationSteps,
  getCharitiesFromIds,
  mapCharities,
  mapCurrentBundle,
  removeCausesDuplicates,
} from '../pages/DonationFlowPage/DonationFlow.utils';
import { AuthenticationContext } from './authentication-provider';
import { LanguageContext } from './language-selector-provider';
import { NotificationsContext } from './notifications-provider';

import { IdealBankElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { DonationFromWidgetContext } from './donation-from-widget-provider';

// types
import type { FC, ReactNode, RefObject } from 'react';
import type { CountryCharities, TCharity, TSelectedCharity } from '../models/Charities';
import type {
  Coupon,
  TBundleInfo,
  TDonationFrequency,
  TMonthlyDonorOption,
  TPaymentMethod,
} from '../models/Donation';

export interface IDonationFlowContext {
  selectedCharities: TSelectedCharity[];
  currentBundle: TSelectedCharity[];
  allCharities: CountryCharities;
  donationFrequency: TDonationFrequency;
  handleSelectCharity: (charity: TCharity) => void;
  handleRemoveCharity: (charity: TCharity) => void;
  handleChooseFrequencyType: (frequencyType: TDonationFrequency) => void;
  onCharityAmountSelected: (val: string, charity: TCharity) => void;
  totalAmount: number;
  currentStep: number;
  handleNextStep: () => void;
  handlePreviousStep: () => void;
  loadingData: boolean;
  paymentMethod: TPaymentMethod;
  handleChoosePaymentMethod: (paymentMethod: TPaymentMethod) => void;
  handlePayment: () => void;
  handleChangeBundle: () => void;
  stepContainerRef: RefObject<HTMLDivElement> | null;
  loadingDonation: boolean;
  resetDonationFlow: () => void;
  monthlyDonorOption: string | undefined;
  handleChooseMonthlyDonorOption: (option: TMonthlyDonorOption) => void;
  mergeCurrentBundleWithNewSelection: () => void;
  bundleInfo: TBundleInfo | undefined;
  clientSecret: undefined | string;
  sendPaymentInitiation: () => void;
  setLoadingDonation: (state: boolean) => void;
  handleIdealSubmit: () => void;
  isBankSelectLoading: boolean;
  validCoupon: Coupon | undefined;
  setValidCoupon: (coupon: Coupon | undefined) => void;
  handleEmptyPayment: () => Promise<void>;
}

export const DonationFlowContext = createContext<IDonationFlowContext>({
  selectedCharities: [],
  currentBundle: [],
  allCharities: { charities: [], country: '' },
  donationFrequency: undefined,
  handleSelectCharity: () => {},
  handleRemoveCharity: () => {},
  handleChooseFrequencyType: () => {},
  onCharityAmountSelected: () => {},
  totalAmount: 0,
  currentStep: 1,
  handleNextStep: () => {},
  handlePreviousStep: () => {},
  loadingData: false,
  paymentMethod: { paymentMethod: undefined },
  handleChoosePaymentMethod: () => {},
  handlePayment: () => {},
  handleChangeBundle: () => {},
  stepContainerRef: null,
  loadingDonation: false,
  resetDonationFlow: () => {},
  monthlyDonorOption: undefined,
  handleChooseMonthlyDonorOption: () => {},
  mergeCurrentBundleWithNewSelection: () => {},
  bundleInfo: undefined,
  clientSecret: undefined,
  sendPaymentInitiation: () => {},
  setLoadingDonation: () => {},
  handleIdealSubmit: () => {},
  isBankSelectLoading: false,
  validCoupon: { code: '', value: 0 },
  setValidCoupon: () => {},
  handleEmptyPayment: async () => {},
});

export const DonationFlowProvider: FC<{ children?: ReactNode }> = (props) => {
  // helpers
  const { setNotification } = useContext(NotificationsContext);
  const {
    loadingData,
    setLoadingData,
    preselectedCharitiesFromWidget,
    preselectedDonationFrequency,
    donationsAmountsFromWidget,
    widgetData,
  } = useContext(DonationFromWidgetContext);
  const navigate = useNavigate();
  const { setCheckYourEmailState, authenticatedUser, userDetails } =
    useContext(AuthenticationContext);
  const { selectedCountry, selectedLanguage, isEnglish } = useContext(LanguageContext);
  const { t } = useTranslation();
  const stepContainerRef = useRef<HTMLDivElement>(null);
  const selectedValuesFromLocalStorage = localStorage.getItem('selectedValues');
  const selectedValuesParsed = selectedValuesFromLocalStorage
    ? (JSON.parse(selectedValuesFromLocalStorage) as CountryCharities)
    : undefined;
  const selectedDonationFrequency = localStorage.getItem('donationFrequency') as TDonationFrequency;

  const [searchParams, setSearchParams] = useSearchParams();
  const step = searchParams.get('step');
  const partnerId = searchParams.get('pid');
  const routeStep =
    (selectedValuesParsed?.charities || partnerId || widgetData?.type) &&
    step &&
    !isNaN(parseInt(step))
      ? parseInt(step)
      : 1;
  const initialStep =
    routeStep === DonationSteps.Authentication && authenticatedUser
      ? DonationSteps.SummaryAndPayment
      : routeStep;
  const currentStep = step ? parseInt(step) : initialStep;

  // state
  const [loadingDonation, setLoadingDonation] = useState(false);

  const [allCharities, setAllCharities] = useState<CountryCharities>({
    charities: [],
    country: '',
  });

  const [selectedCharities, setSelectedCharities] = useState<TSelectedCharity[]>(
    selectedValuesParsed?.country === selectedCountry ? selectedValuesParsed.charities : []
  );
  const [bundleInfo, setBundleInfo] = useState<TBundleInfo | undefined>(undefined);

  const [currentBundle, setCurrentBundle] = useState<TSelectedCharity[]>([]);
  const [donationFrequency, setDonationFrequency] = useState<TDonationFrequency>(
    preselectedDonationFrequency || selectedDonationFrequency || ''
  );
  const [paymentMethod, setPaymentMethod] = useState<TPaymentMethod>({ paymentMethod: '' });
  const [monthlyDonorOption, setMonthlyDonorOption] = useState<TMonthlyDonorOption>('');
  const [clientSecret, setClientSecret] = useState(undefined);
  const [isBankSelectLoading, setIsBankSelectLoading] = useState(false);

  const couponFromLocalStorage = localStorage.getItem('coupon');
  const [validCoupon, setValidCoupon] = useState<Coupon | undefined>(
    couponFromLocalStorage ? JSON.parse(couponFromLocalStorage) : undefined
  );

  const stripe = useStripe();
  const elements = useElements();

  // fetching data

  useEffect(() => {
    if (selectedValuesParsed && selectedValuesParsed?.country !== selectedCountry) {
      localStorage.removeItem('selectedValues');

      if (authenticatedUser && donationFrequency === 'monthly') {
        setNotification({
          message: t('differentPreferredCountry', { country: userDetails.country.toUpperCase() }),
          type: 'error',
        });
      }
    }

    const token = getLocalAccessToken();
    getAllCharities(selectedCountry, token)
      .then((response) => {
        if (response) {
          const allCauses = mapCharities(response.data);
          setAllCharities(allCauses);
          const preselectedFromWidget = getCharitiesFromIds(
            allCauses.charities,
            preselectedCharitiesFromWidget,
            donationsAmountsFromWidget
          );

          if (token) {
            getCurrentBundle()
              .then((response) => {
                if (response) {
                  setCurrentBundle(mapCurrentBundle(response.data.bundle));
                  if (response.data.bundle.length) {
                    const { data } = response;
                    const nextDate = new Date(data.nextDonationDate);
                    const [nextDonationDate] = nextDate.toISOString().split('T');
                    setBundleInfo({
                      status: data.bundleStatus,
                      nextDonationDate,
                      frequency: data.frequency,
                      ibanLast4: data.ibanLast4,
                      sharedInfo: data.sharedInfo,
                    });
                    localStorage.setItem('nextDonationDate', nextDonationDate);

                    if (!donationFrequency) {
                      localStorage.setItem('monthlyDonor', 'yes');
                      setDonationFrequency('monthly');
                    }

                    if (preselectedFromWidget.length && donationFrequency !== 'one-time') {
                      setSearchParams({ step: DonationSteps.CheckExistingBundleStep.toString() });
                    }
                  }

                  if (donationFrequency !== 'one-time') {
                    const uniqueCauses = removeCausesDuplicates([
                      ...preselectedFromWidget,
                      ...selectedCharities,
                      ...mapCurrentBundle(response.data.bundle),
                    ]);

                    setSelectedCharities(uniqueCauses);
                  } else {
                    const uniqueCauses = removeCausesDuplicates([
                      ...preselectedFromWidget,
                      ...selectedCharities,
                    ]);

                    setSelectedCharities(uniqueCauses);
                  }

                  setLoadingData(false);
                }
                return response;
              })
              .catch(() => {
                handleError();
                setLoadingData(false);
              });
          } else {
            const uniqueCauses = removeCausesDuplicates([
              ...preselectedFromWidget,
              ...selectedCharities,
            ]);
            setSelectedCharities(uniqueCauses);
            localStorage.setItem(
              'selectedValues',
              JSON.stringify({ charities: uniqueCauses, country: allCauses.country })
            );
            setLoadingData(false);
          }
        }
      })
      .catch(() => {
        handleError();
      });

    const hasPressedBackBtn =
      window.performance &&
      window.performance
        .getEntriesByType('navigation')
        .map((nav) => (nav as PerformanceNavigationTiming).type)
        .includes('back_forward');
    if (hasPressedBackBtn) {
      setSearchParams({ step: DonationSteps.SummaryAndPayment.toString() });
      setLoadingDonation(false);
    }
  }, []);

  // stateUpdates
  const handleSelectCharity = (charity: TCharity) => {
    setSelectedCharities([...selectedCharities, charity]);
  };
  const handleRemoveCharity = (removedCharity: TCharity) => {
    const updatedCharities = selectedCharities.filter(
      (charity) => charity.id !== removedCharity.id
    );
    setSelectedCharities(updatedCharities);
  };

  const handleChooseFrequencyType = (frequencyType: TDonationFrequency) => {
    setDonationFrequency(frequencyType);
  };

  const handleChoosePaymentMethod = async (paymentMethod: TPaymentMethod) => {
    setPaymentMethod(paymentMethod);
  };

  const onCharityAmountSelected = (value: string, charity: TCharity) => {
    const updatedAmounts = selectedCharities.map((selectedCharity) => ({
      ...selectedCharity,
      donationAmount: selectedCharity.id === charity.id ? value : selectedCharity.donationAmount,
    }));

    setSelectedCharities(updatedAmounts);
  };

  const handleChooseMonthlyDonorOption = (option: TMonthlyDonorOption) => {
    switch (option) {
      case 'one-time': {
        setDonationFrequency('one-time');
        removeCurrentBundleFromSelection();
        break;
      }
      case 'overwrite': {
        setDonationFrequency('monthly');
        removeCurrentBundleFromSelection();
        break;
      }
      case 'merge': {
        setDonationFrequency('monthly');
        mergeCurrentBundleWithNewSelection();
        break;
      }
      default: {
        break;
      }
    }
    setMonthlyDonorOption(option);
  };

  const mergeCurrentBundleWithNewSelection = () => {
    setSelectedCharities([...selectedCharities, ...currentBundle]);
  };

  const removeCurrentBundleFromSelection = () => {
    const updatedSelection = selectedCharities.filter((charity) => !charity.isFromCurrentBundle);

    setSelectedCharities(updatedSelection);
  };

  const getTotalAmount = () =>
    removeCausesDuplicates(selectedCharities).reduce((total, charity) => {
      const amount = charity.donationAmount ? parseInt(charity.donationAmount) : 0;
      return total + amount;
    }, 0);

  const handleNextStep = () => {
    localStorage.setItem(
      'selectedValues',
      JSON.stringify({
        charities: removeCausesDuplicates(selectedCharities),
        country: allCharities.country,
      })
    );
    donationFrequency && localStorage.setItem('donationFrequency', donationFrequency);

    handleScrollToTop();

    let nextOne = currentStep + 1;

    switch (currentStep) {
      case DonationSteps.FrequencyAndAmount: {
        if (authenticatedUser) {
          nextOne = DonationSteps.SummaryAndPayment;
          break;
        }

        break;
      }
      case DonationSteps.Authentication: {
        if (donationFrequency === 'monthly') {
          nextOne = DonationSteps.CheckExistingBundleStep;
          break;
        }
        if (donationFrequency === 'one-time') {
          nextOne = DonationSteps.SummaryAndPayment;
          break;
        }
        break;
      }
      case DonationSteps.CheckExistingBundleStep: {
        if (monthlyDonorOption === 'overwrite' || monthlyDonorOption === 'one-time') {
          nextOne = DonationSteps.SummaryAndPayment;

          if (
            (preselectedCharitiesFromWidget?.length && widgetData?.type === '2') ||
            !widgetData?.type
          ) {
            nextOne = DonationSteps.FrequencyAndAmount;
          }
          break;
        }
        if (monthlyDonorOption === 'merge') {
          nextOne = DonationSteps.FrequencyAndAmount;
          break;
        }
        nextOne = DonationSteps.SummaryAndPayment;
        break;
      }
      default:
        break;
    }

    setSearchParams({ step: nextOne.toString() });
  };

  const handlePreviousStep = () => {
    localStorage.setItem(
      'selectedValues',
      JSON.stringify({
        charities: removeCausesDuplicates(selectedCharities),
        country: allCharities.country,
      })
    );
    donationFrequency && localStorage.setItem('donationFrequency', donationFrequency);
    handleScrollToTop();

    let previousOne = currentStep - 1;

    switch (currentStep) {
      case DonationSteps.SummaryAndPayment: {
        if (authenticatedUser) {
          if (monthlyDonorOption.length) {
            previousOne = DonationSteps.CheckExistingBundleStep;
            break;
          }
          previousOne = DonationSteps.FrequencyAndAmount;
        }

        break;
      }
      case DonationSteps.CheckExistingBundleStep: {
        previousOne = DonationSteps.FrequencyAndAmount;
        break;
      }
      case DonationSteps.SelectCharities: {
        if (authenticatedUser && monthlyDonorOption.length) {
          previousOne = DonationSteps.CheckExistingBundleStep;
        }
        break;
      }
      default:
        break;
    }
    setSearchParams({ step: previousOne.toString() });
  };

  // handlers
  const handleError = useCallback(() => {
    setNotification({
      message: t('somethingWentWrong', { message: t('pleaseTryAgain') }),
      type: 'error',
    });
  }, [setNotification, t]);

  const handleScrollToTop = () => stepContainerRef.current?.scrollTo({ top: 0, behavior: 'auto' });

  const paymentBody = {
    successUrl: `${config.FE_APP_URL}/${isEnglish ? 'thank-you' : 'bedankt'}`,
    cancelUrl: `${config.FE_APP_URL}/${isEnglish ? 'donate' : 'doneren'}?step=${
      DonationSteps.SummaryAndPayment
    }`,
    paymentMethod: paymentMethod.paymentMethod,
    language: selectedLanguage,
    charities: removeCausesDuplicates(selectedCharities).map((item) => ({
      categories: item.categories,
      id: item.id,
      logo: item.logo,
      name: item.name,
      webpageUrl: item.webpageUrl,
      s3Url: item.s3Url,
      donationAmount: item.donationAmount ? parseInt(item.donationAmount) : 0,
    })),
    couponId: donationFrequency !== 'monthly' ? validCoupon?.code : '',
  };

  const handlePayment = async () => {
    setLoadingDonation(true);
    const token = getLocalAccessToken();

    try {
      const response =
        donationFrequency === 'one-time'
          ? await postPayment(paymentBody, token)
          : await createSubscription(paymentBody);

      if (response) {
        setLoadingDonation(false);
        window.location.href = response.data.redirect_url;
      }
    } catch (er) {
      setLoadingDonation(false);
      handleError();
    }
  };

  const handleEmptyPayment = async () => {
    setLoadingDonation(true);

    try {
      const { data } = await postEmptyPayment({
        ...paymentBody,
        successUrl: `/${isEnglish ? 'thank-you' : 'bedankt'}?empty-payment=1`,
        couponId: validCoupon?.code,
      });

      window.location.href = data.return_url;
    } catch {
      handleError();
    } finally {
      setLoadingDonation(false);
    }
  };

  const handleChangeBundle = async () => {
    setLoadingDonation(true);
    try {
      const charities = removeCausesDuplicates(selectedCharities).map((item) => ({
        id: item.id,
        donationAmount: item.donationAmount ? parseInt(item.donationAmount) : 0,
      }));
      const response = await changeBundle(charities);
      if (response) {
        setLoadingDonation(false);
        navigate(`${isEnglish ? '/thank-you' : '/bedankt'}?change-bundle=1`);
      }
    } catch (er) {
      setLoadingDonation(false);
      handleError();
    }
  };

  const resetDonationFlow = () => {
    setSelectedCharities([]);
    setCurrentBundle([]);
    setBundleInfo(undefined);
    setDonationFrequency('');
    setPaymentMethod({ paymentMethod: '', isSubElementSelected: false });
    setLoadingDonation(false);
    setSearchParams({ step: DonationSteps.SelectCharities.toString() });
    setCheckYourEmailState(false);

    resetStorage();
  };

  const sendPaymentInitiation = async () => {
    const token = getLocalAccessToken();
    setIsBankSelectLoading(true);

    try {
      const initiatePaymentBody = {
        charities: removeCausesDuplicates(selectedCharities).map((item) => ({
          categories: item.categories,
          id: item.id,
          logo: item.logo,
          name: item.name,
          webpageUrl: item.webpageUrl,
          s3Url: item.s3Url,
          donationAmount: item.donationAmount ? parseInt(item.donationAmount) : 0,
        })),
        couponId: donationFrequency !== 'monthly' ? validCoupon?.code : '',
      };

      const response = await initiatePayment(initiatePaymentBody, token);

      if (response.data.clientSecret) {
        setClientSecret(response.data.clientSecret);
      }
      setIsBankSelectLoading(false);
      return response.data.clientSecret;
    } catch (error) {
      setNotification({
        type: 'error',
        message: t('somethingWentWrong', { message: t('pleaseTryAgain') }),
      });
    }
    setIsBankSelectLoading(false);
  };

  const handleIdealSubmit = async () => {
    setLoadingDonation(true);
    const clientSecretResponse = await sendPaymentInitiation();

    if (!stripe || !elements) {
      return;
    }

    const idealBank = elements.getElement(IdealBankElement);

    if (idealBank && clientSecretResponse) {
      stripe
        .confirmIdealPayment(clientSecretResponse, {
          payment_method: {
            ideal: idealBank,
          },
          return_url: `${config.FE_APP_URL}/${isEnglish ? '/thank-you' : '/bedankt'}?ideal-method=1`,
        })
        .then((result) => {
          if (result.error) {
            setLoadingDonation(false);
            setNotification({
              type: 'error',
              message: t('somethingWentWrong', { message: t('pleaseTryAgain') }),
            });
          }
        })
        .finally(() => {
          setLoadingDonation(false);
        });
    }
  };

  return (
    <DonationFlowContext.Provider
      value={{
        allCharities,
        selectedCharities,
        currentBundle,
        donationFrequency,
        handleSelectCharity,
        handleRemoveCharity,
        handleChooseFrequencyType,
        onCharityAmountSelected,
        totalAmount: getTotalAmount(),
        handleNextStep,
        handlePreviousStep,
        currentStep,
        loadingData,
        paymentMethod,
        handleChoosePaymentMethod,
        handlePayment,
        handleChangeBundle,
        stepContainerRef,
        loadingDonation,
        resetDonationFlow,
        monthlyDonorOption,
        handleChooseMonthlyDonorOption,
        mergeCurrentBundleWithNewSelection,
        bundleInfo,
        clientSecret,
        sendPaymentInitiation,
        setLoadingDonation,
        handleIdealSubmit,
        isBankSelectLoading,
        validCoupon,
        setValidCoupon,
        handleEmptyPayment,
      }}
    >
      {props.children}
    </DonationFlowContext.Provider>
  );
};
