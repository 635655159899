export const FilterIcon = ({
  color,
  width,
  height,
}: {
  color?: string | undefined;
  width?: string | undefined;
  height?: string | undefined;
}) => (
  <svg
    width={width || '20'}
    height={height || '15'}
    viewBox="0 0 20 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.75 3.83331L12.75 3.83331M12.75 3.83331C12.75 5.3521 13.9812 6.58331 15.5 6.58331C17.0188 6.58331 18.25 5.3521 18.25 3.83331C18.25 2.31453 17.0188 1.08331 15.5 1.08331C13.9812 1.08331 12.75 2.31453 12.75 3.83331ZM7.25 11.1666L18.25 11.1666M7.25 11.1666C7.25 12.6854 6.01878 13.9166 4.5 13.9166C2.98122 13.9166 1.75 12.6854 1.75 11.1666C1.75 9.64786 2.98122 8.41665 4.5 8.41665C6.01878 8.41665 7.25 9.64786 7.25 11.1666Z"
      stroke={color || '#000347'}
      strokeWidth="1.83"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FilterIcon;
