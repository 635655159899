import { Grid, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthenticationContext } from '../../../../providers/authentication-provider';
import { RegisterWithEmailAddress } from '../../../RegisterPage/components/RegisterWithEmailAddress';
import SocialLogin from '../../../RegisterPage/components/SocialLogin';

export const RegistrationAndLoginTabContent = () => {
  const { resetFields } = useContext(AuthenticationContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const partnerId = searchParams.get('pid');

  return (
    <Stack role="tabpanel" gap={4}>
      <Stack width={'100%'}>
        <Grid container gap={0.5} alignItems={'center'}>
          <Typography variant="paragraphM" color={'textSecondary'}>
            {t('alreadyHaveAnAccount')}
          </Typography>
          <Typography
            variant="paragraphM"
            color="primary.main"
            onClick={() => {
              resetFields();
              navigate(`/login${partnerId ? `?pid=${partnerId}` : ''}`);
            }}
            sx={{ cursor: 'pointer' }}
          >
            {t('login')}
          </Typography>
        </Grid>
      </Stack>

      <Stack gap={2} width={'100%'}>
        <SocialLogin />
        <RegisterWithEmailAddress />
      </Stack>

      <Stack flexDirection="row" justifyContent="center">
        <Typography
          variant="paragraphXS"
          textAlign={'center'}
          color="textSecondary"
          maxWidth={'430px'}
        >
          {t('registerDisclaimer')}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default RegistrationAndLoginTabContent;
