import { Grid, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import CouponItem from './CouponItem';

// types
import type { GeneratedCoupon } from '../../api/coupons';

const CouponsList = ({
  coupons,
  handleDeleteCoupon,
  isDeletingCoupon,
}: {
  coupons: GeneratedCoupon[];
  handleDeleteCoupon: (couponId: string) => Promise<void>;
  isDeletingCoupon: boolean;
}) => {
  const sortedCoupons = coupons.sort((a, b) => (a.startDate < b.startDate ? 1 : -1));
  const [toggleValues, setToggleValues] = useState(['redeemed', 'unredeemed']);
  const [filteredCoupons, setFilteredCoupons] = useState<GeneratedCoupon[]>(sortedCoupons);

  useEffect(() => {
    setFilteredCoupons(
      sortedCoupons.filter((coupon) => {
        if (
          (toggleValues.includes('redeemed') && coupon.isRedeemed) ||
          (toggleValues.includes('unredeemed') && !coupon.isRedeemed)
        ) {
          return true;
        }

        return false;
      })
    );
  }, [coupons, toggleValues.length]);

  return (
    <Grid container gap={2} sx={{ height: '95%' }}>
      <Grid item container mobile={12} gap={1}>
        <ToggleButtonGroup
          value={toggleValues}
          onChange={(_, value) => {
            setToggleValues(value);
          }}
        >
          <ToggleButton value="redeemed">
            <Typography>Redeemed</Typography>
          </ToggleButton>
          <ToggleButton value="unredeemed">
            <Typography>Unredeemed</Typography>
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
      <Grid item container spacing={1} sx={{ overflow: 'auto', height: '100%', p: 1 }}>
        {filteredCoupons.map((coupon) => (
          <Grid item mobile={12} mobileS={6} tablet={4} key={coupon.id}>
            <CouponItem
              coupon={coupon}
              handleDeleteCoupon={handleDeleteCoupon}
              isDeletingCoupon={isDeletingCoupon}
            />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default CouponsList;
