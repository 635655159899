import { useContext, useEffect, useState } from 'react';

import { Box, Container, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  getPartners,
  getWidgetByPartnerId,
  saveWidgetData,
  saveWidgetImage,
} from '../../api/partners';
import WidgetConfigurator from '../../components/widget-configurator/WidgetConfigurator';
import { type TWidgetData } from '../../components/widget-configurator/types';
import { type WidgetProps } from '../../components/widgets/types';
import { type TPartner } from '../../models/Partners';
import { NotificationsContext } from '../../providers/notifications-provider';
import { PublicContext } from '../../providers/public-provider';
import { mapCharities } from '../DonationFlowPage/DonationFlow.utils';
import BundleLinksGenerator from './BundleLinksGenerator';

export const WidgetConfiguratorPage = () => {
  const {
    allCharities: { charities },
    logos,
  } = useContext(PublicContext);
  const { setNotification } = useContext(NotificationsContext);
  const [partners, setPartners] = useState<TPartner[]>([]);
  const [selectedPartner, setSelectedPartner] = useState<string | undefined>(undefined);
  const [currentWidgetData, setCurrentWidgetData] = useState<TWidgetData | undefined>(undefined);
  const { t } = useTranslation();
  const [isLoadingSaveData, setIsLoadingSaveData] = useState(false);

  useEffect(() => {
    getPartners()
      .then((response) => {
        setPartners(
          response.data.map((item: TPartner) => ({
            ...item,
            image: item.image,
            label: item.name,
          }))
        );
      })
      .catch(() => {
        setPartners([]);
      });
  }, []);

  const onChangePartner = (partnerId: string | undefined) => {
    setSelectedPartner(partnerId);
    if (partnerId) {
      getWidgetByPartnerId(partnerId)
        .then((res) => {
          if (res.data[0]) {
            setCurrentWidgetData({
              ...res.data[0],
              charities: mapCharities(res.data[0]).charities,
            });
          }
        })
        .catch(() => {
          setNotification({
            type: 'error',
            message: t('somethingWentWrong', { message: t('pleaseTryAgain') }),
          });
          setCurrentWidgetData(undefined);
        });
    }
  };

  const onSaveWidgetData = async (
    widgetData: Omit<WidgetProps, 'charities' | 'selectedCountry' | 'selectedLanguage'> & {
      charities: string[];
    },
    image?: FormData | Blob | string | undefined
  ) => {
    setIsLoadingSaveData(true);
    try {
      await saveWidgetData(widgetData);

      if (widgetData.partnerId && image) {
        await saveWidgetImage(widgetData.partnerId, image);
      }

      setNotification({ type: 'success', message: 'Successfully generated' });
    } catch (error) {
      setNotification({
        type: 'error',
        message: t('somethingWentWrong', { message: t('pleaseTryAgain') }),
      });
    } finally {
      setIsLoadingSaveData(false);
    }

    onChangePartner(selectedPartner);
  };

  return (
    <Box sx={{ width: '100%', py: 5 }}>
      <Container
        sx={{
          px: {
            laptop: 10,
          },
          maxWidth: { mobile: '100vw', desktop: 'desktop' },
        }}
      >
        <Stack gap={5}>
          <WidgetConfigurator
            allCharities={charities}
            partners={partners}
            selectedPartner={selectedPartner}
            setPartner={onChangePartner}
            currentWidgetData={currentWidgetData}
            logos={logos}
            onSaveWidgetData={onSaveWidgetData}
            onCopy={() => {
              setNotification({
                message: 'Copied to clipboard!',
                type: 'info',
              });
            }}
            isLoadingSaveData={isLoadingSaveData}
          />
          <BundleLinksGenerator partners={partners} />
        </Stack>
      </Container>
    </Box>
  );
};

export default WidgetConfiguratorPage;
