import { Button, LinearProgress, Stack, CircularProgress, Box } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { DonationFlowContext } from '../../providers/donation-flow-provider';
import SelectCharitiesInfo from './SelectedCharitiesInfo';
import {
  DonationSteps,
  removeCausesDuplicates,
} from '../../pages/DonationFlowPage/DonationFlow.utils';
import { DonationFromWidgetContext } from '../../providers/donation-from-widget-provider';

const TOTAL_STEPS = 6;

export const DonationProgressBar = () => {
  const { t } = useTranslation();
  const {
    selectedCharities,
    currentStep,
    handleNextStep,
    handlePreviousStep,
    paymentMethod: { paymentMethod, isSubElementSelected },
    donationFrequency,
    handlePayment,
    loadingDonation,
    monthlyDonorOption,
    currentBundle,
    bundleInfo,
    handleChangeBundle,
    totalAmount,
    handleIdealSubmit,
    validCoupon,
    handleEmptyPayment,
  } = useContext(DonationFlowContext);
  const { preselectedCharitiesFromWidget } = useContext(DonationFromWidgetContext);

  const isAlreadyMonthlyDonor =
    currentBundle.length &&
    bundleInfo &&
    (bundleInfo.status === 'active' || bundleInfo.status === 'pending') &&
    donationFrequency === 'monthly';

  const getComputedStepValue = () => {
    switch (currentStep) {
      case DonationSteps.CheckExistingBundleStep:
        return currentStep * 0.75;
      case DonationSteps.SummaryAndPayment:
        return TOTAL_STEPS - 1;
      default:
        return currentStep + 1;
    }
  };

  const progressWidth = (100 / TOTAL_STEPS) * getComputedStepValue();

  const isNextStepDisabled = () => {
    const hasCharitiesWithoutAmountSet = selectedCharities.find(
      (selectedCharity) =>
        selectedCharity.donationAmount === '' ||
        !selectedCharity.donationAmount ||
        parseInt(selectedCharity.donationAmount) < 1
    );

    return (
      !removeCausesDuplicates(selectedCharities).length ||
      (currentStep !== DonationSteps.SelectCharities &&
        (!donationFrequency || !!hasCharitiesWithoutAmountSet) &&
        !preselectedCharitiesFromWidget?.length) ||
      (currentStep === DonationSteps.CheckExistingBundleStep && !monthlyDonorOption) ||
      (currentStep === DonationSteps.FrequencyAndAmount && totalAmount < 5)
    );
  };

  const showPreviousBtn = currentStep !== DonationSteps.SelectCharities;

  const checkHandleClickMethods = () => {
    if (isAlreadyMonthlyDonor) {
      handleChangeBundle();
      return;
    }

    if (totalAmount === validCoupon?.value) {
      handleEmptyPayment();
      return;
    }

    if (paymentMethod === 'ideal') {
      handleIdealSubmit();
      return;
    }

    handlePayment();
  };

  return (
    <Stack direction="column" width={'100%'}>
      <LinearProgress
        variant="determinate"
        value={progressWidth}
        color="success"
        sx={{ width: '100%' }}
      />
      <Stack
        justifyContent={{
          mobile: !removeCausesDuplicates(selectedCharities).length ? 'flex-end' : 'space-between',
          laptop: currentStep > DonationSteps.SelectCharities ? 'space-between' : 'flex-end',
        }}
        py={{ mobile: 2, tablet: 2.5, laptop: 3 }}
        px={{ mobile: 2, tablet: 5, laptop: 4 }}
        flexDirection={'row'}
        alignItems={'center'}
        gap={4}
      >
        {showPreviousBtn ? (
          <Button
            variant="text"
            color="info"
            size="large"
            onClick={handlePreviousStep}
            data-testid="PreviousButton"
            disabled={currentStep === DonationSteps.CheckExistingBundleStep}
          >
            {t('previous')}
          </Button>
        ) : null}

        {selectedCharities.length && currentStep === DonationSteps.SelectCharities ? (
          <SelectCharitiesInfo />
        ) : null}
        {currentStep !== DonationSteps.Authentication &&
        currentStep !== DonationSteps.SummaryAndPayment ? (
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleNextStep}
            disabled={isNextStepDisabled()}
            sx={{ minWidth: '95px' }}
            data-testid="NextButton"
          >
            {t('nextStep')}
          </Button>
        ) : null}
        {currentStep === DonationSteps.SummaryAndPayment ? (
          <Box sx={{ position: 'relative' }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              disabled={
                (!paymentMethod && !isAlreadyMonthlyDonor) ||
                (paymentMethod === 'ideal' && !isSubElementSelected) ||
                (validCoupon && validCoupon?.value > totalAmount) ||
                loadingDonation
              }
              onClick={() => checkHandleClickMethods()}
              sx={{ minWidth: '95px' }}
              data-testid="DonationButton"
            >
              {isAlreadyMonthlyDonor ? t('adjustBundle') : t('donate')}
            </Button>
            {loadingDonation && (
              <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  marginTop: '-12px',
                  marginLeft: '-12px',
                }}
              />
            )}
          </Box>
        ) : null}
      </Stack>
    </Stack>
  );
};

export default DonationProgressBar;
