import { Route, createBrowserRouter, createRoutesFromElements } from 'react-router-dom';
import {
  ActivateAccountPage,
  AllWidgetsPage,
  AppWrapper,
  DonationFlowPage,
  MyGiftShiftPage,
  RegisterPage,
  ResetPasswordPage,
  ThankYouPage,
  UpdatePaymentMethodPage,
  WidgetConfiguratorPage,
  WidgetPage,
} from './pages';

import ProtectedRoute from './components/protected-route/ProtectedRoute';
import BasenameRedirectPage from './pages/BasenameRedirect/BasenameRedirectPage';
import CouponsGeneratorPage from './pages/CouponsGenerator/CouponsGeneratorPage';
import LoginPage from './pages/LoginPage/LoginPage';
import PaymentFailedPage from './pages/PaymentFailedPage/PaymentFailedPage';
import RedirectPage from './pages/RedirectPage/RedirectPage';
import SocialLoginPage from './pages/SocialLoginPage/SocialLoginPage';

export const router = (lng?: string) =>
  createBrowserRouter(
    createRoutesFromElements(
      <>
        <Route path="/" element={<RedirectPage />} errorElement={<BasenameRedirectPage />} />
        <Route path="/thank-you" element={<ThankYouPage />} />
        <Route path="/bedankt" element={<ThankYouPage />} />
        <Route path="/payment-error" element={<PaymentFailedPage />} />
        <Route path="/donate" element={<DonationFlowPage />} />
        <Route path="/doneren" element={<DonationFlowPage />} />
        <Route path="/registreren" element={<RegisterPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/activate-account" element={<ActivateAccountPage />} />
        <Route path="/social-login" element={<SocialLoginPage />} />
        <Route path="/change-password" element={<ResetPasswordPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/update-payment-method" element={<UpdatePaymentMethodPage />} />
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <AppWrapper lightMode />
            </ProtectedRoute>
          }
        >
          <Route path="/my-giftshift" element={<MyGiftShiftPage />} />
          <Route path="/mijn-giftshift" element={<MyGiftShiftPage />} />
        </Route>
        <Route
          path="all-widgets"
          element={
            <ProtectedRoute checkAdminRights>
              <AllWidgetsPage />
            </ProtectedRoute>
          }
        />
        <Route path="widget" element={<WidgetPage />} />
        <Route
          path="/"
          element={
            <ProtectedRoute checkAdminRights>
              <AppWrapper lightMode />
            </ProtectedRoute>
          }
        >
          <Route path="/config-widget" element={<WidgetConfiguratorPage />} />
          <Route path="/coupons" element={<CouponsGeneratorPage />} />
        </Route>
      </>
    ),
    { basename: lng }
  );

export default router;
