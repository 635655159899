import { Box } from '@mui/material';

interface ImageMoleculeProps {
  image: string | Blob | undefined;
  proportions?: 'default' | '2/1' | '4/3';
  width?: string;
  height?: string;
  backgroundSize?: 'cover' | 'contain';
}

export const ImageMolecule = ({
  image,
  proportions = 'default',
  width,
  height,
  backgroundSize = 'cover',
}: ImageMoleculeProps) => {
  return (
    <Box
      sx={{
        aspectRatio: proportions === 'default' ? '100%' : proportions,
        width: width || '100%',
        height: height || '100%',
        borderRadius: '12px',
        background: `url(${image}) transparent 50% / ${backgroundSize} no-repeat`,
      }}
    />
  );
};

export default ImageMolecule;
