import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, Stack, Typography } from '@mui/material';
import { type TCharity, type TSelectedCharity } from '../../models/Charities';
import CharitySelectorItem from './CharitySelectorItem';
import EmptyBundleIllustration from '../../assets/EmptyBundleIllustration';
import { textColor } from '../../styles/variables';

interface CharitiesSelectorProps {
  selectedCharities: TSelectedCharity[];
  charities: TCharity[];
  onSelectCharity: (charity: TCharity) => void;
  onRemoveFromSelection: (charity: TSelectedCharity) => void;
  selectionText: string;
  removeSelectionText: string;
  handleShowDetails?: (charity: TCharity) => void;
  logoOnly?: boolean;
}

export const CharitiesSelector = ({
  selectedCharities,
  charities,
  onSelectCharity,
  onRemoveFromSelection,
  selectionText,
  removeSelectionText,
  handleShowDetails,
  logoOnly = false,
}: CharitiesSelectorProps) => {
  const [selected, setSelectedCharities] = useState<TSelectedCharity[]>(selectedCharities);
  const { t } = useTranslation();

  const handleSelection = (charity: TCharity) => {
    setSelectedCharities((prevState) => [...prevState, charity]);
    onSelectCharity(charity);
  };

  const handleRemoveFromSelection = (charity: TCharity) => {
    const updatedSelection = selected.filter(
      (selectedCharity) => selectedCharity.id !== charity.id
    );
    setSelectedCharities(updatedSelection);
    onRemoveFromSelection(charity);
  };

  return charities.length ? (
    <Grid
      container
      spacing={logoOnly ? '10px' : { mobile: 2, tablet: 4 }}
      justifyContent={'stretch'}
    >
      {charities.map((charity) => (
        <Grid
          item
          key={charity.id}
          mobile={logoOnly ? 4 : 6}
          tablet={logoOnly ? 3 : 4}
          laptop={logoOnly ? 2.4 : 4}
          data-testid={`${charity.name.split(' ').join('')}Charity`}
        >
          <CharitySelectorItem
            charity={charity}
            isSelected={selected.map((selectedCharity) => selectedCharity.id).includes(charity.id)}
            handleSelectCharity={handleSelection}
            handleRemoveFromSelection={handleRemoveFromSelection}
            selectionText={selectionText}
            removeSelectionText={removeSelectionText}
            handleShowDetails={handleShowDetails}
            logoOnly={logoOnly}
          />
        </Grid>
      ))}
    </Grid>
  ) : (
    <Stack alignItems="center" gap="24px" py={5}>
      <EmptyBundleIllustration />
      <Stack gap="8px" alignItems="center">
        <Typography variant="h3">{t('noCharitiesFound')}</Typography>
        <Typography variant="paragraphM" textAlign="center" color={textColor}>
          {t('adjustYourSearch')}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default CharitiesSelector;
