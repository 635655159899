import { Skeleton, Stack } from '@mui/material';

export const ThankYouPageSkeleton = () => {
  return (
    <Stack gap={1} alignItems={'center'} width={{ mobile: '95%', laptop: '60%' }}>
      <Stack gap={2} flexDirection={'row'}>
        <Skeleton variant="rounded" height={125} width={167} />
        <Skeleton variant="rounded" height={125} width={167} />
      </Stack>

      <Skeleton variant="rounded" height={48} width="60%" sx={{ mt: 4 }} />
      <Skeleton variant="rounded" height={27} width="100%" />

      <Skeleton variant="rounded" height={18} width="50%" sx={{ mt: 4 }} />
      <Stack gap={1.5} flexDirection={'row'}>
        <Skeleton variant="rounded" height={44} width={56} />
        <Skeleton variant="rounded" height={44} width={56} />
        <Skeleton variant="rounded" height={44} width={56} />
        <Skeleton variant="rounded" height={44} width={56} />
      </Stack>
    </Stack>
  );
};

export default ThankYouPageSkeleton;
