import type { TFunction } from 'i18next';

export const getThankYouMessage = (
  isPendingPayment: boolean,
  nextDonationDate: string | undefined,
  t: TFunction
) => {
  if (isPendingPayment) {
    return t('pendingPaymentNote');
  } else if (nextDonationDate) {
    return t('ensureNextDebitOn', { date: nextDonationDate });
  } else {
    return t('keepAnEye');
  }
};
