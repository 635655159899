import { Grid, MenuItem, Select, type SelectProps, Stack, Typography } from '@mui/material';
import { ChevronDownIcon } from '../../assets/icons';
import { shadowSM } from '../../styles/variables';

export const Dropdown = <
  T extends {
    label?: string;
    id: string | number;
    logo?: string;
    [key: string]: any;
  },
>({
  options,
  labelValue,
  defaultValue,
  value,
  labelVariant = 'paragraphM',
  labelColor = 'textSecondary',
  optionsFontWeight = '700',
  ...rest
}: SelectProps & {
  options: T[];
  labelValue?: string;
  labelVariant?: 'paragraphS' | 'paragraphXS' | 'paragraphM' | 'paragraphL' | 'h1' | 'h2';
  labelColor?: string;
  optionsFontWeight?: string;
}) => {
  return (
    <Stack gap="4px">
      {labelValue && (
        <Typography variant={labelVariant} color={labelColor}>
          {labelValue}
        </Typography>
      )}
      <Select
        fullWidth
        defaultValue={defaultValue || options[0].id}
        value={value}
        MenuProps={{
          sx: {
            maxHeight: '500px',
            '& .MuiMenu-paper': { borderRadius: '8px', boxShadow: shadowSM },
          },
        }}
        IconComponent={(props) => <ChevronDownIcon {...props} />}
        {...rest}
      >
        {options.map((option) => (
          <MenuItem
            key={option.id}
            value={option.id}
            sx={{
              mr: 1,
              ml: 1,
              '&:not(:last-child)': { mb: 1 },
              justifyContent: 'space-between',
              fontWeight: optionsFontWeight,
            }}
          >
            <Grid container alignItems={'center'} sx={{ '& > img': { mr: 1, flexShrink: 0 } }}>
              {option.logo ? (
                <img
                  loading="lazy"
                  width="27px"
                  height="18.5px"
                  style={{ borderRadius: '3.75px' }}
                  src={option.logo}
                  alt={`${option.label}-logo`}
                />
              ) : null}
              {option.label}
            </Grid>

            {/* TO CHECK ON UI */}
            {/* {option.id === value && <CheckIcon />} */}
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
};

export default Dropdown;
