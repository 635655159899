import { axiosPrivate } from '../common/axiosPrivate';

interface TUpdateUserDetails {
  name?: string;
  resetPassword?: 1;
  birthday?: {
    year: number;
    month: number;
    day: number;
  };
  country?: string;
  gender?: string;
  newsletter?: boolean;
}

export const getUserDetails = async () => await axiosPrivate.get('/auth/user-details/');

export const updateUserDetails = async (body: TUpdateUserDetails) =>
  await axiosPrivate.patch('/auth/user-details/', body);

export const deleteUserAccount = async () => await axiosPrivate.delete('/auth/delete-account/');

export const shareDonorInformation = async () => await axiosPrivate.post('/share-info/');
