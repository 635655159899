import { createContext, useContext, useEffect, useState } from 'react';
import { ALL_LANGUAGES, getCookie } from '../common/utils';
import router from '../router';
import { AuthenticationContext } from './authentication-provider';
import { handleGetFlags, setBasename } from './utils';

// types
import type { FC } from 'react';
import type { BasenameURL, Country, ILanguageContext, TLanguage } from '../models/Basename';

const routesFromCookie = getCookie('routes');
const parsedRoutes: BasenameURL | undefined = routesFromCookie && JSON.parse(routesFromCookie);

const DEFAULT_ROUTE = { id: 'nl', cca2: 'nl' };

export const LanguageContext = createContext<ILanguageContext>({
  handleLanguageChange: () => {},
  selectedLanguage: parsedRoutes?.language || '',
  setSelectedLanguage: () => {},
  languageOptions: [],
  isEnglish: false,
  selectedCountry: parsedRoutes?.country || '',
  setSelectedCountry: () => {},
  allPaths: [],
  allCountries: [],
});

export const LanguageProvider: FC<{ children?: React.ReactNode }> = (props) => {
  const routesCookie = getCookie('routes');
  const isEnglish = routesCookie ? JSON.parse(routesCookie).language === 'en' : false;

  const {
    authenticatedUser,
    userDetails: { country },
  } = useContext(AuthenticationContext);

  const [languageOptions, setLanguageOptions] = useState<(TLanguage | undefined)[]>([]);
  const [allCountries, setAllCountries] = useState<Country[]>();
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedLanguage, setSelectedLanguage] = useState('');
  const allPaths = router()
    .routes.map((route) => {
      if (route.children) {
        return route.children.map((routeChildren) => routeChildren.path);
      }

      return route?.path || '';
    })
    .flat();

  const handleLanguageChange = (language: string) => {
    const definedLanguage = languageOptions?.find(
      (languageOption) => languageOption?.id === language
    );

    setSelectedLanguage(definedLanguage ? definedLanguage.id : DEFAULT_ROUTE.id);

    return definedLanguage || DEFAULT_ROUTE;
  };

  useEffect(() => {
    if (!allCountries?.length || !languageOptions?.length) {
      handleGetFlags(
        async (countries, languages) => {
          const countriesWithFlags = countries.map((country) => ({
            ...country,
            flag: `/country-flags/${country.alias}.png`,
          }));

          const languageData = languages.map((language) => {
            const languageAlias = ALL_LANGUAGES.find((value) => language === value.alias)?.language;

            return {
              id: language,
              label: languageAlias || '',
              logo: `/country-flags/${language}.png`,
              cca2: language,
            };
          });

          setLanguageOptions(languageData);
          setAllCountries(countriesWithFlags);
        },
        () => {
          setAllCountries([{ alias: 'nl', country: 'Netherlands', flag: '/country-flags/nl.png' }]);

          setLanguageOptions([
            {
              id: 'nl',
              label: 'Dutch',
              logo: '/country-flags/nl.png',
              cca2: 'nl',
            },
          ]);
        }
      );
    }
  }, []);

  useEffect(() => {
    if (
      (!authenticatedUser || (authenticatedUser && country)) &&
      (allCountries?.length || languageOptions.length)
    ) {
      setBasename(
        {
          setCountry: (value) => {
            const validCountry =
              allCountries?.find(({ alias }) => alias.toLowerCase() === value.toLowerCase())
                ?.alias || 'nl';

            setSelectedCountry(validCountry);

            return validCountry;
          },
          handleLanguageChange,
        },
        country
      );
    }
  }, [selectedCountry, selectedLanguage, country, allCountries?.length, languageOptions?.length]);

  return (
    <LanguageContext.Provider
      value={{
        handleLanguageChange,
        selectedLanguage,
        setSelectedLanguage,
        languageOptions,
        isEnglish,
        selectedCountry,
        setSelectedCountry,
        allPaths,
        allCountries,
      }}
    >
      {props.children}
    </LanguageContext.Provider>
  );
};
