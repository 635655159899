import { date, number, object, string } from 'yup';

export const couponsSchema = (currentDate: string) =>
  object().shape({
    startDate: date()
      .required()
      .min(currentDate)
      .when(
        'endDate',
        (endDate, schema) => endDate && schema.max(endDate, 'Start Date must be before End Date')
      ),
    endDate: date().required().min(currentDate),
    amount: number().integer().required().min(1).max(10000),
    numberCoupons: number().integer().required().min(1).max(10000),
    prefix: string().required().max(4),
    client: string().required(),
    campaign: string().required(),
  });
