import { createContext, useContext, useEffect, useState } from 'react';
import { getAllCharities } from '../api/charities';
import { getLocalAccessToken } from '../common/utils';

import { getCurrentBundle } from '../api/subscription';
import { mapCharities } from '../pages/DonationFlowPage/DonationFlow.utils';
import { LanguageContext } from './language-selector-provider';

// type
import type { FC, ReactNode } from 'react';
import type {
  CountryCharities,
  TCharity,
  TCharityLogo,
  TSelectedCharity,
} from '../models/Charities';

export interface IPublicContext {
  allCharities: CountryCharities;
  currentBundle: TSelectedCharity[];
  logos: TCharityLogo[];
}

export const PublicContext = createContext<IPublicContext>({
  allCharities: { charities: [], country: '' },
  currentBundle: [],
  logos: [],
});

export const PublicProvider: FC<{ children?: ReactNode }> = (props) => {
  const [allCharities, setAllCharities] = useState<CountryCharities>({
    charities: [],
    country: '',
  });
  const [currentBundle, setCurrentBundle] = useState<TSelectedCharity[]>([]);
  const [logos, setLogos] = useState<TCharityLogo[]>([]);
  const { selectedCountry } = useContext(LanguageContext);

  useEffect(() => {
    const token = getLocalAccessToken();
    getAllCharities(selectedCountry, token).then((response) => {
      if (response) {
        setAllCharities(mapCharities(response.data));
        setLogos(mapLogos(response.data.charities));
      }
    });
    if (token) {
      getCurrentBundle()
        .then((response) => {
          if (response) {
            // TODO: Handle map error
            setCurrentBundle(mapCurrentBundle(response.data.bundle));

            // setLoadingCurrentBundle(false);
          }
          return response;
        })
        .catch(() => {
          setCurrentBundle([]);
          // handleError();
          // TODO: Handle map error
          // handleLogout();
          // setLoadingCurrentBundle(false);
        });
    }
  }, [selectedCountry]);

  const mapLogos = (listFromResponse: TCharity[]) =>
    listFromResponse.map((item) => ({
      id: item.id,
      name: item.name,
      logo: item.s3Url,
    }));
  const mapCurrentBundle = (listFromResponse: TSelectedCharity[] | undefined) =>
    listFromResponse?.map((item) => ({
      ...item,
      donationAmount: item.donationAmount?.toString(),
      isFromCurrentBundle: true,
    })) || [];

  return (
    <PublicContext.Provider
      value={{
        allCharities,
        currentBundle,
        logos,
      }}
    >
      {props.children}
    </PublicContext.Provider>
  );
};
