import { Card, Skeleton, Stack, Typography, Button } from '@mui/material';
import { useContext } from 'react';
import { desktopXXL, white } from '../../../../styles/variables';
import { MyGiftShiftContext } from '../../../../providers/my-giftshift-provider';
import { useTranslation } from 'react-i18next';

import { useNavigate } from 'react-router-dom';
import Illu2 from '../../../../assets/Illu2';
import { LanguageContext } from '../../../../providers/language-selector-provider';
import { useInView } from 'react-intersection-observer';

export const NoPastDonationsCard = (props: {
  hash: string;
  onChangeTab: (tabValue: string) => void;
}) => {
  const { loadingData } = useContext(MyGiftShiftContext);
  const { isEnglish } = useContext(LanguageContext);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const sectionId = 'overview';
  const { ref: inViewRef } = useInView({
    initialInView: props.hash === sectionId,
    onChange: (isInView) => {
      if (isInView) {
        props.onChangeTab(sectionId);
      }
    },
    threshold: [0, 1],
  });

  return loadingData ? (
    <Skeleton
      id={sectionId}
      variant="rounded"
      width={'100%'}
      height={'541px'}
      sx={{
        scrollMarginTop: { mobile: '172px', tablet: '192px', laptop: '161px' },
        scrollMarginBlockStart: { mobile: '172px', tablet: '192px', laptop: '161px' },
      }}
      ref={inViewRef}
    />
  ) : (
    <Stack gap={{ mobile: 2, laptop: 3 }} ref={inViewRef}>
      <Typography variant="h2">{t('myDonationOverview')}</Typography>

      <Card
        id={sectionId}
        role="tabpanel"
        sx={{
          backgroundColor: white,
          boxShadow: desktopXXL,
          borderRadius: '12px',
          overflow: 'initial',
          px: 4,
          py: 7,
          scrollMarginTop: { mobile: '172px', tablet: '192px', laptop: '161px' },
          scrollMarginBlockStart: { mobile: '172px', tablet: '192px', laptop: '161px' },
        }}
      >
        <Stack gap={3} alignItems={'center'}>
          <Illu2 />
          <Typography variant="paragraphL" textAlign={'center'}>
            {t('noPreviousDonations')}
          </Typography>
          <Button
            variant="contained"
            size="large"
            onClick={() => {
              navigate(isEnglish ? '/donate' : '/doneren');
            }}
          >
            {t('createBundle')}
          </Button>
        </Stack>
      </Card>
    </Stack>
  );
};

export default NoPastDonationsCard;
