import { useContext, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { getWidgetByPartnerId } from '../../api/partners';
import Widget from '../../components/widgets/Widget';
import { type WidgetProps } from '../../components/widgets/types';
import { PublicContext } from '../../providers/public-provider';
import { mapCharities } from '../DonationFlowPage/DonationFlow.utils';

export const WidgetPage = () => {
  const [searchParams] = useSearchParams();
  const { logos } = useContext(PublicContext);
  const [loadingWidgetData, setLoadingWidgetData] = useState(true);
  const [widgetData, setWidgetData] = useState<WidgetProps | undefined>(undefined);
  const partnerId = searchParams.get('pid') || undefined;

  useEffect(() => {
    if (partnerId) {
      getWidgetByPartnerId(partnerId)
        .then((res) => {
          setWidgetData({ ...res.data[0], ...mapCharities(res.data[0]) });
        })
        .catch(() => {
          setWidgetData(undefined);
        })
        .finally(() => {
          setLoadingWidgetData(false);
        });
    }
  }, [partnerId]);

  return loadingWidgetData || !widgetData ? (
    <></>
  ) : (
    <Widget
      variant={widgetData.variant}
      buttonBgColor={widgetData.buttonBgColor}
      buttonBorderRadius={widgetData.buttonBorderRadius}
      buttonTextColor={widgetData.buttonTextColor}
      headingColor={widgetData.headingColor}
      subHeadingColor={widgetData.subHeadingColor}
      headingText={widgetData.headingText}
      subHeadingText={widgetData.subHeadingText}
      buttonText={widgetData.buttonText}
      charities={widgetData.charities}
      image={widgetData.image}
      logos={logos}
      partnerId={partnerId}
      language={widgetData.language}
      selectedCountry={widgetData.selectedCountry}
      selectedLanguage={widgetData.selectedLanguage}
    />
  );
};

export default WidgetPage;
