export const EmptyBundleIllustration = () => (
  <svg
    width="218"
    height="199"
    viewBox="0 0 218 199"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M164.505 198.172H40.6836C51.9086 182.435 75.4105 168.438 102.594 168.438C129.778 168.438 153.286 182.435 164.505 198.172Z"
      fill="#FF7979"
    />
    <path
      d="M99.4473 197.493V86.4497"
      stroke="#CC4646"
      strokeWidth="1.1699"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M123.935 116.843H137.71V118.667C137.71 121.728 137.107 124.759 135.935 127.587C134.764 130.415 133.047 132.985 130.883 135.149C128.718 137.314 126.149 139.03 123.321 140.202C120.493 141.373 117.461 141.976 114.4 141.976H105.104V135.676C105.104 133.203 105.591 130.754 106.537 128.47C107.484 126.185 108.871 124.109 110.619 122.36C112.368 120.611 114.444 119.224 116.728 118.277C119.013 117.331 121.462 116.843 123.935 116.843Z"
      fill="#FFBCBC"
    />
    <path
      d="M128.484 125.191L108.003 139.3C105.364 141.118 103.207 143.55 101.716 146.386C100.226 149.223 99.4473 152.379 99.4473 155.583V166.116"
      stroke="#CC4646"
      strokeWidth="1.1699"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M96.4031 158.222H87.9788C82.2599 158.222 76.7752 155.95 72.7313 151.906C68.6875 147.862 66.4157 142.378 66.4157 136.659V134.838H78.9877C83.6076 134.838 88.0384 136.673 91.3052 139.94C94.572 143.207 96.4072 147.637 96.4072 152.257V158.216L96.4031 158.222Z"
      fill="#FFBCBC"
    />
    <path
      d="M75.5645 142.339L90.879 152.896C93.5178 154.714 95.6753 157.146 97.1657 159.983C98.6562 162.819 99.4349 165.975 99.4349 169.18V179.712"
      stroke="#CC4646"
      strokeWidth="1.1699"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M103.978 77.0596H119.382C124.304 77.0596 129.025 79.0149 132.505 82.4953C135.986 85.9757 137.941 90.6962 137.941 95.6183V111.023H122.536C117.614 111.023 112.894 109.068 109.413 105.587C105.933 102.107 103.978 97.3862 103.978 92.4642V77.0596Z"
      fill="#000799"
    />
    <path
      d="M120.959 92.751L111.412 79.9102"
      stroke="white"
      strokeWidth="1.24619"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M76.1259 111.021H60.7213V95.6183C60.7213 93.1811 61.2013 90.7678 62.134 88.5162C63.0666 86.2645 64.4337 84.2186 66.157 82.4953C67.8803 80.772 69.9262 79.4049 72.1779 78.4723C74.4295 77.5396 76.8428 77.0596 79.28 77.0596H94.6846V92.4628C94.6846 97.3849 92.7293 102.105 89.2489 105.586C85.7684 109.066 81.0479 111.021 76.1259 111.021Z"
      fill="#000799"
    />
    <path
      d="M87.6683 79.9102L78.1201 92.751"
      stroke="white"
      strokeWidth="1.24619"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M94.6846 36.2852H79.28C76.8428 36.2852 74.4295 35.8051 72.1779 34.8725C69.9262 33.9398 67.8803 32.5728 66.157 30.8494C64.4337 29.1261 63.0666 27.0802 62.134 24.8286C61.2013 22.5769 60.7213 20.1636 60.7213 17.7265L60.7213 2.32186L76.1259 2.32186C81.0479 2.32186 85.7684 4.27715 89.2489 7.75758C92.7293 11.238 94.6846 15.9585 94.6846 20.8806V36.2852Z"
      fill="#000799"
    />
    <path
      d="M85.8876 32.1443L76.3408 19.3035"
      stroke="white"
      strokeWidth="1.24619"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M122.536 2.32178H137.941V17.7264C137.941 22.6485 135.986 27.3689 132.505 30.8494C129.025 34.3298 124.304 36.2851 119.382 36.2851H103.978V20.8805C103.978 18.4433 104.458 16.03 105.39 13.7784C106.323 11.5267 107.69 9.48083 109.413 7.75749C112.894 4.27706 117.614 2.32178 122.536 2.32178Z"
      fill="#000799"
    />
    <path
      d="M120.765 19.3035L111.217 32.1443"
      stroke="white"
      strokeWidth="1.24619"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M66.4057 45.6558L77.2982 56.5483L66.4057 67.4407C62.9253 70.921 58.2049 72.8763 53.2829 72.8763C48.3608 72.8763 43.6404 70.921 40.16 67.4407L29.2676 56.5483L40.16 45.6558C43.6404 42.1755 48.3608 40.2202 53.2829 40.2202C58.2049 40.2202 62.9253 42.1755 66.4057 45.6558Z"
      fill="#000799"
    />
    <path
      d="M53.2832 56.5483H75.9356"
      stroke="white"
      strokeWidth="1.24619"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M157.223 45.6558L168.116 56.5483L157.223 67.4407C153.743 70.921 149.022 72.8763 144.1 72.8763C139.178 72.8763 134.458 70.921 130.977 67.4407L120.085 56.5483L130.977 45.6558C134.458 42.1755 139.178 40.2202 144.1 40.2202C149.022 40.2202 153.743 42.1755 157.223 45.6558Z"
      fill="#000799"
    />
    <path
      d="M123.717 56.5483H146.369"
      stroke="white"
      strokeWidth="1.24619"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M99.4476 83.8349C115.142 83.8349 127.865 71.112 127.865 55.4176C127.865 39.7231 115.142 27.0002 99.4476 27.0002C83.7531 27.0002 71.0303 39.7231 71.0303 55.4176C71.0303 71.112 83.7531 83.8349 99.4476 83.8349Z"
      fill="#CCCEFF"
    />
    <path
      d="M95.3509 39.399H92.3132C91.8318 39.3991 91.3669 39.575 91.006 39.8937C90.6452 40.2125 90.4132 40.6521 90.3537 41.1299L89.6331 46.9503C89.5575 47.5615 89.2994 48.1357 88.8925 48.598C88.4856 49.0603 87.9488 49.3892 87.3521 49.5417L81.0965 51.1423C80.5246 51.2889 80.0151 51.616 79.6439 52.0751C79.2727 52.5342 79.0594 53.1009 79.0359 53.6908L78.9596 55.6226C78.9371 56.1994 79.1214 56.7653 79.4791 57.2183C79.8368 57.6713 80.3446 57.9817 80.9109 58.0935L82.6667 58.4386C82.9951 58.503 83.3033 58.6451 83.5654 58.8532C83.8275 59.0613 84.0359 59.3293 84.173 59.6345L84.7592 60.9344L85.1611 61.8283L84.4848 61.127L83.5494 60.157C83.2032 59.798 82.7404 59.5739 82.244 59.5251L77.5323 59.065C77.2081 59.0336 76.8954 58.9282 76.6183 58.7569C76.3413 58.5856 76.1073 58.3529 75.9344 58.0769L71.3945 50.8458C72.1473 46.2374 74.0227 41.8852 76.8552 38.173C79.6876 34.4608 83.3902 31.5028 87.6362 29.5598L85.7251 33.684C85.6405 33.8665 85.6041 34.0677 85.6193 34.2684C85.6345 34.469 85.7008 34.6624 85.812 34.8301C85.9231 34.9979 86.0754 35.1343 86.2543 35.2265C86.4332 35.3187 86.6327 35.3635 86.8338 35.3566L91.8157 35.1987C92.2752 35.1854 92.725 35.3324 93.0879 35.6144L95.9011 37.7943C96.0496 37.9085 96.1585 38.0664 96.2125 38.2458C96.2664 38.4251 96.2628 38.6169 96.2021 38.7941C96.1413 38.9713 96.0265 39.125 95.8738 39.2335C95.7211 39.342 95.5382 39.3999 95.3509 39.399Z"
      fill="#CC4646"
    />
    <path
      d="M102.305 67.5211V70.5948C102.305 71.0909 102.116 71.5682 101.776 71.9294L98.4722 75.4299C98.2595 75.6559 97.9877 75.8179 97.6877 75.8975C97.3877 75.9771 97.0714 75.9711 96.7746 75.8803L95.3971 75.4646C95.2984 75.4347 95.1942 75.428 95.0925 75.445C94.9908 75.4619 94.8943 75.502 94.8106 75.5621C94.7269 75.6223 94.6581 75.7009 94.6096 75.7918C94.5611 75.8828 94.5342 75.9837 94.531 76.0868L94.409 80.3287C94.3995 80.648 94.4625 80.9653 94.5933 81.2567C94.7242 81.5481 94.9194 81.806 95.1643 82.0111L97.2555 83.7447C95.3524 83.6008 93.4687 83.2647 91.6333 82.7414L88.5637 80.0973C88.2265 79.8068 88.0109 79.4002 87.9595 78.9582L87.4315 74.385L82.1904 71.7645C81.7323 71.5336 81.3472 71.1801 81.0781 70.7434C80.8089 70.3067 80.6663 69.8038 80.666 69.2908V65.071C80.6659 64.6777 80.7607 64.2902 80.9424 63.9413C81.124 63.5925 81.3872 63.2926 81.7095 63.0672L84.4894 61.127L84.7666 60.9344L87.6047 58.9388C87.8842 58.7432 88.2163 58.6368 88.5575 58.6336C88.8986 58.6304 89.2326 58.7305 89.5158 58.9208L93.6579 61.7105C93.8865 61.8642 94.0823 62.0618 94.2338 62.2919C94.3854 62.5219 94.4897 62.7798 94.5407 63.0505L94.8511 64.6955C94.8937 64.9194 95.013 65.1216 95.1886 65.267C95.3641 65.4125 95.5849 65.4921 95.8128 65.4923H100.274C100.812 65.4923 101.328 65.706 101.709 66.0864C102.09 66.4668 102.305 66.9828 102.305 67.5211Z"
      fill="#CC4646"
    />
    <path
      d="M112.165 29.9978L110.27 30.5978C110.052 30.6674 109.824 30.7029 109.595 30.7031H105.529C105.149 30.703 104.778 30.8185 104.465 31.0344L100.042 34.0831C99.8785 34.1954 99.6944 34.2732 99.5003 34.3118C99.3063 34.3505 99.1064 34.3491 98.9128 34.3079C98.7193 34.2666 98.5362 34.1863 98.3748 34.0719C98.2133 33.9575 98.0769 33.8114 97.9739 33.6424L96.9761 32.0044C96.8088 31.7289 96.7175 31.4138 96.7117 31.0915C96.7058 30.7692 96.7856 30.4511 96.9429 30.1696L97.3059 29.5197C97.3704 29.4063 97.4114 29.2811 97.4264 29.1516C97.4414 29.022 97.4302 28.8908 97.3933 28.7657C97.3564 28.6406 97.2947 28.5242 97.2118 28.4235C97.129 28.3228 97.0266 28.2399 96.911 28.1796L95.2646 27.3065C96.6494 27.1026 98.0473 27.0002 99.447 27.0003C103.862 26.9931 108.217 28.0197 112.165 29.9978Z"
      fill="#CC4646"
    />
    <path
      d="M127.865 55.4176C127.869 59.5673 126.963 63.6675 125.209 67.4286C123.456 71.1896 120.898 74.5199 117.717 77.1844L118.856 72.0569L118.706 68.6396C118.68 68.0513 118.428 67.4958 118.003 67.0887C117.577 66.6816 117.011 66.4543 116.422 66.4541H112.796C112.47 66.4542 112.148 66.3855 111.85 66.2525C111.553 66.1195 111.287 65.9251 111.07 65.6822L108.892 63.2349C108.513 62.8109 108.303 62.2624 108.303 61.6939V56.5664C108.302 55.8677 108.522 55.1866 108.93 54.6196C109.339 54.0527 109.915 53.6289 110.578 53.4082L119.008 50.5978C119.6 50.3994 120.245 50.4371 120.81 50.7031L126.598 53.4165C126.977 53.5931 127.297 53.8737 127.522 54.2256C127.747 54.5775 127.867 54.9861 127.869 55.4037L127.865 55.4176Z"
      fill="#CC4646"
    />
    <path
      d="M57.9994 48.523H7.43555C7.43555 44.4654 12.0406 41.1782 17.7238 41.1782C18.9577 41.1753 20.1865 41.336 21.3781 41.6563C23.2628 39.1619 26.155 37.5571 29.3978 37.5571C34.2675 37.5571 38.3473 41.1602 39.4144 46.0105C41.2866 44.8174 44.3063 43.8764 47.7126 43.8764C53.3944 43.8736 57.9994 46.4942 57.9994 48.523Z"
      fill="#999EFF"
    />
    <path
      d="M181.077 32.0887H139.818C139.818 26.4665 143.699 21.9114 148.492 21.9114C151.226 21.9114 153.665 23.3956 155.253 25.714C156.254 25.4173 157.293 25.2679 158.337 25.2706C161.836 25.2706 164.85 26.9003 166.222 29.2478C167.795 28.3928 169.982 27.7733 172.399 27.7733C177.192 27.7733 181.077 30.2054 181.077 32.0887Z"
      fill="#666DFF"
    />
    <path
      d="M206.514 132.262V133.204H205.573C204.817 133.204 204.091 132.903 203.556 132.368C203.021 131.833 202.72 131.107 202.72 130.351V129.563H196.552C195.118 129.563 193.744 128.994 192.73 127.98C191.717 126.967 191.147 125.592 191.147 124.159V123.711H189.707C188.274 123.711 186.899 123.142 185.886 122.128C184.872 121.115 184.303 119.74 184.303 118.306V118.02H179.657C178.485 118.019 177.36 117.553 176.53 116.724C175.701 115.894 175.235 114.769 175.235 113.596V111.419C175.235 110.659 175.385 109.906 175.676 109.203C175.967 108.501 176.393 107.862 176.931 107.325C177.468 106.787 178.107 106.36 178.809 106.07C179.512 105.779 180.265 105.629 181.025 105.629H184.44C185.025 105.629 185.604 105.744 186.144 105.968C186.685 106.192 187.176 106.52 187.589 106.934C188.003 107.347 188.331 107.838 188.555 108.379C188.779 108.919 188.894 109.498 188.894 110.083V112.023H191.539C192.721 112.023 193.854 112.493 194.689 113.328C195.524 114.163 195.993 115.296 195.993 116.477V117.863H198.378C198.963 117.863 199.543 117.978 200.083 118.202C200.624 118.425 201.115 118.753 201.529 119.167C201.942 119.581 202.271 120.072 202.494 120.612C202.718 121.153 202.834 121.732 202.834 122.317V129.4H203.651C204.028 129.398 204.4 129.471 204.748 129.615C205.096 129.758 205.412 129.969 205.679 130.235C205.945 130.501 206.156 130.817 206.299 131.165C206.442 131.512 206.516 131.885 206.514 132.262Z"
      fill="#CC4646"
    />
    <path
      d="M204.415 126.477H204.49C204.745 126.477 204.989 126.579 205.17 126.759C205.35 126.939 205.451 127.184 205.451 127.439V127.514C205.451 127.64 205.427 127.765 205.378 127.882C205.33 127.999 205.259 128.105 205.17 128.194C205.08 128.283 204.974 128.354 204.858 128.402C204.741 128.451 204.616 128.476 204.49 128.476H203.453V127.439C203.453 127.184 203.554 126.939 203.735 126.759C203.915 126.579 204.16 126.477 204.415 126.477Z"
      fill="#000799"
    />
    <path
      d="M200.969 132.127C200.838 132.127 200.708 132.101 200.587 132.051C200.466 132.001 200.356 131.927 200.263 131.834C200.171 131.742 200.097 131.632 200.047 131.51C199.997 131.389 199.971 131.259 199.972 131.128C199.971 130.997 199.997 130.867 200.047 130.746C200.097 130.625 200.171 130.514 200.263 130.422C200.356 130.329 200.466 130.255 200.587 130.205C200.708 130.155 200.838 130.129 200.969 130.129H201.969V131.128C201.969 131.259 201.943 131.389 201.893 131.51C201.842 131.632 201.769 131.742 201.676 131.835C201.583 131.927 201.473 132.001 201.352 132.051C201.231 132.101 201.101 132.127 200.969 132.127Z"
      fill="#000799"
    />
    <path
      d="M181.374 133.218L175.954 141.781L166.128 155.5L164.176 153.805L178.727 119.838C179.485 118.438 180.869 117.578 182.364 117.578C184.28 117.578 185.955 118.979 186.444 120.989L181.374 133.218Z"
      fill="#FF9A9A"
    />
    <path
      d="M153.536 144.793C154.635 144.793 155.525 143.547 155.525 142.009C155.525 140.472 154.635 139.225 153.536 139.225C152.438 139.225 151.548 140.472 151.548 142.009C151.548 143.547 152.438 144.793 153.536 144.793Z"
      fill="#CC4646"
    />
    <path
      d="M154.924 142.009C155.617 142.009 156.293 142.22 156.864 142.612C157.435 143.005 157.873 143.562 158.121 144.209L164.18 159.757"
      stroke="#CC4646"
      strokeWidth="0.756618"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M178.638 156.201C178.844 157.078 175.762 158.557 171.753 159.502C167.744 160.447 164.327 160.507 164.12 159.631L164.112 159.558L161.699 140.295C161.846 140.914 164.305 140.863 167.19 140.183C170.075 139.502 172.298 138.445 172.151 137.827L178.621 156.135L178.638 156.201Z"
      fill="#CC4646"
    />
    <path
      d="M172.151 137.823C172.298 138.442 170.072 139.498 167.19 140.179C164.307 140.859 161.846 140.91 161.699 140.291C161.552 139.671 163.778 138.615 166.66 137.935C169.543 137.255 172.005 137.202 172.151 137.823Z"
      fill="#CCCEFF"
    />
    <path
      d="M213.236 197.758H211.351L210.173 195.817L208.996 197.758H201.281C201.252 197.759 201.224 197.75 201.202 197.732C201.18 197.714 201.165 197.688 201.16 197.66C201.155 197.632 201.161 197.603 201.175 197.579C201.19 197.554 201.213 197.536 201.241 197.528L202.042 197.242C202.79 196.977 203.413 196.443 203.789 195.744C204.165 195.045 204.267 194.231 204.076 193.46L203.236 190.071H211.056L213.236 197.758Z"
      fill="#CC4646"
    />
    <path
      d="M203.382 193.873H202.393C202.13 193.873 201.879 193.769 201.693 193.583C201.508 193.398 201.403 193.146 201.403 192.884C201.403 192.754 201.429 192.625 201.478 192.505C201.528 192.384 201.601 192.275 201.693 192.183C201.785 192.091 201.894 192.018 202.014 191.968C202.134 191.918 202.263 191.893 202.393 191.893V191.893C202.655 191.893 202.907 191.998 203.093 192.184C203.278 192.369 203.382 192.621 203.382 192.884V193.873Z"
      stroke="#CCCEFF"
      strokeWidth="0.344611"
      strokeMiterlimit="10"
    />
    <path
      d="M204.348 193.873H205.337C205.6 193.873 205.851 193.769 206.037 193.583C206.223 193.398 206.328 193.146 206.328 192.884V192.884C206.328 192.621 206.224 192.369 206.038 192.183C205.852 191.997 205.6 191.893 205.337 191.893C205.075 191.893 204.823 191.998 204.637 192.184C204.452 192.369 204.348 192.621 204.348 192.884V193.873Z"
      stroke="#CCCEFF"
      strokeWidth="0.344611"
      strokeMiterlimit="10"
    />
    <path
      d="M187.377 197.758H185.493L185.019 195.817L183.138 197.758H176.208C176.155 197.757 176.103 197.737 176.062 197.702C176.021 197.667 175.994 197.618 175.986 197.565C175.978 197.511 175.989 197.457 176.017 197.411C176.046 197.365 176.089 197.331 176.141 197.314L176.375 197.241C178.125 196.686 179.601 195.055 179.787 193.459L180.179 190.069H187.999L187.377 197.758Z"
      fill="#CC4646"
    />
    <path
      d="M177.928 191.893C178.058 191.893 178.187 191.918 178.307 191.968C178.427 192.018 178.536 192.091 178.628 192.183C178.719 192.275 178.792 192.384 178.842 192.504C178.892 192.624 178.917 192.752 178.917 192.882V193.872H177.928C177.666 193.872 177.414 193.768 177.228 193.582C177.043 193.396 176.938 193.145 176.938 192.882V192.882C176.938 192.752 176.964 192.624 177.014 192.504C177.064 192.384 177.136 192.275 177.228 192.183C177.32 192.091 177.429 192.018 177.549 191.968C177.669 191.918 177.798 191.893 177.928 191.893V191.893Z"
      stroke="#CCCEFF"
      strokeWidth="0.344611"
      strokeMiterlimit="10"
    />
    <path
      d="M179.888 193.873H180.877C181.14 193.873 181.392 193.769 181.577 193.583C181.763 193.398 181.868 193.146 181.868 192.884C181.868 192.621 181.764 192.369 181.578 192.183C181.392 191.997 181.14 191.893 180.877 191.893C180.615 191.893 180.363 191.998 180.177 192.184C179.992 192.369 179.888 192.621 179.888 192.884V193.873Z"
      stroke="#CCCEFF"
      strokeWidth="0.344611"
      strokeMiterlimit="10"
    />
    <path
      d="M213.527 190.069H203.241L194.016 162.394L190.093 190.069H180.02L183.943 142.009L189.827 133.446C189.827 133.446 191.26 140.725 200.338 140.137L213.527 190.069Z"
      fill="#000799"
    />
    <path
      d="M194.016 162.394L190.284 149.887"
      stroke="#CC4646"
      strokeWidth="0.619282"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M200.338 140.137C191.259 140.725 189.826 133.446 189.826 133.446L183.942 142.009L170.167 158.152L166.122 155.501L186.955 120.066C187.413 119.286 188.092 118.66 188.906 118.265C189.72 117.871 190.633 117.726 191.529 117.849C192.426 117.972 193.265 118.358 193.943 118.957C194.62 119.557 195.105 120.343 195.336 121.218L200.338 140.137Z"
      fill="#FF9A9A"
    />
    <path
      d="M166.128 155.275L185.28 122.541"
      stroke="white"
      strokeWidth="0.513737"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M200.338 140.137C191.259 140.725 190.054 133.672 190.054 133.672L191.162 131.829"
      stroke="white"
      strokeWidth="0.513737"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M165.848 155.226L164 158.213C163.978 158.25 163.966 158.292 163.967 158.334C163.968 158.377 163.981 158.418 164.005 158.454C164.03 158.489 164.063 158.516 164.103 158.532C164.142 158.548 164.186 158.553 164.228 158.545L165.059 158.389C165.075 158.386 165.092 158.388 165.107 158.395C165.121 158.402 165.133 158.414 165.14 158.429C165.147 158.444 165.149 158.461 165.145 158.477C165.141 158.493 165.132 158.507 165.119 158.517L163.906 159.522C163.887 159.54 163.864 159.553 163.839 159.56C163.814 159.567 163.788 159.568 163.762 159.562C163.737 159.557 163.713 159.545 163.693 159.528C163.673 159.512 163.657 159.49 163.647 159.466L163.423 158.92C163.407 158.878 163.376 158.844 163.336 158.823C163.296 158.803 163.25 158.798 163.207 158.809C163.163 158.82 163.126 158.847 163.101 158.885C163.077 158.922 163.067 158.967 163.073 159.012L163.247 160.424C163.299 160.661 163.401 160.885 163.548 161.079C163.694 161.274 163.88 161.434 164.094 161.55C164.307 161.667 164.543 161.736 164.786 161.752C165.029 161.769 165.272 161.734 165.5 161.649L167.315 160.97C167.895 160.753 168.405 160.382 168.79 159.897L170.176 158.152L165.848 155.226Z"
      fill="#FF9A9A"
    />
    <path
      d="M191.648 117.864C190.736 117.715 189.8 117.846 188.964 118.239C188.128 118.632 187.43 119.269 186.964 120.067L185.495 122.561L181.925 117.651C181.26 118.016 180.568 118.143 179.857 117.928C177.882 117.327 177.798 114.741 177.798 111.868C177.798 110.56 178.462 109.504 179.372 108.807C179.452 112.259 183.65 113.557 185.247 113.926L191.648 117.864Z"
      fill="#FFBCBC"
    />
    <path
      d="M182.929 119.036L181.924 117.65C181.924 117.65 183.136 116.818 183.552 116.274L182.929 119.036Z"
      fill="#CC4646"
    />
    <path
      d="M177.737 112.381H178.121C178.417 112.381 178.702 112.499 178.911 112.708C179.12 112.917 179.238 113.202 179.238 113.498V115.093C179.238 115.156 179.264 115.217 179.309 115.262C179.354 115.306 179.415 115.331 179.478 115.331H179.91"
      stroke="#CC4646"
      strokeWidth="0.413279"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M180.928 113.758C180.914 113.827 180.881 113.889 180.833 113.94C180.785 113.99 180.724 114.026 180.656 114.043C180.589 114.059 180.518 114.057 180.452 114.035C180.386 114.013 180.328 113.973 180.283 113.919C180.239 113.865 180.211 113.8 180.203 113.731C180.194 113.662 180.206 113.592 180.235 113.529C180.265 113.466 180.312 113.413 180.371 113.376C180.43 113.339 180.498 113.319 180.567 113.319C180.673 113.33 180.77 113.383 180.837 113.465C180.905 113.547 180.937 113.653 180.928 113.758Z"
      fill="#CC4646"
    />
    <path
      d="M178.563 113.758C178.563 113.958 178.455 114.119 178.324 114.119C178.192 114.119 178.084 113.958 178.084 113.758C178.084 113.559 178.191 113.399 178.324 113.399C178.457 113.399 178.563 113.56 178.563 113.758Z"
      fill="#CC4646"
    />
    <path
      d="M153.538 142.608V142.763C151.358 143.164 149.389 144.316 147.971 146.019C146.553 147.722 145.777 149.868 145.777 152.084V159.487C145.777 161.967 144.792 164.345 143.039 166.098C141.285 167.852 138.907 168.837 136.427 168.837H135.561C135.12 168.837 134.684 168.924 134.277 169.093C133.869 169.261 133.499 169.508 133.188 169.82C132.876 170.132 132.629 170.502 132.46 170.909C132.291 171.316 132.205 171.753 132.205 172.193V177.263C132.205 179.743 131.22 182.121 129.466 183.875C127.713 185.628 125.335 186.614 122.855 186.614H120.734C120.121 186.614 119.532 186.858 119.098 187.291C118.664 187.725 118.42 188.313 118.42 188.927V191.596C118.42 193.34 117.727 195.013 116.494 196.246C115.261 197.479 113.588 198.172 111.844 198.172H100.093V182.483C100.093 180.063 101.054 177.742 102.765 176.031C104.476 174.32 106.797 173.359 109.217 173.359H113.345C114.129 173.359 114.88 173.048 115.434 172.494C115.988 171.939 116.3 171.188 116.3 170.404V166.789C116.3 165.591 116.536 164.404 116.994 163.297C117.453 162.19 118.125 161.185 118.972 160.338C119.82 159.49 120.825 158.818 121.932 158.36C123.039 157.902 124.226 157.666 125.424 157.666H128.826C129.61 157.666 130.361 157.354 130.915 156.8C131.469 156.246 131.78 155.495 131.781 154.712V151.732C131.781 149.312 132.742 146.991 134.453 145.28C136.164 143.569 138.485 142.608 140.905 142.608H153.538Z"
      fill="#CCCEFF"
    />
    <path
      d="M102.803 184.973C102.803 183.775 103.039 182.589 103.497 181.482C103.956 180.375 104.628 179.369 105.475 178.522C106.322 177.675 107.328 177.003 108.435 176.545C109.542 176.086 110.729 175.85 111.927 175.85H115.282C115.771 175.85 116.256 175.754 116.708 175.567C117.161 175.379 117.572 175.105 117.918 174.759C118.264 174.412 118.539 174.002 118.726 173.549C118.913 173.097 119.01 172.612 119.01 172.123V169.28C119.01 166.86 119.971 164.54 121.682 162.829C123.393 161.118 125.714 160.156 128.134 160.156H130.894C131.85 160.156 132.766 159.777 133.442 159.101C134.118 158.425 134.497 157.509 134.497 156.553V154.217C134.497 151.797 135.459 149.476 137.17 147.765C138.881 146.054 141.202 145.093 143.622 145.093"
      stroke="white"
      strokeWidth="0.513737"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M105.747 188.373C105.747 187.175 105.983 185.988 106.441 184.881C106.899 183.774 107.571 182.768 108.419 181.921C109.266 181.074 110.271 180.402 111.378 179.943C112.485 179.485 113.672 179.249 114.87 179.249H118.226C118.716 179.249 119.2 179.152 119.653 178.965C120.105 178.777 120.516 178.503 120.862 178.157C121.208 177.81 121.482 177.4 121.669 176.947C121.857 176.495 121.953 176.01 121.953 175.521V172.68C121.953 170.26 122.914 167.939 124.625 166.228C126.336 164.517 128.657 163.556 131.077 163.556H133.839C134.794 163.556 135.711 163.176 136.386 162.5C137.062 161.825 137.442 160.908 137.442 159.953"
      stroke="white"
      strokeWidth="0.513737"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M112.05 170.036C112.932 170.036 113.648 169.32 113.648 168.438C113.648 167.555 112.932 166.84 112.05 166.84C111.168 166.84 110.452 167.555 110.452 168.438C110.452 169.32 111.168 170.036 112.05 170.036Z"
      fill="#CCCEFF"
    />
    <path
      d="M96.8118 192.291C97.0553 190.791 96.0365 189.377 94.5361 189.133C93.0357 188.89 91.622 189.909 91.3785 191.409C91.1349 192.909 92.1538 194.323 93.6541 194.567C95.1545 194.81 96.5682 193.791 96.8118 192.291Z"
      fill="#CCCEFF"
    />
    <path
      d="M149.523 149.127C150.185 149.127 150.722 148.59 150.722 147.928C150.722 147.266 150.185 146.729 149.523 146.729C148.861 146.729 148.324 147.266 148.324 147.928C148.324 148.59 148.861 149.127 149.523 149.127Z"
      fill="#CCCEFF"
    />
    <path
      d="M62.4901 197.432H48.833L49.2404 193.342L54.4026 191.751L54.6354 192.264C54.8682 192.776 55.2435 193.211 55.7165 193.516C56.1896 193.82 56.7403 193.982 57.303 193.983H58.8274C59.5812 193.983 60.3152 194.224 60.922 194.671C61.5287 195.119 61.9762 195.749 62.1991 196.469L62.4901 197.432Z"
      fill="#CC4646"
    />
    <path
      d="M54.4746 197.666H62.4901"
      stroke="#000799"
      strokeWidth="0.825286"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M48.833 197.666H52.6398"
      stroke="#000799"
      strokeWidth="0.825286"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M55.1904 194.246L55.7697 192.969"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M56.8369 194.502L57.1612 193.694"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M18.7109 193.105L18.043 197.431H31.7015L31.4035 196.469C31.1808 195.749 30.7333 195.119 30.1266 194.671C29.5198 194.224 28.7857 193.983 28.0318 193.983H25.2852C25.0327 193.983 24.7874 193.898 24.5889 193.742C24.3904 193.586 24.25 193.367 24.1904 193.122L18.7109 193.105Z"
      fill="#CC4646"
    />
    <path
      d="M23.6855 197.666H31.701"
      stroke="#000799"
      strokeWidth="0.825286"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M18.043 197.666H21.8498"
      stroke="#000799"
      strokeWidth="0.825286"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M24.6475 194.736L25.2281 193.438"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M26.0488 194.736L26.4161 193.65"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M34.1767 131.869L55.3795 189.89C55.4445 190.069 55.473 190.258 55.4633 190.447C55.4536 190.637 55.4059 190.823 55.323 190.993C55.2401 191.164 55.1238 191.316 54.9809 191.441C54.838 191.566 54.6714 191.66 54.4912 191.72L49.3387 193.412C48.8684 193.566 48.358 193.545 47.9024 193.351C47.4468 193.157 47.0772 192.804 46.8623 192.358L26.9788 151.107L25.593 191.214C25.5743 191.761 25.3436 192.28 24.9495 192.661C24.5554 193.041 24.0289 193.254 23.481 193.254H18.4131C17.8624 193.254 17.3332 193.039 16.9385 192.655C16.5438 192.271 16.3146 191.748 16.2998 191.197L15.0318 143.334C14.8322 139.022 15.8328 134.982 17.9337 131.238L21.0282 127.119L34.1767 131.869Z"
      fill="#FF7979"
    />
    <path
      d="M27.3652 151.556L28.0762 140.481L29.7904 139.936"
      stroke="#CC4646"
      strokeWidth="0.619282"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M35.6505 97.6703L44.6794 100.848C45.1731 101.022 45.5775 101.385 45.8037 101.857C46.03 102.329 46.0594 102.871 45.8857 103.365L45.2655 105.127L32.5137 100.639L33.1339 98.8766C33.3077 98.3829 33.6704 97.9785 34.1424 97.7522C34.6143 97.526 35.1568 97.4965 35.6505 97.6703Z"
      fill="#000799"
    />
    <path
      d="M43.3724 103.382L33.5041 131.617L26.0928 129.047L35.9708 100.71L43.3724 103.382Z"
      fill="#CCCEFF"
    />
    <path
      d="M36.1826 100.441L26.2491 128.671C25.9277 129.584 25.2568 130.331 24.3841 130.75C23.5114 131.168 22.5084 131.222 21.5956 130.901L20.2098 130.413C19.2926 130.09 18.5333 129.43 18.0869 128.566C17.6405 127.703 17.5408 126.701 17.8082 125.766L24.7372 101.524C24.9784 100.681 25.526 99.9585 26.2726 99.4983C27.0193 99.038 27.911 98.8735 28.7727 99.0369L36.1826 100.441Z"
      fill="#000799"
    />
    <path
      d="M33.6562 130.992L43.3666 103.381L47.165 105.662C47.8907 106.098 48.435 106.78 48.6977 107.585C48.9604 108.389 48.924 109.262 48.5952 110.042L38.8461 133.356C38.7129 133.674 38.5132 133.961 38.2606 134.195C38.0079 134.43 37.708 134.608 37.3809 134.718C37.0538 134.827 36.7071 134.866 36.364 134.831C36.0209 134.795 35.6892 134.687 35.3912 134.513L34.8272 134.185C34.2904 133.873 33.8796 133.383 33.6658 132.8C33.452 132.217 33.4486 131.577 33.6562 130.992Z"
      fill="#000799"
    />
    <path
      d="M34.8164 128.193L40.1906 130.084"
      stroke="white"
      strokeWidth="0.798582"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M18.9004 122.512L27.2706 125.459"
      stroke="white"
      strokeWidth="0.798582"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M37.7793 120.001L43.1534 121.893"
      stroke="white"
      strokeWidth="0.798582"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M21.4082 114.241L30.0071 117.267"
      stroke="white"
      strokeWidth="0.798582"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M40.7422 111.584L46.7982 113.715"
      stroke="white"
      strokeWidth="0.798582"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M23.917 105.663L32.9691 108.849"
      stroke="white"
      strokeWidth="0.798582"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M75.2117 188.891H70.1355C69.7835 188.891 69.498 189.177 69.498 189.529V191.197C69.498 191.549 69.7835 191.835 70.1355 191.835H75.2117C75.5638 191.835 75.8492 191.549 75.8492 191.197V189.529C75.8492 189.177 75.5638 188.891 75.2117 188.891Z"
      fill="#FFBCBC"
    />
    <path d="M64.25 198.172L66.3883 191.165L72.3473 198.179L64.25 198.172Z" fill="#000799" />
    <path
      d="M81.452 192.523H71.8954C70.3961 192.523 69.1807 193.738 69.1807 195.237C69.1807 196.736 70.3961 197.951 71.8954 197.951H81.452C82.9513 197.951 84.1668 196.736 84.1668 195.237C84.1668 193.738 82.9513 192.523 81.452 192.523Z"
      stroke="#CCCEFF"
      strokeWidth="0.344611"
      strokeMiterlimit="10"
    />
    <path
      d="M71.8945 197.953C73.3938 197.953 74.6093 196.737 74.6093 195.238C74.6093 193.739 73.3938 192.523 71.8945 192.523C70.3951 192.523 69.1797 193.739 69.1797 195.238C69.1797 196.737 70.3951 197.953 71.8945 197.953Z"
      stroke="#CCCEFF"
      strokeWidth="0.344611"
      strokeMiterlimit="10"
    />
    <path
      d="M71.4042 195.924C71.7832 195.767 71.9632 195.332 71.8062 194.953C71.6492 194.574 71.2147 194.394 70.8357 194.551C70.4567 194.708 70.2767 195.143 70.4337 195.522C70.5907 195.901 71.0252 196.081 71.4042 195.924Z"
      stroke="#CCCEFF"
      strokeWidth="0.344611"
      strokeMiterlimit="10"
    />
    <path
      d="M68.2551 191.671C68.6079 191.671 68.8939 191.385 68.8939 191.032C68.8939 190.679 68.6079 190.393 68.2551 190.393C67.9022 190.393 67.6162 190.679 67.6162 191.032C67.6162 191.385 67.9022 191.671 68.2551 191.671Z"
      fill="#CC4646"
    />
    <path
      d="M64.6866 194.525C64.8183 193.967 64.4727 193.408 63.9148 193.276C63.3569 193.145 62.7978 193.49 62.6662 194.048C62.5345 194.606 62.88 195.165 63.4379 195.297C63.9959 195.428 64.5549 195.083 64.6866 194.525Z"
      fill="#CCCEFF"
    />
    <path
      d="M17.7373 121.822L79.8518 192.594C80.6727 193.562 81.1231 194.789 81.1226 196.058V197.533"
      stroke="#CC4646"
      strokeWidth="0.619282"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M75.7134 197.53L75.6926 195.197C75.6868 194.542 75.5609 193.894 75.3212 193.286L70.8936 182.042L84.146 192.509C84.7801 193.01 85.2925 193.648 85.6448 194.375C85.9972 195.102 86.1802 195.899 86.1804 196.707V197.53"
      stroke="#CC4646"
      strokeWidth="0.619282"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M14.0136 120.237L21.7394 112.023L25.6197 100.015L24.5526 99.8282C23.4424 99.6346 22.3003 99.7483 21.25 100.157C20.1997 100.566 19.2811 101.254 18.5937 102.147L5.36061 115.064C4.19087 116.205 3.51425 117.759 3.4754 119.394C3.43656 121.028 4.03857 122.612 5.15274 123.808L16.4706 134.202L20.9426 128.701L14.0136 120.237Z"
      fill="#FFDDDD"
    />
    <path
      d="M21.2071 135.497L17.8271 132.536L20.9424 128.705L23.0336 128.349C23.6897 128.238 24.3642 128.344 24.9543 128.651L27.3656 129.913L27.2368 130.159C27.1286 130.366 26.9544 130.53 26.742 130.627C26.5295 130.723 26.291 130.745 26.0644 130.69L25.4117 130.531C25.2099 130.482 24.9976 130.501 24.8085 130.587C24.6194 130.673 24.4644 130.819 24.3681 131.003C24.2858 131.161 24.2694 131.345 24.3227 131.515C24.3759 131.685 24.4945 131.827 24.6522 131.909L27.9685 133.757C28.0757 133.816 28.1619 133.907 28.2155 134.018C28.2691 134.128 28.2875 134.252 28.2681 134.373C28.2488 134.494 28.1926 134.606 28.1072 134.695C28.0219 134.783 27.9115 134.842 27.7911 134.865L22.5874 135.872C22.344 135.918 22.0932 135.909 21.854 135.844C21.6148 135.779 21.3935 135.661 21.2071 135.497Z"
      fill="#FFBCBC"
    />
    <path
      d="M49.35 127.65L51.024 111.978C51.0573 110.111 50.3034 108.956 49.2627 108.408L48.8469 108.185L40.8467 128.456L43.3799 146.463L49.9154 145.418L49.35 127.65Z"
      fill="#FFDDDD"
    />
    <path
      d="M49.7725 150.098L49.9194 145.12L46.8429 145.095C45.6441 145.085 44.4823 145.511 43.5738 146.293L43.3756 146.463C42.8714 146.898 42.5234 147.486 42.3847 148.137L41.8193 150.798L42.0965 150.856C42.3246 150.905 42.5625 150.876 42.7721 150.773C42.9816 150.671 43.1507 150.501 43.2523 150.291L43.546 149.687C43.6366 149.5 43.7871 149.349 43.9734 149.258C44.1598 149.167 44.3714 149.14 44.5743 149.184C44.6606 149.202 44.7425 149.237 44.8152 149.287C44.8879 149.337 44.9501 149.401 44.9981 149.475C45.0461 149.549 45.0791 149.631 45.095 149.718C45.111 149.805 45.1097 149.894 45.0912 149.98L44.9152 153.751C44.9089 153.883 44.9393 154.014 45.003 154.13C45.0667 154.246 45.1611 154.341 45.276 154.407C45.3908 154.472 45.5214 154.504 45.6535 154.5C45.7855 154.495 45.9137 154.455 46.0239 154.382L48.1871 152.953C48.6608 152.639 49.052 152.216 49.3279 151.719C49.6038 151.222 49.7563 150.666 49.7725 150.098Z"
      fill="#FFBCBC"
    />
    <path
      d="M44.3214 101.224L43.0562 104.288C42.5393 105.502 41.0675 106.089 39.7857 105.578L37.1859 104.554C35.9193 104.055 35.322 102.686 35.8486 101.493L37.2954 98.2263L44.3214 101.224Z"
      fill="#FFBCBC"
    />
    <path
      d="M48.389 100.79C48.3915 100.883 48.373 100.976 48.335 101.061C48.2971 101.146 48.2405 101.222 48.1696 101.282C48.0986 101.342 48.0151 101.386 47.925 101.41C47.8349 101.434 47.7406 101.438 47.649 101.421L46.7302 101.246C46.5459 101.63 46.2802 101.97 45.9517 102.241C45.6231 102.513 45.2395 102.71 44.8274 102.818C44.4152 102.927 43.9844 102.945 43.5648 102.87C43.1451 102.796 42.7466 102.631 42.3968 102.388L37.7599 99.1603C37.4472 98.9428 37.2087 98.6345 37.0769 98.2771C36.945 97.9197 36.9261 97.5305 37.0227 97.162L37.7765 95.1387C38.5263 92.2618 41.5972 90.7997 44.1817 92.2673L46.4877 93.5838C47.1161 93.9412 47.6233 94.4781 47.9443 95.1258C48.2653 95.7735 48.3854 96.5023 48.2893 97.2188L48.389 100.79Z"
      fill="#FFBCBC"
    />
    <path
      d="M38.7148 96.7891C38.7148 96.7891 39.6475 97.2977 39.1722 98.2968"
      stroke="#CC4646"
      strokeWidth="0.413279"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M38.3545 97.6637L39.1971 97.3408"
      stroke="#CC4646"
      strokeWidth="0.413279"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M46.5544 99.6355C46.5031 99.6761 46.441 99.7007 46.3758 99.706C46.3106 99.7113 46.2453 99.6972 46.1881 99.6655C46.1309 99.6337 46.0844 99.5858 46.0544 99.5276C46.0244 99.4695 46.0123 99.4038 46.0197 99.3388C46.027 99.2738 46.0534 99.2124 46.0956 99.1624C46.1377 99.1124 46.1938 99.076 46.2566 99.0578C46.3194 99.0396 46.3862 99.0404 46.4486 99.0601C46.511 99.0798 46.5661 99.1175 46.6071 99.1685C46.6344 99.2026 46.6547 99.2417 46.6669 99.2837C46.6791 99.3256 46.6828 99.3696 46.6779 99.413C46.673 99.4564 46.6596 99.4984 46.6384 99.5366C46.6172 99.5748 46.5886 99.6084 46.5544 99.6355Z"
      fill="#CC4646"
    />
    <path
      d="M46.2379 97.6567H47.7484V97.8119C47.7484 97.9979 47.6745 98.1763 47.543 98.3078C47.4115 98.4393 47.2332 98.5132 47.0472 98.5132H45.4258V98.4688C45.4258 98.2534 45.5113 98.0469 45.6636 97.8946C45.8159 97.7423 46.0225 97.6567 46.2379 97.6567Z"
      fill="#CC4646"
    />
    <path
      d="M50.5856 94.9198C50.1158 96.1102 48.6899 96.6618 47.3983 96.1532C46.82 95.9281 46.3406 95.5047 46.0457 94.9586C45.753 95.1086 45.4314 95.1937 45.1028 95.208C44.7742 95.2224 44.4463 95.1657 44.1416 95.0418C43.9009 94.9476 43.6776 94.8142 43.4806 94.6468L43.4903 94.7327C43.5568 95.3289 43.4438 95.9313 43.1658 96.4629C42.8878 96.9944 42.4575 97.4309 41.9299 97.7164L41.3756 98.0143C41.0652 98.868 40.7506 99.6149 41.5474 100.37L42.8806 101.362L43.2755 101.224C44.3957 100.824 45.6199 100.824 46.74 101.224L45.8614 104.016C45.7994 104.209 45.6909 104.383 45.5456 104.524C45.4003 104.665 45.2226 104.767 45.0281 104.823C44.8337 104.879 44.6285 104.886 44.4307 104.844C44.2328 104.802 44.0484 104.712 43.8936 104.582L40.084 101.375C39.8664 101.191 39.7063 100.949 39.623 100.676C39.5397 100.404 39.5367 100.113 39.6142 99.8394L40.2752 98.1764C40.4315 97.7797 40.4302 97.3382 40.2718 96.9423C40.1134 96.5464 39.8098 96.226 39.423 96.0465L39.3883 96.0312C38.9811 95.841 38.5151 95.8196 38.0922 95.9718C37.6692 96.1239 37.3236 96.4372 37.1309 96.8433L37.5466 93.7862C37.6198 93.2353 37.9078 92.7357 38.3478 92.3962C38.7878 92.0566 39.3441 91.9048 39.8955 91.9736C39.9156 91.896 39.9401 91.8197 39.969 91.7449C40.3847 90.6917 41.6486 90.2039 42.7863 90.6543C43.1504 90.797 43.4729 91.0285 43.7245 91.3278C44.0206 91.1693 44.3479 91.0781 44.6833 91.0606C45.0186 91.0431 45.3537 91.0998 45.6646 91.2266C46.0982 91.3964 46.4708 91.6924 46.7345 92.0761C47.0825 91.8456 47.4817 91.7037 47.8972 91.6629C48.3126 91.622 48.7318 91.6835 49.1181 91.8419C50.3972 92.3505 51.0568 93.7294 50.5856 94.9198Z"
      fill="#CC4646"
    />
  </svg>
);

export default EmptyBundleIllustration;
