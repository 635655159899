export const SecurityAndPrivacyIcon = () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="21.7" cy="34.3" r="21.7" fill="#FFBCBC" />
    <path
      d="M24.7497 23.9583L28.9163 28.125L38.2913 18.75M47.6663 25C47.6663 35.2259 36.5123 42.6633 32.4538 45.031C31.9926 45.3001 31.762 45.4346 31.4365 45.5044C31.1839 45.5586 30.8154 45.5586 30.5628 45.5044C30.2374 45.4346 30.0068 45.3001 29.5455 45.031C25.4871 42.6633 14.333 35.2259 14.333 25V15.0367C14.333 13.371 14.333 12.5382 14.6054 11.8223C14.8461 11.1899 15.2371 10.6256 15.7448 10.1782C16.3195 9.67176 17.0993 9.37934 18.6589 8.79449L29.8293 4.60559C30.2624 4.44317 30.4789 4.36196 30.7017 4.32977C30.8993 4.30121 31.1 4.30121 31.2976 4.32977C31.5204 4.36196 31.737 4.44317 32.1701 4.60559L43.3405 8.79449C44.9001 9.37934 45.6799 9.67176 46.2546 10.1782C46.7622 10.6256 47.1533 11.1899 47.3939 11.8223C47.6663 12.5382 47.6663 13.371 47.6663 15.0367V25Z"
      stroke="#000CFF"
      strokeWidth="4.17"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SecurityAndPrivacyIcon;
