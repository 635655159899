import { type TFunction } from 'i18next';
import { object, ref, string } from 'yup';

export const formatDateToLocaleString = (
  date: string,
  lng: string,
  short?: boolean,
  withoutYear?: boolean
) => {
  if (!date?.length) {
    return '';
  }
  const nextPayment = new Date(date);
  const nextDay = nextPayment.getDate();
  const nextMonth = nextPayment.getMonth();
  const nextYear = nextPayment.getFullYear();

  const nextDonationDate = new Date(Date.UTC(nextYear, nextMonth, nextDay));

  const formattingOptions: Intl.DateTimeFormatOptions = {
    year: !withoutYear ? 'numeric' : undefined,
    month: short ? 'short' : 'long',
    day: 'numeric',
  };
  return nextDonationDate.toLocaleDateString(lng, formattingOptions);
};

export const separateDateValues = (date: string) => {
  const d = new Date(date);
  const day = d.getDate();
  const month = d.getMonth() + 1;
  const year = d.getFullYear();

  return { year, month, day };
};

export const formatCharitiesOverview = (
  charities: Array<{ id: string; name: string; donationAmount: number }>
) => {
  const formatted = charities?.reduce((prev, current, i) => {
    return prev + `${i === 0 ? '' : ','} ${current.name}: € ${current.donationAmount}`;
  }, '');

  return formatted || '';
};

export const loginSchema = (t: TFunction) =>
  object().shape({
    email: string().email(t('invalidEmailAddress')),
    password: string(),
  });

export const forgotPasswordSchema = (t: TFunction) =>
  object().shape({
    email: string().email(t('invalidEmailAddress')),
  });

export const changePasswordSchema = (t: TFunction) =>
  object().shape({
    password: string().required(t('required', { field: t('password') })),
    confirmPassword: string()
      .required(t('required', { field: t('confirmPassword') }))
      .oneOf([ref('password')], t('passwordsDoNotMatch')),
  });

export const addressSchema = (t: TFunction) => {
  const addressRegExp = /^[a-zA-Z0-9\-\s]+$/;

  return object().shape({
    street: string()
      .matches(addressRegExp, `${t('street')} ${t('fieldShouldContainNoSpecialCharacters')}`)
      .max(50, `${t('street')} ${t('fieldShouldContainMaximum50characters')}`),
    number: string()
      .matches(addressRegExp, `${t('houseNumber')} ${t('fieldShouldContainNoSpecialCharacters')}`)
      .max(50, `${t('houseNumber')} ${t('fieldShouldContainMaximum50characters')}`),
    postcode: string()
      .matches(addressRegExp, `${t('postCode')} ${t('fieldShouldContainNoSpecialCharacters')}`)
      .max(50, `${t('postCode')} ${t('fieldShouldContainMaximum50characters')}`),
    city: string()
      .matches(addressRegExp, `${t('city')} ${t('fieldShouldContainNoSpecialCharacters')}`)
      .max(50, `${t('postCode')} ${t('fieldShouldContainMaximum50characters')}`),
  });
};

export const openShareMenu = (
  url: string,
  text: string,
  onSuccess: () => void,
  onError: () => void
) => {
  navigator
    .share({
      url,
      text,
    })
    .catch(() => {
      navigator.clipboard
        .writeText(`${text} ${url}`)
        .then(() => {
          onSuccess();
        })
        .catch(() => {
          onError();
        });
    });
};

export const handleChangeUrlHash = (newValue: string) => {
  const url = `${window.location.pathname}#${newValue}`;
  window.history.pushState({}, document.title, url);
};

export const handleChangeTab = (newValue: string, setTabValue: (newValue: string) => void) => {
  setTabValue(newValue);
  document.getElementById(newValue)?.scrollIntoView({ behavior: 'smooth' });
  handleChangeUrlHash(newValue);
};

export const countryOptions = [
  {
    id: 'Afghanistan',
    label: 'Afghanistan',
  },
  {
    id: 'Albania',
    label: 'Albania',
  },
  {
    id: 'Algeria',
    label: 'Algeria',
  },
  {
    id: 'Andorra',
    label: 'Andorra',
  },
  {
    id: 'Angola',
    label: 'Angola',
  },
  {
    id: 'Anguilla',
    label: 'Anguilla',
  },
  {
    id: 'Antigua and Barbuda',
    label: 'Antigua and Barbuda',
  },
  {
    id: 'Argentina',
    label: 'Argentina',
  },
  {
    id: 'Armenia',
    label: 'Armenia',
  },
  {
    id: 'Aruba',
    label: 'Aruba',
  },
  {
    id: 'Australia',
    label: 'Australia',
  },
  {
    id: 'Austria',
    label: 'Austria',
  },
  {
    id: 'Azerbaijan',
    label: 'Azerbaijan',
  },
  {
    id: 'Bahamas',
    label: 'Bahamas',
  },
  {
    id: 'Bahrain',
    label: 'Bahrain',
  },
  {
    id: 'Bangladesh',
    label: 'Bangladesh',
  },
  {
    id: 'Barbados',
    label: 'Barbados',
  },
  {
    id: 'Belarus',
    label: 'Belarus',
  },
  {
    id: 'Belgium',
    label: 'Belgium',
  },
  {
    id: 'Belize',
    label: 'Belize',
  },
  {
    id: 'Benin',
    label: 'Benin',
  },
  {
    id: 'Bermuda',
    label: 'Bermuda',
  },
  {
    id: 'Bhutan',
    label: 'Bhutan',
  },
  {
    id: 'Bolivia',
    label: 'Bolivia',
  },
  {
    id: 'Bosnia and Herzegovina',
    label: 'Bosnia and Herzegovina',
  },
  {
    id: 'Botswana',
    label: 'Botswana',
  },
  {
    id: 'Brazil',
    label: 'Brazil',
  },
  {
    id: 'British Virgin Islands',
    label: 'British Virgin Islands',
  },
  {
    id: 'Brunei',
    label: 'Brunei',
  },
  {
    id: 'Bulgaria',
    label: 'Bulgaria',
  },
  {
    id: 'Burkina Faso',
    label: 'Burkina Faso',
  },
  {
    id: 'Burundi',
    label: 'Burundi',
  },
  {
    id: 'Cambodia',
    label: 'Cambodia',
  },
  {
    id: 'Cameroon',
    label: 'Cameroon',
  },
  {
    id: 'Cape Verde',
    label: 'Cape Verde',
  },
  {
    id: 'Cayman Islands',
    label: 'Cayman Islands',
  },
  {
    id: 'Chad',
    label: 'Chad',
  },
  {
    id: 'Chile',
    label: 'Chile',
  },
  {
    id: 'China',
    label: 'China',
  },
  {
    id: 'Colombia',
    label: 'Colombia',
  },
  {
    id: 'Congo',
    label: 'Congo',
  },
  {
    id: 'Cook Islands',
    label: 'Cook Islands',
  },
  {
    id: 'Costa Rica',
    label: 'Costa Rica',
  },
  {
    id: 'Cote D Ivoire',
    label: 'Cote D Ivoire',
  },
  {
    id: 'Croatia',
    label: 'Croatia',
  },
  {
    id: 'Cruise Ship',
    label: 'Cruise Ship',
  },
  {
    id: 'Cuba',
    label: 'Cuba',
  },
  {
    id: 'Cyprus',
    label: 'Cyprus',
  },
  {
    id: 'Czech Republic',
    label: 'Czech Republic',
  },
  {
    id: 'Denmark',
    label: 'Denmark',
  },
  {
    id: 'Djibouti',
    label: 'Djibouti',
  },
  {
    id: 'Dominica',
    label: 'Dominica',
  },
  {
    id: 'Dominican Republic',
    label: 'Dominican Republic',
  },
  {
    id: 'Ecuador',
    label: 'Ecuador',
  },
  {
    id: 'Egypt',
    label: 'Egypt',
  },
  {
    id: 'El Salvador',
    label: 'El Salvador',
  },
  {
    id: 'Equatorial Guinea',
    label: 'Equatorial Guinea',
  },
  {
    id: 'Estonia',
    label: 'Estonia',
  },
  {
    id: 'Ethiopia',
    label: 'Ethiopia',
  },
  {
    id: 'Falkland Islands',
    label: 'Falkland Islands',
  },
  {
    id: 'Faroe Islands',
    label: 'Faroe Islands',
  },
  {
    id: 'Fiji',
    label: 'Fiji',
  },
  {
    id: 'Finland',
    label: 'Finland',
  },
  {
    id: 'France',
    label: 'France',
  },
  {
    id: 'French Polynesia',
    label: 'French Polynesia',
  },
  {
    id: 'French West Indies',
    label: 'French West Indies',
  },
  {
    id: 'Gabon',
    label: 'Gabon',
  },
  {
    id: 'Gambia',
    label: 'Gambia',
  },
  {
    id: 'Georgia',
    label: 'Georgia',
  },
  {
    id: 'Germany',
    label: 'Germany',
  },
  {
    id: 'Ghana',
    label: 'Ghana',
  },
  {
    id: 'Gibraltar',
    label: 'Gibraltar',
  },
  {
    id: 'Greece',
    label: 'Greece',
  },
  {
    id: 'Greenland',
    label: 'Greenland',
  },
  {
    id: 'Grenada',
    label: 'Grenada',
  },
  {
    id: 'Guam',
    label: 'Guam',
  },
  {
    id: 'Guatemala',
    label: 'Guatemala',
  },
  {
    id: 'Guernsey',
    label: 'Guernsey',
  },
  {
    id: 'Guinea',
    label: 'Guinea',
  },
  {
    id: 'Guinea Bissau',
    label: 'Guinea Bissau',
  },
  {
    id: 'Guyana',
    label: 'Guyana',
  },
  {
    id: 'Haiti',
    label: 'Haiti',
  },
  {
    id: 'Honduras',
    label: 'Honduras',
  },
  {
    id: 'Hong Kong',
    label: 'Hong Kong',
  },
  {
    id: 'Hungary',
    label: 'Hungary',
  },
  {
    id: 'Iceland',
    label: 'Iceland',
  },
  {
    id: 'India',
    label: 'India',
  },
  {
    id: 'Indonesia',
    label: 'Indonesia',
  },
  {
    id: 'Iran',
    label: 'Iran',
  },
  {
    id: 'Iraq',
    label: 'Iraq',
  },
  {
    id: 'Ireland',
    label: 'Ireland',
  },
  {
    id: 'Isle of Man',
    label: 'Isle of Man',
  },
  {
    id: 'Israel',
    label: 'Israel',
  },
  {
    id: 'Italy',
    label: 'Italy',
  },
  {
    id: 'Jamaica',
    label: 'Jamaica',
  },
  {
    id: 'Japan',
    label: 'Japan',
  },
  {
    id: 'Jersey',
    label: 'Jersey',
  },
  {
    id: 'Jordan',
    label: 'Jordan',
  },
  {
    id: 'Kazakhstan',
    label: 'Kazakhstan',
  },
  {
    id: 'Kenya',
    label: 'Kenya',
  },
  {
    id: 'Kuwait',
    label: 'Kuwait',
  },
  {
    id: 'Kyrgyz Republic',
    label: 'Kyrgyz Republic',
  },
  {
    id: 'Laos',
    label: 'Laos',
  },
  {
    id: 'Latvia',
    label: 'Latvia',
  },
  {
    id: 'Lebanon',
    label: 'Lebanon',
  },
  {
    id: 'Lesotho',
    label: 'Lesotho',
  },
  {
    id: 'Liberia',
    label: 'Liberia',
  },
  {
    id: 'Libya',
    label: 'Libya',
  },
  {
    id: 'Liechtenstein',
    label: 'Liechtenstein',
  },
  {
    id: 'Lithuania',
    label: 'Lithuania',
  },
  {
    id: 'Luxembourg',
    label: 'Luxembourg',
  },
  {
    id: 'Macau',
    label: 'Macau',
  },
  {
    id: 'Macedonia',
    label: 'Macedonia',
  },
  {
    id: 'Madagascar',
    label: 'Madagascar',
  },
  {
    id: 'Malawi',
    label: 'Malawi',
  },
  {
    id: 'Malaysia',
    label: 'Malaysia',
  },
  {
    id: 'Maldives',
    label: 'Maldives',
  },
  {
    id: 'Mali',
    label: 'Mali',
  },
  {
    id: 'Malta',
    label: 'Malta',
  },
  {
    id: 'Mauritania',
    label: 'Mauritania',
  },
  {
    id: 'Mauritius',
    label: 'Mauritius',
  },
  {
    id: 'Mexico',
    label: 'Mexico',
  },
  {
    id: 'Moldova',
    label: 'Moldova',
  },
  {
    id: 'Monaco',
    label: 'Monaco',
  },
  {
    id: 'Mongolia',
    label: 'Mongolia',
  },
  {
    id: 'Montenegro',
    label: 'Montenegro',
  },
  {
    id: 'Montserrat',
    label: 'Montserrat',
  },
  {
    id: 'Morocco',
    label: 'Morocco',
  },
  {
    id: 'Mozambique',
    label: 'Mozambique',
  },
  {
    id: 'Namibia',
    label: 'Namibia',
  },
  {
    id: 'Nepal',
    label: 'Nepal',
  },
  {
    id: 'Netherlands',
    label: 'Netherlands',
  },
  {
    id: 'Netherlands Antilles',
    label: 'Netherlands Antilles',
  },
  {
    id: 'New Caledonia',
    label: 'New Caledonia',
  },
  {
    id: 'New Zealand',
    label: 'New Zealand',
  },
  {
    id: 'Nicaragua',
    label: 'Nicaragua',
  },
  {
    id: 'Niger',
    label: 'Niger',
  },
  {
    id: 'Nigeria',
    label: 'Nigeria',
  },
  {
    id: 'Norway',
    label: 'Norway',
  },
  {
    id: 'Oman',
    label: 'Oman',
  },
  {
    id: 'Pakistan',
    label: 'Pakistan',
  },
  {
    id: 'Palestine',
    label: 'Palestine',
  },
  {
    id: 'Panama',
    label: 'Panama',
  },
  {
    id: 'Papua New Guinea',
    label: 'Papua New Guinea',
  },
  {
    id: 'Paraguay',
    label: 'Paraguay',
  },
  {
    id: 'Peru',
    label: 'Peru',
  },
  {
    id: 'Philippines',
    label: 'Philippines',
  },
  {
    id: 'Poland',
    label: 'Poland',
  },
  {
    id: 'Portugal',
    label: 'Portugal',
  },
  {
    id: 'Puerto Rico',
    label: 'Puerto Rico',
  },
  {
    id: 'Qatar',
    label: 'Qatar',
  },
  {
    id: 'Reunion',
    label: 'Reunion',
  },
  {
    id: 'Romania',
    label: 'Romania',
  },
  {
    id: 'Russia',
    label: 'Russia',
  },
  {
    id: 'Rwanda',
    label: 'Rwanda',
  },
  {
    id: 'Saint Pierre and Miquelon',
    label: 'Saint Pierre and Miquelon',
  },
  {
    id: 'Samoa',
    label: 'Samoa',
  },
  {
    id: 'San Marino',
    label: 'San Marino',
  },
  {
    id: 'Satellite',
    label: 'Satellite',
  },
  {
    id: 'Saudi Arabia',
    label: 'Saudi Arabia',
  },
  {
    id: 'Senegal',
    label: 'Senegal',
  },
  {
    id: 'Serbia',
    label: 'Serbia',
  },
  {
    id: 'Seychelles',
    label: 'Seychelles',
  },
  {
    id: 'Sierra Leone',
    label: 'Sierra Leone',
  },
  {
    id: 'Singapore',
    label: 'Singapore',
  },
  {
    id: 'Slovakia',
    label: 'Slovakia',
  },
  {
    id: 'Slovenia',
    label: 'Slovenia',
  },
  {
    id: 'South Africa',
    label: 'South Africa',
  },
  {
    id: 'South Korea',
    label: 'South Korea',
  },
  {
    id: 'Spain',
    label: 'Spain',
  },
  {
    id: 'Sri Lanka',
    label: 'Sri Lanka',
  },
  {
    id: 'St Kitts and Nevis',
    label: 'St Kitts and Nevis',
  },
  {
    id: 'St Lucia',
    label: 'St Lucia',
  },
  {
    id: 'St Vincent',
    label: 'St Vincent',
  },
  {
    id: 'St. Lucia',
    label: 'St. Lucia',
  },
  {
    id: 'Sudan',
    label: 'Sudan',
  },
  {
    id: 'Suriname',
    label: 'Suriname',
  },
  {
    id: 'Swaziland',
    label: 'Swaziland',
  },
  {
    id: 'Sweden',
    label: 'Sweden',
  },
  {
    id: 'Switzerland',
    label: 'Switzerland',
  },
  {
    id: 'Syria',
    label: 'Syria',
  },
  {
    id: 'Taiwan',
    label: 'Taiwan',
  },
  {
    id: 'Tajikistan',
    label: 'Tajikistan',
  },
  {
    id: 'Tanzania',
    label: 'Tanzania',
  },
  {
    id: 'Thailand',
    label: 'Thailand',
  },
  {
    id: "Timor L'Este",
    label: "Timor L'Este",
  },
  {
    id: 'Togo',
    label: 'Togo',
  },
  {
    id: 'Tonga',
    label: 'Tonga',
  },
  {
    id: 'Trinidad and Tobago',
    label: 'Trinidad and Tobago',
  },
  {
    id: 'Tunisia',
    label: 'Tunisia',
  },
  {
    id: 'Turkey',
    label: 'Turkey',
  },
  {
    id: 'Turkmenistan',
    label: 'Turkmenistan',
  },
  {
    id: 'Turks and Caicos',
    label: 'Turks and Caicos',
  },
  {
    id: 'Uganda',
    label: 'Uganda',
  },
  {
    id: 'Ukraine',
    label: 'Ukraine',
  },
  {
    id: 'United Arab Emirates',
    label: 'United Arab Emirates',
  },
  {
    id: 'United Kingdom',
    label: 'United Kingdom',
  },
  {
    id: 'Uruguay',
    label: 'Uruguay',
  },
  {
    id: 'Uzbekistan',
    label: 'Uzbekistan',
  },
  {
    id: 'Venezuela',
    label: 'Venezuela',
  },
  {
    id: 'Vietnam',
    label: 'Vietnam',
  },
  {
    id: 'Virgin Islands (US)',
    label: 'Virgin Islands (US)',
  },
  {
    id: 'Yemen',
    label: 'Yemen',
  },
  {
    id: 'Zambia',
    label: 'Zambia',
  },
  {
    id: 'Zimbabwe',
    label: 'Zimbabwe',
  },
];
