import { Tab, type TabProps } from '@mui/material';
import { backgroundColor, darkBlue, primaryColor, textColor } from '../../../styles/variables';

export const CustomStyledHorizontalTab = (
  props: TabProps & { onChangeTab: (val: string) => void }
) => {
  return (
    <Tab
      onClick={() => {
        if (props.onChangeTab) {
          props.onChangeTab(props.value);
        }
      }}
      sx={{
        backgroundColor,
        color: darkBlue,
        borderRadius: '6px',
        border: 'none',
        padding: { mobile: '10px 12px', tablet: '11px 14px' },
        marginRight: '8px',
        fontSize: { mobile: '13px', tablet: '14px' },
        fontWeight: 600,
        height: { mobile: '36px', tablet: '40px' },
        minHeight: 'auto',
        '&:hover': {
          color: textColor,
        },
        '&.Mui-selected': { color: primaryColor, backgroundColor },
      }}
      {...props}
    />
  );
};

export default CustomStyledHorizontalTab;
