export const EmailIllustration = ({
  width = '194',
  height = '240',
}: {
  width?: string;
  height?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 194 240"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M103.142 77.0908C99.4985 74.3721 94.5015 74.3721 90.8585 77.0908L15.1271 133.607C12.5299 135.545 11 138.596 11 141.836V229.105C11 234.776 15.5974 239.373 21.2687 239.373H172.731C178.403 239.373 183 234.776 183 229.105V141.836C183 138.596 181.47 135.545 178.873 133.607L103.142 77.0908Z"
      fill="#000CFF"
    />
    <mask
      id="mask0_2447_17280"
      style={{ maskType: 'alpha' }} // "mask-type:alpha"
      maskUnits="userSpaceOnUse"
      x="11"
      y="136"
      width="172"
      height="64"
    >
      <path d="M97 199.853L183 136.18H11L97 199.853Z" fill="white" />
    </mask>
    <g mask="url(#mask0_2447_17280)">
      <rect x="28.9702" y="136.181" width="136.06" height="54.89" fill="white" />
      <path
        opacity="0.14"
        d="M97.6417 189.313L188.134 136.687L97.6417 209.851L9.71631 138.612L97.6417 189.313Z"
        fill="black"
      />
    </g>
    <path
      d="M91.7698 178.29C94.9953 176.381 99.0047 176.381 102.23 178.29L178.167 223.234C181.162 225.007 183 228.23 183 231.712C183 235.943 179.57 239.373 175.338 239.373H18.6618C14.4303 239.373 11 235.943 11 231.712C11 228.23 12.8375 225.007 15.8334 223.234L91.7698 178.29Z"
      fill="#000BDE"
    />
    <g filter="url(#filter0_f_2447_17280)">
      <path
        d="M31.786 98.925C30.4008 94.7692 33.494 90.4775 37.8746 90.4775H156.126C160.506 90.4775 163.599 94.7692 162.214 98.925L154.761 121.284H39.2389L31.786 98.925Z"
        fill="black"
        fillOpacity="0.33"
      />
    </g>
    <path
      d="M28.9702 96.8955C28.9702 93.3509 31.8436 90.4775 35.3881 90.4775H158.612C162.157 90.4775 165.03 93.3509 165.03 96.8954V144.388H28.9702V96.8955Z"
      fill="white"
    />
    <path
      d="M122.671 117.433L87.3729 152.731L71.3281 136.686"
      stroke="#71DE82"
      strokeWidth="11.5522"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g clipPath="url(#clip0_2447_17280)">
      <path
        d="M97.9214 5.48047L97.9214 26.8388"
        stroke="#FFBCBC"
        strokeWidth="8.73748"
        strokeLinecap="round"
      />
    </g>
    <g clipPath="url(#clip1_2447_17280)">
      <path
        d="M52.4971 17.499L63.1762 35.9958"
        stroke="#FFBCBC"
        strokeWidth="8.73748"
        strokeLinecap="round"
      />
    </g>
    <g clipPath="url(#clip2_2447_17280)">
      <path
        d="M19.1704 50.625L37.6672 61.3041"
        stroke="#FFBCBC"
        strokeWidth="8.73748"
        strokeLinecap="round"
      />
    </g>
    <g clipPath="url(#clip3_2447_17280)">
      <path
        d="M157.616 61.3037L176.113 50.6246"
        stroke="#FFBCBC"
        strokeWidth="8.73748"
        strokeLinecap="round"
      />
    </g>
    <g clipPath="url(#clip4_2447_17280)">
      <path
        d="M132.107 36.001L142.786 17.5042"
        stroke="#FFBCBC"
        strokeWidth="8.73748"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <filter
        id="filter0_f_2447_17280"
        x="0.645691"
        y="59.6716"
        width="192.709"
        height="92.4176"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feGaussianBlur stdDeviation="15.403" result="effect1_foregroundBlur_2447_17280" />
      </filter>
      <clipPath id="clip0_2447_17280">
        <rect
          width="9.70831"
          height="191.254"
          fill="white"
          transform="translate(92.7871 0.626953)"
        />
      </clipPath>
      <clipPath id="clip1_2447_17280">
        <rect
          width="9.70831"
          height="191.254"
          fill="white"
          transform="translate(45.6235 15.8633) rotate(-30)"
        />
      </clipPath>
      <clipPath id="clip2_2447_17280">
        <rect
          width="9.70831"
          height="191.254"
          fill="white"
          transform="translate(12.3989 52.6445) rotate(-60)"
        />
      </clipPath>
      <clipPath id="clip3_2447_17280">
        <rect
          width="9.70831"
          height="191.254"
          fill="white"
          transform="translate(17.2529 148.271) rotate(-120)"
        />
      </clipPath>
      <clipPath id="clip4_2447_17280">
        <rect
          width="9.70831"
          height="191.254"
          fill="white"
          transform="translate(54.0327 181.498) rotate(-150)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default EmailIllustration;
