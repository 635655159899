import { useContext, useState } from 'react';

import { Card, Divider, Skeleton, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { cartBgColor, errorColor } from '../../../../styles/variables';

import { MyGiftShiftContext } from '../../../../providers/my-giftshift-provider';
import ConfirmRemoveBundleDialog from './confirmation-dialogs/ConfirmRemoveBundleDialog';
import BundleSection from './sections/BundleSection';
import FrequencySection from './sections/FrequencySection';
import IBANNumberSection from './sections/IBANNumberSection';
import NextDonationDateSection from './sections/NextDonationDateSection';
import StatusSection from './sections/StatusSection';
import { useInView } from 'react-intersection-observer';
import ShareDonorInformation from './share-donor-information/ShareDonorInformation';

interface TEditStates {
  status: boolean;
  nextDonationDate: boolean;
  ibanNumber: boolean;
}

const editStatesInitialValues = { nextDonationDate: false, ibanNumber: false, status: false };

export const MyBundleCard = (props: { hash: string; onChangeTab: (tabValue: string) => void }) => {
  const { t } = useTranslation();
  const { bundleInfo, loadingData } = useContext(MyGiftShiftContext);
  const [showEditStates, setShowEditStates] = useState<TEditStates>(editStatesInitialValues);
  const [openConfirmRemoveDialog, setOpenConfirmRemoveDialog] = useState(false);
  const sectionId = 'my-bundle';
  const pausedBundle = bundleInfo.status === 'paused';
  const { ref: inViewRef } = useInView({
    initialInView: props.hash === sectionId,
    onChange: (isInView) => {
      if (isInView) {
        props.onChangeTab(sectionId);
      }
    },
    threshold: [0.5],
  });

  const updateEditStates = (fieldKey: string, newValue: boolean) => {
    setShowEditStates({ ...showEditStates, [`${fieldKey}`]: newValue });
  };

  const cardStyles = {
    overflow: 'initial',
    backgroundColor: cartBgColor,
    scrollMarginTop: { mobile: '172px', tablet: '192px', laptop: '161px' },
    scrollMarginBlockStart: { mobile: '172px', tablet: '192px', laptop: '161px' },
    boxShadow: 'none',
    padding: { mobile: 3, laptop: 4 },
    borderRadius: { mobile: '10px', laptop: '12px' },
  };

  const skeletonStyles = {
    scrollMarginTop: { mobile: '172px', tablet: '192px', laptop: '161px' },
    scrollMarginBlockStart: { mobile: '172px', tablet: '192px', laptop: '161px' },
    height: { mobile: '819px', tablet: '691px', laptop: '703px' },
  };

  return loadingData ? (
    <Skeleton id={sectionId} variant="rounded" width={'100%'} sx={skeletonStyles} ref={inViewRef} />
  ) : (
    <Card id={sectionId} role="tabpanel" sx={cardStyles} ref={inViewRef}>
      <Stack gap={{ mobile: 2, laptop: 3 }}>
        <Typography variant="h2">{t('myBundle')}</Typography>
        <Divider />
        <StatusSection
          showEditState={showEditStates.status}
          updateEditState={() => {
            updateEditStates('status', !showEditStates.status);
          }}
        />
        <Divider />
        <BundleSection />
        {!bundleInfo.sharedInfo ? <ShareDonorInformation /> : ''}
        <Divider />

        {!pausedBundle && (
          <>
            <NextDonationDateSection
              showEditState={showEditStates.nextDonationDate}
              updateEditState={() => {
                updateEditStates('nextDonationDate', !showEditStates.nextDonationDate);
              }}
            />
            <Divider />
          </>
        )}

        <FrequencySection />
        <Divider />
        <IBANNumberSection
          showEditState={showEditStates.ibanNumber}
          updateEditState={() => {
            updateEditStates('ibanNumber', !showEditStates.ibanNumber);
          }}
        />
        <Divider />
        <Typography
          onClick={() => {
            setOpenConfirmRemoveDialog(true);
          }}
          variant="h6"
          fontWeight={400}
          lineHeight={'150%'}
          sx={{ color: errorColor, textDecoration: 'underline', cursor: 'pointer' }}
        >
          {t('removeBundle')}
        </Typography>
        <ConfirmRemoveBundleDialog
          open={openConfirmRemoveDialog}
          onClose={() => {
            setOpenConfirmRemoveDialog(false);
          }}
        />
      </Stack>
    </Card>
  );
};
