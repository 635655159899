import { Box, Button, Card, Divider, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { LogoutIcon } from '../../../../assets/icons';
import { AuthenticationContext } from '../../../../providers/authentication-provider';
import { LanguageContext } from '../../../../providers/language-selector-provider';
import { MyGiftShiftContext } from '../../../../providers/my-giftshift-provider';
import { desktopXXL, errorColor, successColor, white } from '../../../../styles/variables';
import { AccountDeletionDialog } from './AccountDeletionDialog';
import MyDetailsCardSkeleton from './MyDetailsCardSkeleton';
import { RenderCountryField } from './RenderCountryField';
import { RenderDateOfBirthField } from './RenderDateOfBirthField';
import { RenderGenderField } from './RenderGenderField';
import { RenderNameField } from './RenderNameField';
import { RenderNewsletterField } from './RenderNewsletterField';
import { RenderPasswordField } from './RenderPasswordField';
import RowItem from './RowItem';

type TRenderStates = {
  name: boolean;
  email: boolean;
  password: boolean;
  birthday: boolean;
  country: boolean;
  gender: boolean;
  newsletter: boolean;
};

const renderStatesInitialValues: TRenderStates = {
  name: false,
  email: false,
  password: false,
  birthday: false,
  country: false,
  gender: false,
  newsletter: false,
};

export const MyDetailsCard = (props: { hash: string; onChangeTab: (tabValue: string) => void }) => {
  const {
    handleLogout,
    authenticatedUser,
    userDetails: { name, birthday, country, gender, newsletter },
  } = useContext(AuthenticationContext);
  const { t } = useTranslation();
  const [renderStates, setRenderStates] = useState<TRenderStates>(renderStatesInitialValues);
  const [openAccountDeletionDialog, setOpenAccountDeletionDialog] = useState(false);
  const sectionId = 'my-details';
  const { ref: inViewRef } = useInView({
    initialInView: props.hash === sectionId,
    onChange: (isInView) => {
      if (isInView) {
        props.onChangeTab(sectionId);
      }
    },
    threshold: 0.7,
  });

  const { loadingData } = useContext(MyGiftShiftContext);
  const { allCountries } = useContext(LanguageContext);
  const selectedCountry = allCountries?.find((el) => el.alias === country);

  const updateRenderStates = (fieldKey: string, newValue: boolean) => {
    setRenderStates({ ...renderStates, [`${fieldKey}`]: newValue });
  };

  const mapGenders = (value: string) => {
    if (value === 'm') {
      return t('male');
    } else if (value === 'f') {
      return t('female');
    } else if (value === 'o') {
      return t('other');
    } else if (value === 'x') {
      return t('idRatherNotSay');
    } else {
      return;
    }
  };

  return loadingData ? (
    <MyDetailsCardSkeleton id={sectionId} inViewRef={inViewRef} />
  ) : (
    <Card
      id={sectionId}
      role="tabpanel"
      sx={{
        backgroundColor: white,
        overflow: 'initial',
        padding: '32px',
        scrollMarginTop: { mobile: '172px', tablet: '192px', laptop: '161px' },
        scrollMarginBlockStart: { mobile: '172px', tablet: '192px', laptop: '161px' },
        boxShadow: desktopXXL,
        borderRadius: '10px',
      }}
      ref={inViewRef}
    >
      <Stack gap={{ mobile: 2, laptop: 3 }}>
        <Stack
          direction={{ mobile: 'column', tablet: 'row' }}
          justifyContent="space-between"
          alignItems={{ mobile: 'flex-start', tablet: 'center' }}
        >
          <Typography variant="h2">{t('myDetails')}</Typography>
          <Button
            variant="text"
            onClick={handleLogout}
            color="error"
            endIcon={<LogoutIcon color={errorColor} />}
            data-testid="LogoutButtonUserDetails"
            size="medium"
            sx={{ padding: 0 }}
          >
            {t('logout')}
          </Button>
        </Stack>
        <Divider />

        <>
          <RowItem
            title={t('name')}
            actionLabel={renderStates.name ? t('cancel') : t('modify')}
            content={
              renderStates.name ? (
                <RenderNameField
                  updateRenderStates={() => updateRenderStates('name', !renderStates.name)}
                />
              ) : (
                <Typography variant="paragraphM" sx={{ wordBreak: 'break-word' }}>
                  {name}
                </Typography>
              )
            }
            handleAction={() => updateRenderStates('name', !renderStates.name)}
          />

          <Divider />

          <RowItem
            title={t('emailAddress')}
            actionLabel={''}
            content={<Typography variant="paragraphM">{authenticatedUser?.email}</Typography>}
            handleAction={() => updateRenderStates('email', !renderStates.email)}
          />

          <Divider />

          <>
            <RowItem
              title={t('password')}
              actionLabel={renderStates.password ? t('cancel') : t('modify')}
              content={
                renderStates.password ? (
                  <RenderPasswordField
                    isSocialAccount={authenticatedUser?.socialAccount}
                    updateRenderStates={() =>
                      updateRenderStates('password', !renderStates.password)
                    }
                  />
                ) : (
                  <Typography variant="paragraphM">******</Typography>
                )
              }
              handleAction={() => updateRenderStates('password', !renderStates.password)}
            />
            <Divider />
          </>

          <RowItem
            title={t('dateOfBirth')}
            actionLabel={renderStates.birthday ? t('cancel') : t('modify')}
            content={
              renderStates.birthday ? (
                <RenderDateOfBirthField
                  updateRenderStates={() => updateRenderStates('birthday', !renderStates.birthday)}
                />
              ) : (
                <Typography variant="paragraphM">{birthday}</Typography>
              )
            }
            handleAction={() => updateRenderStates('birthday', !renderStates.birthday)}
          />

          <Divider />

          <RowItem
            title={t('preferredCountry')}
            actionLabel={renderStates.country ? t('cancel') : t('modify')}
            content={
              renderStates.country ? (
                <RenderCountryField
                  updateRenderStates={() => updateRenderStates('country', !renderStates.country)}
                />
              ) : (
                <Stack direction="row" alignItems="center" gap={1}>
                  <img
                    src={selectedCountry?.flag}
                    style={{ width: '30px', height: '20px', borderRadius: '5px' }}
                  />
                  <Typography flex={2} variant="paragraphM">
                    {selectedCountry?.country}
                  </Typography>
                </Stack>
              )
            }
            handleAction={() => updateRenderStates('country', !renderStates.country)}
          />

          <Divider />

          <RowItem
            title={t('gender')}
            actionLabel={renderStates.gender ? t('cancel') : t('modify')}
            content={
              renderStates.gender ? (
                <RenderGenderField
                  updateRenderStates={() => updateRenderStates('gender', !renderStates.gender)}
                />
              ) : (
                <Typography variant="paragraphM">{mapGenders(gender)}</Typography>
              )
            }
            handleAction={() => updateRenderStates('gender', !renderStates.gender)}
          />

          <Divider />

          <RowItem
            title={t('receiveNewsletter')}
            actionLabel={renderStates.newsletter ? t('cancel') : t('modify')}
            content={
              renderStates.newsletter ? (
                <RenderNewsletterField
                  updateRenderStates={() =>
                    updateRenderStates('newsletter', !renderStates.newsletter)
                  }
                />
              ) : (
                <Stack direction="row" alignItems="center" gap="8px">
                  <Box
                    width="8px"
                    height="8px"
                    borderRadius="50%"
                    bgcolor={newsletter ? successColor : errorColor}
                  />
                  <Typography variant="paragraphM">{t(newsletter ? 'yes' : 'no')}</Typography>
                </Stack>
              )
            }
            handleAction={() => updateRenderStates('newsletter', !renderStates.newsletter)}
          />
        </>

        <Divider />

        <AccountDeletionDialog
          open={openAccountDeletionDialog}
          onClose={() => setOpenAccountDeletionDialog(false)}
        />

        <Button
          variant="outlined"
          color="error"
          sx={{ width: 'fit-content' }}
          onClick={() => setOpenAccountDeletionDialog(true)}
        >
          <Typography color={errorColor} variant="buttonM">
            {t('deleteAccount')}
          </Typography>
        </Button>
      </Stack>
    </Card>
  );
};

export default MyDetailsCard;
