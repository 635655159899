import { Dialog, DialogActions, Grid, Stack } from '@mui/material';
import { useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { config } from '../../api/config';
import DonationProgressBar from '../../components/payment-flow-modal/DonationProgressBar';
import { DonationFlowProvider } from '../../providers/donation-flow-provider';
import { DonationFromWidgetProvider } from '../../providers/donation-from-widget-provider';
import { LanguageContext } from '../../providers/language-selector-provider';
import { shadowLG } from '../../styles/variables';
import DonationFlowSteps from './DonationFlowSteps';
import DonationSideContainer from './DonationSideContainer';

export const DonationFlowPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isEnglish } = useContext(LanguageContext);

  const handleClose = () => {
    localStorage.removeItem('monthlyDonor');

    if (location.state?.from) {
      navigate(location.state?.from);
    } else {
      window.location.href = `${config.FE_APP_URL}/${isEnglish ? 'en' : ''}`;
    }
  };

  return (
    <DonationFromWidgetProvider>
      <DonationFlowProvider>
        <Dialog
          fullScreen
          open
          onClose={handleClose}
          scroll="paper"
          PaperProps={{ sx: { overflowY: 'initial' } }}
        >
          <Grid container sx={{ height: '100%', flexWrap: 'nowrap' }}>
            <DonationSideContainer />

            <Stack
              flexDirection={'column'}
              flexWrap={'nowrap'}
              justifyContent={'center'}
              width={{ mobile: '100%', laptop: '70%' }}
            >
              <DonationFlowSteps handleClose={handleClose} />
              <DialogActions
                sx={{
                  boxShadow: shadowLG,
                  zIndex: 1,
                  p: 0,
                }}
              >
                <DonationProgressBar />
              </DialogActions>
            </Stack>
          </Grid>
        </Dialog>
      </DonationFlowProvider>
    </DonationFromWidgetProvider>
  );
};

export default DonationFlowPage;
