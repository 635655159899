import { Box, Stack } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingButton from '../../../../../components/loading-button/LoadingButton';
import { MyGiftShiftContext } from '../../../../../providers/my-giftshift-provider';
import EditDonationPaymentDate from '../../../components/EditDonationPaymentDate';

interface DonationDateEditStateProps {
  updateEditState: () => void;
}

export const DonationDateEditState = ({ updateEditState }: DonationDateEditStateProps) => {
  const { t } = useTranslation();
  const { bundleInfo, handleUpdateNextPaymentDate } = useContext(MyGiftShiftContext);

  const [loadingSaveData, setLoadingSaveData] = useState(false);
  const [newPaymentDateValue, setNewPaymentDateValue] = useState<string>(
    bundleInfo.nextDonationDate
  );

  const today = new Date();
  const [min] = today.toISOString().split('T');

  const handleSave = async () => {
    setLoadingSaveData(true);
    const response = await handleUpdateNextPaymentDate(newPaymentDateValue);
    if (response) {
      setLoadingSaveData(false);
      updateEditState();
    }
  };

  return (
    <Stack gap={2}>
      <Box>
        <EditDonationPaymentDate
          value={newPaymentDateValue}
          handleChange={(newValue) => {
            setNewPaymentDateValue(newValue);
          }}
          min={min}
        />
      </Box>
      <Box width="fit-content">
        <LoadingButton
          variant="contained"
          color="primary"
          onClick={handleSave}
          loading={loadingSaveData}
          disabled={loadingSaveData || newPaymentDateValue < min}
        >
          {t('save')}
        </LoadingButton>
      </Box>
    </Stack>
  );
};

export default DonationDateEditState;
