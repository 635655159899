import { Card, Skeleton, Grid, Stack, Button, Typography, Box } from '@mui/material';
import { useContext } from 'react';
import { primaryColor } from '../../../../styles/variables';
import { MyGiftShiftContext } from '../../../../providers/my-giftshift-provider';
import EmptyBundleIllustration from '../../../../assets/EmptyBundleIllustration';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { LanguageContext } from '../../../../providers/language-selector-provider';

export const ExtraDonationCard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { loadingData } = useContext(MyGiftShiftContext);
  const { isEnglish } = useContext(LanguageContext);

  const handleExtraDonation = () => {
    localStorage.setItem('donationFrequency', 'one-time');
    localStorage.setItem('monthlyDonor', 'yes');
    navigate(isEnglish ? '/donate' : '/doneren');
  };

  return loadingData ? (
    <Skeleton
      variant="rounded"
      width={'100%'}
      sx={{ height: { mobile: '475px', tablet: '265px', laptop: '293px' } }}
    />
  ) : (
    <Card
      sx={{
        backgroundColor: primaryColor,
        boxShadow: 'none',
        borderRadius: { mobile: '10px', tablet: '12px' },
        px: { mobile: 3, tablet: 4, laptop: 6 },
        py: 4,
      }}
    >
      <Grid container spacing={3}>
        <Grid item mobile={12} tablet={6} container justifyContent={'center'} alignItems={'center'}>
          <EmptyBundleIllustration />
        </Grid>
        <Grid item mobile={12} tablet={6}>
          <Stack gap={{ mobile: 4, laptop: 5 }}>
            <Stack gap={1}>
              <Typography variant="h3" color="white">
                {t('extra')}
              </Typography>
              <Typography variant="paragraphM" color="white">
                {t('extraOneTimeInfo')}
              </Typography>
            </Stack>
            <Box>
              <Button
                color="secondary"
                variant="contained"
                size="large"
                onClick={handleExtraDonation}
              >
                {t('makeAdditionalDonation')}
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};

export default ExtraDonationCard;
