import { useTranslation } from 'react-i18next';
import { Stack, Typography } from '@mui/material';
import InputWithLabel from '../../inputWithLabel/InputWithLabel';
import type { TWidgetTextErrors, TWidgetText } from '../types';

type TextContentSectionProps = {
  widgetTextState: TWidgetText;
  setWidgetTextState: (widgetText: TWidgetText) => void;
  widgetTextErrors: TWidgetTextErrors;
  setWidgetTextErrors: (widgetTextErrors: TWidgetTextErrors) => void;
  setChangedFields: (fieldsState: boolean) => void;
};

export const TextContentSection = ({
  widgetTextState,
  setWidgetTextState,
  widgetTextErrors,
  setWidgetTextErrors,
  setChangedFields,
}: TextContentSectionProps) => {
  const { t } = useTranslation();

  const updateWidgetTextErrors = (property: keyof TWidgetText, value: string): boolean => {
    setChangedFields(true);

    const widgetTextValidation = [
      {
        key: 'headingText',
        rule: value.length > 150,
      },
      {
        key: 'subHeadingText',
        rule: value.length > 250,
      },
      {
        key: 'buttonText',
        rule: value.length > 20,
      },
    ];

    const fieldValidationRule = widgetTextValidation.find(
      (widgetValue) => widgetValue.key === property
    )?.rule;

    if (fieldValidationRule) {
      const newWidgetTextErrorsState: TWidgetTextErrors = {
        ...widgetTextErrors,
        [property]: true,
      };
      setWidgetTextErrors(newWidgetTextErrorsState);
      return true;
    }

    return false;
  };

  const updateWidgetTextState = (property: keyof TWidgetText, value: string) => {
    const hasError = updateWidgetTextErrors(property, value);
    const newWidgetTextState: TWidgetText = {
      ...widgetTextState,
      [property]: value,
    };
    setWidgetTextState(newWidgetTextState);

    if (!hasError) {
      const newWidgetTextErrorState: TWidgetTextErrors = {
        ...widgetTextErrors,
        [property]: false,
      };
      setWidgetTextErrors(newWidgetTextErrorState);
    } else {
      setChangedFields(false);
    }
  };

  return (
    <Stack gap={3}>
      <Stack gap={1}>
        <Typography variant="h3">Heading Text</Typography>
        <InputWithLabel
          fullWidth
          name="headingText"
          value={widgetTextState.headingText}
          onChange={(e) => updateWidgetTextState('headingText', e.target.value)}
          error={!!widgetTextErrors.headingText}
          errorMessage={t('headingTextError')}
          inputProps={{ maxLength: 150 }}
        />
      </Stack>
      <Stack gap={1}>
        <Typography variant="h3">Subheading Text</Typography>
        <InputWithLabel
          fullWidth
          name="subHeadingText"
          value={widgetTextState.subHeadingText}
          onChange={(e) => updateWidgetTextState('subHeadingText', e.target.value)}
          error={!!widgetTextErrors.subHeadingText}
          errorMessage={t('subheadingTextError')}
          inputProps={{ maxLength: 250 }}
        />
      </Stack>
      <Stack gap={1}>
        <Typography variant="h3">Button Text</Typography>
        <InputWithLabel
          fullWidth
          name="buttonText"
          value={widgetTextState.buttonText}
          onChange={(e) => updateWidgetTextState('buttonText', e.target.value)}
          error={!!widgetTextErrors.buttonText}
          errorMessage={t('buttonTextError')}
          inputProps={{ maxLength: 20 }}
        />
      </Stack>
    </Stack>
  );
};
