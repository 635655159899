import { Container, Stack, Tabs, useMediaQuery, type Theme } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MyGiftShiftContext } from '../../providers/my-giftshift-provider';
import { handleChangeTab, handleChangeUrlHash } from './MyGiftShift.utils';
import CustomStyledHorizontalTab from './components/CustomStyledHorizontalTab';
import CustomStyledVerticalTab from './components/CustomStyledVerticalTab';
import { DonationsOverviewCard, ExtraDonationCard, MyBundleCard, MyDetailsCard } from './sections';
import NoPastDonationsCard from './sections/donations-overview-card/NoPastDonationsCard';
import NoBundleCard from './sections/my-bundle-card/NoBundleCard';
import ShareButtonsCard from './sections/share-buttons-card/ShareButtonsCard';

const a11yProps = (index: number) => {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
};

export const MyGiftShiftTabs = () => {
  const { t } = useTranslation();
  const { hasCurrentBundle, donationOverviewDetails, loadingData } = useContext(MyGiftShiftContext);
  const [tabValue, setTabValue] = useState('my-bundle');
  const isTabletOrMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('laptop'));
  const hash = window.location.hash.slice(1);
  const [triggeredFromClick, setTriggeredFromClick] = useState(false);

  const onChangeTab = (tabValue: string) => {
    if (!triggeredFromClick) {
      setTabValue(tabValue);
      handleChangeUrlHash(tabValue);
    }
  };

  useEffect(() => {
    if (triggeredFromClick) {
      const triggeredFromClickTimeout = setTimeout(() => {
        setTriggeredFromClick(false);
      }, 500);

      return () => {
        clearTimeout(triggeredFromClickTimeout);
      };
    }
  }, [triggeredFromClick]);

  useEffect(() => {
    if (hash && !loadingData) {
      document.getElementById(hash)?.scrollIntoView({ behavior: 'smooth' });
      setTabValue(hash);
    }
  }, [loadingData, hash]);

  const sections = [
    { label: t('myBundle'), id: 'my-bundle' },
    { label: t('myDetails'), id: 'my-details' },
    { label: t('donationOverview'), id: 'overview' },
  ];
  return (
    <Container
      maxWidth="desktop"
      sx={{
        px: { mobile: '20px', tablet: 5, laptop: '112px' },
        ...(!isTabletOrMobile && { display: 'flex', alignItems: 'flex-start', gap: 8 }),
      }}
    >
      <Tabs
        orientation={isTabletOrMobile ? 'horizontal' : 'vertical'}
        value={tabValue}
        onChange={(_, value: string) => {
          setTriggeredFromClick(true);
          handleChangeTab(value, setTabValue);
        }}
        aria-label="My Giftshift Tabs"
        variant="scrollable"
        allowScrollButtonsMobile={false}
        scrollButtons={false}
        sx={{
          display: 'flex',
          alignItems: isTabletOrMobile ? 'center' : 'flex-start',
          minHeight: 0,
          paddingTop: { mobile: 2, laptop: 0 },
          paddingBottom: { mobile: 6, laptop: 0 },
          position: { mobile: 'static', laptop: 'sticky' },
          top: { laptop: '161px' },
          width: '100%',
          maxWidth: { laptop: '330px' },
        }}
      >
        {sections.map((section, i) =>
          isTabletOrMobile ? (
            <CustomStyledHorizontalTab
              key={section.id}
              label={section.label}
              value={section.id}
              onChangeTab={(value: string) => {
                setTriggeredFromClick(true);
                handleChangeTab(value, setTabValue);
              }}
              {...a11yProps(i)}
            />
          ) : (
            <CustomStyledVerticalTab
              key={section.label}
              value={section.id}
              label={section.label}
              {...a11yProps(i)}
            />
          )
        )}
      </Tabs>

      <Stack gap={{ mobile: 2, laptop: 3 }} width={'100%'}>
        {hasCurrentBundle ? (
          <MyBundleCard hash={hash} onChangeTab={onChangeTab} />
        ) : (
          <NoBundleCard hash={hash} onChangeTab={onChangeTab} />
        )}
        {hasCurrentBundle ? <ExtraDonationCard /> : null}
        <MyDetailsCard hash={hash} onChangeTab={onChangeTab} />
        <ShareButtonsCard />
        {donationOverviewDetails?.lastDonationDate ? (
          <DonationsOverviewCard hash={hash} onChangeTab={onChangeTab} />
        ) : (
          <NoPastDonationsCard hash={hash} onChangeTab={onChangeTab} />
        )}
      </Stack>
    </Container>
  );
};

export default MyGiftShiftTabs;
