import { useContext } from 'react';

import { Card, Grid, Stack, Typography } from '@mui/material';
import { desktopS } from '../../../../../styles/variables';

import { MyGiftShiftContext } from '../../../../../providers/my-giftshift-provider';

export const CurrentBundleData = () => {
  const { currentBundle } = useContext(MyGiftShiftContext);

  return (
    <Grid container spacing={'12px'}>
      {currentBundle.map((charity) => (
        <Grid
          key={charity.id}
          item
          container
          alignItems={'center'}
          justifyContent={{ mobile: 'flex-start' }}
          gap={{ mobile: 2, tablet: '20px', laptop: '3' }}
          flexWrap={'nowrap'}
        >
          <Grid item height={'100%'}>
            <Card
              sx={{
                width: '100px',
                borderRadius: '8px',
                boxShadow: desktopS,
                padding: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <img src={charity.logo} alt={`${charity.name}-logo`} width={'100%'} />
            </Card>
          </Grid>
          <Grid item>
            <Stack gap={'6px'}>
              <Typography
                variant="h6"
                sx={{ wordBreak: { mobile: 'break-all', tablet: 'break-word' } }}
              >
                {charity.name}
              </Typography>
              <Typography variant="paragraphM">{`€ ${charity.donationAmount}`}</Typography>
            </Stack>
          </Grid>
        </Grid>
      ))}
    </Grid>
  );
};

export default CurrentBundleData;
