import { Box, Button, Card, Chip, Grid, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { config } from '../../api/config';
import Dropdown from '../../components/dropdown/Dropdown';
import DropdownAutocomplete from '../../components/dropdown/DropdownAutocomplete';
import { NotificationsContext } from '../../providers/notifications-provider';
import { PublicContext } from '../../providers/public-provider';
import { desktopL, disabledColor, white } from '../../styles/variables';

// types
import type { TCharity } from '../../models/Charities';
import type { TPartner } from '../../models/Partners';
import { LanguageContext } from '../../providers/language-selector-provider';

export const BundleLinksGenerator = ({ partners }: { partners: TPartner[] }) => {
  const {
    allCharities: { charities },
  } = useContext(PublicContext);
  const { setNotification } = useContext(NotificationsContext);
  const [selectedCharities, setSelectedCharities] = useState<TCharity[]>([]);
  const [selectedPartner, setPartner] = useState<string | undefined>(undefined);
  const { isEnglish, selectedCountry, selectedLanguage } = useContext(LanguageContext);

  const handleCopyToClipboard = async () => {
    const link = handleGenerateLink();
    await navigator.clipboard.writeText(link);
    setNotification({
      message: 'Copied to clipboard!',
      type: 'info',
    });
  };

  const handleGenerateLink = () => {
    const charities = selectedCharities.map((item) => item.id).join(',');
    const partnerId = selectedPartner ? `&pid=${selectedPartner}` : '';

    return `${config.FE_APP_URL}/${selectedCountry}/${selectedLanguage}/${
      isEnglish ? 'donate' : 'doneren'
    }?charities=${charities}${partnerId}`;
  };

  return (
    <Stack width={'100%'} alignItems={'center'}>
      <Stack
        width={'100%'}
        gap={3}
        sx={{
          maxWidth: {
            mobile: '100%',
            tablet: '800px',
            laptop: '100%',
          },
        }}
      >
        <Typography variant="h2">Bundle link generator</Typography>
        <Card
          sx={{
            margin: '0px auto',
            borderRadius: '12px',
            width: '100%',
            padding: '24px 20px',
            alignSelf: 'stretch',
            display: 'flex',
            flexDirection: 'column',
            gap: 3,
            backgroundColor: white,
            boxShadow: desktopL,
          }}
        >
          <Dropdown
            labelValue="Partner (optional)"
            options={[{ id: '', label: 'None', name: 'None' }, ...partners]}
            onChange={(e) => {
              setPartner(e.target.value as string);
            }}
            variant="outlined"
          />
          <Stack>
            <DropdownAutocomplete
              multiple
              autoComplete={false}
              limitTags={0}
              showLogos={false}
              labelValue={`Charities`}
              defaultValue={[]}
              value={selectedCharities?.map((item) => ({ ...item, label: item.name }))}
              options={charities?.map((item) => ({ ...item, label: item.name }))}
              renderInput={() => <> </>}
              onChange={(_, values) => {
                if (values) {
                  setSelectedCharities(values as TCharity[]);
                }
              }}
              renderTags={() => null}
              isOptionEqualToValue={(option, value) => option.id === value.id}
            />
            <Grid container gap={1} py={2}>
              {selectedCharities?.map((selectedCharity) => (
                <Chip label={selectedCharity.name} key={selectedCharity.id} />
              ))}
            </Grid>
          </Stack>
          {selectedCharities.length ? (
            <Box sx={{ background: disabledColor, p: 3, borderRadius: '12px' }}>
              <Stack gap={2}>
                <Typography sx={{ wordBreak: 'break-all' }}>
                  <code>{`${handleGenerateLink()}`}</code>
                </Typography>
                <Grid container justifyContent={'flex-end'}>
                  <Button color="info" variant="outlined" onClick={handleCopyToClipboard}>
                    Copy
                  </Button>
                </Grid>
              </Stack>
            </Box>
          ) : null}
        </Card>
      </Stack>
    </Stack>
  );
};

export default BundleLinksGenerator;
