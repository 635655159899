import { axiosPrivate } from '../common/axiosPrivate';
import { axiosPublic } from '../common/axiosPublic';

//types
import type {
  EmptyPayment,
  PaymentMethod,
  TInitiatePaymentBody,
  TPaymentBody,
  TUpdatePaymentMethodBody,
} from '../models/Payments';

export const postPayment = async (body: TPaymentBody, token?: string) =>
  token ? await axiosPrivate.post('/payments/', body) : await axiosPublic.post('/payments/', body);

export const updateSuccessfulPayment = async (sessionId: string, token: string | undefined) =>
  token
    ? await axiosPrivate.patch('/update-checkout/', { sessionId })
    : await axiosPublic.patch('/update-checkout/', { sessionId });

export const createSubscription = async (body: TPaymentBody) =>
  await axiosPrivate.post('/bundles/', body);

export const changePaymentMethod = async (body: TUpdatePaymentMethodBody) =>
  await axiosPrivate.post('/update-payment-method/', body);

export const initiatePayment = async (body: TInitiatePaymentBody, token: string | undefined) =>
  token
    ? await axiosPrivate.post('/create-ideal-pi/', body)
    : await axiosPublic.post('/create-ideal-pi/', body);

export const getPaymentMethods = async (country: string) =>
  await axiosPublic.get<PaymentMethod[]>(`/payment-methods/?country=${country}`);

export const postEmptyPayment = async (body: EmptyPayment) =>
  await axiosPublic.post('/empty-payment/', body);
