import { Box, Stack } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputWithLabel from '../../../../components/inputWithLabel/InputWithLabel';
import LoadingButton from '../../../../components/loading-button/LoadingButton';
import { MyGiftShiftContext } from '../../../../providers/my-giftshift-provider';
import { validateName } from '../../../RegisterPage/RegisterPage.utils';
import { AuthenticationContext } from '../../../../providers/authentication-provider';

export const RenderNameField = ({ updateRenderStates }: { updateRenderStates: () => void }) => {
  const { handleNameUpdate } = useContext(MyGiftShiftContext);
  const {
    userDetails: { name },
  } = useContext(AuthenticationContext);
  const [nameValue, setNameValue] = useState(name);
  const [invalidNameError, setInvalidNameError] = useState(false);
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const onSuccess = () => {
    setLoading(false);
    updateRenderStates();
  };

  const handleSave = () => {
    if (nameValue && validateName(nameValue)) {
      setLoading(true);
      handleNameUpdate(nameValue, onSuccess);
    } else {
      setInvalidNameError(true);
    }
  };

  const handleNameChange = (value: string) => {
    setNameValue(value);
    setInvalidNameError(false);
  };

  return (
    <Stack gap="8px">
      <InputWithLabel
        fullWidth
        value={nameValue}
        onChange={(e) => handleNameChange(e.target.value)}
        error={invalidNameError}
        errorMessage={t('nameMustBeAtLeastAndNoSymbols')}
        inputProps={{ maxLength: 50 }}
      />

      <Box width="fit-content">
        <LoadingButton
          size="medium"
          loading={loading}
          disabled={loading}
          variant="contained"
          color="primary"
          onClick={() => handleSave()}
        >
          {t('save')}
        </LoadingButton>
      </Box>
    </Stack>
  );
};
