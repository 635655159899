import { CircularProgress, Stack } from '@mui/material';
import { useContext, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { AuthenticationContext } from '../../providers/authentication-provider';
import { LanguageContext } from '../../providers/language-selector-provider';
import { onVisibilityChange } from './utils';
import { getCookie } from '../../common/utils';
import { config } from '../../api/config';

const ProtectedRoute = ({
  children,
  checkAdminRights,
}: {
  children: JSX.Element;
  checkAdminRights?: boolean;
}) => {
  const { authenticatedUser, handleLogout, isManualLogout } = useContext(AuthenticationContext);
  const { isEnglish } = useContext(LanguageContext);
  const isLoggedInUser = getCookie('access_token');
  const navigate = useNavigate();
  const isNotLocalEnv = import.meta.env.VITE_DEV_STAGE !== 'local';

  useEffect(() => {
    if (isNotLocalEnv) {
      document.addEventListener('visibilitychange', () =>
        onVisibilityChange(handleLogout, isEnglish, navigate)
      );

      return () =>
        document.removeEventListener('visibilitychange', () =>
          onVisibilityChange(handleLogout, isEnglish, navigate)
        );
    }
  }, []);

  useEffect(() => {
    if (isNotLocalEnv && !isLoggedInUser) {
      handleLogout();
    }
  }, [isLoggedInUser]);

  if (isManualLogout) {
    window.location.href = isEnglish ? `${config.FE_APP_URL}/en` : `${config.FE_APP_URL}`;
    return (
      <Stack width={'100%'} height="100vh" justifyContent={'center'} alignItems={'center'} gap={2}>
        <CircularProgress />
      </Stack>
    );
  }

  if (
    !authenticatedUser ||
    (isNotLocalEnv && !isLoggedInUser) ||
    (checkAdminRights && !authenticatedUser.isAdmin)
  ) {
    return <Navigate to={'/login'} replace />;
  }

  return children;
};
export default ProtectedRoute;
