import { Box, TextField, Stack, Typography } from '@mui/material';
import type { AutocompleteProps } from '@mui/material/Autocomplete';
import Autocomplete from '@mui/material/Autocomplete';
import { ChevronDownIcon } from '../../assets/icons';

export const DropdownAutocomplete = <
  T extends { label: string; id: number | string; logo?: string },
>({
  options,
  placeholder,
  labelValue,
  showLogos = true,
  labelVariant = 'paragraphM',
  labelColor = 'textSecondary',
  ...rest
}: AutocompleteProps<T, any, any, any, any> & {
  labelValue?: string;
  showLogos?: boolean;
  labelVariant?: 'paragraphS' | 'paragraphXS' | 'paragraphM' | 'paragraphL' | 'h1' | 'h2';
  labelColor?: string;
  placeholder?: string;
}) => {
  return (
    <Stack gap="4px">
      {labelValue && (
        <Typography variant={labelVariant} color={labelColor}>
          {labelValue}
        </Typography>
      )}
      <Autocomplete
        {...rest}
        clearIcon={null}
        options={options}
        renderInput={(params) => {
          return (
            <TextField
              {...params}
              placeholder={placeholder}
              inputProps={{ ...params.inputProps, autoComplete: 'off' }}
            />
          );
        }}
        popupIcon={<ChevronDownIcon />}
        renderOption={(props, option) => (
          <Box component="li" sx={{ '& > img': { mr: 1, flexShrink: 0 } }} {...props}>
            {option.logo && showLogos && (
              <img loading="lazy" width="27px" src={option.logo} alt={`${option.label}-logo`} />
            )}
            {option.label}
          </Box>
        )}
      />
    </Stack>
  );
};

export default DropdownAutocomplete;
