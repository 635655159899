import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LoadingComponent from '../../components/loading/LoadingComponent';
import { AuthenticationContext } from '../../providers/authentication-provider';
import { LanguageContext } from '../../providers/language-selector-provider';
import { NotificationsContext } from '../../providers/notifications-provider';
import { handleRedirectAfterLogin } from '../utils';

const SocialLoginPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const accessToken = searchParams.get('accessToken');
  const accessDenied = searchParams.get('access');
  const { setNotification } = useContext(NotificationsContext);
  const { handleSocialLogin, handleSocialAccountExists } = useContext(AuthenticationContext);
  const { t } = useTranslation();
  const { isEnglish } = useContext(LanguageContext);

  const handleSocialLoginRedirect = async () => {
    if (!accessToken) {
      navigate('/login');
      if (accessDenied) {
        handleSocialAccountExists();
      }
    } else {
      localStorage.setItem('access_token', accessToken);
      await handleSocialLogin(
        () => {
          handleRedirectAfterLogin({ isEnglish });
        },
        () => {
          setNotification({
            type: 'error',
            message: t('somethingWentWrong', { message: t('pleaseTryAgain') }),
          });

          navigate('/login');
        }
      );
    }
  };

  useEffect(() => {
    handleSocialLoginRedirect();
  }, []);

  return <LoadingComponent />;
};

export default SocialLoginPage;
