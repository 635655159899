export const CheckboxCheckedIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="6" fill="#000CFF" />
    <path
      d="M17.8516 7.64844C18.207 7.97656 18.207 8.55078 17.8516 8.87891L10.8516 15.8789C10.5234 16.2344 9.94922 16.2344 9.62109 15.8789L6.12109 12.3789C5.76562 12.0508 5.76562 11.4766 6.12109 11.1484C6.44922 10.793 7.02344 10.793 7.35156 11.1484L10.2227 14.0195L16.6211 7.64844C16.9492 7.29297 17.5234 7.29297 17.8516 7.64844Z"
      fill="white"
    />
  </svg>
);

export default CheckboxCheckedIcon;
