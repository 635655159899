export const LinkedInIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M21 1.5C21.4375 1.5 21.7969 1.64062 22.0781 1.92188C22.3594 2.23438 22.5 2.59375 22.5 3V21C22.5 21.4062 22.3594 21.7656 22.0781 22.0781C21.7969 22.3594 21.4375 22.5 21 22.5H3C2.5625 22.5 2.20312 22.3594 1.92188 22.0781C1.64062 21.7656 1.5 21.4062 1.5 21V3C1.5 2.59375 1.64062 2.23438 1.92188 1.92188C2.20312 1.64062 2.5625 1.5 3 1.5H21ZM7.82812 19.5H7.875V9.46875H4.73438V19.5H7.82812ZM6.28125 8.10938C6.8125 8.10938 7.23438 7.9375 7.54688 7.59375C7.89062 7.25 8.07812 6.82812 8.10938 6.32812C8.07812 5.79688 7.89062 5.35938 7.54688 5.01562C7.23438 4.67188 6.8125 4.5 6.28125 4.5C5.78125 4.5 5.35938 4.67188 5.01562 5.01562C4.67188 5.35938 4.5 5.79688 4.5 6.32812C4.5 6.82812 4.67188 7.25 5.01562 7.59375C5.35938 7.9375 5.78125 8.10938 6.28125 8.10938ZM19.5 19.5V14.0156C19.5625 12.6406 19.3438 11.5 18.8438 10.5938C18.3438 9.71875 17.3281 9.26562 15.7969 9.23438C15.0156 9.23438 14.375 9.40625 13.875 9.75C13.375 10.0625 13.0312 10.4219 12.8438 10.8281H12.7969V9.46875H9.79688V19.5H12.9375V14.5312C12.9062 13.8438 13 13.25 13.2188 12.75C13.4688 12.25 13.9844 11.9844 14.7656 11.9531C15.5469 12.0156 16.0156 12.3125 16.1719 12.8438C16.3594 13.4062 16.4375 14 16.4062 14.625V19.5H19.5Z"
      fill="#0077B5"
    />
  </svg>
);

export default LinkedInIcon;
