import { type Theme } from '@mui/material/styles';
import { font } from '../custom-theme';
import { white } from '../variables';

export const MuiTabStyles = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      border: `2px solid ${theme.palette.text.secondary}`,
      textTransform: 'none',
      fontFamily: font,
      padding: '14px 16px',
      height: '48px',
      fontSize: '16px',
      fontWeight: 600,

      '&:first-of-type': {
        borderTopLeftRadius: '8px',
        borderBottomLeftRadius: '8px',
      },

      '&:last-of-type': {
        borderTopRightRadius: '8px',
        borderBottomRightRadius: '8px',
      },
      '&.Mui-selected': {
        color: white,
        backgroundColor: theme.palette.text.secondary,
      },

      [theme.breakpoints.up('tablet')]: {
        padding: '15px 18px',
        height: '51px',
        fontSize: '17px',
      },
      [theme.breakpoints.up('laptop')]: {
        padding: '16px 20px',
        height: '55px',
        fontSize: '18px',
      },
    }),
  },
};

export const MuiTabsStyles = {
  styleOverrides: {
    indicator: () => ({
      display: 'none',
    }),
  },
};
