import { Box, Grid, OutlinedInput, Typography } from '@mui/material';

import { SketchPicker } from 'react-color';
import { disabledColor } from '../../../styles/variables';
import { openColorPickerDefaultValues } from '../helpers';
import type { TColorPickerOpen } from '../types';

type ButtonSectionProps = {
  buttonBgColor: string;
  buttonTextColor: string;
  buttonBorderRadius: number;
  isColorPickerOpen: TColorPickerOpen;
  setColorPickerOpen: (val: TColorPickerOpen) => void;
  setButtonBgColor: (val: string) => void;
  setButtonTextColor: (val: string) => void;
  setButtonBorderRadius: (val: number) => void;
};

export const ButtonSection = ({
  buttonBgColor,
  buttonBorderRadius,
  buttonTextColor,
  isColorPickerOpen,
  setColorPickerOpen,
  setButtonBorderRadius,
  setButtonBgColor,
  setButtonTextColor,
}: ButtonSectionProps) => {
  return (
    <>
      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <Grid item>
          <Typography variant="h3">Button Background</Typography>
        </Grid>
        <Grid container item width={'fit-content'} alignItems="center" justifyContent={'flex-end'}>
          <Box
            sx={{
              width: '56px',
              height: '56px',
              borderRadius: '16px',
              border: `1px solid ${disabledColor}`,
              backgroundColor: buttonBgColor,
              boxShadow: '0px 0px 0px 6px #FFF inset',
              cursor: 'pointer',
            }}
            onClick={() => {
              setColorPickerOpen({
                ...openColorPickerDefaultValues,
                bgButton: !isColorPickerOpen.bgButton,
              });
            }}
          />
          {isColorPickerOpen.bgButton && (
            <Box sx={{ position: 'absolute', zIndex: 2 }}>
              <Box
                sx={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px' }}
                onClick={() =>
                  setColorPickerOpen({
                    ...openColorPickerDefaultValues,
                    bgButton: !isColorPickerOpen.bgButton,
                  })
                }
              />
              <SketchPicker
                color={buttonBgColor}
                onChange={(color) => setButtonBgColor(color.hex)}
                styles={{ default: { picker: { position: 'absolute', top: 20, right: 0 } } }}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <Grid item>
          <Typography variant="h3">Button Text</Typography>
        </Grid>
        <Grid container item width={'fit-content'} alignItems="center" justifyContent={'flex-end'}>
          <Box
            sx={{
              width: '56px',
              height: '56px',
              borderRadius: '16px',
              border: `1px solid ${disabledColor}`,
              backgroundColor: buttonTextColor,
              boxShadow: '0px 0px 0px 6px #FFF inset',
              cursor: 'pointer',
            }}
            onClick={() => {
              setColorPickerOpen({
                ...openColorPickerDefaultValues,
                textButton: !isColorPickerOpen.textButton,
              });
            }}
          />
          {isColorPickerOpen.textButton && (
            <Box sx={{ position: 'absolute', zIndex: 2 }}>
              <Box
                sx={{ position: 'fixed', top: '0px', right: '0px', bottom: '0px', left: '0px' }}
                onClick={() =>
                  setColorPickerOpen({
                    ...openColorPickerDefaultValues,
                    textButton: !isColorPickerOpen.textButton,
                  })
                }
              />
              <SketchPicker
                color={buttonTextColor}
                onChange={(color) => setButtonTextColor(color.hex)}
                styles={{ default: { picker: { position: 'absolute', top: 20, right: 0 } } }}
              />
            </Box>
          )}
        </Grid>
      </Grid>
      <Grid container justifyContent={'space-between'} alignItems={'center'}>
        <Grid item mobile={6}>
          <Typography variant="h3">Button Border Radius</Typography>
        </Grid>

        <Grid
          container
          width={'fit-content'}
          justifyContent={'flex-end'}
          gap={1}
          item
          alignItems="center"
          mobile={6}
        >
          <OutlinedInput
            value={buttonBorderRadius}
            type="number"
            inputProps={{ pattern: '[0-9]*' }}
            onKeyDown={(e) => {
              const disabledKeys = ['e', '-', '.'];
              if (disabledKeys.includes(e.key)) {
                e.preventDefault();
              }
            }}
            onChange={(e) => {
              const numberValue = parseInt(e.target.value);
              setButtonBorderRadius(numberValue);
            }}
            sx={{
              border: 0,
              '& input': {
                paddingRight: '4px',
                fontSize: '24px',
                fontWeight: '400',
                textAlign: 'right',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                border: '0',
              },
            }}
            componentsProps={{ input: { min: 0 } }}
            endAdornment={
              <Typography fontSize="24px" fontWeight={400}>
                px
              </Typography>
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ButtonSection;
