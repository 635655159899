export const MergeIcon = ({
  color,
  width,
  height,
}: {
  color?: string | undefined;
  width?: string | undefined;
  height?: string | undefined;
}) => (
  <svg
    width={width || '24'}
    height={height || '24'}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14 12H20M20 12L16 8M20 12L16 16"
      stroke={color || '#000347'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M4 7H7.01177C7.74555 7 8.11243 7 8.4577 7.08289C8.7638 7.15638 9.05644 7.27759 9.32485 7.44208C9.6276 7.6276 9.88703 7.88703 10.4059 8.40589L14 12L10.4059 15.5941C9.88703 16.113 9.6276 16.3724 9.32485 16.5579C9.05644 16.7224 8.7638 16.8436 8.4577 16.9171C8.11243 17 7.74555 17 7.01177 17H4"
      stroke={color || '#000347'}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default MergeIcon;
