export const RadioCheckedIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" rx="12" fill="white" />
    <rect width="24" height="24" rx="12" fill="#000CFF" />
    <path
      d="M18 7.5L9.75 15.75L6 12"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default RadioCheckedIcon;
