import { Box, Typography } from '@mui/material';
import { darkBlue, desktopXXL } from '../../../../../styles/variables';

export const InfoCardDark = ({ label, value }: { label: string; value: string }) => {
  return (
    <Box
      sx={{
        padding: 3,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        gap: 1,
        alignSelf: 'stretch',
        borderRadius: '12px',
        backgroundColor: darkBlue,
        boxShadow: desktopXXL,
        flex: '1 0 0',
      }}
    >
      <Typography variant="paragraphS" color="white" textAlign={'center'}>
        {label}
      </Typography>
      <Typography variant="h2" color="white" textAlign={'center'}>
        {value}
      </Typography>
    </Box>
  );
};

export default InfoCardDark;
