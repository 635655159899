import { Box, CircularProgress, Container, Grid, Stack } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { deleteCoupon, generateCoupons, getAllCoupons } from '../../api/coupons';
import CouponsGenerator from '../../components/coupons-generator/CouponsGenerator';
import CouponsList from '../../components/coupons-generator/CouponsList';
import { NotificationsContext } from '../../providers/notifications-provider';

// types
import type { CreateCoupons, GeneratedCoupon } from '../../api/coupons';

const CouponsGeneratorPage = () => {
  const { setNotification } = useContext(NotificationsContext);
  const { t } = useTranslation();
  const [coupons, setCoupons] = useState<GeneratedCoupon[]>([]);
  const [isDeletingCoupon, setIsDeletingCoupon] = useState(false);

  useEffect(() => {
    handleGetAllCoupons();
  }, []);

  const handleGetAllCoupons = async () => {
    try {
      const { data } = await getAllCoupons();

      setCoupons(data);
    } catch {
      setNotification({
        type: 'error',
        message: t('somethingWentWrong', { message: t('pleaseTryAgain') }),
      });
    }
  };

  const handleGenerateCoupons = async (couponsData: CreateCoupons, resetFunction: () => void) => {
    try {
      await generateCoupons(couponsData);

      resetFunction();

      setNotification({ message: t('successfullySent', { data: t('data') }), type: 'success' });

      await handleGetAllCoupons();
    } catch {
      setNotification({
        type: 'error',
        message: t('somethingWentWrong', { message: t('pleaseTryAgain') }),
      });
    }
  };

  const handleDeleteCoupon = async (couponId: string) => {
    setIsDeletingCoupon(true);
    try {
      await deleteCoupon(couponId);

      setCoupons((prevCoupons) => prevCoupons.filter((coupon) => coupon.id !== couponId));
    } catch (error) {
      setNotification({
        type: 'error',
        message: t('somethingWentWrong', { message: t('pleaseTryAgain') }),
      });
    } finally {
      setIsDeletingCoupon(false);
    }
  };

  return (
    <Box sx={{ width: '100%', pt: 5 }}>
      <Container
        sx={{
          px: { laptop: 10 },
          maxWidth: { mobile: '100vw', desktop: 'desktop' },
        }}
      >
        <Grid container gap={1}>
          <Grid item mobile={12} laptop={4.9}>
            <CouponsGenerator handleGenerateCoupons={handleGenerateCoupons} />
          </Grid>
          <Grid item mobile={12} laptop={7} sx={{ height: 'calc(100vh - 130px)', p: 1 }}>
            {!coupons.length ? (
              <Stack
                sx={{
                  width: '100%',
                  height: '100%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress />
              </Stack>
            ) : (
              <CouponsList
                coupons={coupons}
                handleDeleteCoupon={handleDeleteCoupon}
                isDeletingCoupon={isDeletingCoupon}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default CouponsGeneratorPage;
