import { Skeleton } from '@mui/material';

export const MyDetailsCardSkeleton = (props: {
  id: string;
  inViewRef: (node: HTMLSpanElement | null) => void;
}) => {
  return (
    <Skeleton
      id={props.id}
      variant="rounded"
      width={'100%'}
      sx={{
        scrollMarginTop: { mobile: '172px', tablet: '192px', laptop: '161px' },
        scrollMarginBlockStart: { mobile: '172px', tablet: '192px', laptop: '161px' },
        height: { mobile: '819px', tablet: '691px', laptop: '710px' },
      }}
      ref={props.inViewRef}
    />
  );
};

export default MyDetailsCardSkeleton;
