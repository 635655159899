type StripeMessages = { code: string; messageKey: string };

const STRIPE_MESSAGES: StripeMessages[] = [
  {
    code: 'payment_method_customer_decline',
    messageKey: 'paymentMethodCustomerDecline',
  },
  {
    code: 'insufficient_funds',
    messageKey: 'insufficientFunds',
  },
  {
    code: 'bank_account_unusable',
    messageKey: 'bankAccountUnusable',
  },
  {
    code: 'bank_account_verification_failed',
    messageKey: 'bankAccountVerificationFailed',
  },
  {
    code: 'card_declined',
    messageKey: 'cardDeclined',
  },
  {
    code: 'expired_card',
    messageKey: 'expiredCard',
  },
  {
    code: 'payment_method_not_available',
    messageKey: 'paymentMethodNotAvailable',
  },
  {
    code: 'processing_error',
    messageKey: 'processingError',
  },
];

export const getStripeUserMessageKey = (reason: string | undefined) => {
  if (!reason) return;

  return STRIPE_MESSAGES.find((message) => message.code === reason)?.messageKey;
};
