import { Button, Grid, IconButton, Stack, Typography } from '@mui/material';
import { useRef } from 'react';
import { TrashIcon } from '../../../assets/icons';
import { darkBlue, white } from '../../../styles/variables';
import ImageMolecule from '../../molecules/ImageMolecule';

interface ImagesSectionProps {
  image: File | Blob | string | undefined;
  previewImage: File | Blob | string | undefined;
  onChangeImage: (val: File | undefined) => void;
}

export const ImagesSection = ({ image, previewImage, onChangeImage }: ImagesSectionProps) => {
  const hiddenImageFileInput = useRef<HTMLInputElement>(null);

  return (
    <Stack gap={2}>
      <Stack gap={2}>
        <Typography variant="h2">Image</Typography>
        <Grid container spacing={3} alignItems={'center'}>
          <Grid item mobile={6}>
            <Button
              variant="outlined"
              fullWidth
              color="info"
              size="large"
              onClick={() => {
                hiddenImageFileInput.current?.click();
              }}
            >
              Upload image
            </Button>
            <input
              hidden
              type="file"
              accept="image/*"
              name="myImage"
              ref={hiddenImageFileInput}
              onChange={(event) => {
                const imageFile = event?.target?.files?.[0];

                if (imageFile) {
                  onChangeImage(imageFile);
                }
              }}
            />
          </Grid>
          <Grid item mobile={6} sx={{ position: 'relative' }}>
            {image && (
              <ImageMolecule
                image={previewImage || image}
                height="157px"
                backgroundSize="contain"
              />
            )}
            {image && (
              <IconButton
                sx={{
                  backgroundColor: darkBlue,
                  position: 'absolute',
                  top: '4px',
                  right: '-12px',
                  width: '45px',
                  height: '45px',
                }}
                onClick={() => {
                  onChangeImage(undefined);
                  if (hiddenImageFileInput.current) {
                    hiddenImageFileInput.current.value = '';
                  }
                }}
              >
                <TrashIcon color={white} />
              </IconButton>
            )}
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
};

export default ImagesSection;
