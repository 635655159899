export const UsabilityIcon = () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="21.7" cy="34.3" r="21.7" fill="#FFBCBC" />
    <path
      d="M33.0827 27.0834L45.5827 39.5834M43.4247 22.3986L32.9856 25.9442C32.7002 26.0411 32.5575 26.0896 32.4388 26.1712C32.3337 26.2434 32.2427 26.3344 32.1705 26.4395C32.0889 26.5582 32.0404 26.7009 31.9435 26.9863L28.3979 37.4253C27.9582 38.7198 27.7384 39.367 27.3947 39.5627C27.0968 39.7322 26.7346 39.7444 26.426 39.5953C26.0699 39.4232 25.807 38.7922 25.2813 37.5303L13.8058 9.98594C13.318 8.81503 13.0741 8.22957 13.1928 7.8633C13.2957 7.54552 13.5448 7.2964 13.8626 7.19343C14.2289 7.07476 14.8144 7.31867 15.9853 7.80649L43.5296 19.282C44.7915 19.8077 45.4225 20.0705 45.5946 20.4267C45.7438 20.7353 45.7316 21.0975 45.562 21.3953C45.3663 21.7391 44.7191 21.9589 43.4247 22.3986Z"
      stroke="#000CFF"
      strokeWidth="4.16667"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default UsabilityIcon;
