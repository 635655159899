import { OutlinedInput, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckIcon } from '../../assets/icons';
import { backgroundColor, errorColor, successColor, textColor } from '../../styles/variables';
import LoadingButton from '../loading-button/LoadingButton';

type CouponInput = {
  validCode: string | undefined;
  isLoading: boolean;
  isValid: boolean | undefined;
  onCouponSubmit: (code: string) => Promise<void>;
};

const CouponInput = ({ validCode, isLoading, isValid, onCouponSubmit }: CouponInput) => {
  const [inputCode, setInputCode] = useState(validCode || '');
  const { t } = useTranslation();

  return (
    <Stack
      sx={{
        backgroundColor: `${backgroundColor}80`,
        borderRadius: '10px',
        padding: 2,
        gap: '2px',
      }}
    >
      <Typography color={textColor}>{t('couponCode')}</Typography>
      <Stack direction="row" alignItems="center" gap={1}>
        <OutlinedInput
          fullWidth
          value={inputCode}
          onChange={(e) => {
            setInputCode(e.target.value);
          }}
          inputProps={{ maxLength: 14 }}
          sx={{ flex: 1 }}
          endAdornment={isValid ? <CheckIcon color={successColor} /> : null}
          error={isValid === false}
          disabled={isLoading}
          onKeyDown={(key) => {
            if (key.code === 'Enter') {
              onCouponSubmit(inputCode);
            }
          }}
        />
        <LoadingButton
          fullWidth
          variant="contained"
          sx={{ padding: '0', width: '78px' }}
          disabled={!inputCode || isLoading}
          onClick={() => {
            onCouponSubmit(inputCode);
          }}
          loading={isLoading}
        >
          {t('apply')}
        </LoadingButton>
      </Stack>

      {isValid === false && <Typography color={errorColor}>{t('invalidCoupon')}</Typography>}
    </Stack>
  );
};

export default CouponInput;
