import { useEffect } from 'react';
import LoadingComponent from '../../components/loading/LoadingComponent';
import { config } from '../../api/config';

const RedirectPage = () => {
  useEffect(() => {
    window.history.replaceState(null, '', config.FE_APP_URL);
  }, []);

  return <LoadingComponent />;
};

export default RedirectPage;
