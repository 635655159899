import { type Theme } from '@mui/material/styles';
import { font } from '../custom-theme';
import { darkBlue } from '../variables';

export const MuiInputStyles = {
  styleOverrides: {
    root: () => ({
      '&:before': {
        borderBottom: 'none',
      },
      '&:hover:not(.Mui-disabled, .Mui-error):before': {
        borderBottom: 'none',
      },
      '&:after': {
        borderBottom: 'none',
      },
    }),
    input: ({ theme }: { theme: Theme }) => ({
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 700,
      fontFamily: font,

      display: 'flex',
      alignItems: 'center',
      gap: '8px',

      // remove increment/decrement icon for type="number"
      '&::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },

      '&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      '&[type=number]': {
        MozAppearance: 'textfield',
        margin: 0,
      },

      [theme.breakpoints.only('tablet')]: {
        fontSize: '16px',
      },
      [theme.breakpoints.up('laptop')]: {
        fontSize: '18px',
      },
      '&::placeholder': {
        color: darkBlue,
        opacity: 1,
      },
    }),
  },
};
