import axios from 'axios';
import { config as urlConfig } from '../api/config';
import { refreshTokenFn } from './refreshToken';
import { addValuesFromCookieToHeader } from './utils';

export const axiosPrivate = axios.create({
  baseURL: urlConfig.BASE_URL,
});

axiosPrivate.interceptors.request.use(
  async (config) => addValuesFromCookieToHeader(config),
  async (error) => await Promise.reject(error)
);

axiosPrivate.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error?.config;
    if (error?.response?.status === 401 && !config?.sent) {
      config.sent = true;

      const result = await refreshTokenFn();

      if (result?.access) {
        config.headers = {
          ...config.headers,
          authorization: `${result?.access}`,
        };
      }

      return await axios(config);
    }
    return await Promise.reject(error);
  }
);
