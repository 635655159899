import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Typography } from '@mui/material';
import CharitiesFilterDialog from './CharitiesFilterDialog';
import FilterIcon from '../../../../assets/icons/FilterIcon';
import { backgroundColor, errorColor, white } from '../../../../styles/variables';

type CharitiesFilterProps = {
  selectedFilters: string[];
  setSelectedFilters: (value: string[]) => void;
};

const CharitiesFilter = ({ selectedFilters, setSelectedFilters }: CharitiesFilterProps) => {
  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const [activeFiltersNumber, setActiveFiltersNumber] = useState(0);
  const { t } = useTranslation();

  const filterCounterStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    width: '16px',
    height: '16px',
    backgroundColor: errorColor,
    borderRadius: '50%',
    top: '-50%',
    left: '50%',
  };

  const filterButtonStyles = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderColor: backgroundColor,
    borderWidth: '2px',
    minHeight: { mobile: '36px', tablet: '43px' },
    padding: { mobile: '0 20px', tablet: '0 24px' },
    '&:hover': { borderWidth: '2px', borderColor: backgroundColor },
    '&:focus': { boxShadow: 'none' },
  };

  return (
    <>
      <Button
        variant="outlined"
        color="info"
        size="medium"
        onClick={() => setOpenFilterDialog(true)}
        sx={filterButtonStyles}
      >
        <Box position="relative" mr={1} lineHeight={0}>
          <FilterIcon width="18.5px" height="15px" />
          {activeFiltersNumber > 0 && (
            <Box sx={filterCounterStyles}>
              <Typography color={white} lineHeight={0} fontSize={'9px'}>
                {activeFiltersNumber}
              </Typography>
            </Box>
          )}
        </Box>

        <Typography variant="paragraphM" fontWeight={700} lineHeight={0}>
          {t('filter')}
        </Typography>
      </Button>
      <CharitiesFilterDialog
        open={openFilterDialog}
        onClose={() => setOpenFilterDialog(false)}
        setActiveFiltersNumber={setActiveFiltersNumber}
        selectedFilters={selectedFilters}
        setSelectedFilters={setSelectedFilters}
      />
    </>
  );
};

export default CharitiesFilter;
