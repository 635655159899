export const IdealLogo = ({ width = '54', height = '29' }: { width?: string; height?: string }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 60 60"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.9363 57.0583H4.39644C3.23091 57.0568 2.11358 56.5931 1.28943 55.7689C0.465282 54.9448 0.00158037 53.8274 0 52.6619V9.39644C0.000791154 8.23067 0.46424 7.11288 1.28856 6.28856C2.11288 5.46424 3.23067 5.00079 4.39644 5H30.9363C56.2248 5 60 21.2716 60 30.9694C60 47.7936 49.6779 57.0583 30.9363 57.0583Z"
      fill="white"
    />
    <path
      d="M30.9363 57.0584H4.39644C3.23091 57.0568 2.11358 56.5931 1.28943 55.7689C0.465282 54.9448 0.00158037 53.8274 0 52.6619V9.39644C0.000791154 8.23067 0.46424 7.11288 1.28856 6.28856C2.11288 5.46424 3.23067 5.00079 4.39644 5H30.9363C56.2248 5 60 21.2716 60 30.9694C60 47.7936 49.6779 57.0584 30.9363 57.0584ZM4.39644 6.68451C3.67743 6.6853 2.9881 6.97127 2.47968 7.47969C1.97127 7.9881 1.68529 8.67743 1.6845 9.39644V52.6619C1.68529 53.3809 1.97127 54.0703 2.47968 54.5787C2.9881 55.0871 3.67743 55.3731 4.39644 55.3738H30.9363C48.5938 55.3738 58.3155 46.7124 58.3155 30.9694C58.3155 9.83847 41.1628 6.68451 30.9363 6.68451H4.39644Z"
      fill="black"
    />
    <path
      d="M16.4001 13.5897V51.3148H32.827C47.7457 51.3148 54.2149 42.8834 54.2149 31.0052C54.2149 19.6139 47.7457 10.7583 32.827 10.7583H19.2465C18.4942 10.7583 17.7724 11.0561 17.2391 11.5867C16.7057 12.1173 16.4041 12.8374 16.4001 13.5897Z"
      fill="#CC0066"
    />
    <path
      d="M22.642 26.0772C23.1723 26.073 23.6992 26.163 24.1981 26.343C24.6664 26.5175 25.0905 26.7928 25.4406 27.1494C25.8068 27.5299 26.0878 27.984 26.2649 28.4815C26.4743 29.0853 26.5754 29.7212 26.5636 30.3601C26.569 30.9513 26.4875 31.54 26.3216 32.1074C26.1737 32.6121 25.9247 33.0816 25.5899 33.4872C25.2553 33.8797 24.8368 34.1921 24.3653 34.4012C23.8207 34.6358 23.2318 34.7499 22.639 34.7357H18.9893V26.0742L22.642 26.0772ZM22.5076 33.1229C22.7798 33.1227 23.0501 33.0783 23.308 32.9914C23.5691 32.8994 23.8052 32.7481 23.998 32.5494C24.2159 32.3189 24.381 32.0438 24.4818 31.743C24.6131 31.3526 24.6748 30.9421 24.664 30.5304C24.6691 30.1286 24.627 29.7277 24.5386 29.3357C24.4632 29.0112 24.3208 28.7059 24.1204 28.4397C23.9183 28.1843 23.6525 27.9865 23.3499 27.8662C22.9737 27.7224 22.5727 27.6543 22.1701 27.6661H20.8918V33.1229H22.5076Z"
      fill="white"
    />
    <path
      d="M34.4011 26.0772V27.6781H29.921V29.5388H34.0307V31.0173H29.9001V33.1229H34.4817V34.7208H27.9976V26.0593L34.4011 26.0772Z"
      fill="white"
    />
    <path
      d="M40.8224 26.0771L44.0571 34.7386H42.0799L41.4258 32.8122H38.1912L37.5132 34.7386H35.5957L38.8811 26.0771H40.8224ZM40.93 31.3845L39.8398 28.2097H39.8159L38.6899 31.3845H40.93Z"
      fill="white"
    />
    <path
      d="M47.0734 26.0922V33.1438H51.207V34.7447H45.1709V26.0833L47.0734 26.0922Z"
      fill="white"
    />
    <path
      d="M8.97807 36.9519C9.81376 36.9519 10.6152 37.2839 11.2061 37.8748C11.7971 38.4657 12.1291 39.2672 12.1291 40.1029V51.315C10.4585 51.315 8.85629 50.6513 7.67501 49.4701C6.49372 48.2888 5.83008 46.6866 5.83008 45.016V40.0999C5.83087 39.2652 6.16279 38.465 6.75298 37.8748C7.34317 37.2846 8.14341 36.9527 8.97807 36.9519Z"
      fill="black"
    />
    <path
      d="M8.97806 34.5626C11.2742 34.5626 13.1356 32.7012 13.1356 30.4051C13.1356 28.1089 11.2742 26.2476 8.97806 26.2476C6.68193 26.2476 4.82056 28.1089 4.82056 30.4051C4.82056 32.7012 6.68193 34.5626 8.97806 34.5626Z"
      fill="black"
    />
  </svg>
);
