import { type ButtonProps } from '@mui/material';
import { type Theme } from '@mui/material/styles';
import {
  darkBlue,
  disabledColor,
  errorColor,
  primaryColor,
  secondaryColor,
  white,
} from '../variables';
import { font } from '../custom-theme';

export const MuiButtonStyles = {
  styleOverrides: {
    root: ({ ownerState, theme }: { theme: Theme; ownerState: ButtonProps }) => ({
      fontFamily: font,
      fontWeight: 600,
      fontStyle: 'normal',
      textTransform: 'none',
      boxShadow: 'none',
      boxSizing: 'border-box',

      '&:hover': {
        boxShadow: 'none',
        opacity: 0.75,
      },

      '&:active': {
        boxShadow: 'none',
        opacity: 0.75,
      },

      ...(ownerState.size === 'small' && {
        fontSize: '10px',
        lineHeight: 'normal',
        padding: '8px 10px',
        borderRadius: '6px',
        height: '29px',
      }),
      ...(ownerState.size === 'medium' && {
        fontSize: '13px',
        lineHeight: 'normal',
        padding: '10px 12px',
        borderRadius: '6px',
        height: '36px',
      }),
      ...(ownerState.size === 'large' && {
        fontSize: '16px',
        lineHeight: 'normal',
        padding: '14px 16px',
        borderRadius: '8px',
        height: '48px',
      }),

      [theme.breakpoints.up('tablet')]: {
        ...(ownerState.size === 'small' && {
          fontSize: '11px',
          lineHeight: 'normal',
          padding: '9px 11px',
          borderRadius: '6px',
          height: '32px',
        }),
        ...(ownerState.size === 'medium' && {
          fontSize: '14px',
          lineHeight: 'normal',
          padding: '11px 14px',
          borderRadius: '6px',
          height: '40px',
        }),
        ...(ownerState.size === 'large' && {
          fontSize: '17px',
          lineHeight: 'normal',
          padding: '15px 18px',
          borderRadius: '8px',
          height: '51px',
        }),
      },

      [theme.breakpoints.up('laptop')]: {
        ...(ownerState.size === 'small' && {
          fontSize: '12px',
          lineHeight: 'normal',
          padding: '10px 12px',
          borderRadius: '6px',
          height: '35px',
        }),
        ...(ownerState.size === 'medium' && {
          fontSize: '15px',
          lineHeight: 'normal',
          padding: '12px 16px',
          borderRadius: '6px',
          height: '43px',
        }),
        ...(ownerState.size === 'large' && {
          fontSize: '18px',
          lineHeight: 'normal',
          padding: '16px 20px',
          borderRadius: '8px',
          height: '55px',
        }),
      },
    }),

    containedPrimary: ({ ownerState, theme }: { theme: Theme; ownerState: ButtonProps }) => ({
      fontFamily: font,
      fontWeight: 600,
      fontStyle: 'normal',
      textTransform: 'none',
      boxShadow: 'none',
      boxSizing: 'border-box',
      // @ts-expect-error
      border: `1px solid ${theme.palette[ownerState.color].main}`,

      '&.Mui-disabled': {
        backgroundColor: disabledColor,
        border: `1px solid ${disabledColor}`,
        color: '#FFF',
      },
      '&:hover': {
        boxShadow: 'none',
        opacity: 0.75,
        borderColor: 'transparent',
      },
      '&:focus': {
        border: '1px solid #FFF',
        // @ts-expect-error
        boxShadow: `0px 0px 0px 2px ${theme.palette[ownerState.color].main}`,
      },

      '&:active': {
        boxShadow: 'none',
        opacity: 0.75,
      },
      ...(ownerState.color === 'error' && {
        backgroundColor: theme.palette.error.main,
        border: `1px solid ${theme.palette.error.main}`,
        color: white,
        '&:hover': {
          color: white,
          borderColor: 'transparent',
        },
      }),
      ...(ownerState.size === 'small' && {
        fontSize: '10px',
        lineHeight: 'normal',
        padding: '8px 10px',
        borderRadius: '6px',
        height: '29px',
      }),
      ...(ownerState.size === 'medium' && {
        fontSize: '13px',
        lineHeight: 'normal',
        padding: '10px 12px',
        borderRadius: '6px',
        height: '36px',
      }),
      ...(ownerState.size === 'large' && {
        fontSize: '16px',
        lineHeight: 'normal',
        padding: '14px 16px',
        borderRadius: '8px',
        height: '48px',

        // @ts-expect-error
        border: `2px solid ${theme.palette[ownerState.color].main}`,
        ...(ownerState.color === 'error' && {
          border: `2px solid ${errorColor}`,
        }),

        '&:focus': {
          border: '2px solid #FFF',
          // @ts-expect-error
          boxShadow: `0px 0px 0px 2px ${theme.palette[ownerState.color].main}`,
        },
        '&.Mui-disabled': {
          backgroundColor: disabledColor,
          border: `2px solid ${disabledColor}`,
          color: '#FFF',
        },
      }),

      [theme.breakpoints.up('tablet')]: {
        ...(ownerState.size === 'small' && {
          fontSize: '11px',
          lineHeight: 'normal',
          padding: '9px 11px',
          borderRadius: '6px',
          height: '32px',
        }),
        ...(ownerState.size === 'medium' && {
          fontSize: '14px',
          lineHeight: 'normal',
          padding: '11px 14px',
          borderRadius: '6px',
          height: '40px',
        }),
        ...(ownerState.size === 'large' && {
          fontSize: '17px',
          lineHeight: 'normal',
          padding: '15px 18px',
          borderRadius: '8px',
          height: '51px',
        }),
      },

      [theme.breakpoints.up('laptop')]: {
        ...(ownerState.size === 'small' && {
          fontSize: '12px',
          lineHeight: 'normal',
          padding: '10px 12px',
          borderRadius: '6px',
          height: '35px',
        }),
        ...(ownerState.size === 'medium' && {
          fontSize: '15px',
          lineHeight: 'normal',
          padding: '12px 16px',
          borderRadius: '6px',
          height: '43px',
        }),
        ...(ownerState.size === 'large' && {
          fontSize: '18px',
          lineHeight: 'normal',
          padding: '16px 20px',
          borderRadius: '8px',
          height: '55px',
        }),
      },
    }),
    containedSecondary: ({ ownerState, theme }: { theme: Theme; ownerState: ButtonProps }) => ({
      [theme.breakpoints.down('tablet')]: {
        '&:focus': {
          border: `1px solid ${darkBlue}`,
        },
        ...(ownerState.size === 'large' && {
          '&:focus': {
            border: `2px solid ${darkBlue}`,
          },
        }),
      },
    }),

    outlinedPrimary: ({ ownerState }: { ownerState: ButtonProps }) => ({
      color: primaryColor,
      border: `1px solid ${primaryColor}`,
      '&:hover': {
        border: `1px solid ${primaryColor}`,
        opacity: 0.75,
        backgroundColor: 'transparent',
      },
      '&:active': {
        border: `1px solid ${primaryColor}`,
        opacity: 0.75,
      },
      '&:focus': {
        boxShadow: `0px 0px 0px 2px ${primaryColor}`,
      },
      '&.Mui-disabled': {
        backgroundColor: 'transparent',
        border: `1px solid ${disabledColor}`,
        color: disabledColor,
      },

      ...(ownerState.size === 'large' && {
        border: `2px solid ${primaryColor}`,
        '&:hover': {
          border: `2px solid ${primaryColor}`,
          opacity: 0.75,
          backgroundColor: 'transparent',
        },
        '&:active': {
          border: `2px solid ${primaryColor}`,
        },
        '&.Mui-disabled': {
          backgroundColor: 'transparent',
          border: `2px solid ${disabledColor}`,
          color: disabledColor,
        },
      }),
    }),
    outlinedSecondary: ({ ownerState }: { ownerState: ButtonProps }) => ({
      color: secondaryColor,
      border: `1px solid ${secondaryColor}`,
      '&:hover': {
        border: `1px solid ${secondaryColor}`,
        opacity: 0.75,
        backgroundColor: 'transparent',
      },
      '&:active': {
        border: `1px solid ${secondaryColor}`,
        opacity: 0.75,
      },
      '&:focus': {
        boxShadow: `0px 0px 0px 2px ${secondaryColor}`,
      },
      '&.Mui-disabled': {
        backgroundColor: 'transparent',
        border: `1px solid ${disabledColor}`,
        color: disabledColor,
      },

      ...(ownerState.size === 'large' && {
        border: `2px solid ${secondaryColor}`,
        '&:hover': {
          border: `2px solid ${secondaryColor}`,
          opacity: 0.75,
          backgroundColor: 'transparent',
        },
        '&:active': {
          border: `2px solid ${secondaryColor}`,
        },
        '&.Mui-disabled': {
          backgroundColor: 'transparent',
          border: `2px solid ${disabledColor}`,
          color: disabledColor,
        },
      }),
    }),
    outlinedLight: ({ ownerState }: { ownerState: ButtonProps }) => ({
      color: white,
      border: '1px solid #FFF',
      '&:hover': {
        border: '1px solid #FFF',
        opacity: 0.75,
        backgroundColor: 'transparent',
      },
      '&:active': {
        border: '1px solid #FFF',
        opacity: 0.75,
      },
      '&:focus': {
        boxShadow: '0px 0px 0px 2px #FFF',
      },
      '&.Mui-disabled': {
        backgroundColor: 'transparent',
        border: `1px solid ${disabledColor}`,
        color: disabledColor,
      },

      ...(ownerState.size === 'large' && {
        border: '2px solid #FFF',
        '&:hover': {
          border: '2px solid #FFF',
          opacity: 0.75,
          backgroundColor: 'transparent',
        },
        '&:active': {
          border: '2px solid #FFF',
        },
        '&.Mui-disabled': {
          backgroundColor: 'transparent',
          border: `2px solid ${disabledColor}`,
          color: disabledColor,
        },
      }),
    }),
    outlinedInfo: ({ ownerState }: { ownerState: ButtonProps }) => ({
      color: darkBlue,
      border: `1px solid ${darkBlue}`,
      '&:hover': {
        border: `1px solid ${darkBlue}`,
        opacity: 0.75,
        backgroundColor: 'transparent',
      },
      '&:active': {
        border: `1px solid ${darkBlue}`,
        opacity: 0.75,
      },
      '&:focus': {
        boxShadow: `0px 0px 0px 2px ${darkBlue}`,
      },
      '&.Mui-disabled': {
        backgroundColor: 'transparent',
        border: `1px solid ${disabledColor}`,
        color: disabledColor,
      },

      ...(ownerState.size === 'large' && {
        border: `2px solid ${darkBlue}`,
        '&:hover': {
          border: `2px solid ${darkBlue}`,
          opacity: 0.75,
          backgroundColor: 'transparent',
        },
        '&:active': {
          border: `2px solid ${darkBlue}`,
        },
        '&.Mui-disabled': {
          backgroundColor: 'transparent',
          border: `2px solid ${disabledColor}`,
          color: disabledColor,
        },
      }),
    }),
    outlinedError: ({ ownerState, theme }: { theme: Theme; ownerState: ButtonProps }) => ({
      color: theme.palette.error.main,
      border: `1px solid ${theme.palette.error.main}`,
      '&:hover': {
        border: `1px solid ${theme.palette.error.main}`,
        opacity: 0.75,
        backgroundColor: 'transparent',
      },
      '&:active': {
        border: `1px solid ${theme.palette.error.main}`,
        opacity: 0.75,
      },
      '&:focus': {
        boxShadow: `0px 0px 0px 2px ${theme.palette.error.main}`,
      },
      '&.Mui-disabled': {
        backgroundColor: 'transparent',
        border: `1px solid ${disabledColor}`,
        color: disabledColor,
      },

      ...(ownerState.size === 'large' && {
        border: `2px solid ${theme.palette.error.main}`,
        '&:hover': {
          border: `2px solid ${theme.palette.error.main}`,
          opacity: 0.75,
          backgroundColor: 'transparent',
        },
        '&:active': {
          border: `2px solid ${theme.palette.error.main}`,
        },
        '&.Mui-disabled': {
          backgroundColor: 'transparent',
          border: `2px solid ${disabledColor}`,
          color: disabledColor,
        },
      }),
    }),

    textPrimary: ({ ownerState }: { ownerState: ButtonProps }) => ({
      color: primaryColor,
      border: '1px solid transparent',
      '&:hover': {
        boxShadow: 'none',
        opacity: 0.75,
        backgroundColor: 'transparent',
      },
      '&:active': {
        boxShadow: 'none',
        opacity: 0.75,
      },
      '&:focus': {
        border: `1px solid ${primaryColor}`,
        boxShadow: 'none',
      },

      '&.Mui-disabled': {
        backgroundColor: 'transparent',
        border: '1px solid transparent',
        color: disabledColor,
      },

      ...(ownerState.size === 'large' && {
        border: '2px solid transparent',
        '&:hover': {
          backgroundColor: 'transparent',
        },

        '&:focus': {
          border: `2px solid ${primaryColor}`,
          boxShadow: 'none',
        },
        '&.Mui-disabled': {
          backgroundColor: 'transparent',
          border: '2px solid transparent',
          color: disabledColor,
        },
      }),
    }),
    textSecondary: ({ ownerState }: { ownerState: ButtonProps }) => ({
      color: secondaryColor,
      border: '1px solid transparent',
      '&:hover': {
        boxShadow: 'none',
        opacity: 0.75,
        backgroundColor: 'transparent',
      },
      '&:active': {
        boxShadow: 'none',
        opacity: 0.75,
      },
      '&:focus': {
        border: `1px solid ${secondaryColor}`,
        boxShadow: 'none',
      },

      '&.Mui-disabled': {
        backgroundColor: 'transparent',
        border: '1px solid transparent',
        color: disabledColor,
      },

      ...(ownerState.size === 'large' && {
        border: '2px solid transparent',
        '&:hover': {
          backgroundColor: 'transparent',
        },

        '&:focus': {
          border: `2px solid ${secondaryColor}`,
          boxShadow: 'none',
        },
        '&.Mui-disabled': {
          backgroundColor: 'transparent',
          border: '2px solid transparent',
          color: disabledColor,
        },
      }),
    }),
    textInfo: ({ ownerState }: { ownerState: ButtonProps }) => ({
      color: darkBlue,
      border: '1px solid transparent',
      '&:hover': {
        boxShadow: 'none',
        opacity: 0.75,
        backgroundColor: 'transparent',
      },
      '&:active': {
        boxShadow: 'none',
        opacity: 0.75,
      },
      '&:focus': {
        border: `1px solid ${darkBlue}`,
        boxShadow: 'none',
      },

      '&.Mui-disabled': {
        backgroundColor: 'transparent',
        border: '1px solid transparent',
        color: disabledColor,
      },

      ...(ownerState.size === 'large' && {
        border: '2px solid transparent',
        '&:hover': {
          backgroundColor: 'transparent',
        },

        '&:focus': {
          border: `2px solid ${darkBlue}`,
          boxShadow: 'none',
        },
        '&.Mui-disabled': {
          backgroundColor: 'transparent',
          border: '2px solid transparent',
          color: disabledColor,
        },
      }),
    }),
    textError: ({ ownerState }: { ownerState: ButtonProps }) => ({
      color: errorColor,
      border: '1px solid transparent',
      '&:hover': {
        boxShadow: 'none',
        opacity: 0.75,
        backgroundColor: 'transparent',
      },
      '&:active': {
        boxShadow: 'none',
        opacity: 0.75,
      },
      '&:focus': {
        border: `1px solid ${errorColor}`,
        boxShadow: 'none',
      },

      '&.Mui-disabled': {
        backgroundColor: 'transparent',
        border: '1px solid transparent',
        color: disabledColor,
      },

      ...(ownerState.size === 'large' && {
        border: '2px solid transparent',
        '&:hover': {
          backgroundColor: 'transparent',
        },

        '&:focus': {
          border: `2px solid ${errorColor}`,
          boxShadow: 'none',
        },
        '&.Mui-disabled': {
          backgroundColor: 'transparent',
          border: '2px solid transparent',
          color: disabledColor,
        },
      }),
    }),
    textLight: ({ ownerState }: { ownerState: ButtonProps }) => ({
      color: white,
      border: '1px solid transparent',
      '&:hover': {
        boxShadow: 'none',
        opacity: 0.75,
        backgroundColor: 'transparent',
      },
      '&:active': {
        boxShadow: 'none',
        opacity: 0.75,
      },
      '&:focus': {
        border: `1px solid ${white}`,
        boxShadow: 'none',
      },

      '&.Mui-disabled': {
        backgroundColor: 'transparent',
        border: '1px solid transparent',
        color: disabledColor,
      },

      ...(ownerState.size === 'large' && {
        border: '2px solid transparent',
        '&:hover': {
          backgroundColor: 'transparent',
        },

        '&:focus': {
          border: `2px solid ${white}`,
          boxShadow: 'none',
        },
        '&.Mui-disabled': {
          backgroundColor: 'transparent',
          border: '2px solid transparent',
          color: disabledColor,
        },
      }),
    }),
  },
};
