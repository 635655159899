import { Card } from '@mui/material';
import RadioButtonLabeled from '../../../../components/radio-button-labeled/RadioButtonLabeled';
import { desktopS } from '../../../../styles/variables';
import { PAYMENT_COMPONENTS } from './PaymentComponents';

// types
import type { TFunction } from 'i18next';
import type { TPaymentMethod } from '../../../../models/Donation';
import type { PaymentMethod } from '../../../../models/Payments';

export const getPaymentComponents = (
  paymentData: PaymentMethod[],
  paymentMethod: TPaymentMethod['paymentMethod'],
  handleChoosePaymentMethod: (paymentMethod: TPaymentMethod) => void,
  t: TFunction
) => {
  const cardStyles = {
    p: 2,
    justifyContent: 'space-between',
    display: 'flex',
    alignItems: 'center',
    boxShadow: desktopS,
    borderRadius: { mobile: '6px', tablet: '8px' },
    cursor: 'pointer',
  };

  return PAYMENT_COMPONENTS(paymentMethod, handleChoosePaymentMethod)
    .filter(({ props }) =>
      paymentData.some(
        (el) =>
          el.paymentMethod === props.paymentMethod &&
          el.paymentProvider === props.paymentProvider &&
          (!props.donationFrequency ? true : el.donationFrequency === props.donationFrequency)
      )
    )
    .map(({ props, component, extraStyles }) => (
      <Card
        key={props.paymentMethod}
        sx={{
          ...cardStyles,
          ...extraStyles,
        }}
        data-testid={`${props.paymentMethod}PaymentMethod`}
      >
        <RadioButtonLabeled
          key={props.paymentMethod}
          value={props.paymentMethod}
          label={t(props.paymentMethod)}
          checked={paymentMethod === props.paymentMethod}
          typographyVariant="h6"
        />
        {component}
      </Card>
    ));
};
