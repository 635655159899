export const DeleteAccountIllustration = ({
  width = '247',
  height = '194',
}: {
  width?: string;
  height?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 248 194"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.68664 27.315C-0.202214 19.1554 12.9255 9.14707 31.0061 4.96194C43.7402 2.01434 55.5348 2.74466 62.0578 6.27708C63.0705 6.82925 63.9501 7.59651 64.6345 8.52496C65.319 9.4534 65.7919 10.5205 66.0198 11.6512L73.0874 46.1688L127.976 90.0573L86.9926 146.385L54.7352 107.78L63.3743 134.862C64.1352 137.349 64.3376 139.974 63.9671 142.549C63.5965 145.124 62.662 147.586 61.2305 149.758L59.8696 151.824C59.6502 152.157 59.3498 152.429 58.9964 152.615C58.6431 152.8 58.2484 152.893 57.8495 152.884C57.4506 152.875 57.0605 152.765 56.7157 152.564C56.371 152.363 56.0829 152.078 55.8785 151.735L26.3068 102.153C21.6972 94.4279 19.904 85.3456 21.232 76.4487L23.362 62.1749L1.68664 27.315Z"
      fill="#FFBCBC"
    />
    <path
      d="M50.9863 142.736C52.993 141.904 55.2934 142.166 56.9065 143.624C59.2 145.703 59.2346 149.399 56.9827 151.892"
      stroke="#CC4646"
      strokeWidth="1.30723"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M222.64 62.1749L225.746 68.5094C230.413 78.027 231.406 88.9317 228.534 99.1357L208.005 172.094C207.896 172.481 207.703 172.839 207.44 173.143C207.177 173.447 206.85 173.69 206.483 173.854C206.116 174.017 205.717 174.098 205.316 174.091C204.914 174.083 204.518 173.987 204.158 173.81L201.998 172.747C199.766 171.648 197.882 169.952 196.556 167.846C195.231 165.741 194.515 163.309 194.489 160.821L194.317 144.293L124.463 191.688C123.435 192.386 122.203 192.721 120.963 192.64C119.723 192.559 118.546 192.066 117.618 191.24C116.69 190.414 116.064 189.301 115.84 188.079C115.616 186.857 115.806 185.595 116.381 184.493L120.713 176.178L106.641 187.637C105.436 188.617 103.916 189.124 102.364 189.064C100.812 189.004 99.3354 188.381 98.2098 187.311C97.0842 186.241 96.3868 184.798 96.2481 183.251C96.1094 181.704 96.5389 180.16 97.4562 178.907L105.472 167.975L97.3246 175.681C96.2774 176.679 94.8974 177.255 93.4509 177.296C92.0044 177.337 90.5939 176.841 89.4916 175.904C88.3894 174.966 87.6735 173.653 87.4821 172.219C87.2907 170.784 87.6375 169.33 88.4554 168.136L96.4002 157.269L88.7201 161.694C87.6319 162.265 86.3824 162.451 85.1748 162.223C83.9671 161.994 82.8724 161.363 82.0685 160.434C81.2647 159.504 80.7989 158.33 80.747 157.102C80.6952 155.874 81.0602 154.664 81.7828 153.67L145.768 65.6047C149.378 60.6328 154.059 56.5353 159.465 53.615C164.871 50.6947 170.864 49.0261 177.001 48.7326L180.783 17.6393C184.061 10.8488 200.708 7.80008 215.457 11.0775C232.382 14.8441 243.882 27.2748 241.511 34.8842L241.486 34.9618L222.64 62.1749Z"
      fill="#FFBCBC"
    />
    <path
      d="M194.315 144.289L196.362 116.334L191.389 107.779"
      stroke="#CC4646"
      strokeWidth="1.3759"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M222.64 62.1749C222.64 62.1749 219.896 58.9335 215.277 56.2644"
      stroke="#CC4646"
      strokeWidth="1.3759"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M207.18 172.93C204.863 171.854 203.279 169.669 203.279 167.147C203.279 163.56 206.487 160.653 210.445 160.653"
      stroke="#CC4646"
      strokeWidth="1.3759"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M191.387 107.779C188.446 116.904 183.765 124.851 178.005 131.178C177.134 132.137 176.239 133.058 175.318 133.94L168.089 139.78L123.039 176.175L77.9892 139.78L70.7512 133.932C69.8338 133.05 68.9395 132.131 68.0683 131.174C62.3102 124.846 57.6276 116.9 54.6869 107.775C45.6016 79.5736 56.6049 50.7932 79.2628 43.4928C94.4526 38.5995 111.023 46.2464 123.033 59.23C135.046 46.2464 151.616 38.5995 166.805 43.4928C189.469 50.7974 200.472 79.5778 191.387 107.779Z"
      fill="#FFDDDD"
    />
    <path
      d="M82.041 176.591V42.5227"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M75.9199 176.591V44.6484"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M88.3477 176.591V42.5227"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M94.6562 176.591V42.5227"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M100.963 176.591V44.6484"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M107.27 176.591V46.7744"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M113.576 176.591V51.0273"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M119.883 176.591V51.0273"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M57.0859 176.591V51.0273"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M63.3926 176.591V51.0273"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M69.6992 176.591V46.7744"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M126.011 105.761H50.6797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M126.011 99.4543H50.6797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M126.011 93.1462H50.6797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M126.011 86.8394H50.6797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M126.011 80.5325H50.6797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M126.011 74.2258H50.6797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M126.011 67.9189H50.6797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M126.011 61.6121H51.0352"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M126.011 55.304H50.6797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M126.011 156.254H50.6797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M126.011 162.628H50.6797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M126.011 149.948H50.6797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M126.011 143.641H50.6797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M126.011 137.334H50.6797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M126.011 131.027H50.6797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M126.011 124.719H50.6797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M126.011 118.412H50.6797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M126.011 112.105H51.0352"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M151.43 176.591V42.5227"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M145.309 176.591V44.6484"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M157.736 176.591V42.5227"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M164.045 176.591V42.5227"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M170.352 176.591V44.6484"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M176.658 176.591V47.1997"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M182.965 176.591V51.0273"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M189.273 176.591V51.0273"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M126.477 176.591V51.0273"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M132.783 176.591V51.0273"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M139.09 176.591V47.1997"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M195.401 105.761H120.07"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M195.401 99.4543H120.07"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M195.401 93.1462H120.07"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M195.401 86.8394H120.07"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M195.401 80.5325H120.07"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M195.401 74.2258H120.07"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M195.401 67.9189H120.07"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M195.4 61.6121H120.424"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M195.401 55.304H120.07"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M176.692 48.7146H69.4707"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M195.401 156.254H120.07"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M195.401 162.628H120.07"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M178.393 168.793H103.062"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M195.401 149.948H120.07"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M195.401 143.641H120.07"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M195.401 137.334H120.07"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M195.401 131.027H120.07"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M195.401 124.719H120.07"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M195.401 118.412H120.07"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M195.4 112.105H120.424"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M191.389 107.78C188.448 116.904 183.767 124.851 178.008 131.178C177.137 132.137 176.241 133.058 175.321 133.94L169.886 138.33H130.822C117.368 138.33 110.48 122.198 119.787 112.481L145.49 85.6393L194.356 85.4272C194.97 92.9674 193.816 100.243 191.389 107.78Z"
      fill="#CC4646"
    />
    <path
      d="M155.838 122.952H173.593C177.148 122.952 180.625 121.903 183.586 119.936C186.548 117.969 188.863 115.172 190.242 111.895L191.159 109.715"
      stroke="#FFBCBC"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M186.31 121.518L178.879 135.411L183.501 137.593C186.181 138.944 188.301 141.193 189.491 143.948C189.536 144.052 189.617 144.136 189.719 144.184C189.821 144.232 189.938 144.241 190.046 144.208C190.154 144.175 190.246 144.104 190.305 144.007C190.364 143.911 190.385 143.796 190.364 143.685L186.31 121.518Z"
      fill="#CCCEFF"
    />
    <path
      d="M186.309 121.518L190.362 143.685"
      stroke="#CC4646"
      strokeWidth="1.03256"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M180.769 139.857C180.364 139.857 179.976 139.697 179.691 139.411C179.405 139.125 179.244 138.737 179.244 138.333C179.244 137.929 179.405 137.541 179.691 137.255C179.976 136.969 180.364 136.809 180.769 136.809H181.877C181.932 136.808 181.987 136.818 182.038 136.838C182.09 136.859 182.136 136.889 182.176 136.928C182.215 136.967 182.246 137.013 182.268 137.064C182.289 137.115 182.3 137.169 182.3 137.224V138.333C182.3 138.534 182.26 138.733 182.183 138.918C182.106 139.103 181.993 139.272 181.851 139.413C181.709 139.555 181.54 139.667 181.354 139.743C181.168 139.82 180.969 139.858 180.769 139.857Z"
      stroke="white"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M182.094 134.648L182.293 137.227"
      stroke="white"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M194.03 80.078C196.363 92.966 189.936 101.753 179.883 106.392L146.26 121.903L142.518 111.904L172.613 89.3878L161.441 92.8967C158.361 93.8634 155.036 93.692 152.071 92.4137C149.107 91.1354 146.7 88.8352 145.288 85.9317L138.359 71.6787C138.192 71.3618 137.956 71.0865 137.668 70.8731C137.38 70.6597 137.048 70.5135 136.696 70.4454C136.345 70.3773 135.982 70.389 135.636 70.4797C135.289 70.5703 134.967 70.7376 134.694 70.9691L127.349 77.4824C126.933 74.532 126.326 71.583 125.635 68.6133C125.095 64.7636 123.756 61.0694 121.703 57.768C133.715 44.8051 151.636 38.6009 166.811 43.4942C180.781 47.9953 190.532 59.1718 193.665 74.8314L194.03 80.078Z"
      fill="#000799"
    />
    <path
      d="M122.104 57.5906C122.104 57.5906 125.443 62.1152 126.021 68.4082"
      stroke="#CC4646"
      strokeWidth="0.825286"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M175.777 77.323L172.402 88.9665"
      stroke="#CC4646"
      strokeWidth="0.825286"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M147.535 52.3356C147.535 45.7544 152.653 42.0085 159.752 42.0085C166.852 42.0085 170.48 48.6396 170.48 54.4614L172.606 89.3837L157.995 94.1591L160.628 59.2105L147.535 52.3356Z"
      fill="#CC4646"
    />
    <path
      d="M161.725 56.9406V54.7385"
      stroke="#000799"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M162.911 56.2781L161.725 56.9405C161.725 56.9405 153.87 59.4114 149.612 49.6775L147.533 52.5239L148.056 58.6672C148.44 63.1849 153.474 65.6891 157.307 63.2681L162.623 59.9116C163.115 59.5997 163.515 59.163 163.783 58.6458C164.051 58.1287 164.177 57.5498 164.148 56.9682C164.141 56.8264 164.099 56.6886 164.025 56.5677C163.95 56.4469 163.846 56.3472 163.722 56.278C163.598 56.2088 163.459 56.1725 163.317 56.1725C163.175 56.1725 163.035 56.2089 162.911 56.2781Z"
      fill="#FFBCBC"
    />
    <path
      d="M147.777 55.3635L149.278 56.08C149.749 56.3449 150.146 56.7233 150.433 57.1807C150.72 57.6381 150.889 58.1601 150.923 58.6992L151.116 61.7133"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M156.343 59.5153C156.004 59.7717 155.587 59.9042 155.161 59.8909C154.607 59.8909 154.129 59.6982 153.871 59.4114"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M149.622 58.4483C149.513 58.5687 149.38 58.6646 149.232 58.7294C149.083 58.7941 148.922 58.8263 148.76 58.8238C148.576 58.8273 148.394 58.7855 148.23 58.7021C148.067 58.6187 147.926 58.4963 147.82 58.3457"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M167.631 95.6227L148.541 107.972L145.098 128.971L157.422 136.947C158.323 137.531 159.072 138.319 159.61 139.248C160.147 140.177 160.457 141.22 160.513 142.291C160.569 143.363 160.371 144.433 159.934 145.413C159.497 146.393 158.834 147.256 158 147.93L134.79 166.68C134.669 166.778 134.518 166.834 134.362 166.839C134.206 166.844 134.053 166.798 133.925 166.708C133.797 166.618 133.702 166.489 133.654 166.341C133.607 166.192 133.608 166.032 133.659 165.884L137.235 155.611C137.419 155.082 137.799 154.645 138.298 154.389C138.796 154.134 139.374 154.08 139.911 154.239L141.633 154.752L148.541 143.903L136.63 141.115L120.832 168.189L123.596 173.578C123.816 174.009 123.881 174.502 123.78 174.974C123.679 175.447 123.418 175.87 123.041 176.173L114.186 169.019C113.538 168.495 113.07 167.782 112.846 166.98C112.622 166.177 112.655 165.325 112.939 164.542L134.338 105.567C134.874 104.09 135.75 102.76 136.896 101.685C138.042 100.61 139.425 99.8203 140.933 99.3796L169.908 90.1252C169.66 90.2139 166.615 91.3655 167.631 95.6227Z"
      fill="#CCCEFF"
    />
    <path
      d="M150.356 144.289L148.543 143.906L149.302 142.811"
      stroke="white"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M136.629 141.117L135.98 140.897C135.165 140.619 134.436 140.133 133.867 139.485C133.299 138.838 132.91 138.053 132.74 137.208"
      stroke="white"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M136.075 99.4004L134.689 104.734L134.882 104.363C136.01 102.182 137.937 100.522 140.262 99.7302L147.245 97.3743L146.478 96.3184C146.288 96.0563 146.02 95.861 145.712 95.76C145.405 95.659 145.073 95.6575 144.764 95.7557L137.273 98.1435C136.983 98.2352 136.722 98.3985 136.513 98.6182C136.303 98.8379 136.153 99.107 136.075 99.4004Z"
      fill="white"
    />
    <path
      d="M137.657 154.083L133.447 166.95"
      stroke="#CC4646"
      strokeWidth="0.825286"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M111.969 167.231L123.04 176.175"
      stroke="#CC4646"
      strokeWidth="0.825286"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M178.62 93.0991C178.695 93.1504 178.758 93.2178 178.804 93.2963C178.85 93.3749 178.878 93.4627 178.886 93.5533C178.894 93.644 178.882 93.7353 178.85 93.8207C178.818 93.906 178.768 93.9833 178.703 94.0469L177.233 95.484C176.749 95.9565 176.126 96.2595 175.456 96.3474C174.786 96.4354 174.105 96.3036 173.516 95.9718L171.091 94.604C170.787 94.4328 170.441 94.3478 170.092 94.3583C169.744 94.3688 169.404 94.4744 169.111 94.6636L167.631 95.6226C166.615 91.3654 169.659 90.218 169.907 90.1279H169.923L171.083 89.7579C171.642 89.5793 172.235 89.5306 172.817 89.6156C173.398 89.7006 173.952 89.9171 174.438 90.2485L178.62 93.0991Z"
      fill="#FFBCBC"
    />
    <path
      d="M175.857 92.9673L177.847 94.2547"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M174.795 94.0303L176.785 95.3177"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M144.67 95.9414L136.326 98.4359C135.763 98.6045 135.162 98.5963 134.605 98.4124C134.047 98.2285 133.559 97.8778 133.207 97.4076L130.87 94.2937C130.267 93.4895 129.857 92.5574 129.671 91.5694C129.486 90.5814 129.53 89.564 129.8 88.5958C130.071 87.6276 130.56 86.7346 131.231 85.9857C131.902 85.2369 132.735 84.6523 133.668 84.2771L138.874 82.1818C139.893 81.7719 141.026 81.7497 142.059 82.1196C143.093 82.4894 143.955 83.2255 144.481 84.1884L145.815 86.6219L147.415 87.1402C147.479 87.1608 147.539 87.1938 147.59 87.2375C147.642 87.2811 147.684 87.3344 147.715 87.3944C147.745 87.4544 147.764 87.5198 147.77 87.587C147.775 87.6542 147.767 87.7218 147.746 87.786L147.266 89.2743L147.346 89.4129C147.672 90.0058 147.861 90.6641 147.899 91.3396C147.938 92.015 147.824 92.6905 147.568 93.3165C147.311 93.9425 146.918 94.5031 146.416 94.9573C145.915 95.4115 145.318 95.7478 144.67 95.9414Z"
      fill="#FFBCBC"
    />
    <path
      d="M136.941 93.7394C136.556 93.426 136.088 93.2306 135.595 93.1767C135.101 93.1229 134.602 93.2129 134.159 93.4359L134.107 93.4622C133.579 93.7271 133.156 94.1626 132.906 94.698C132.656 95.2334 132.594 95.8375 132.73 96.4126L132.522 96.4985L130.87 94.2965C130.267 93.4923 129.857 92.5602 129.671 91.5722C129.486 90.5842 129.53 89.5668 129.8 88.5986C130.071 87.6304 130.56 86.7374 131.231 85.9885C131.902 85.2397 132.735 84.655 133.668 84.2799L138.874 82.1845C139.577 81.9008 140.34 81.8006 141.092 81.8935C142.08 86.0107 140.463 90.5687 136.941 93.7394Z"
      fill="#CC4646"
    />
    <path
      d="M134.381 95.0185C134.381 95.0185 136.044 94.4878 136.637 95.9138"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M135.09 96.0732L135.51 94.959"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M144.467 88.7296C144.799 88.7296 145.067 88.461 145.067 88.1296C145.067 87.7982 144.799 87.5295 144.467 87.5295C144.136 87.5295 143.867 87.7982 143.867 88.1296C143.867 88.461 144.136 88.7296 144.467 88.7296Z"
      fill="#CC4646"
    />
    <path
      d="M67.2654 130.225C66.542 129.532 65.3668 127.808 64.642 126.99C60.2881 121.186 56.9392 114.693 54.7349 107.78C45.6482 79.5778 56.6487 50.7974 79.3108 43.497C82.6198 42.4303 86.0687 41.8606 89.5449 41.8063C94.9426 41.7162 100.354 42.8415 105.512 45.0144C108.472 46.2691 111.296 47.8209 113.942 49.6458C117.1 51.8249 119.771 54.637 121.784 57.9035C123.797 61.17 125.109 64.8196 125.635 68.6202C129.525 85.3261 130.586 101.393 112.307 120.695L67.2654 130.225Z"
      fill="#CCCEFF"
    />
    <path
      d="M101.842 55.4441L100.922 65.5743L92.7138 60.5508L84.6387 65.1045L84.0566 55.4441H101.842Z"
      fill="white"
    />
    <path
      d="M92.9669 66.1326C93.4621 66.1326 93.8636 65.7312 93.8636 65.236C93.8636 64.7408 93.4621 64.3394 92.9669 64.3394C92.4717 64.3394 92.0703 64.7408 92.0703 65.236C92.0703 65.7312 92.4717 66.1326 92.9669 66.1326Z"
      stroke="#000799"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M92.9669 72.4119C93.4621 72.4119 93.8636 72.0105 93.8636 71.5153C93.8636 71.0201 93.4621 70.6187 92.9669 70.6187C92.4717 70.6187 92.0703 71.0201 92.0703 71.5153C92.0703 72.0105 92.4717 72.4119 92.9669 72.4119Z"
      stroke="white"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M105.117 52.4299L104.781 53.5704C104.632 54.077 104.323 54.5218 103.9 54.838C103.477 55.1543 102.963 55.3251 102.435 55.3249H101.813C101.554 56.8929 100.746 58.3179 99.5345 59.3461C98.3226 60.3742 96.785 60.9387 95.1957 60.9388H90.739C89.1779 60.9393 87.6656 60.3951 86.4627 59.4002C85.2598 58.4052 84.4417 57.0218 84.1495 55.4884H83.4981C82.9702 55.4885 82.4565 55.3177 82.0338 55.0014C81.6111 54.6851 81.3022 54.2405 81.1534 53.734L80.818 52.5934C80.749 52.3598 80.7355 52.1133 80.7785 51.8736C80.8215 51.6338 80.9198 51.4074 81.0656 51.2123C81.2115 51.0172 81.4008 50.8589 81.6186 50.7498C81.8364 50.6407 82.0766 50.584 82.3202 50.584H82.6279C82.853 50.5838 83.0735 50.6482 83.2631 50.7695C83.4527 50.8909 83.6036 51.0641 83.6977 51.2686C85.0835 50.1904 86.0009 48.2503 86.0009 46.033C86.0016 45.7617 85.9864 45.4905 85.9552 45.2209C89.198 47.0932 94.0427 48.3238 97.2204 48.1478C98.1446 48.1132 99.0606 47.9609 99.9463 47.6946C100.286 49.2426 101.091 50.5424 102.157 51.3351C102.206 51.0772 102.343 50.8445 102.546 50.6774C102.748 50.5104 103.003 50.4195 103.265 50.4205H103.62C103.863 50.4216 104.102 50.4792 104.319 50.5887C104.536 50.6982 104.725 50.8567 104.87 51.0516C105.015 51.2465 105.113 51.4725 105.156 51.7117C105.199 51.9509 105.185 52.1968 105.117 52.4299Z"
      fill="#FFBCBC"
    />
    <path
      d="M97.8157 53.8241C97.497 54.0642 97.1058 54.1879 96.707 54.1747C96.1915 54.1747 95.737 53.9945 95.5 53.7271"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M90.1614 53.8241C89.8427 54.0642 89.4515 54.1879 89.0527 54.1747C88.5372 54.1747 88.0827 53.9945 87.8457 53.7271"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M91.6914 51.8381L92.5201 57.5587H94.1748"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M91.75 52.2069H85.791L86.3453 51.1204C86.436 50.9411 86.5748 50.7906 86.7461 50.6856C86.9174 50.5806 87.1145 50.5253 87.3154 50.5259H90.5998C90.9278 50.5275 91.2461 50.6381 91.5044 50.8403C91.7628 51.0425 91.9467 51.3248 92.0271 51.6428L92.0673 51.7994C92.0796 51.8477 92.0806 51.8982 92.0704 51.9469C92.0602 51.9957 92.0389 52.0415 92.0083 52.0808C91.9777 52.1201 91.9385 52.1519 91.8937 52.1737C91.849 52.1956 91.7998 52.2069 91.75 52.2069Z"
      fill="#CC4646"
    />
    <path
      d="M100.215 52.2069H94.2949L94.8049 51.1204C94.8824 50.9461 95.0081 50.7975 95.1672 50.6922C95.3263 50.5869 95.5121 50.5292 95.7029 50.5259H98.7516C99.0673 50.5342 99.371 50.6491 99.613 50.8519C99.8551 51.0547 100.021 51.3335 100.085 51.6428L100.215 52.2069Z"
      fill="#CC4646"
    />
    <path
      d="M103.379 41.1827L102.228 51.3892C101.12 50.602 100.288 49.2786 99.9433 47.6946C99.0576 47.9609 98.1416 48.1132 97.2174 48.1478C94.0397 48.3238 89.195 47.0932 85.9522 45.2209C85.9833 45.4905 85.9986 45.7617 85.9979 46.033C85.9979 48.2503 85.075 50.1904 83.6905 51.2728L82.5625 41.0053C82.7011 37.2969 87.313 35.9236 92.9727 35.9236C98.7168 35.9194 103.379 37.3371 103.379 41.1827Z"
      fill="#CC4646"
    />
    <path
      d="M89.5831 135.162C83.4537 134.726 77.9507 128.131 75.9094 113.864C73.9901 100.455 73.2113 88.5868 81.4526 86.2863C85.5061 85.1527 89.64 86.6827 93.232 90.0834L97.4005 82.4075L102.707 84.4557L98.8847 97.898C100.152 100.402 101.167 103.025 101.914 105.731C102.266 106.99 102.55 108.246 102.768 109.487C104.446 109.539 106.089 109.975 107.572 110.762C111.371 112.841 112.944 115.925 112.366 120.453C112.681 120.737 112.977 121.042 113.253 121.365C118.194 127.152 116.868 134.652 104.352 142.464C102.333 143.725 99.3447 145.62 97.0415 147.083L100.805 155.75C100.86 155.967 100.845 156.195 100.762 156.403C100.68 156.611 100.534 156.788 100.345 156.908C100.157 157.028 99.935 157.086 99.7117 157.074C99.4883 157.061 99.2746 156.978 99.1008 156.838L88.1779 148.013C87.3023 147.306 86.7018 146.315 86.4805 145.212C86.2593 144.109 86.4313 142.963 86.9668 141.973L89.5831 135.162Z"
      fill="white"
    />
    <path
      d="M91.4917 95.1042L84.0098 95.2207"
      stroke="#000799"
      strokeWidth="0.616739"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M80.4844 104.768L92.2637 104.616C93.6125 104.599 94.9207 104.153 95.9983 103.341C97.0759 102.53 97.8667 101.396 98.2559 100.104L102.621 84.7551"
      stroke="#000799"
      strokeWidth="0.616739"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M112.265 120.695C112.265 120.695 113.641 129.939 98.6953 144.289"
      stroke="#000799"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M89.582 135.162L96.9171 125.453"
      stroke="#000799"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M97.0179 83.0449L96.4995 78.5923C96.4838 78.4515 96.5225 78.31 96.6078 78.1968C96.6931 78.0837 96.8185 78.0075 96.9583 77.984C97.085 77.9639 97.2148 77.9884 97.3255 78.0534C97.4362 78.1183 97.5209 78.2197 97.5652 78.3401L98.1293 79.8867C98.1522 79.9503 98.1938 80.0055 98.2485 80.0451C98.3033 80.0848 98.3687 80.107 98.4363 80.1089C98.5039 80.1109 98.5705 80.0924 98.6274 80.056C98.6844 80.0196 98.7291 79.9669 98.7556 79.9047L100.506 75.8651C100.549 75.7628 100.612 75.6701 100.692 75.5926C100.771 75.5151 100.865 75.4542 100.968 75.4137C101.072 75.3731 101.182 75.3537 101.293 75.3565C101.404 75.3593 101.513 75.3842 101.615 75.4299L102.945 76.0134C103.421 76.2223 103.807 76.5931 104.035 77.0599C104.264 77.5268 104.319 78.0594 104.192 78.5632L102.708 84.4529C102.708 84.4529 99.4693 85.7334 97.0179 83.0449Z"
      fill="#FFBCBC"
    />
    <path
      d="M101.681 75.8665L100.727 78.3568"
      stroke="#CC4646"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M102.76 76.2351L101.805 78.7309"
      stroke="#CC4646"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M103.594 76.8337L102.779 78.9596"
      stroke="#CC4646"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M80.9417 89.5555C75.8017 90.1209 72.4883 88.9402 71.9062 83.5966C71.6298 81.1764 72.256 78.7393 73.6648 76.7521C73.476 76.7953 73.285 76.8277 73.0925 76.8491C72.0269 76.9651 70.9526 76.7287 70.0342 76.1759C69.1158 75.6232 68.4039 74.7846 68.0075 73.7887C67.6112 72.7927 67.5522 71.6943 67.8397 70.6617C68.1272 69.629 68.7453 68.7191 69.5993 68.0712C70.4533 67.4234 71.4961 67.0733 72.568 67.0747C73.6399 67.0761 74.6818 67.4288 75.5342 68.0788C76.3865 68.7288 77.0022 69.6404 77.2871 70.6737C77.572 71.7071 77.5102 72.8054 77.1113 73.8003C78.063 73.3269 79.0909 73.0257 80.1476 72.9106C85.2876 72.3452 89.9286 76.213 90.5162 81.5497C91.1038 86.8864 86.0789 88.9901 80.9417 89.5555Z"
      fill="#000799"
    />
    <path
      d="M73.0606 78.1865C72.8301 78.1461 72.6098 78.0607 72.4123 77.9351C72.2149 77.8095 72.0441 77.6462 71.9099 77.4546C71.7756 77.2629 71.6805 77.0466 71.6299 76.8181C71.5794 76.5896 71.5744 76.3534 71.6152 76.123V76.123C71.656 75.8929 71.7418 75.673 71.8676 75.476C71.9934 75.279 72.1568 75.1087 72.3484 74.9749C72.54 74.8411 72.7561 74.7463 72.9844 74.696C73.2126 74.6457 73.4486 74.6409 73.6787 74.6818L75.4317 74.9936L75.1199 76.7466C75.0371 77.2103 74.7739 77.6222 74.3879 77.8921C74.0018 78.1621 73.5246 78.2679 73.0606 78.1865V78.1865Z"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M79.1344 73.4427C79.1564 73.2096 79.1322 72.9745 79.0632 72.7508C78.9941 72.5271 78.8817 72.3192 78.7323 72.139C78.5828 71.9588 78.3993 71.8099 78.1923 71.7007C77.9852 71.5915 77.7586 71.5242 77.5255 71.5026V71.5026C77.2924 71.4805 77.0572 71.5046 76.8335 71.5735C76.6097 71.6424 76.4017 71.7549 76.2215 71.9043C76.0412 72.0538 75.8923 72.2374 75.7831 72.4445C75.674 72.6517 75.6068 72.8783 75.5854 73.1115L75.4219 74.884L77.1943 75.0475C77.6643 75.0915 78.1325 74.9472 78.4963 74.6463C78.86 74.3455 79.0895 73.9126 79.1344 73.4427V73.4427Z"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M89.5717 91.3348C86.7779 92.8439 82.6427 92 80.4046 89.1923L79.6771 89.8284C79.4603 90.0177 79.2074 90.1611 78.9337 90.2499C78.66 90.3387 78.3711 90.3712 78.0845 90.3453C77.7978 90.3194 77.5194 90.2357 77.2661 90.0992C77.0127 89.9627 76.7896 89.7763 76.6103 89.5512L76.1752 89.0191C75.8 88.5611 75.6158 87.9761 75.661 87.3858C75.7062 86.7955 75.9772 86.2453 76.4177 85.8498C76.8025 85.5097 77.2983 85.3219 77.8118 85.3218C78.2202 85.3226 78.6199 85.4404 78.9634 85.6613C79.3852 85.718 79.8123 85.724 80.2356 85.6793C83.1125 85.3786 85.1801 82.8689 84.8558 80.071C84.757 79.2198 84.4405 78.4084 83.937 77.7151C84.6976 78.2871 85.608 78.6257 86.5574 78.6896C87.5069 78.7536 88.4545 78.5402 89.2849 78.0754C89.7935 78.6879 90.2549 79.5776 90.6928 80.811C92.2643 85.1998 93.5282 89.1979 89.5717 91.3348Z"
      fill="#FFBCBC"
    />
    <path
      d="M78.9213 88.0782C78.9213 88.0782 78.4502 86.6411 76.8828 87.6625"
      stroke="#CC4646"
      strokeWidth="0.642172"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M90.1386 80.2178C88.3371 80.4617 87.5749 82.5432 87.9213 85.1305C88.0945 86.4151 88.6142 87.5307 89.3071 88.2984"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M85.2763 86.1809C85.2209 86.7616 84.5391 87.1718 83.752 87.0983"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M90.444 83.311C90.3941 83.8321 89.7815 84.2007 89.0762 84.1328"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M73.9062 136.479L76.5517 131.938C77.0185 131.137 77.6671 130.457 78.4452 129.953C79.2233 129.449 80.109 129.135 81.0308 129.036C81.9527 128.937 82.8848 129.057 83.7518 129.385C84.6189 129.713 85.3967 130.241 86.0223 130.925L95.4791 141.267L94.9247 141.625C94.4033 141.965 93.7964 142.151 93.1738 142.162C92.5511 142.173 91.938 142.009 91.4048 141.687L89.078 140.28C88.9618 140.211 88.8256 140.181 88.691 140.198C88.5563 140.214 88.4309 140.274 88.3344 140.369C88.2379 140.465 88.1758 140.589 88.1579 140.724C88.14 140.858 88.1673 140.995 88.2355 141.112L93.6623 150.29C94.1515 151.121 93.1274 152.001 92.3804 151.399L73.9062 136.479Z"
      fill="#FFBCBC"
    />
    <path
      d="M80.7146 129.077L77.961 123.396C77.961 123.396 70.4777 123.95 67.2266 130.219L67.8031 130.961C68.9953 132.493 70.3891 133.857 71.9466 135.015L73.9061 136.475L80.7146 129.077Z"
      fill="#CC4646"
    />
    <path
      d="M76.3322 128.825C76.8274 128.825 77.2288 128.424 77.2288 127.929C77.2288 127.433 76.8274 127.032 76.3322 127.032C75.837 127.032 75.4355 127.433 75.4355 127.929C75.4355 128.424 75.837 128.825 76.3322 128.825Z"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M112.578 76.7258C113.887 83.6687 113.225 89.9797 108.592 95.315L100.377 104.775"
      stroke="white"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M101.475 103.81L105.746 99.2367C105.746 99.2367 113.171 97.5031 116.149 108.231L109.376 112.101L101.475 103.81Z"
      fill="#CC4646"
    />
    <path
      d="M109.569 111.522C109.2 112.874 108.365 114.016 107.341 114.975C103.574 118.495 98.7777 122.08 96.3928 123.497C95.8093 123.842 95.237 123.003 95.7414 122.549L99.2059 119.021L92.2256 122.042C91.4219 122.389 90.7511 121.349 91.4039 120.757L92.2173 120.031L89.9723 120.862C88.9607 121.235 88.1555 119.961 88.9288 119.208L90.0624 118.099L89.0494 118.472C88.0128 118.854 87.288 117.439 88.204 116.821L95.2245 112.089L95.3423 112.008C95.4085 111.961 95.4582 111.894 95.4845 111.816C95.5109 111.739 95.5126 111.656 95.4895 111.578C95.4664 111.499 95.4195 111.43 95.3554 111.38C95.2913 111.329 95.2131 111.3 95.1317 111.296C95.0929 111.295 95.0542 111.299 95.0166 111.309L91.7822 112.122C91.4852 112.196 91.1751 112.202 90.8756 112.138C90.5761 112.075 90.2951 111.944 90.0541 111.755L89.6467 111.435L98.9315 104.984C100.334 103.919 101.228 103.275 103.104 103.459C107.083 103.863 109.978 107.478 109.568 111.521L109.569 111.522Z"
      fill="#FFBCBC"
    />
    <path
      d="M90.0625 118.095L96.1614 114.632"
      stroke="#CC4646"
      strokeWidth="0.825286"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M92.2188 120.022L97.7162 116.953"
      stroke="#CC4646"
      strokeWidth="0.825286"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M106.305 102.76C106.8 102.76 107.201 102.358 107.201 101.863C107.201 101.368 106.8 100.966 106.305 100.966C105.81 100.966 105.408 101.368 105.408 101.863C105.408 102.358 105.81 102.76 106.305 102.76Z"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M153.141 118.727L146.259 121.903L142.518 111.904L148.238 108.197C149.297 107.511 151.585 109.492 152.937 112.418C154.29 115.345 154.287 118.196 153.141 118.727Z"
      fill="#000799"
    />
    <path
      d="M142.511 111.903H134.335C134.076 111.903 133.827 112.003 133.64 112.182C133.453 112.361 133.342 112.605 133.33 112.863C133.32 113.097 133.391 113.327 133.532 113.514C133.673 113.701 133.874 113.833 134.102 113.887L137.022 114.58C137.142 114.609 137.25 114.672 137.333 114.763C137.416 114.854 137.47 114.968 137.488 115.089C137.506 115.211 137.487 115.335 137.434 115.446C137.38 115.557 137.295 115.649 137.188 115.711L130.241 119.73C130.067 119.83 129.915 119.964 129.793 120.124C129.67 120.283 129.581 120.465 129.529 120.659C129.478 120.854 129.465 121.056 129.492 121.255C129.519 121.454 129.585 121.646 129.687 121.82L131.023 124.105C131.501 124.923 132.253 125.545 133.147 125.861C134.04 126.176 135.016 126.165 135.902 125.829L146.25 121.9C146.25 121.9 147.887 115.784 142.511 111.903Z"
      fill="#FFBCBC"
    />
    <path
      d="M130.545 122.246L134.852 119.993"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M131.396 123.735L135.711 121.478"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M132.654 125.121L136.329 123.197"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M112.581 55.6185L107.66 51.9725C107.539 51.8821 107.439 51.7681 107.364 51.6376C107.289 51.5071 107.241 51.3626 107.224 51.2131C107.207 51.0637 107.22 50.9122 107.263 50.7681C107.307 50.6239 107.379 50.49 107.475 50.3746L109.321 48.1962C109.69 47.8743 110.141 47.6604 110.624 47.5778C111.107 47.4952 111.603 47.547 112.058 47.7278L118.337 50.2222C118.875 50.4355 119.331 50.8134 119.641 51.3017C119.952 51.79 120.099 52.3638 120.064 52.9411L119.914 55.3843L119.443 54.5972C119.295 54.3491 119.094 54.1366 118.854 53.9746C118.615 53.8126 118.342 53.7051 118.057 53.6596C117.771 53.6142 117.479 53.6319 117.201 53.7115C116.924 53.7911 116.666 53.9307 116.448 54.1205L114.992 55.394C114.664 55.6787 114.255 55.8534 113.822 55.8937C113.39 55.9339 112.955 55.8377 112.581 55.6185Z"
      fill="#FF9A9A"
    />
    <path
      d="M108.449 52.1872L111.196 48.9805"
      stroke="#CC4646"
      strokeWidth="0.825286"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M110.283 53.4692L113.03 50.2625"
      stroke="#CC4646"
      strokeWidth="0.825286"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M112.062 54.7385L114.809 51.5317"
      stroke="#CC4646"
      strokeWidth="0.825286"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);
