import { useContext } from 'react';

import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import AnalysisAndPerformanceIcon from '../../../../assets/icons/why-register-icons-set/AnalysisAndPerformanceIcon';
import ImpactAndOverviewIcon from '../../../../assets/icons/why-register-icons-set/ImpactAndOverviewIcon';
import SecurityAndPrivacyIcon from '../../../../assets/icons/why-register-icons-set/SecurityAndPrivacyIcon';
import UsabilityIcon from '../../../../assets/icons/why-register-icons-set/UsabilityIcon';
import { DonationFlowContext } from '../../../../providers/donation-flow-provider';
import { textColor } from '../../../../styles/variables';

export const AnonymousTabContent = () => {
  const { t } = useTranslation();
  const { handleNextStep } = useContext(DonationFlowContext);

  const advices = [
    {
      index: 0,
      title: t('usability'),
      content: t('usabilityContent'),
      icon: UsabilityIcon,
    },
    {
      index: 1,
      title: t('securityAndPrivacy'),
      content: t('securityAndPrivacyContent'),
      icon: SecurityAndPrivacyIcon,
    },
    {
      index: 2,
      title: t('impactAndOverview'),
      content: t('impactAndOverviewContent'),
      icon: ImpactAndOverviewIcon,
    },
    {
      index: 3,
      title: t('analysisAndPerformance'),
      content: t('analysisAndPerformanceContent'),
      icon: AnalysisAndPerformanceIcon,
    },
  ];

  return (
    <Stack role="tabpanel" gap={4}>
      <Stack gap={0.5}>
        <Typography variant="h3">{t('whyRegister')}</Typography>
        <Typography variant="paragraphL" color="textSecondary">
          {t('whyRegisterParagraph')}
        </Typography>
      </Stack>
      {advices.map((advice) => (
        <Stack key={advice.index} flexDirection={'row'} gap={2}>
          <Stack width="fit-content">
            <advice.icon />
          </Stack>
          <Stack width="fit-content" overflow="auto">
            <Typography variant="h4" overflow="hidden" textOverflow="ellipsis" title={advice.title}>
              {advice.title}
            </Typography>
            <Typography variant="paragraphM" color={textColor}>
              {advice.content}
            </Typography>
          </Stack>
        </Stack>
      ))}
      <Button
        variant="contained"
        size="large"
        onClick={handleNextStep}
        data-testid="ContinueAnonymouslyButton"
      >
        {t('continueWithoutRegistration')}
      </Button>
    </Stack>
  );
};

export default AnonymousTabContent;
