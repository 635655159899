import { Grid, Typography } from '@mui/material';
import { type ReactNode } from 'react';

interface OverviewRowGridItemProps {
  date: string;
  total: string;
  content: ReactNode;
  status?: 'pending' | 'succeeded';
}

export const OverviewRowGridItem = ({ date, total, content, status }: OverviewRowGridItemProps) => {
  return (
    <Grid
      container
      gap={{ mobile: 0.5, tablet: 2 }}
      flexWrap={{ mobile: 'wrap', tablet: 'nowrap' }}
      alignSelf={'stretch'}
      sx={{ opacity: `${status === 'pending' ? '50%' : 'initial'}` }}
    >
      <Grid item mobile={12} tablet={4}>
        <Typography variant="h6" lineHeight={'150%'}>
          {date}
        </Typography>
      </Grid>
      <Grid item flex={'1 0 0'} alignSelf={'stretch'} mobile={12} tablet={6}>
        {content}
      </Grid>

      <Grid
        item
        container
        mobile={12}
        tablet={2}
        justifyContent={{ mobile: 'flex-start', tablet: 'flex-end' }}
      >
        <Typography variant="h6" lineHeight={'150%'}>
          {total}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default OverviewRowGridItem;
