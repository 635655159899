import { FormControl, OutlinedInput } from '@mui/material';

interface EditDonationPaymentDateProps {
  value: string;
  handleChange: (newValue: string) => void;

  min: string;
}

export const EditDonationPaymentDate = ({
  value,
  handleChange,
  min,
}: EditDonationPaymentDateProps) => {
  return (
    <FormControl>
      <OutlinedInput
        name="nextDonationDate"
        type="date"
        value={value}
        inputProps={{ min }}
        onChange={(ev) => {
          handleChange(ev.target.value);
        }}
        error={value < min}
      />
    </FormControl>
  );
};

export default EditDonationPaymentDate;
