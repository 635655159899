import { Grid, Skeleton, Stack } from '@mui/material';

export const SelectCharitiesSkeleton = () => {
  return (
    <Stack px={{ mobile: '20px', tablet: '40px', laptop: '110px', desktop: '220px' }} width="100%">
      <Skeleton variant="text" sx={{ fontSize: '38px' }} />
      <Grid container spacing={{ mobile: '10px', tablet: 2, laptop: 3 }} justifyContent={'stretch'}>
        <Grid item mobile={6} tablet={4}>
          <Skeleton variant="rounded" height={200} width={'100%'} />
        </Grid>
        <Grid item mobile={6} tablet={4}>
          <Skeleton variant="rounded" height={200} width={'100%'} />
        </Grid>
        <Grid item mobile={6} tablet={4}>
          <Skeleton variant="rounded" height={200} width={'100%'} />
        </Grid>
        <Grid item mobile={6} tablet={4}>
          <Skeleton variant="rounded" height={200} width={'100%'} />
        </Grid>
        <Grid item mobile={6} tablet={4}>
          <Skeleton variant="rounded" height={200} width={'100%'} />
        </Grid>
        <Grid item mobile={6} tablet={4}>
          <Skeleton variant="rounded" height={200} width={'100%'} />
        </Grid>
        <Grid item mobile={6} tablet={4}>
          <Skeleton variant="rounded" height={200} width={'100%'} />
        </Grid>
        <Grid item mobile={6} tablet={4}>
          <Skeleton variant="rounded" height={200} width={'100%'} />
        </Grid>
        <Grid item mobile={6} tablet={4}>
          <Skeleton variant="rounded" height={200} width={'100%'} />
        </Grid>
      </Grid>
    </Stack>
  );
};

export default SelectCharitiesSkeleton;
