import { useState, useContext } from 'react';
import { Stack, InputAdornment, IconButton, Alert, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SocialButton from '../../../components/social-button/SocialButton';
import { AlertTriangleIcon, EyeClosedIcon, EyeIcon, MailIcon } from '../../../assets/icons';
import InputWithLabel from '../../../components/inputWithLabel/InputWithLabel';
import { AuthenticationContext } from '../../../providers/authentication-provider';
import PasswordRequirements from './PasswordRequirements';
import { errorColor } from '../../../styles/variables';
import { Form, Formik } from 'formik';
import LoadingButton from '../../../components/loading-button/LoadingButton';
import { registerSchema } from '../RegisterPage.utils';
import CheckboxLabeled from '../../../components/checkbox-labeled/CheckboxLabeled';

export const RegisterWithEmailAddress = () => {
  const { t } = useTranslation();
  const {
    invalidPassword,
    emailAlreadyExists,
    handleRegisterAccount,
    loadingAuthentication,
    handleCheckPasswordRequirements,
    socialAccountExists,
  } = useContext(AuthenticationContext);

  const [isRegisteringWithEmail, setIsRegisteringWithEmail] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return !isRegisteringWithEmail ? (
    <SocialButton
      onClick={() => {
        setIsRegisteringWithEmail(true);
      }}
      icon={<MailIcon />}
      label={t('registerWithEmail')}
    />
  ) : (
    <Formik
      initialValues={{
        name: '',
        email: '',
        password: '',
        newsletter: false,
      }}
      validationSchema={registerSchema(t)}
      onSubmit={handleRegisterAccount}
      validateOnChange={false}
    >
      {({ handleChange, errors, values, handleBlur, setErrors }) => (
        <Form>
          <Stack width={'100%'} gap={3} pt={1}>
            <InputWithLabel
              fullWidth
              name="email"
              labelName={t('emailAddress')}
              value={values.email}
              onChange={(e) => {
                setErrors({});
                handleChange(e);
              }}
              onBlur={handleBlur}
              error={!!errors.email}
              errorMessage={errors.email}
            />
            <InputWithLabel
              fullWidth
              labelName={t('name')}
              name="name"
              value={values.name}
              onChange={(e) => {
                setErrors({});
                handleChange(e);
              }}
              onBlur={handleBlur}
              error={!!errors.name}
              errorMessage={errors.name}
            />
            <InputWithLabel
              fullWidth
              labelName={t('password')}
              name="password"
              value={values.password}
              type={showPassword ? 'text' : 'password'}
              onChange={(e) => {
                setErrors({});
                handleCheckPasswordRequirements(e.target.value);
                handleChange(e);
              }}
              onBlur={handleBlur}
              error={!!errors.password}
              errorMessage={errors.password}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    onMouseDown={handleMouseDownPassword}
                    edge="end"
                    sx={{ mr: 0 }}
                  >
                    {showPassword ? <EyeClosedIcon /> : <EyeIcon />}
                  </IconButton>
                </InputAdornment>
              }
            />
            {values.password.length ? <PasswordRequirements /> : null}
            <CheckboxLabeled
              name="newsletter"
              value={values.newsletter}
              label={t('stayInformedByEmail')}
              onChange={(e) => {
                setErrors({});
                handleChange(e);
              }}
            />

            <Box>
              <LoadingButton
                size="large"
                variant="contained"
                data-testid="RegisterButton"
                type="submit"
                fullWidth
                loading={loadingAuthentication}
                disabled={
                  !(Object.keys(errors).length === 0) || invalidPassword || loadingAuthentication
                }
              >
                {t('register')}
              </LoadingButton>
            </Box>
            {emailAlreadyExists && (
              <Alert icon={<AlertTriangleIcon color={errorColor} />} severity={'error'}>
                {t('emailExists')}
              </Alert>
            )}

            {socialAccountExists && (
              <Alert icon={<AlertTriangleIcon color={errorColor} />} severity={'error'}>
                {t('emailExists')}. {t('socialAccountExists')}
              </Alert>
            )}
          </Stack>
        </Form>
      )}
    </Formik>
  );
};
