import { Box, Stack } from '@mui/material';
import { type TCharityLogo } from '../../models/Charities';

interface LogoTickerProps {
  images: TCharityLogo[];
  handleClick?: (id: string) => void;
  direction?: 'left' | 'right';
}

const Gradient = ({ alignment }: { alignment: 'left' | 'right' }) => (
  <Box
    sx={{
      position: 'absolute',
      top: '0',
      ...(alignment === 'left' && { left: 0 }),
      ...(alignment === 'right' && { right: 0 }),
      width: { mobile: '50px', laptop: '148px' },
      height: '100px',
      zIndex: 1,
      background:
        alignment === 'left'
          ? 'linear-gradient(90deg, #FFF 0%, rgba(255, 255, 255, 0.00) 100%)'
          : 'linear-gradient(90deg, rgba(255, 255, 255, 0.00) 0%, #FFF 100%)',
    }}
  />
);

export const LogoTicker = ({ images, handleClick, direction = 'left' }: LogoTickerProps) => {
  return (
    <Box
      sx={{
        height: '100px',
        position: 'relative',
        overflow: 'hidden',
        padding: '5px 0 6px 24px',
        display: 'flex',
        width: '100%',
      }}
    >
      <Gradient alignment="left" />
      <Gradient alignment="right" />

      <Stack
        direction={'row'}
        alignItems={'center'}
        sx={{
          animation: `${direction === 'left' ? 'carousel' : 'carouselRight'} 35s linear infinite`,
        }}
      >
        {images.map((image) => (
          <Box
            key={`${image.id}`}
            margin={{ mobile: '12px', laptop: '48px' }}
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: handleClick ? 'pointer' : 'default',
              height: '100%',
            }}
            onClick={() => handleClick && handleClick(image.id)}
          >
            <img src={image.logo} alt={image.name} height="auto" width="133.33px" />
          </Box>
        ))}
      </Stack>
      <Stack
        direction={'row'}
        alignItems={'center'}
        sx={{
          animation: `${direction === 'left' ? 'carousel' : 'carouselRight'} 35s linear infinite`,
        }}
      >
        {images.map((image) => (
          <Box
            key={`${image.id}-2`}
            margin={{ mobile: '12px', laptop: '48px' }}
            sx={{ display: 'flex', alignItems: 'center', cursor: 'pointer', height: '100%' }}
            onClick={() => handleClick && handleClick(image.id)}
          >
            <img src={image.logo} alt={image.name} height="auto" width="133.33px" />
          </Box>
        ))}
      </Stack>
    </Box>
  );
};

export default LogoTicker;
