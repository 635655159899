import { Skeleton, Stack } from '@mui/material';

export const CheckExistingBundleSkeleton = () => {
  return (
    <Stack
      px={{ mobile: '20px', tablet: '40px', laptop: 0 }}
      mx={{ mobile: '0', laptop: '32px' }}
      width={{ mobile: '100%', laptop: '70%' }}
      maxWidth={'1216px'}
      gap={{ mobile: 3, tablet: 4 }}
      mt={2}
    >
      <Skeleton variant="rounded" width={'100%'} height={'44px'} />
      <Stack gap={2}>
        <Skeleton
          variant="rounded"
          width={'100%'}
          sx={{ height: { mobile: 166, tablet: 101, laptop: 135 } }}
        />
        <Skeleton
          variant="rounded"
          width={'100%'}
          sx={{ height: { mobile: 166, tablet: 101, laptop: 135 } }}
        />
      </Stack>
    </Stack>
  );
};

export default CheckExistingBundleSkeleton;
