import { useTranslation } from 'react-i18next';
import { useState, useContext } from 'react';
import { Box, Button, Stack, Container, Typography, IconButton } from '@mui/material';

import { Link, useNavigate } from 'react-router-dom';
import LogoLight from '../../assets/LogoLightSVG';
import { MenuIcon } from '../../assets/icons';
import { darkBlue, lightNavbarShadow, white } from '../../styles/variables';
import NavbarMenuDrawer from './NavbarMenuDrawer';
import AuthenticatedUserMenu from './AuthenticatedUserMenu';
import LogoDark from '../../assets/LogoDarkSVG';
import { AuthenticationContext } from '../../providers/authentication-provider';
import { LanguageContext } from '../../providers/language-selector-provider';
import NestedMenu from '../country-selector/CountrySelector';
import { config } from '../../api/config';

const Navbar = ({ lightMode = false }: { lightMode?: boolean }) => {
  const navigate = useNavigate();
  const [menuDrawerOpen, setMenuDrawerOpen] = useState(false);
  const { authenticatedUser } = useContext(AuthenticationContext);
  const { isEnglish } = useContext(LanguageContext);

  const { t } = useTranslation();

  const handleDonate = () => {
    if (isEnglish) {
      navigate('/donate', { state: { from: `/my-giftshift` } });
    } else {
      navigate('/doneren', { state: { from: `/mijn-giftshift` } });
    }
  };

  const menuItems = [
    {
      key: 1,
      text: t('home'),
      linkEn: `${config.FE_APP_URL}/en`,
      linkNl: `${config.FE_APP_URL}`,
    },
    {
      key: 2,
      text: t('charities'),
      linkEn: `${config.FE_APP_URL}/en/charities`,
      linkNl: `${config.FE_APP_URL}/goede-doelen`,
    },
    {
      key: 3,
      text: 'Partners',
      linkEn: `${config.FE_APP_URL}/en/partners`,
      linkNl: `${config.FE_APP_URL}/partners`,
    },
    {
      key: 4,
      text: t('aboutUs'),
      linkEn: `${config.FE_APP_URL}/en/about-us`,
      linkNl: `${config.FE_APP_URL}/over-ons`,
    },
    {
      key: 5,
      text: 'Contact',
      linkEn: `${config.FE_APP_URL}/en/contact`,
      linkNl: `${config.FE_APP_URL}/contact`,
    },
  ];

  return (
    <Box
      bgcolor={lightMode ? 'common.white' : 'primary.main'}
      sx={{
        boxShadow: lightMode ? lightNavbarShadow : 'none',
        position: 'sticky',
        top: 0,
        zIndex: 100,
      }}
    >
      <Container
        maxWidth="desktop"
        sx={{
          px: {
            mobile: '20px',
            tablet: 5,
            laptop: '112px',
          },
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          py={{ mobile: 2, tablet: lightMode ? 2 : 3, laptop: lightMode ? 2 : 4 }}
          sx={{ position: { mobile: 'relative', laptop: 'static' } }}
          alignItems="center"
        >
          <Box display={{ mobile: 'flex', laptop: 'none' }}>
            <IconButton
              sx={{ p: 0 }}
              onClick={() => {
                setMenuDrawerOpen(true);
              }}
            >
              <MenuIcon color={lightMode ? darkBlue : white} />
            </IconButton>
            <NavbarMenuDrawer
              open={menuDrawerOpen}
              onClose={() => {
                setMenuDrawerOpen(false);
              }}
              menuItems={menuItems}
            />
          </Box>

          <Stack
            direction="row"
            gap={8}
            alignItems={'center'}
            sx={{
              position: { mobile: 'absolute', laptop: 'static' },
              top: {
                mobile: '16px',
              },
              left: 'calc(50% - 49px)',
            }}
          >
            <Link
              to={isEnglish ? `${config.FE_APP_URL}/en` : `${config.FE_APP_URL}`}
              data-testid="GiftShiftLogo"
            >
              <Box height={{ mobile: '33px', tablet: '40px', laptop: '49px' }}>
                {lightMode ? <LogoDark height="100%" /> : <LogoLight height="100%" />}
              </Box>
            </Link>
            <Stack
              direction="row"
              gap={4}
              alignItems={'center'}
              display={{ mobile: 'none', laptop: 'flex' }}
            >
              {menuItems.map((menuItem) => (
                <Link
                  key={menuItem.key}
                  to={isEnglish ? menuItem.linkEn : menuItem.linkNl}
                  style={{ textDecoration: 'none' }}
                >
                  <Typography color={lightMode ? darkBlue : 'white'} variant="menuItem">
                    {`${menuItem.text.slice(0, 1).toUpperCase()}${menuItem.text.slice(1)}`}
                  </Typography>
                </Link>
              ))}

              <NestedMenu />
            </Stack>
          </Stack>

          <Stack direction="row" gap={2} alignItems={'center'}>
            {authenticatedUser ? (
              <AuthenticatedUserMenu lightMode={lightMode} handleDonate={handleDonate} />
            ) : (
              <>
                <Button
                  variant="outlined"
                  color={lightMode ? 'secondary' : 'light'}
                  onClick={() => {
                    navigate('/login');
                  }}
                  sx={{ display: { mobile: 'none', laptop: 'flex' } }}
                  data-testid="LoginButton"
                >
                  {t('login')}
                </Button>
                <Button
                  variant="contained"
                  color={lightMode ? 'primary' : 'secondary'}
                  onClick={handleDonate}
                  data-testid="DonateButton"
                >
                  {t('donate')}
                </Button>
              </>
            )}
          </Stack>
        </Stack>
      </Container>
    </Box>
  );
};

export default Navbar;
