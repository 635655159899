import {
  Badge,
  Button,
  Divider,
  IconButton,
  Menu,
  Stack,
  Typography,
  useMediaQuery,
  type Theme,
} from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ChevronDownIcon, LogoutIcon, UserIcon } from '../../assets/icons';
import { AuthenticationContext } from '../../providers/authentication-provider';
import { LanguageContext } from '../../providers/language-selector-provider';
import { PublicContext } from '../../providers/public-provider';
import { darkBlue, errorColor, shadowMD, white } from '../../styles/variables';
import UserMenuDrawer from './UserMenuDrawer';

type AuthenticatedUserMenu = {
  lightMode?: boolean;
  handleDonate: () => void;
};

const AuthenticatedUserMenu = ({ lightMode = false, handleDonate }: AuthenticatedUserMenu) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openUserDrawer, setOpenUserDrawer] = useState(false);
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('tablet'));
  const isMobileOrTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('laptop'));

  const { t } = useTranslation();

  const { authenticatedUser, handleLogout } = useContext(AuthenticationContext);
  const { currentBundle } = useContext(PublicContext);
  const { isEnglish } = useContext(LanguageContext);

  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onLogout = () => {
    handleLogout();
    handleClose();
  };

  const renderMobileVersion = isMobile ? (
    <>
      <IconButton
        onClick={() => {
          setOpenUserDrawer(true);
        }}
        sx={{ p: { mobile: 0, tablet: 1 } }}
      >
        <UserIcon color={lightMode ? darkBlue : white} />
      </IconButton>
      <UserMenuDrawer
        open={openUserDrawer}
        onClose={() => {
          setOpenUserDrawer(false);
        }}
      />
    </>
  ) : (
    <>
      <Button
        variant="text"
        color={lightMode ? 'info' : 'light'}
        startIcon={<UserIcon color={lightMode ? darkBlue : white} />}
        onClick={() => {
          setOpenUserDrawer(true);
        }}
      >
        {authenticatedUser?.name}
      </Button>
      <UserMenuDrawer
        open={openUserDrawer}
        onClose={() => {
          setOpenUserDrawer(false);
        }}
      />
    </>
  );

  return isMobileOrTablet ? (
    renderMobileVersion
  ) : (
    <>
      <Button
        variant="text"
        color={lightMode ? 'info' : 'light'}
        aria-controls={open ? 'account-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        startIcon={<UserIcon color={lightMode ? darkBlue : white} />}
        endIcon={<ChevronDownIcon color={lightMode ? darkBlue : white} />}
        onClick={handleClick}
      >
        {authenticatedUser?.name}
      </Button>
      <Menu
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              borderRadius: '8px',
              width: '260px',
              overflow: 'visible',
              boxShadow: shadowMD,
              padding: '22px 16px',
              mt: 1,
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Stack gap={0.5} mb={2}>
          <Typography variant="h4">{authenticatedUser?.name}</Typography>
          <Typography variant="paragraphXS">{authenticatedUser?.email}</Typography>
        </Stack>
        <Stack gap={2}>
          <Divider />

          <Stack gap={2}>
            <Link
              to={isEnglish ? '/my-giftshift#my-bundle' : '/mijn-giftshift#my-bundle'}
              style={{ textDecoration: 'none' }}
            >
              <Typography
                component={'p'}
                variant={'paragraphM'}
                color={darkBlue}
                onClick={handleClose}
              >
                {t('myBundle')}
              </Typography>
            </Link>
            <Link
              to={isEnglish ? '/my-giftshift#my-details' : '/mijn-giftshift#my-details'}
              style={{ textDecoration: 'none' }}
            >
              <Typography variant={'paragraphM'} color={darkBlue} onClick={handleClose}>
                {t('myDetails')}
              </Typography>
            </Link>
            <Link
              to={isEnglish ? '/my-giftshift#overview' : '/mijn-giftshift#overview'}
              style={{ textDecoration: 'none' }}
            >
              <Typography variant={'paragraphM'} color={darkBlue} onClick={handleClose}>
                {t('donationOverview')}
              </Typography>
            </Link>
            <Badge badgeContent={currentBundle.length || undefined} color="error">
              <Button
                fullWidth
                variant="contained"
                onClick={handleDonate}
                data-testid="DonateButton"
              >
                {t('donate')}
              </Button>
            </Badge>
            <Button
              variant="outlined"
              color="error"
              onClick={onLogout}
              endIcon={<LogoutIcon color={errorColor} />}
              data-testid="LogoutButton"
            >
              {t('logout')}
            </Button>
          </Stack>
        </Stack>
      </Menu>
    </>
  );
};

export default AuthenticatedUserMenu;
