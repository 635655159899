import { type NavigateFunction } from 'react-router-dom';
import { getCookie } from '../../common/utils';

export const onVisibilityChange = (
  handleLogout: () => void,
  isEnglish: boolean,
  navigate: NavigateFunction
) => {
  const accessTokenCookie = getCookie('access_token');
  const refreshTokenCookie = getCookie('refresh_token');

  if (document.visibilityState === 'visible' && (!accessTokenCookie || !refreshTokenCookie)) {
    handleLogout();
    navigate('/login');
  }
};
