export const Illu2 = () => (
  <svg
    width="247"
    height="194"
    viewBox="0 0 247 194"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.18664 27.315C-0.702214 19.1554 12.4255 9.14707 30.5061 4.96194C43.2402 2.01434 55.0348 2.74466 61.5578 6.27708C62.5705 6.82925 63.4501 7.59651 64.1345 8.52496C64.819 9.4534 65.2919 10.5205 65.5198 11.6512L72.5874 46.1688L127.476 90.0573L86.4926 146.385L54.2352 107.78L62.8743 134.862C63.6352 137.349 63.8376 139.974 63.4671 142.549C63.0965 145.124 62.162 147.586 60.7305 149.758L59.3696 151.824C59.1502 152.157 58.8498 152.429 58.4964 152.615C58.1431 152.8 57.7484 152.893 57.3495 152.884C56.9506 152.875 56.5605 152.765 56.2157 152.564C55.871 152.363 55.5829 152.078 55.3785 151.735L25.8068 102.153C21.1972 94.4279 19.404 85.3456 20.732 76.4487L22.862 62.1749L1.18664 27.315Z"
      fill="#FFBCBC"
    />
    <path
      d="M50.4863 142.736C52.493 141.904 54.7934 142.166 56.4065 143.624C58.7 145.703 58.7346 149.399 56.4827 151.892"
      stroke="#CC4646"
      strokeWidth="1.30723"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M222.14 62.1749L225.246 68.5094C229.913 78.027 230.906 88.9317 228.034 99.1357L207.505 172.094C207.396 172.481 207.203 172.839 206.94 173.143C206.677 173.447 206.35 173.69 205.983 173.854C205.616 174.017 205.217 174.098 204.816 174.091C204.414 174.083 204.018 173.987 203.658 173.81L201.498 172.747C199.266 171.648 197.382 169.952 196.056 167.846C194.731 165.741 194.015 163.309 193.989 160.821L193.817 144.293L123.963 191.688C122.935 192.386 121.703 192.721 120.463 192.64C119.223 192.559 118.046 192.066 117.118 191.24C116.19 190.414 115.564 189.301 115.34 188.079C115.116 186.857 115.306 185.595 115.881 184.493L120.213 176.178L106.141 187.637C104.936 188.617 103.416 189.124 101.864 189.064C100.312 189.004 98.8354 188.381 97.7098 187.311C96.5842 186.241 95.8868 184.798 95.7481 183.251C95.6094 181.704 96.0389 180.16 96.9562 178.907L104.972 167.975L96.8246 175.681C95.7774 176.679 94.3974 177.255 92.9509 177.296C91.5044 177.337 90.0939 176.841 88.9916 175.904C87.8894 174.966 87.1735 173.653 86.9821 172.219C86.7907 170.784 87.1375 169.33 87.9554 168.136L95.9002 157.269L88.2201 161.694C87.1319 162.265 85.8824 162.451 84.6748 162.223C83.4671 161.994 82.3724 161.363 81.5685 160.434C80.7647 159.504 80.2989 158.33 80.247 157.102C80.1952 155.874 80.5602 154.664 81.2828 153.67L145.268 65.6047C148.878 60.6328 153.559 56.5353 158.965 53.615C164.371 50.6947 170.364 49.0261 176.501 48.7326L180.283 17.6393C183.561 10.8488 200.208 7.80008 214.957 11.0775C231.882 14.8441 243.382 27.2748 241.011 34.8842L240.986 34.9618L222.14 62.1749Z"
      fill="#FFBCBC"
    />
    <path
      d="M193.815 144.289L195.862 116.334L190.889 107.779"
      stroke="#CC4646"
      strokeWidth="1.3759"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M222.14 62.1749C222.14 62.1749 219.396 58.9335 214.777 56.2644"
      stroke="#CC4646"
      strokeWidth="1.3759"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M206.68 172.93C204.363 171.854 202.779 169.669 202.779 167.147C202.779 163.56 205.987 160.653 209.945 160.653"
      stroke="#CC4646"
      strokeWidth="1.3759"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M190.887 107.779C187.946 116.904 183.265 124.851 177.505 131.178C176.634 132.137 175.739 133.058 174.818 133.94L167.589 139.78L122.539 176.175L77.4892 139.78L70.2512 133.932C69.3338 133.05 68.4395 132.131 67.5683 131.174C61.8102 124.846 57.1276 116.9 54.1869 107.775C45.1016 79.5736 56.1049 50.7932 78.7628 43.4928C93.9526 38.5995 110.523 46.2464 122.533 59.23C134.546 46.2464 151.116 38.5995 166.305 43.4928C188.969 50.7974 199.972 79.5778 190.887 107.779Z"
      fill="#FFDDDD"
    />
    <path
      d="M81.541 176.591V42.5227"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M75.4199 176.591V44.6486"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M87.8477 176.591V42.5227"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M94.1562 176.591V42.5227"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M100.463 176.591V44.6486"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M106.77 176.591V46.7743"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M113.076 176.591V51.0273"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M119.383 176.591V51.0273"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M56.5859 176.591V51.0273"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M62.8926 176.591V51.0273"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M69.1992 176.591V46.7743"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M125.511 105.761H50.1797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M125.511 99.4543H50.1797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M125.511 93.1461H50.1797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M125.511 86.8394H50.1797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M125.511 80.5326H50.1797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M125.511 74.2257H50.1797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M125.511 67.9189H50.1797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M125.511 61.6122H50.5352"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M125.511 55.304H50.1797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M125.511 156.254H50.1797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M125.511 162.628H50.1797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M125.511 149.948H50.1797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M125.511 143.641H50.1797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M125.511 137.334H50.1797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M125.511 131.027H50.1797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M125.511 124.719H50.1797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M125.511 118.412H50.1797"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M125.511 112.105H50.5352"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M150.93 176.591V42.5227"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M144.809 176.591V44.6486"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M157.236 176.591V42.5227"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M163.545 176.591V42.5227"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M169.852 176.591V44.6486"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M176.158 176.591V47.1998"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M182.465 176.591V51.0273"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M188.773 176.591V51.0273"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M125.977 176.591V51.0273"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M132.283 176.591V51.0273"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M138.59 176.591V47.1998"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M194.901 105.761H119.57"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M194.901 99.4543H119.57"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M194.901 93.1461H119.57"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M194.901 86.8394H119.57"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M194.901 80.5326H119.57"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M194.901 74.2257H119.57"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M194.901 67.9189H119.57"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M194.9 61.6122H119.924"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M194.901 55.304H119.57"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M176.192 48.7145H68.9707"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M194.901 156.254H119.57"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M194.901 162.628H119.57"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M177.893 168.793H102.562"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M194.901 149.948H119.57"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M194.901 143.641H119.57"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M194.901 137.334H119.57"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M194.901 131.027H119.57"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M194.901 124.719H119.57"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M194.901 118.412H119.57"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M194.9 112.105H119.924"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
    />
    <path
      d="M190.889 107.78C187.948 116.904 183.267 124.851 177.508 131.178C176.637 132.137 175.741 133.058 174.821 133.94L169.386 138.33H130.322C116.868 138.33 109.98 122.198 119.287 112.481L144.99 85.6393L193.856 85.4272C194.47 92.9674 193.316 100.243 190.889 107.78Z"
      fill="#CC4646"
    />
    <path
      d="M155.338 122.952H173.093C176.648 122.952 180.125 121.903 183.086 119.936C186.048 117.969 188.363 115.172 189.742 111.895L190.659 109.715"
      stroke="#FFBCBC"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M185.81 121.518L178.379 135.41L183.001 137.593C185.681 138.944 187.801 141.193 188.991 143.948C189.036 144.052 189.117 144.136 189.219 144.184C189.321 144.232 189.438 144.24 189.546 144.208C189.654 144.175 189.746 144.104 189.805 144.007C189.864 143.911 189.885 143.796 189.864 143.685L185.81 121.518Z"
      fill="#CCCEFF"
    />
    <path
      d="M185.809 121.518L189.862 143.685"
      stroke="#CC4646"
      strokeWidth="1.03256"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M180.269 139.858C179.864 139.858 179.476 139.697 179.191 139.411C178.905 139.125 178.744 138.737 178.744 138.333C178.744 137.929 178.905 137.541 179.191 137.255C179.476 136.969 179.864 136.809 180.269 136.809H181.377C181.432 136.808 181.487 136.818 181.538 136.838C181.59 136.859 181.636 136.889 181.676 136.928C181.715 136.967 181.746 137.013 181.768 137.064C181.789 137.115 181.8 137.169 181.8 137.225V138.333C181.8 138.534 181.76 138.733 181.683 138.918C181.606 139.104 181.493 139.272 181.351 139.414C181.209 139.555 181.04 139.667 180.854 139.743C180.668 139.82 180.469 139.858 180.269 139.858Z"
      stroke="white"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M181.594 134.648L181.793 137.227"
      stroke="white"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M193.53 80.078C195.863 92.966 189.436 101.753 179.383 106.392L145.76 121.903L142.018 111.904L172.113 89.3878L160.941 92.8967C157.861 93.8634 154.536 93.692 151.571 92.4137C148.607 91.1354 146.2 88.8352 144.788 85.9317L137.859 71.6787C137.692 71.3618 137.456 71.0865 137.168 70.8731C136.88 70.6597 136.548 70.5135 136.196 70.4454C135.845 70.3773 135.482 70.389 135.136 70.4797C134.789 70.5703 134.467 70.7376 134.194 70.9691L126.849 77.4824C126.433 74.532 125.826 71.583 125.135 68.6133C124.595 64.7636 123.256 61.0694 121.203 57.768C133.215 44.8051 151.136 38.6009 166.311 43.4942C180.281 47.9953 190.032 59.1718 193.165 74.8314L193.53 80.078Z"
      fill="#000799"
    />
    <path
      d="M121.604 57.5906C121.604 57.5906 124.943 62.1152 125.521 68.4082"
      stroke="#CC4646"
      strokeWidth="0.825286"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M175.277 77.323L171.902 88.9665"
      stroke="#CC4646"
      strokeWidth="0.825286"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M147.035 52.3356C147.035 45.7544 152.153 42.0085 159.252 42.0085C166.352 42.0085 169.98 48.6396 169.98 54.4614L172.106 89.3837L157.495 94.1591L160.128 59.2105L147.035 52.3356Z"
      fill="#CC4646"
    />
    <path
      d="M161.225 56.9406V54.7385"
      stroke="#000799"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M162.411 56.2782L161.225 56.9406C161.225 56.9406 153.37 59.4115 149.112 49.6776L147.033 52.5241L147.556 58.6673C147.94 63.185 152.974 65.6892 156.807 63.2682L162.123 59.9118C162.615 59.5999 163.015 59.1631 163.283 58.646C163.551 58.1289 163.677 57.5499 163.648 56.9683C163.641 56.8265 163.599 56.6887 163.525 56.5679C163.45 56.4471 163.346 56.3473 163.222 56.2781C163.098 56.209 162.959 56.1726 162.817 56.1727C162.675 56.1727 162.535 56.209 162.411 56.2782Z"
      fill="#FFBCBC"
    />
    <path
      d="M147.277 55.3635L148.778 56.08C149.249 56.3449 149.646 56.7233 149.933 57.1807C150.22 57.6381 150.389 58.1601 150.423 58.6992L150.616 61.7133"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M155.843 59.5154C155.504 59.7718 155.087 59.9043 154.661 59.891C154.107 59.891 153.629 59.6984 153.371 59.4115"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M149.122 58.4484C149.013 58.5689 148.88 58.6647 148.732 58.7295C148.583 58.7943 148.422 58.8265 148.26 58.8239C148.076 58.8274 147.894 58.7856 147.73 58.7022C147.567 58.6188 147.426 58.4964 147.32 58.3458"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M167.131 95.6226L148.041 107.972L144.598 128.971L156.922 136.947C157.823 137.53 158.572 138.319 159.11 139.248C159.647 140.176 159.957 141.219 160.013 142.291C160.069 143.363 159.871 144.432 159.434 145.413C158.997 146.393 158.334 147.255 157.5 147.93L134.29 166.68C134.169 166.778 134.018 166.833 133.862 166.838C133.706 166.844 133.553 166.798 133.425 166.708C133.297 166.618 133.202 166.489 133.154 166.341C133.107 166.192 133.108 166.032 133.159 165.884L136.735 155.611C136.919 155.082 137.299 154.645 137.798 154.389C138.296 154.134 138.874 154.08 139.411 154.239L141.133 154.752L148.041 143.903L136.13 141.114L120.332 168.189L123.096 173.578C123.316 174.008 123.381 174.501 123.28 174.974C123.179 175.447 122.918 175.87 122.541 176.172L113.686 169.019C113.038 168.495 112.57 167.782 112.346 166.98C112.122 166.177 112.155 165.325 112.439 164.541L133.838 105.567C134.374 104.09 135.25 102.76 136.396 101.685C137.542 100.61 138.925 99.8202 140.433 99.3795L169.408 90.1251C169.16 90.2138 166.115 91.3654 167.131 95.6226Z"
      fill="#CCCEFF"
    />
    <path
      d="M149.856 144.289L148.043 143.905L148.802 142.811"
      stroke="white"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M136.129 141.117L135.48 140.897C134.665 140.619 133.936 140.132 133.367 139.485C132.799 138.838 132.41 138.052 132.24 137.208"
      stroke="white"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M135.575 99.4003L134.189 104.734L134.382 104.363C135.51 102.182 137.437 100.522 139.762 99.7301L146.745 97.3742L145.978 96.3182C145.788 96.0562 145.52 95.8608 145.212 95.7598C144.905 95.6588 144.573 95.6574 144.264 95.7556L136.773 98.1433C136.483 98.2351 136.222 98.3983 136.013 98.6181C135.803 98.8378 135.653 99.1069 135.575 99.4003Z"
      fill="white"
    />
    <path
      d="M137.157 154.083L132.947 166.95"
      stroke="#CC4646"
      strokeWidth="0.825286"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M111.469 167.231L122.54 176.175"
      stroke="#CC4646"
      strokeWidth="0.825286"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M178.12 93.0991C178.195 93.1504 178.258 93.2178 178.304 93.2963C178.35 93.3749 178.378 93.4627 178.386 93.5533C178.394 93.644 178.382 93.7353 178.35 93.8207C178.318 93.906 178.268 93.9833 178.203 94.0469L176.733 95.484C176.249 95.9565 175.626 96.2595 174.956 96.3474C174.286 96.4354 173.605 96.3036 173.016 95.9718L170.591 94.604C170.287 94.4328 169.941 94.3478 169.592 94.3583C169.244 94.3688 168.904 94.4744 168.611 94.6636L167.131 95.6226C166.115 91.3654 169.159 90.218 169.407 90.1279H169.423L170.583 89.7579C171.142 89.5793 171.735 89.5306 172.317 89.6156C172.898 89.7006 173.452 89.9171 173.938 90.2485L178.12 93.0991Z"
      fill="#FFBCBC"
    />
    <path
      d="M175.357 92.9674L177.347 94.2548"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M174.295 94.0303L176.285 95.3177"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M144.17 95.9413L135.826 98.4357C135.263 98.6044 134.662 98.5962 134.105 98.4123C133.547 98.2284 133.059 97.8776 132.707 97.4075L130.37 94.2936C129.767 93.4894 129.357 92.5573 129.171 91.5693C128.986 90.5813 129.03 89.5639 129.3 88.5957C129.571 87.6275 130.06 86.7345 130.731 85.9856C131.402 85.2368 132.235 84.6522 133.168 84.277L138.374 82.1817C139.393 81.7717 140.526 81.7496 141.559 82.1195C142.593 82.4893 143.455 83.2254 143.981 84.1883L145.315 86.6218L146.915 87.1401C146.979 87.1607 147.039 87.1937 147.09 87.2373C147.142 87.281 147.184 87.3343 147.215 87.3943C147.245 87.4543 147.264 87.5197 147.27 87.5869C147.275 87.6541 147.267 87.7217 147.246 87.7858L146.766 89.2742L146.846 89.4128C147.172 90.0057 147.361 90.664 147.399 91.3394C147.438 92.0149 147.324 92.6904 147.068 93.3164C146.811 93.9424 146.418 94.503 145.916 94.9572C145.415 95.4114 144.818 95.7477 144.17 95.9413Z"
      fill="#FFBCBC"
    />
    <path
      d="M136.441 93.7393C136.056 93.4258 135.588 93.2305 135.095 93.1766C134.601 93.1228 134.102 93.2128 133.659 93.4358L133.607 93.4621C133.079 93.727 132.656 94.1624 132.406 94.6979C132.156 95.2333 132.094 95.8374 132.23 96.4125L132.022 96.4984L130.37 94.2963C129.767 93.4922 129.357 92.5601 129.171 91.5721C128.986 90.5841 129.03 89.5667 129.3 88.5985C129.571 87.6303 130.06 86.7372 130.731 85.9884C131.402 85.2395 132.235 84.6549 133.168 84.2798L138.374 82.1844C139.077 81.9007 139.84 81.8005 140.592 81.8934C141.58 86.0106 139.963 90.5685 136.441 93.7393Z"
      fill="#CC4646"
    />
    <path
      d="M133.881 95.0184C133.881 95.0184 135.544 94.4876 136.137 95.9136"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M134.59 96.0729L135.01 94.9587"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M143.967 88.7295C144.299 88.7295 144.567 88.4609 144.567 88.1295C144.567 87.7981 144.299 87.5294 143.967 87.5294C143.636 87.5294 143.367 87.7981 143.367 88.1295C143.367 88.4609 143.636 88.7295 143.967 88.7295Z"
      fill="#CC4646"
    />
    <path
      d="M66.7654 130.225C66.042 129.532 64.8668 127.808 64.142 126.99C59.7881 121.186 56.4392 114.693 54.2349 107.78C45.1482 79.5778 56.1487 50.7974 78.8108 43.497C82.1198 42.4303 85.5687 41.8606 89.0449 41.8063C94.4426 41.7162 99.8542 42.8415 105.012 45.0144C107.972 46.2691 110.796 47.8209 113.442 49.6458C116.6 51.8249 119.271 54.637 121.284 57.9035C123.297 61.17 124.609 64.8196 125.135 68.6202C129.025 85.3261 130.086 101.393 111.807 120.695L66.7654 130.225Z"
      fill="#CCCEFF"
    />
    <path
      d="M101.342 55.444L100.422 65.5742L92.2138 60.5507L84.1387 65.1044L83.5566 55.444H101.342Z"
      fill="white"
    />
    <path
      d="M92.4669 66.1327C92.9621 66.1327 93.3636 65.7313 93.3636 65.2361C93.3636 64.7409 92.9621 64.3395 92.4669 64.3395C91.9717 64.3395 91.5703 64.7409 91.5703 65.2361C91.5703 65.7313 91.9717 66.1327 92.4669 66.1327Z"
      stroke="#000799"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M92.4669 72.4118C92.9621 72.4118 93.3636 72.0103 93.3636 71.5152C93.3636 71.02 92.9621 70.6185 92.4669 70.6185C91.9717 70.6185 91.5703 71.02 91.5703 71.5152C91.5703 72.0103 91.9717 72.4118 92.4669 72.4118Z"
      stroke="white"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M104.617 52.4299L104.281 53.5704C104.132 54.077 103.823 54.5218 103.4 54.838C102.977 55.1543 102.463 55.3251 101.935 55.3249H101.313C101.054 56.8929 100.246 58.3179 99.0345 59.3461C97.8226 60.3742 96.285 60.9387 94.6957 60.9388H90.239C88.6779 60.9393 87.1656 60.3951 85.9627 59.4002C84.7598 58.4052 83.9417 57.0218 83.6495 55.4884H82.9981C82.4702 55.4885 81.9565 55.3177 81.5338 55.0014C81.1111 54.6851 80.8022 54.2405 80.6534 53.734L80.318 52.5934C80.249 52.3598 80.2355 52.1133 80.2785 51.8736C80.3215 51.6338 80.4198 51.4074 80.5656 51.2123C80.7115 51.0172 80.9008 50.8589 81.1186 50.7498C81.3364 50.6407 81.5766 50.584 81.8202 50.584H82.1279C82.353 50.5838 82.5735 50.6482 82.7631 50.7695C82.9527 50.8909 83.1036 51.0641 83.1977 51.2686C84.5835 50.1904 85.5009 48.2503 85.5009 46.033C85.5016 45.7617 85.4864 45.4905 85.4552 45.2209C88.698 47.0932 93.5427 48.3238 96.7204 48.1478C97.6446 48.1132 98.5606 47.9609 99.4463 47.6946C99.7858 49.2426 100.591 50.5424 101.657 51.3351C101.706 51.0772 101.843 50.8445 102.046 50.6774C102.248 50.5104 102.503 50.4195 102.765 50.4205H103.12C103.363 50.4216 103.602 50.4792 103.819 50.5887C104.036 50.6982 104.225 50.8567 104.37 51.0516C104.515 51.2465 104.613 51.4725 104.656 51.7117C104.699 51.9509 104.685 52.1968 104.617 52.4299Z"
      fill="#FFBCBC"
    />
    <path
      d="M97.3157 53.8239C96.997 54.064 96.6058 54.1878 96.207 54.1745C95.6915 54.1745 95.237 53.9944 95 53.7269"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M89.6614 53.8239C89.3427 54.064 88.9515 54.1878 88.5527 54.1745C88.0372 54.1745 87.5827 53.9944 87.3457 53.7269"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M91.1914 51.8381L92.0201 57.5587H93.6748"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M91.25 52.2067H85.291L85.8453 51.1203C85.936 50.941 86.0748 50.7905 86.2461 50.6855C86.4174 50.5805 86.6145 50.5252 86.8154 50.5258H90.0998C90.4278 50.5274 90.7461 50.638 91.0044 50.8402C91.2628 51.0424 91.4467 51.3247 91.5271 51.6427L91.5673 51.7993C91.5796 51.8476 91.5806 51.8981 91.5704 51.9468C91.5602 51.9956 91.5389 52.0414 91.5083 52.0807C91.4777 52.12 91.4385 52.1518 91.3937 52.1736C91.349 52.1955 91.2998 52.2068 91.25 52.2067Z"
      fill="#CC4646"
    />
    <path
      d="M99.7151 52.2067H93.7949L94.3049 51.1203C94.3824 50.946 94.5081 50.7974 94.6672 50.6921C94.8263 50.5868 95.0121 50.5291 95.2029 50.5258H98.2516C98.5673 50.534 98.871 50.6489 99.113 50.8517C99.3551 51.0546 99.5214 51.3333 99.5848 51.6427L99.7151 52.2067Z"
      fill="#CC4646"
    />
    <path
      d="M102.879 41.1827L101.728 51.3892C100.62 50.602 99.7883 49.2786 99.4433 47.6946C98.5576 47.9609 97.6416 48.1132 96.7174 48.1478C93.5397 48.3238 88.695 47.0932 85.4522 45.2209C85.4833 45.4905 85.4986 45.7617 85.4979 46.033C85.4979 48.2503 84.575 50.1904 83.1905 51.2728L82.0625 41.0053C82.2011 37.2969 86.813 35.9236 92.4727 35.9236C98.2168 35.9194 102.879 37.3371 102.879 41.1827Z"
      fill="#CC4646"
    />
    <path
      d="M89.0831 135.162C82.9537 134.726 77.4507 128.131 75.4094 113.864C73.4901 100.455 72.7113 88.5869 80.9526 86.2865C85.0061 85.1529 89.14 86.6828 92.732 90.0836L96.9005 82.4076L102.207 84.4558L98.3847 97.8981C99.6524 100.402 100.667 103.026 101.414 105.731C101.766 106.99 102.05 108.246 102.268 109.488C103.946 109.539 105.589 109.975 107.072 110.763C110.871 112.841 112.444 115.925 111.866 120.453C112.181 120.738 112.477 121.042 112.753 121.365C117.694 127.152 116.368 134.652 103.852 142.464C101.833 143.725 98.8447 145.62 96.5415 147.083L100.305 155.75C100.36 155.967 100.345 156.195 100.262 156.403C100.18 156.611 100.034 156.788 99.8452 156.908C99.6567 157.029 99.435 157.087 99.2117 157.074C98.9883 157.061 98.7746 156.979 98.6008 156.838L87.6779 148.013C86.8023 147.306 86.2018 146.315 85.9805 145.212C85.7593 144.109 85.9313 142.963 86.4668 141.974L89.0831 135.162Z"
      fill="white"
    />
    <path
      d="M90.9917 95.1044L83.5098 95.2208"
      stroke="#000799"
      strokeWidth="0.616739"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M79.9844 104.768L91.7637 104.616C93.1125 104.599 94.4207 104.153 95.4983 103.341C96.5759 102.53 97.3667 101.396 97.7559 100.104L102.121 84.7551"
      stroke="#000799"
      strokeWidth="0.616739"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M111.765 120.695C111.765 120.695 113.141 129.939 98.1953 144.289"
      stroke="#000799"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M89.082 135.162L96.4171 125.453"
      stroke="#000799"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M96.5179 83.045L95.9995 78.5925C95.9838 78.4516 96.0225 78.3101 96.1078 78.197C96.1931 78.0838 96.3185 78.0077 96.4583 77.9841C96.585 77.964 96.7148 77.9885 96.8255 78.0535C96.9362 78.1184 97.0209 78.2198 97.0652 78.3402L97.6293 79.8868C97.6522 79.9504 97.6938 80.0056 97.7485 80.0452C97.8033 80.0849 97.8687 80.1071 97.9363 80.1091C98.0039 80.111 98.0705 80.0926 98.1274 80.0561C98.1844 80.0197 98.2291 79.967 98.2556 79.9048L100.006 75.8652C100.049 75.7629 100.112 75.6702 100.192 75.5927C100.271 75.5152 100.365 75.4544 100.468 75.4138C100.572 75.3733 100.682 75.3538 100.793 75.3566C100.904 75.3594 101.013 75.3844 101.115 75.4301L102.445 76.0135C102.921 76.2225 103.307 76.5933 103.535 77.0601C103.764 77.5269 103.819 78.0595 103.692 78.5634L102.208 84.453C102.208 84.453 98.9693 85.7335 96.5179 83.045Z"
      fill="#FFBCBC"
    />
    <path
      d="M101.181 75.8666L100.227 78.3569"
      stroke="#CC4646"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M102.26 76.2352L101.305 78.7311"
      stroke="#CC4646"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M103.094 76.8339L102.279 78.9597"
      stroke="#CC4646"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M80.4417 89.5555C75.3017 90.1209 71.9883 88.9402 71.4062 83.5966C71.1298 81.1764 71.756 78.7393 73.1648 76.7521C72.976 76.7953 72.785 76.8277 72.5925 76.8491C71.5269 76.9651 70.4526 76.7287 69.5342 76.1759C68.6158 75.6232 67.9039 74.7846 67.5075 73.7887C67.1112 72.7927 67.0522 71.6943 67.3397 70.6617C67.6272 69.629 68.2453 68.7191 69.0993 68.0712C69.9533 67.4234 70.9961 67.0733 72.068 67.0747C73.1399 67.0761 74.1818 67.4288 75.0342 68.0788C75.8865 68.7288 76.5022 69.6404 76.7871 70.6737C77.072 71.7071 77.0102 72.8054 76.6113 73.8003C77.563 73.3269 78.5909 73.0257 79.6476 72.9106C84.7876 72.3452 89.4286 76.213 90.0162 81.5497C90.6038 86.8864 85.5789 88.9901 80.4417 89.5555Z"
      fill="#000799"
    />
    <path
      d="M72.5606 78.1863C72.3301 78.146 72.1098 78.0606 71.9123 77.935C71.7149 77.8094 71.5441 77.6461 71.4099 77.4544C71.2756 77.2628 71.1805 77.0465 71.1299 76.818C71.0794 76.5895 71.0744 76.3533 71.1152 76.1229V76.1229C71.156 75.8927 71.2418 75.6729 71.3676 75.4759C71.4934 75.2789 71.6568 75.1086 71.8484 74.9748C72.04 74.8409 72.2561 74.7462 72.4844 74.6959C72.7126 74.6456 72.9486 74.6407 73.1787 74.6816L74.9317 74.9934L74.6199 76.7465C74.5371 77.2102 74.2739 77.6221 73.8879 77.892C73.5018 78.1619 73.0246 78.2678 72.5606 78.1863V78.1863Z"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M78.6344 73.4427C78.6564 73.2096 78.6322 72.9745 78.5632 72.7508C78.4941 72.5271 78.3817 72.3192 78.2323 72.139C78.0828 71.9588 77.8993 71.8099 77.6923 71.7007C77.4852 71.5915 77.2586 71.5242 77.0255 71.5026V71.5026C76.7924 71.4805 76.5572 71.5046 76.3335 71.5735C76.1097 71.6424 75.9017 71.7549 75.7215 71.9043C75.5412 72.0538 75.3923 72.2374 75.2831 72.4445C75.174 72.6517 75.1068 72.8783 75.0854 73.1115L74.9219 74.884L76.6943 75.0475C77.1643 75.0915 77.6325 74.9472 77.9963 74.6463C78.36 74.3455 78.5895 73.9126 78.6344 73.4427V73.4427Z"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M89.0717 91.3349C86.2779 92.844 82.1427 92.0001 79.9046 89.1924L79.1771 89.8285C78.9603 90.0178 78.7074 90.1612 78.4337 90.25C78.16 90.3389 77.8711 90.3713 77.5845 90.3454C77.2978 90.3195 77.0194 90.2358 76.7661 90.0993C76.5127 89.9628 76.2896 89.7764 76.1103 89.5514L75.6752 89.0192C75.3 88.5612 75.1158 87.9762 75.161 87.3859C75.2062 86.7956 75.4772 86.2455 75.9177 85.8499C76.3025 85.5098 76.7983 85.3221 77.3118 85.3219C77.7202 85.3227 78.1199 85.4405 78.4634 85.6614C78.8852 85.7181 79.3123 85.7242 79.7356 85.6794C82.6125 85.3787 84.6801 82.869 84.3558 80.0711C84.257 79.2199 83.9405 78.4086 83.437 77.7152C84.1976 78.2872 85.108 78.6258 86.0574 78.6897C87.0069 78.7537 87.9545 78.5403 88.7849 78.0755C89.2935 78.688 89.7549 79.5777 90.1928 80.8111C91.7643 85.1999 93.0282 89.198 89.0717 91.3349Z"
      fill="#FFBCBC"
    />
    <path
      d="M78.4213 88.0782C78.4213 88.0782 77.9502 86.6411 76.3828 87.6625"
      stroke="#CC4646"
      strokeWidth="0.642172"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M89.6386 80.218C87.8371 80.4619 87.0749 82.5434 87.4213 85.1307C87.5945 86.4153 88.1142 87.5309 88.8071 88.2986"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M84.7763 86.181C84.7209 86.7617 84.0391 87.1719 83.252 87.0984"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M89.944 83.311C89.8941 83.8321 89.2815 84.2007 88.5762 84.1328"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M73.4062 136.479L76.0517 131.938C76.5185 131.137 77.1671 130.457 77.9452 129.953C78.7233 129.448 79.609 129.134 80.5308 129.036C81.4527 128.937 82.3848 129.057 83.2518 129.385C84.1189 129.713 84.8967 130.24 85.5223 130.925L94.9791 141.267L94.4247 141.624C93.9033 141.965 93.2964 142.151 92.6738 142.162C92.0511 142.173 91.438 142.008 90.9048 141.687L88.578 140.28C88.4618 140.21 88.3256 140.181 88.191 140.197C88.0563 140.214 87.9309 140.274 87.8344 140.369C87.7379 140.465 87.6758 140.589 87.6579 140.724C87.64 140.858 87.6673 140.994 87.7355 141.112L93.1623 150.29C93.6515 151.121 92.6274 152.001 91.8804 151.398L73.4062 136.479Z"
      fill="#FFBCBC"
    />
    <path
      d="M80.2146 129.077L77.461 123.396C77.461 123.396 69.9777 123.95 66.7266 130.219L67.3031 130.961C68.4953 132.493 69.8891 133.857 71.4466 135.015L73.4061 136.475L80.2146 129.077Z"
      fill="#CC4646"
    />
    <path
      d="M75.8322 128.825C76.3274 128.825 76.7288 128.424 76.7288 127.928C76.7288 127.433 76.3274 127.032 75.8322 127.032C75.337 127.032 74.9355 127.433 74.9355 127.928C74.9355 128.424 75.337 128.825 75.8322 128.825Z"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M112.078 76.7258C113.387 83.6687 112.725 89.9797 108.092 95.315L99.877 104.775"
      stroke="white"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M100.975 103.81L105.246 99.2369C105.246 99.2369 112.671 97.5032 115.649 108.231L108.876 112.101L100.975 103.81Z"
      fill="#CC4646"
    />
    <path
      d="M109.069 111.522C108.7 112.874 107.865 114.016 106.841 114.975C103.074 118.495 98.2777 122.08 95.8928 123.497C95.3093 123.842 94.737 123.003 95.2414 122.549L98.7059 119.021L91.7256 122.042C90.9219 122.389 90.2511 121.349 90.9039 120.757L91.7173 120.031L89.4723 120.862C88.4607 121.235 87.6555 119.961 88.4288 119.208L89.5624 118.099L88.5494 118.472C87.5128 118.854 86.788 117.439 87.704 116.821L94.7245 112.089L94.8423 112.008C94.9085 111.961 94.9582 111.894 94.9845 111.816C95.0109 111.739 95.0126 111.656 94.9895 111.578C94.9664 111.499 94.9195 111.43 94.8554 111.38C94.7913 111.329 94.7131 111.3 94.6317 111.296C94.5929 111.295 94.5542 111.299 94.5166 111.309L91.2822 112.122C90.9852 112.196 90.6751 112.202 90.3756 112.138C90.0761 112.075 89.7951 111.944 89.5541 111.755L89.1467 111.435L98.4315 104.984C99.834 103.919 100.728 103.275 102.604 103.459C106.583 103.863 109.478 107.478 109.068 111.521L109.069 111.522Z"
      fill="#FFBCBC"
    />
    <path
      d="M89.5625 118.095L95.6614 114.632"
      stroke="#CC4646"
      strokeWidth="0.825286"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M91.7188 120.023L97.2162 116.953"
      stroke="#CC4646"
      strokeWidth="0.825286"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M105.805 102.76C106.3 102.76 106.701 102.358 106.701 101.863C106.701 101.368 106.3 100.966 105.805 100.966C105.31 100.966 104.908 101.368 104.908 101.863C104.908 102.358 105.31 102.76 105.805 102.76Z"
      stroke="#000799"
      strokeWidth="0.550614"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M152.641 118.727L145.759 121.903L142.018 111.904L147.738 108.197C148.797 107.511 151.085 109.492 152.437 112.418C153.79 115.345 153.787 118.196 152.641 118.727Z"
      fill="#000799"
    />
    <path
      d="M142.011 111.903H133.835C133.576 111.903 133.327 112.003 133.14 112.182C132.953 112.361 132.842 112.605 132.83 112.863C132.82 113.097 132.891 113.327 133.032 113.514C133.173 113.701 133.374 113.833 133.602 113.887L136.522 114.58C136.642 114.609 136.75 114.673 136.833 114.763C136.916 114.854 136.97 114.968 136.988 115.089C137.006 115.211 136.987 115.335 136.934 115.446C136.88 115.557 136.795 115.649 136.688 115.711L129.741 119.73C129.567 119.83 129.415 119.964 129.293 120.124C129.17 120.283 129.081 120.465 129.029 120.66C128.978 120.854 128.965 121.056 128.992 121.255C129.019 121.455 129.085 121.646 129.187 121.82L130.523 124.105C131.001 124.923 131.753 125.545 132.647 125.861C133.54 126.176 134.516 126.165 135.402 125.829L145.75 121.9C145.75 121.9 147.387 115.785 142.011 111.903Z"
      fill="#FFBCBC"
    />
    <path
      d="M130.045 122.247L134.352 119.993"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M130.896 123.735L135.211 121.478"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M132.154 125.121L135.829 123.197"
      stroke="#CC4646"
      strokeWidth="0.68795"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M112.081 55.6185L107.16 51.9725C107.039 51.8821 106.939 51.7681 106.864 51.6376C106.789 51.5071 106.741 51.3626 106.724 51.2131C106.707 51.0637 106.72 50.9122 106.763 50.7681C106.807 50.6239 106.879 50.49 106.975 50.3746L108.821 48.1962C109.19 47.8743 109.641 47.6604 110.124 47.5778C110.607 47.4952 111.103 47.547 111.558 47.7278L117.837 50.2222C118.375 50.4355 118.831 50.8134 119.141 51.3017C119.452 51.79 119.599 52.3638 119.564 52.9411L119.414 55.3843L118.943 54.5972C118.795 54.3491 118.594 54.1366 118.354 53.9746C118.115 53.8126 117.842 53.7051 117.557 53.6596C117.271 53.6142 116.979 53.6319 116.701 53.7115C116.424 53.7911 116.166 53.9307 115.948 54.1205L114.492 55.394C114.164 55.6787 113.755 55.8534 113.322 55.8937C112.89 55.9339 112.455 55.8377 112.081 55.6185Z"
      fill="#FF9A9A"
    />
    <path
      d="M107.949 52.1872L110.696 48.9805"
      stroke="#CC4646"
      strokeWidth="0.825286"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M109.783 53.4691L112.53 50.2623"
      stroke="#CC4646"
      strokeWidth="0.825286"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
    <path
      d="M111.562 54.7385L114.309 51.5317"
      stroke="#CC4646"
      strokeWidth="0.825286"
      strokeMiterlimit="10"
      strokeLinecap="round"
    />
  </svg>
);

export default Illu2;
