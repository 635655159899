import {
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  useMediaQuery,
  useRadioGroup,
  type Theme,
} from '@mui/material';

import { useState } from 'react';
import { TrashIcon } from '../../assets/icons';
import { type TCharity } from '../../models/Charities';
import {
  backgroundColor,
  darkBlue,
  desktopS,
  errorColor,
  primaryColor,
  textColor,
} from '../../styles/variables';

const DEFAULT_AMOUNT_VALUES = ['5', '10', '25', '50'];

export interface CharityAmountDividerProps {
  value?: string | undefined;
  charity: TCharity;
  inputValueLabel: string;
  fixedValues?: string[];
  onChange?: (value: string) => void;
  currency?: string | undefined;
  onRemoveCharity?: (charity: TCharity) => void;
}

export const CustomRadioButton = ({
  label,
  value,
  isInput = false,
  currency,
  onInputChange,
  inputValue,
  inputError,
  onSetCustomValue,
}: {
  label: string | React.ReactNode;
  value: string;
  inputError?: boolean;
  inputValue?: string;
  onInputChange?: (val: string) => void;
  isInput?: boolean;
  currency?: string | undefined;
  onSetCustomValue?: () => void;
}) => {
  const radioGroup = useRadioGroup();
  const isChecked = radioGroup?.value === value;
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('tablet'));
  const [inputWidth, setInputWidth] = useState(
    inputValue ? `${inputValue.length * (isMobile ? 12 : 15)}px` : ''
  );

  const width = isMobile && !isInput ? '25%' : '100%';

  return (
    <FormControlLabel
      id={`${value}-label`}
      value={value}
      data-testid={`${value}AmountValue`}
      defaultValue={1}
      label={
        isInput && isChecked ? (
          <Stack
            sx={{
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Typography variant="h5" color={textColor}>
              {currency}
            </Typography>
            <TextField
              variant="standard"
              value={inputValue}
              onChange={(e) => {
                setInputWidth(`${e.target.value.length * (isMobile ? 12 : 15)}px`);
                onInputChange && onInputChange(e.target.value);
              }}
              onBlur={onSetCustomValue}
              onFocus={() => {
                if (inputValue && inputValue?.length <= 1) {
                  setInputWidth('15px');
                }
              }}
              onWheel={(e) => {
                (e.target as HTMLInputElement).blur();
              }}
              autoFocus
              autoComplete="off"
              type="text"
              inputMode="numeric"
              inputProps={{ pattern: '[0-9]*', min: 1 }}
              sx={{
                width: inputWidth,
                minWidth: 5,
                maxWidth: 200,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                '& .MuiInput-input': {
                  color: inputError ? errorColor : primaryColor,
                  textAlign: 'center',
                },
              }}
            />
          </Stack>
        ) : (
          <Typography variant="h5" color={isChecked ? primaryColor : darkBlue}>
            {label}
          </Typography>
        )
      }
      control={<Radio sx={{ display: 'none' }} inputProps={{ 'aria-label': `${value}-label` }} />}
      sx={{
        width,
        justifyContent: 'center',
        borderRadius: '0',
        position: 'relative',
        border: `3px solid ${
          isChecked ? (inputError ? errorColor : primaryColor) : backgroundColor
        }`,
        borderBlockStartWidth: '3.02px',
        borderInlineStartWidth: '0',
        borderInlineEndWidth: '3px',

        ...(isChecked && {
          zIndex: 1,
          '&:before': {
            position: 'absolute',
            insetBlockStart: '-3px',
            insetInlineStart: '-3px',
            display: 'block',
            boxSizing: 'content-box',
            width: '3px',
            height: '100%',
            paddingBlock: '3px',
            paddingInline: '0',
            backgroundColor: inputError ? errorColor : primaryColor,
            content: '""',
          },
        }),
        '&:first-of-type': {
          borderInlineStart: `3px solid ${
            isChecked ? (inputError ? errorColor : primaryColor) : backgroundColor
          }`,
          borderStartStartRadius: '12px',
          borderEndStartRadius: '12px',
          '&:before': {
            display: 'none',
          },
        },
        '&:last-of-type': {
          borderRadius: '0 12px 12px 0',
        },
        ...(isMobile && {
          '&:nth-last-of-type(2)': {
            borderRadius: '0 12px 12px 0',
          },
          '&:last-of-type': {
            borderRadius: '12px',

            borderInlineStart: `3px solid ${
              isChecked ? (inputError ? errorColor : primaryColor) : backgroundColor
            }`,
            '&:before': {
              display: 'none',
            },
          },
        }),
        height: isMobile ? '51px' : '65px',
        margin: 0,
        padding: '14px',
        zIndex: isChecked ? 1 : 0,
      }}
    />
  );
};

export const CharityAmountDivider = ({
  charity,
  value,
  onChange,
  fixedValues = DEFAULT_AMOUNT_VALUES,
  inputValueLabel,
  currency = '€',
  onRemoveCharity,
}: CharityAmountDividerProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('tablet'));
  const [showMinValueError, setShowMinValueError] = useState(false);
  const initialInputValue = !value || (value && fixedValues.includes(value)) ? '' : value;
  const [inputValue, setInputValue] = useState(initialInputValue);

  const handleChange = (val: string, isFromInput?: boolean) => {
    if (!isFromInput) {
      setInputValue('1');
      setShowMinValueError(false);
      if (val !== 'custom') {
        onChange && !isNaN(parseInt(val)) && onChange(val);
      } else {
        onChange && onChange('1');
      }
    } else {
      onChange && !isNaN(parseInt(inputValue)) && onChange(inputValue);
    }
  };

  return (
    <Stack gap="20px" width="100%">
      <Grid container justifyContent="space-between" alignItems="center" flexWrap={'nowrap'}>
        <Grid
          item
          container
          alignItems="center"
          gap={{ mobile: 2, tablet: '20px', laptop: 3 }}
          flexWrap={'nowrap'}
        >
          <Card
            sx={{
              width: { mobile: '96px', tablet: '120px' },
              borderRadius: { mobile: '6.4px', tablet: '8px' },
              boxShadow: desktopS,
              padding: '0',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img src={charity.logo} alt={'logo'} width={'100%'} />
          </Card>
          <Typography variant="h4" sx={{ display: { mobile: 'none', mobileS: 'block' } }}>
            {charity.name}
          </Typography>
        </Grid>
        <Grid item>
          {onRemoveCharity && (
            <IconButton
              onClick={() => {
                onRemoveCharity(charity);
              }}
              sx={{
                backgroundColor,
                padding: 0,
                height: isMobile ? '32px' : '40px',
                width: isMobile ? '32px' : '40px',
              }}
            >
              <TrashIcon
                color={errorColor}
                width={isMobile ? '12.8' : '16'}
                height={isMobile ? '12.8' : '16'}
              />
            </IconButton>
          )}
        </Grid>
      </Grid>

      <FormControl sx={{ flexDirection: 'row' }}>
        <RadioGroup
          row
          aria-labelledby="donation-amount"
          aria-label="donation-amount"
          name="donation-amount"
          value={value && !fixedValues.includes(value) ? 'custom' : value}
          onChange={(_, val) => {
            handleChange(val);
          }}
          sx={{
            flexWrap: isMobile ? 'wrap' : 'nowrap',
            width: '100%',
            justifyContent: 'center',
            rowGap: 1,
          }}
        >
          {fixedValues.map((fixedValue) => (
            <CustomRadioButton
              key={fixedValue}
              value={fixedValue}
              label={
                <>
                  <span style={{ color: textColor, fontWeight: 400 }}>{currency}</span> {fixedValue}
                </>
              }
            />
          ))}
          <CustomRadioButton
            key={'other'}
            isInput
            value="custom"
            label={inputValueLabel}
            currency={currency}
            inputValue={inputValue}
            inputError={showMinValueError}
            onInputChange={(val) => {
              const isNumber = !isNaN(parseInt(val));
              const value = isNumber ? parseInt(val).toString() : '';

              setShowMinValueError(isNumber && parseInt(val) < 1);

              setInputValue(value);
            }}
            onSetCustomValue={() => {
              handleChange(inputValue, true);
            }}
          />
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};

export default CharityAmountDivider;
