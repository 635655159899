import { Grid, Typography } from '@mui/material';
import { type ReactNode } from 'react';
import { primaryColor } from '../../../../styles/variables';

interface BundleCardGridItemProps {
  title: string;
  actionLabel: string | undefined;
  handleAction: () => void;
  content: ReactNode;
}

export const BundleCardGridItem = ({
  title,
  actionLabel,
  handleAction,
  content,
}: BundleCardGridItemProps) => {
  return (
    <Grid container gap={{ mobile: 1, tablet: 0 }}>
      <Grid item container mobile={12} tablet={10} laptop={10} gap={{ mobile: 1, laptop: 0 }}>
        <Grid item mobile={12} tablet={12} laptop={4}>
          <Typography variant="h6" lineHeight={'150%'}>
            {title}
          </Typography>
        </Grid>
        <Grid item mobile={12} tablet={12} laptop={8}>
          {content}
        </Grid>
      </Grid>
      <Grid
        item
        container
        mobile={12}
        tablet={2}
        laptop={2}
        justifyContent={{ mobile: 'flex-start', tablet: 'flex-end', laptop: 'flex-end' }}
        alignItems={{ mobile: 'flex-start', tablet: 'flex-end', laptop: 'flex-start' }}
      >
        {actionLabel && (
          <Typography
            variant="h6"
            fontWeight={400}
            lineHeight={'150%'}
            sx={{ color: primaryColor, textDecoration: 'underline', cursor: 'pointer' }}
            onClick={handleAction}
          >
            {actionLabel}
          </Typography>
        )}
      </Grid>
    </Grid>
  );
};

export default BundleCardGridItem;
