import type { FC, ReactNode } from 'react';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import type { TSelectedCharity } from '../models/Charities';

import { useTranslation } from 'react-i18next';
import { NotificationsContext } from './notifications-provider';

import type { AxiosResponse } from 'axios';
import { config } from '../api/config';
import { getDonationsOverview } from '../api/donations';
import { changePaymentMethod } from '../api/payments';
import {
  getCurrentBundle,
  pauseSubscription,
  stopSubscription,
  unpauseSubscription,
  updateNextDebitDate,
} from '../api/subscription';
import { deleteUserAccount, updateUserDetails } from '../api/user-details';
import type {
  TBundleInfo,
  TDonationOverviewDetails,
  TDonationOverviewItem,
} from '../models/Donation';
import {
  formatDateToLocaleString,
  separateDateValues,
} from '../pages/MyGiftShiftPage/MyGiftShift.utils';
import { AuthenticationContext } from './authentication-provider';
import { LanguageContext } from './language-selector-provider';
import { handleRedirectUserPreferredCountry } from './utils';

const bundleInfoInitialValues: TBundleInfo = {
  status: 'active',
  nextDonationDate: '',
  frequency: '',
  ibanLast4: '',
  sharedInfo: false,
};

export interface IMyGiftShiftContext {
  currentBundle: TSelectedCharity[];
  bundleInfo: TBundleInfo;
  loadingData: boolean;
  hasCurrentBundle: boolean;
  donationsOverviewList: TDonationOverviewItem[];
  donationOverviewDetails: TDonationOverviewDetails | undefined;
  donationsOverviewTotalCount: number;
  loadingDonationsOverview: boolean;
  updateBundleInfo: (newVal: TBundleInfo) => void;
  handlePauseBundle: () => Promise<AxiosResponse<any, any> | undefined>;
  handleReactivateBundle: (newPaymentDate: string) => Promise<AxiosResponse<any, any> | void>;
  handleUpdateNextPaymentDate: (newDate: string) => Promise<AxiosResponse<any, any> | undefined>;
  updatePaymentMethod: () => Promise<AxiosResponse<any, any> | undefined>;
  handleRemoveBundle: () => Promise<AxiosResponse<any, any> | undefined>;
  handleLoadMoreDonations: (startIndex: number) => Promise<void | AxiosResponse<any, any>>;
  handleNameUpdate: (value: string, onSuccess: () => void) => Promise<void>;
  handleSendResetPasswordLink: (onSuccess: () => void) => Promise<void>;
  handleUpdateBirthday: (value: string, onSuccess: () => void) => Promise<void>;
  handleUpdateCountry: (value: string, onSuccess: () => void) => Promise<void>;
  handleUpdateGender: (value: string, onSuccess: () => void) => Promise<void>;
  handleUpdateNewsletter: (value: boolean, onSuccess: () => void) => Promise<void>;
  handleDeleteAccount: () => Promise<void>;
}

export const MyGiftShiftContext = createContext<IMyGiftShiftContext>({
  currentBundle: [],
  bundleInfo: bundleInfoInitialValues,
  loadingData: false,
  hasCurrentBundle: false,
  donationsOverviewList: [],
  donationOverviewDetails: undefined,
  donationsOverviewTotalCount: 0,
  loadingDonationsOverview: false,
  updateBundleInfo: () => {},
  handlePauseBundle: async () => undefined,
  handleReactivateBundle: async () => undefined,
  handleUpdateNextPaymentDate: async () => undefined,
  updatePaymentMethod: async () => undefined,
  handleRemoveBundle: async () => undefined,
  handleLoadMoreDonations: async () => {},
  handleNameUpdate: async () => {},
  handleSendResetPasswordLink: async () => {},
  handleUpdateBirthday: async () => {},
  handleUpdateCountry: async () => {},
  handleUpdateGender: async () => {},
  handleUpdateNewsletter: async () => {},
  handleDeleteAccount: async () => {},
});

export const MyGiftShiftProvider: FC<{ children?: ReactNode }> = (props) => {
  //helpers
  const { setNotification } = useContext(NotificationsContext);
  const { setSelectedCountry, selectedLanguage, isEnglish } = useContext(LanguageContext);
  const { handleLogout, updateAuthenticatedUserName, userDetails, setUserDetails } =
    useContext(AuthenticationContext);

  const { t } = useTranslation();

  //state
  const [loadingCurrentBundle, setLoadingCurrentBundle] = useState(true);
  const [loadingDonationsOverview, setLoadingDonationsOverview] = useState(true);

  const [currentBundle, setCurrentBundle] = useState<TSelectedCharity[]>([]);
  const [bundleInfo, setBundleInfo] = useState<TBundleInfo>(bundleInfoInitialValues);
  const [donationOverviewDetails, setDonationOverviewDetails] = useState<
    TDonationOverviewDetails | undefined
  >(undefined);
  const [donationsOverviewList, setDonationsOverviewList] = useState<TDonationOverviewItem[]>([]);
  const [donationsOverviewTotalCount, setDonationsOverviewTotalCount] = useState(0);

  // fetching data

  const fetchCurrentBundle = async () => {
    return await getCurrentBundle()
      .then((response) => {
        if (response) {
          setCurrentBundle(mapCurrentBundle(response.data.bundle));
          if (response.data.bundle.length) {
            const { data } = response;
            const nextDate = new Date(data.nextDonationDate);
            const [nextDonationDate] = nextDate.toISOString().split('T');
            setBundleInfo({
              status: data.bundleStatus,
              nextDonationDate,
              frequency: data.frequency,
              ibanLast4: data.ibanLast4,
              sharedInfo: data.sharedInfo,
            });
          }
          setLoadingCurrentBundle(false);
        }
        return response;
      })
      .catch(() => {
        handleError();
        handleLogout();
        setLoadingCurrentBundle(false);
      });
  };

  const fetchDonationsOverview = async (startIndex: number) => {
    return await getDonationsOverview(startIndex)
      .then((response) => {
        if (response) {
          if (response.data.lastDonationDate) {
            setDonationOverviewDetails({
              lastDonationDate: formatDateToLocaleString(
                response.data.lastDonationDate,
                selectedLanguage,
                true
              ),
              maxDonationCharity: response.data.maxDonationCharity,
              maxTotalDoantionForCharity: response.data.maxTotalDonationForCharity,
              totalDonatedAmount: response.data.totalDonatedAmount,
            });
            setDonationsOverviewTotalCount(response.data.totalDonations);
            if (startIndex === 0) {
              setDonationsOverviewList(response.data.donationsOverviewList);
            } else {
              setDonationsOverviewList([
                ...donationsOverviewList,
                ...response.data.donationsOverviewList,
              ]);
            }
          }
          setLoadingDonationsOverview(false);
        }
        return response;
      })
      .catch(() => {
        handleError();
        setLoadingDonationsOverview(false);
      });
  };

  useEffect(() => {
    fetchCurrentBundle();
    fetchDonationsOverview(0);
  }, []);

  // handlers

  const handlePauseBundle = async () => {
    try {
      const response = await pauseSubscription();
      if (response) {
        setBundleInfo({ ...bundleInfo, status: 'paused' });
      }
      return response;
    } catch {
      setNotification({
        type: 'error',
        message: t('somethingWentWrong', { message: t('pleaseTryAgain') }),
      });
    }
  };

  const handleReactivateBundle = async (newPaymentDate: string) => {
    try {
      await unpauseSubscription(newPaymentDate);
      await fetchCurrentBundle();
    } catch (er) {
      setNotification({
        type: 'error',
        message: t('somethingWentWrong', { message: t('pleaseTryAgain') }),
      });
    }
  };

  const handleUpdateNextPaymentDate = async (newPaymentDate: string) => {
    try {
      const response = await updateNextDebitDate(newPaymentDate);
      if (response) {
        setBundleInfo({ ...bundleInfo, nextDonationDate: newPaymentDate });
      }
      return response;
    } catch (er) {
      setNotification({
        type: 'error',
        message: t('somethingWentWrong', { message: t('pleaseTryAgain') }),
      });
    }
  };

  const handleRemoveBundle = async () => {
    try {
      const response = await stopSubscription();
      if (response) {
        setCurrentBundle([]);
      }
      return response;
    } catch (er) {
      setNotification({
        type: 'error',
        message: t('somethingWentWrong', { message: t('pleaseTryAgain') }),
      });
    }
  };

  const updatePaymentMethod = async () => {
    try {
      const paymentBody = {
        successUrl: `${config.FE_APP_URL}/update-payment-method`,
        cancelUrl: `${config.FE_APP_URL}/${isEnglish ? '/my-giftshift' : '/mijn-giftshift'}`,
        language: selectedLanguage,
      };

      const response = await changePaymentMethod(paymentBody);

      if (response) {
        window.location.href = response.data.redirect_url;
        return response;
      }
    } catch (er) {
      setNotification({
        type: 'error',
        message: t('somethingWentWrong', { message: t('pleaseTryAgain') }),
      });
    }
  };

  // Update user profile handlers
  const handleNameUpdate = async (value: string, onSuccess: () => void) => {
    updateUserDetails({ name: value })
      .then(() => {
        setUserDetails({ ...userDetails, name: value });
        updateAuthenticatedUserName(value);
      })
      .then(() => onSuccess());
  };

  const handleSendResetPasswordLink = async (onSuccess: () => void) => {
    updateUserDetails({ resetPassword: 1 }).then(() => {
      onSuccess();
    });
  };

  const handleUpdateBirthday = async (value: string, onSuccess: () => void) => {
    if (value) {
      updateUserDetails({ birthday: separateDateValues(value) })
        .then(() => {
          setUserDetails({
            ...userDetails,
            birthday: value,
          });
        })
        .then(() => onSuccess());
    } else {
      handleError();
    }
  };

  const handleUpdateCountry = async (value: string, onSuccess: () => void) => {
    if (value) {
      updateUserDetails({ country: value })
        .then(() => {
          setUserDetails({ ...userDetails, country: value });
          handleRedirectUserPreferredCountry(setSelectedCountry, '/my-giftshift');
        })
        .then(() => onSuccess());
    } else {
      handleError();
    }
  };

  const handleUpdateGender = async (value: string, onSuccess: () => void) => {
    if (value) {
      updateUserDetails({ gender: value })
        .then(() => setUserDetails({ ...userDetails, gender: value }))
        .then(() => onSuccess());
    } else {
      handleError();
    }
  };

  const handleUpdateNewsletter = async (value: boolean, onSuccess: () => void) => {
    updateUserDetails({ newsletter: value })
      .then(() => setUserDetails({ ...userDetails, newsletter: value }))
      .then(() => onSuccess());
  };

  const handleDeleteAccount = async () => {
    deleteUserAccount();
  };

  const handleError = useCallback(() => {
    setNotification({
      message: t('somethingWentWrong', { message: t('pleaseTryAgain') }),
      type: 'error',
    });
  }, [setNotification, t]);

  // stateUpdates

  const mapCurrentBundle = (listFromResponse: TSelectedCharity[]) =>
    listFromResponse.map((item) => ({
      ...item,
      donationAmount: item.donationAmount?.toString(),
      isFromCurrentBundle: true,
    }));

  return (
    <MyGiftShiftContext.Provider
      value={{
        currentBundle,
        bundleInfo,
        loadingData: loadingCurrentBundle || loadingDonationsOverview,
        hasCurrentBundle: !(currentBundle.length === 0),
        donationsOverviewList,
        donationOverviewDetails,
        donationsOverviewTotalCount,
        loadingDonationsOverview,
        updateBundleInfo: (newVal) => setBundleInfo(newVal),
        handlePauseBundle,
        handleReactivateBundle,
        handleUpdateNextPaymentDate,
        updatePaymentMethod,
        handleRemoveBundle,
        handleLoadMoreDonations: (startIndex: number) => fetchDonationsOverview(startIndex),
        handleNameUpdate,
        handleSendResetPasswordLink,
        handleUpdateBirthday,
        handleUpdateCountry,
        handleUpdateGender,
        handleUpdateNewsletter,
        handleDeleteAccount,
      }}
    >
      {props.children}
    </MyGiftShiftContext.Provider>
  );
};
