import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { config } from './api/config';
import './i18n';
import './index.css';
import { AuthenticationProvider } from './providers/authentication-provider';
import { LanguageProvider } from './providers/language-selector-provider';
import { NotificationsProvider } from './providers/notifications-provider';
import { PublicProvider } from './providers/public-provider';
import { theme } from './styles/custom-theme';

const stripePromise = loadStripe(config.STRIPE_PUBLISH_KEY);

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <StrictMode>
    <ThemeProvider theme={theme}>
      <NotificationsProvider>
        <AuthenticationProvider>
          <LanguageProvider>
            <PublicProvider>
              <Elements stripe={stripePromise}>
                <CssBaseline />
                <App />
              </Elements>
            </PublicProvider>
          </LanguageProvider>
        </AuthenticationProvider>
      </NotificationsProvider>
    </ThemeProvider>
  </StrictMode>
);
