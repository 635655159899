import { type Theme } from '@mui/material/styles';
import { font } from '../custom-theme';
import { darkBlue, white } from '../variables';

export const MuiOutlinedInputStyles = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      backgroundColor: white,
      borderRadius: '6px',
      height: '44px',

      '&:hover': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: theme.palette.grey[600],
        },
      },

      '&.Mui-focused': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '2px',
          borderColor: theme.palette.primary.main,
        },
      },
      '&.Mui-error': {
        '& .MuiOutlinedInput-notchedOutline': {
          borderWidth: '2px',
          borderColor: theme.palette.error.main,
        },
      },

      [theme.breakpoints.up('tablet')]: {
        borderRadius: '7px',
        height: '46px',
      },

      [theme.breakpoints.up('laptop')]: {
        borderRadius: '8px',
        height: '50px',
        '&:hover': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '3px',
          },
        },
        '&.Mui-focused': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '3px',
          },
        },
        '&.Mui-error': {
          '& .MuiOutlinedInput-notchedOutline': {
            borderWidth: '3px',
          },
        },
      },
    }),
    input: ({ theme }: { theme: Theme }) => ({
      padding: '11px 14px 12px',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 700,
      fontFamily: font,

      display: 'flex',
      alignItems: 'center',
      gap: '8px',

      // remove increment/decrement icon for type="number"
      '&::-webkit-outer-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },

      '&::-webkit-inner-spin-button': {
        WebkitAppearance: 'none',
        margin: 0,
      },
      '&[type=number]': {
        MozAppearance: 'textfield',
        margin: 0,
      },

      '&::-ms-reveal': {
        display: 'none',
      },
      [theme.breakpoints.only('tablet')]: {
        padding: '12px 14px 13px',
        fontSize: '16px',
      },
      [theme.breakpoints.up('laptop')]: {
        padding: '13px 14px 14px',
        fontSize: '18px',
      },
      '&::placeholder': {
        color: darkBlue,
        opacity: 1,
      },
    }),
    notchedOutline: ({ theme }: { theme: Theme }) => ({
      border: `2px solid ${theme.palette.grey[500]}`,
      [theme.breakpoints.up('laptop')]: {
        border: `3px solid ${theme.palette.grey[500]}`,
      },
    }),
  },
};
