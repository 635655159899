export const ImpactAndOverviewIcon = () => (
  <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="21.7" cy="34.3" r="21.7" fill="#FFBCBC" />
    <path
      d="M22.6667 27.0833V35.4167M39.3333 22.9167V35.4167M31 14.5833V35.4167M22.25 43.75H39.75C43.2503 43.75 45.0005 43.75 46.3374 43.0688C47.5135 42.4696 48.4696 41.5135 49.0688 40.3374C49.75 39.0005 49.75 37.2503 49.75 33.75V16.25C49.75 12.7497 49.75 10.9995 49.0688 9.66256C48.4696 8.48655 47.5135 7.53042 46.3374 6.93121C45.0005 6.25 43.2503 6.25 39.75 6.25H22.25C18.7497 6.25 16.9995 6.25 15.6626 6.93121C14.4865 7.53042 13.5304 8.48655 12.9312 9.66256C12.25 10.9995 12.25 12.7497 12.25 16.25V33.75C12.25 37.2503 12.25 39.0005 12.9312 40.3374C13.5304 41.5135 14.4865 42.4696 15.6626 43.0688C16.9995 43.75 18.7497 43.75 22.25 43.75Z"
      stroke="#000CFF"
      strokeWidth="4.17"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ImpactAndOverviewIcon;
