import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { FacebookIcon, GoogleIcon } from '../../../assets/icons';
import SocialButton from '../../../components/social-button/SocialButton';
import { AuthenticationContext } from '../../../providers/authentication-provider';

export const SocialLogin = () => {
  const { t } = useTranslation();
  const { handleSocialAuth } = useContext(AuthenticationContext);

  return (
    <>
      <SocialButton
        icon={<FacebookIcon />}
        label={`${t('continueWith')} Facebook`}
        onClick={() => {
          handleSocialAuth('facebook');
        }}
      />
      <SocialButton
        icon={<GoogleIcon />}
        label={`${t('continueWith')} Google`}
        onClick={() => {
          handleSocialAuth('google');
        }}
      />
    </>
  );
};

export default SocialLogin;
