import { Box, Typography } from '@mui/material';
import type { ReactNode } from 'react';
import { backgroundColor, darkBlue, disabledColor, white } from '../../styles/variables';

type SocialButtonProps = {
  icon: ReactNode;
  onClick?: () => void;
  label: string;
  justifyContent?: 'center' | 'left';
};

export const SocialButton = ({
  icon,
  onClick,
  label,
  justifyContent = 'left',
}: SocialButtonProps) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        display: 'flex',
        p: '16px 22px',
        borderRadius: '8px',
        alignItems: 'center',
        justifyContent: justifyContent,
        border: `1px solid ${disabledColor}`,
        gap: '14px',
        cursor: 'pointer',
        backgroundColor: white,
        '&:hover': {
          backgroundColor: backgroundColor,
        },
      }}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>{icon}</Box>
      <Typography variant={'paragraphS'} color={darkBlue}>
        {label}
      </Typography>
    </Box>
  );
};

export default SocialButton;
