import { Stack, Grid, Typography, Box } from '@mui/material';
import { useTranslation } from 'react-i18next';

import EmailIllustration from '../../assets/EmailIllustration';

export const EmailReceivedContent = () => {
  const { t } = useTranslation();

  return (
    <Grid
      item
      mobile={12}
      laptop={6}
      alignItems={'center'}
      justifyContent={'center'}
      width={'100%'}
      sx={{
        overflowY: { mobile: 'auto', laptop: 'auto' },
      }}
      height={'100%'}
      display={'flex'}
      flexDirection={'column'}
      gap={3}
      px={{ mobile: 2, tablet: '220px', laptop: '147px' }}
      py={{ mobile: '52px', tablet: '144px', laptop: '112px' }}
    >
      <Box sx={{ width: { mobile: '129px', tablet: '141px', laptop: '173px' } }}>
        <EmailIllustration width="100%" />
      </Box>
      <Stack gap={0.5} alignItems="center">
        <Typography maxWidth={'415px'} variant="h2" textAlign={'center'}>
          {t('youHaveReceivedAnEmail')}
        </Typography>
        <Typography color="textSecondary" variant="paragraphM" textAlign={'center'}>
          {t('confirmRegistrationToContinue')}
        </Typography>
      </Stack>
    </Grid>
  );
};

export default EmailReceivedContent;
