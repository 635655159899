import { Tab, type TabProps } from '@mui/material';
import { darkBlue, disabledColor, primaryColor, textColor } from '../../../styles/variables';

export const CustomStyledVerticalTab = (props: TabProps) => {
  return (
    <Tab
      sx={{
        backgroundColor: 'transparent',
        color: darkBlue,
        border: 'none',
        padding: '16px',
        borderRadius: 0,
        alignItems: 'flex-start',
        borderLeft: `1px solid ${disabledColor}`,
        minWidth: '330px',
        width: '100%',
        height: { laptop: '51px' },
        '&:hover': {
          color: textColor,
        },
        '&.Mui-selected': {
          backgroundColor: 'transparent',
          color: primaryColor,
          borderRadius: 0,
          borderLeft: `3px solid ${primaryColor}`,
        },
        '&:first-of-type': {
          borderRadius: 0,
        },
      }}
      {...props}
    />
  );
};

export default CustomStyledVerticalTab;
