import { Alert, OutlinedInput, Stack, Typography, type OutlinedInputProps } from '@mui/material';
import { AlertTriangleIcon } from '../../assets/icons';
import { errorColor } from '../../styles/variables';

export const InputWithLabel = ({
  labelName,
  helperText,
  extraLabel,
  errorMessage,
  ...props
}: OutlinedInputProps & {
  labelName?: string | undefined;
  errorMessage?: string | undefined;
  extraLabel?: React.ReactNode;
  helperText?: string | undefined;
}) => {
  return (
    <Stack width={props.fullWidth ? '100%' : 'fit-content'} gap="4px">
      {labelName ? (
        <Stack direction={'row'} justifyContent={'space-between'}>
          <Typography variant="paragraphM" color={'textSecondary'}>
            {labelName}
          </Typography>
          {extraLabel}
        </Stack>
      ) : null}

      <OutlinedInput {...props} />
      {helperText ? <Typography>{helperText}</Typography> : null}
      {props.error ? (
        <Alert icon={<AlertTriangleIcon color={errorColor} />} severity={'error'}>
          {errorMessage}
        </Alert>
      ) : null}
    </Stack>
  );
};

export default InputWithLabel;
