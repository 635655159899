import { IdealBankElement } from '@stripe/react-stripe-js';
import { Box } from '@mui/material';
import { backgroundColor, darkBlue } from '../../../../styles/variables';
import { font } from '../../../../styles/custom-theme';

const IdealDropdown = ({ onChange }: { onChange: (isSelected: boolean) => void }) => {
  const idealElementOptions = {
    style: {
      base: {
        padding: '10px 14px',
        color: darkBlue,
        fontSize: '15px',
        fontWeight: '600',
        fontFamily: font,
      },
    },
  };

  const boxStyles = {
    width: '100%',
    border: `2px solid ${backgroundColor}`,
    borderRadius: '6px',
    margin: '10px 0',
    maxWidth: { mobile: '100%', tablet: 'calc(100% - 44.5px)' },
  };

  return (
    <Box sx={boxStyles}>
      <IdealBankElement
        options={idealElementOptions}
        onChange={(e) => {
          onChange(!e.empty);
        }}
      />
    </Box>
  );
};

export default IdealDropdown;
