import { type Theme } from '@mui/material/styles';

export const MuiCheckboxStyles = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      color: theme.palette.grey[500],
      borderRadius: '6px',
      padding: 0,
      '&:hover': {
        backgroundColor: 'rgba(0, 12, 255 , 0.03)',
      },
      '& .MuiSvgIcon-root': { fontSize: '2rem' },
    }),
  },
};
