import { Stack } from '@mui/material';

import { MyGiftShiftProvider } from '../../providers/my-giftshift-provider';
import MyGiftShiftTabs from './MyGiftShiftTabs';

export const MyGiftShiftPage = () => {
  return (
    <MyGiftShiftProvider>
      <Stack pt={{ mobile: 2, laptop: 10 }} pb={{ mobile: 8, tablet: 9, laptop: 10 }}>
        <MyGiftShiftTabs />
      </Stack>
    </MyGiftShiftProvider>
  );
};

export default MyGiftShiftPage;
