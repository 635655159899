import { Box, Button, Card, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { config } from '../../api/config';
import { LogoGrey } from '../../assets/LogoGrey';
import { type TCharity } from '../../models/Charities';
import { DonationSteps } from '../../pages/DonationFlowPage/DonationFlow.utils';
import {
  darkBlue,
  desktopL,
  disabledColor,
  primaryColor,
  textColor,
  white,
} from '../../styles/variables';
import CharitiesSelector from '../charities-selector/CharitiesSelector';
import { type WidgetProps } from './types';

export const Widget2 = ({
  buttonBgColor = primaryColor,
  buttonTextColor = 'primary',
  buttonBorderRadius = 8,
  headingColor = darkBlue,
  subHeadingColor = textColor,
  charities = [],
  partnerId,
  language,
  headingText,
  subHeadingText,
  buttonText,
  selectedCountry,
  selectedLanguage,
}: WidgetProps) => {
  const [selectedCharities, setSelectedCharities] = useState<TCharity[]>([]);
  const { t } = useTranslation(undefined, { lng: language });

  const handleSelectCharity = (charity: TCharity) => {
    setSelectedCharities([...selectedCharities, charity]);
  };

  const handleRemoveCharity = (charityToRemove: TCharity) => {
    const updatedCharities = selectedCharities.filter(
      (charity) => charity.id !== charityToRemove.id
    );
    setSelectedCharities(updatedCharities);
  };

  const cardStyles = {
    borderRadius: '12px',
    width: '100%',
    margin: '4px auto',
    padding: '24px 20px',
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    backgroundColor: white,
    boxShadow: desktopL,
    maxWidth: '800px',
  };

  const buttonStyles = {
    backgroundColor: buttonBgColor,
    border: `1px solid ${buttonBgColor}`,
    color: buttonTextColor || white,
    width: { mobile: '100%', tablet: 'initial' },
    '&:hover': { backgroundColor: buttonBgColor || primaryColor },
    '&:focus': {
      border: `2px solid ${white}`,
      boxShadow: `0px 0px 0px 2px ${buttonBgColor}`,
    },
    '&.MuiButton-root': {
      borderRadius: `${buttonBorderRadius}px`,
    },
  };

  const formatRedirectLink = () => {
    const chartiesIds = selectedCharities.map((el) => el.id).join(',');

    return `${config.FE_APP_URL}/${selectedCountry}/${selectedLanguage}/${
      language === 'en' ? 'donate' : 'doneren'
    }?w=2&pid=${partnerId}&step=${DonationSteps.FrequencyAndAmount}&charities=${chartiesIds}`;
  };

  return (
    <Card sx={cardStyles}>
      <Stack gap="4px">
        <Typography variant="h3" color={headingColor}>
          {headingText}
        </Typography>
        <Typography variant="paragraphM" color={subHeadingColor}>
          {subHeadingText}
        </Typography>
      </Stack>

      <CharitiesSelector
        selectedCharities={selectedCharities}
        charities={charities}
        onRemoveFromSelection={handleRemoveCharity}
        onSelectCharity={handleSelectCharity}
        selectionText={t('addButton')}
        removeSelectionText={t('remove')}
        logoOnly
      />

      <Stack
        direction={{ mobile: 'column', tablet: 'row' }}
        justifyContent="space-between"
        alignItems="center"
        gap="24px"
      >
        <Box width={{ mobile: '100%', tablet: 'initial' }}>
          <Link
            target="_parent_blank"
            to={formatRedirectLink()}
            style={{ pointerEvents: selectedCharities.length > 0 ? 'auto' : 'none' }}
          >
            <Button
              variant="contained"
              sx={buttonStyles}
              size="large"
              disabled={!(selectedCharities.length > 0)}
            >
              {buttonText}
            </Button>
          </Link>
        </Box>

        <Stack direction="row" gap="8px" alignItems="center" justifyContent="center">
          <Typography variant="paragraphXS" color={disabledColor}>
            Powered by
          </Typography>
          <LogoGrey width="39" height="19.5" />
        </Stack>
      </Stack>
    </Card>
  );
};

export default Widget2;
