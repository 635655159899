import { Skeleton, Stack } from '@mui/material';

export const FrequencyAndAmountStepSkeleton = () => {
  return (
    <Stack
      px={{ mobile: '20px', tablet: '40px', laptop: '110px', desktop: '220px' }}
      gap={11}
      width="100%"
    >
      <Stack gap={{ mobile: 2, tablet: '12px', laptop: 2 }}>
        <Skeleton variant="rounded" height={44} width={'100%'} />
        <Skeleton variant="rounded" height={28} width={'137px'} />
        <Skeleton variant="rounded" height={28} width={'222px'} />
      </Stack>
      <Skeleton variant="rounded" height={44} width={'100%'} />{' '}
    </Stack>
  );
};

export default FrequencyAndAmountStepSkeleton;
