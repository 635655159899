import { Box, Grid, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { RadioOutlinedIcon } from '../../../assets/icons';
import CheckedSuccesIcon from '../../../assets/icons/CheckedSuccesIcon';
import { AuthenticationContext } from '../../../providers/authentication-provider';
import { disabledColor } from '../../../styles/variables';

export const PasswordRequirements = () => {
  const {
    passwordRequirements: { minimum8Chars, oneCapitalLetter, oneSpecialCharacter },
  } = useContext(AuthenticationContext);
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        borderRadius: '8px',
        border: `1px solid ${disabledColor}`,
        p: { mobile: '10px 12px', tablet: '12px 14px', laptop: '14px 16px' },
      }}
    >
      <Stack gap={0.5}>
        <Typography variant="paragraphXS">{t('yourPasswordMust')}</Typography>
        <Grid container alignItems={'center'} gap={1}>
          {minimum8Chars ? <CheckedSuccesIcon /> : <RadioOutlinedIcon width={'14'} height={'14'} />}
          <Typography variant="paragraphXS">{t('haveAtLeast8Chars')}</Typography>
        </Grid>
        <Grid container alignItems={'center'} gap={1}>
          {oneCapitalLetter ? (
            <CheckedSuccesIcon />
          ) : (
            <RadioOutlinedIcon width={'14'} height={'14'} />
          )}
          <Typography variant="paragraphXS">{t('haveOneCapitalLetter')}</Typography>
        </Grid>
        <Grid container alignItems={'center'} gap={1} flexWrap={'nowrap'}>
          {oneSpecialCharacter ? (
            <CheckedSuccesIcon />
          ) : (
            <RadioOutlinedIcon width={'14'} height={'14'} />
          )}
          <Typography variant="paragraphXS">{t('haveSpecialCharacter')}</Typography>
        </Grid>
      </Stack>
    </Box>
  );
};

export default PasswordRequirements;
