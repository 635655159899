import {
  Box,
  Dialog,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
  useMediaQuery,
  type Theme,
} from '@mui/material';

import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, useNavigate, useSearchParams } from 'react-router-dom';

import { Formik } from 'formik';
import { EyeClosedIcon, EyeIcon } from '../../assets/icons';
import InputWithLabel from '../../components/inputWithLabel/InputWithLabel';
import LoadingButton from '../../components/loading-button/LoadingButton';
import { AuthenticationContext } from '../../providers/authentication-provider';
import { NotificationsContext } from '../../providers/notifications-provider';
import { backgroundColor, shadowLG } from '../../styles/variables';
import { changePasswordSchema } from '../MyGiftShiftPage/MyGiftShift.utils';
import PasswordRequirements from '../RegisterPage/components/PasswordRequirements';

//types
import type { TResetPassword } from '../../models/User';

export const ResetPasswordPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [loadingResetPassword, setLoadingResetPassword] = useState(false);

  const { handleCheckPasswordRequirements, handleSubmitNewPassword, invalidPassword } =
    useContext(AuthenticationContext);
  const { setNotification } = useContext(NotificationsContext);

  const isLandscape = useMediaQuery(
    (theme: Theme) => `${theme.breakpoints.down('laptop')} and (orientation: landscape)`
  );

  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  const handleClickShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword((show) => !show);
  };
  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const handleClose = () => {
    navigate('/');
  };

  useEffect(() => {
    if (!code) {
      navigate('/');
    }
  }, []);

  const onSuccess = () => {
    setLoadingResetPassword(false);
    window.location.href = '/login';
  };

  const onError = () => {
    setLoadingResetPassword(false);
    setNotification({
      message: t('somethingWentWrong', { message: t('pleaseTryAgain') }),
      type: 'error',
    });
  };

  const handleChangePasswordSubmit = (values: TResetPassword) => {
    handleSubmitNewPassword(code, values, onSuccess, onError);
  };

  const changePasswordBoxStyles = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    bottom: { mobile: '0', tablet: 'unset' },
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: '16px',
    boxShadow: shadowLG,
    padding: {
      mobile: isLandscape ? '16px' : '64px 16px 16px',
      tablet: isLandscape ? '15px 40px' : '64px 90px',
      laptop: '64px 104px',
    },
    width: { mobile: 'calc(100% - 40px)', tablet: '508px', laptop: '592px' },
    overflow: 'auto',
    outline: 0,
    height: { mobile: '85vh', tablet: 'initial' },
  };

  return (
    <Dialog
      open
      onClose={handleClose}
      aria-labelledby="Set up new passord"
      fullScreen
      hideBackdrop
      PaperProps={{ sx: { bgcolor: backgroundColor } }}
    >
      <Box sx={changePasswordBoxStyles}>
        <Formik
          initialValues={{ password: '', confirmPassword: '' }}
          validationSchema={changePasswordSchema(t)}
          onSubmit={handleChangePasswordSubmit}
        >
          {({ handleChange, errors, values, handleSubmit, handleBlur, touched }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Stack gap={isLandscape ? '10px' : '24px'}>
                  <Typography variant={isLandscape ? 'h3' : 'h2'} mb={'4px'}>
                    {t('setUpNewPassword')}
                  </Typography>

                  <InputWithLabel
                    fullWidth
                    labelName={t('password')}
                    type={showPassword ? 'text' : 'password'}
                    name="password"
                    value={values.password}
                    error={!!errors.password}
                    errorMessage={errors.password}
                    onChange={(e) => {
                      handleCheckPasswordRequirements(e.target.value);
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          sx={{ mr: 0 }}
                        >
                          {showPassword ? <EyeClosedIcon /> : <EyeIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />

                  <PasswordRequirements />

                  <InputWithLabel
                    fullWidth
                    labelName={t('confirmPassword')}
                    name="confirmPassword"
                    type={showConfirmPassword ? 'text' : 'password'}
                    value={values.confirmPassword}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    onBlur={handleBlur}
                    error={!!errors.confirmPassword && touched.confirmPassword}
                    errorMessage={errors.confirmPassword}
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowConfirmPassword}
                          onMouseDown={handleMouseDownPassword}
                          edge="end"
                          sx={{ mr: 0 }}
                        >
                          {showConfirmPassword ? <EyeClosedIcon /> : <EyeIcon />}
                        </IconButton>
                      </InputAdornment>
                    }
                  />

                  <Box>
                    <LoadingButton
                      size="large"
                      variant="contained"
                      data-testid="ResetPasswordButton"
                      type="submit"
                      fullWidth
                      loading={loadingResetPassword}
                      disabled={
                        !(Object.keys(errors).length === 0) ||
                        invalidPassword ||
                        loadingResetPassword
                      }
                      color="primary"
                    >
                      {t('resetPassword')}
                    </LoadingButton>
                  </Box>
                </Stack>
              </Form>
            );
          }}
        </Formik>
      </Box>
    </Dialog>
  );
};

export default ResetPasswordPage;
