import { Button, Card, Grid, Stack, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import { config } from '../../api/config';
import { LogoGrey } from '../../assets/LogoGrey';
import { DonationSteps } from '../../pages/DonationFlowPage/DonationFlow.utils';
import { desktopL, disabledColor, primaryColor, white } from '../../styles/variables';
import LogoTicker from '../logo-ticker/LogoTicker';

// types
import type { WidgetProps } from './types';

export const Widget1 = ({
  buttonBgColor,
  buttonBorderRadius = 8,
  buttonTextColor,
  headingColor,
  subHeadingColor,
  partnerId,
  logos,
  language,
  headingText,
  subHeadingText,
  buttonText,
  selectedCountry,
  selectedLanguage,
}: WidgetProps) => {
  const formatRedirectLink = () => {
    return `${config.FE_APP_URL}/${selectedCountry}/${selectedLanguage}/${
      language === 'en' ? 'donate' : 'doneren'
    }?w=1&pid=${partnerId}&step=${DonationSteps.SelectCharities}`;
  };

  const buttonStyles = {
    width: { mobile: '100%', tablet: 'auto' },
    backgroundColor: buttonBgColor || primaryColor,
    color: buttonTextColor || white,
    border: `1px solid ${buttonBgColor}`,
    '&:hover': { backgroundColor: buttonBgColor || primaryColor },
    '&:focus': {
      border: '2px solid #FFF',

      boxShadow: `0px 0px 0px 2px ${buttonBgColor}`,
    },
    '&.MuiButton-root': {
      borderRadius: `${buttonBorderRadius}px`,
    },
  };

  const cardStyles = {
    borderRadius: '12px',
    width: '100%',
    margin: '4px auto',
    padding: '24px 20px',
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    backgroundColor: white,
    boxShadow: desktopL,
    maxWidth: '800px',
  };

  return (
    <Card sx={cardStyles}>
      <Stack gap={0.5}>
        <Typography variant="h3" color={headingColor}>
          {headingText}
        </Typography>
        <Typography variant="paragraphM" color={subHeadingColor}>
          {subHeadingText}
        </Typography>
      </Stack>
      <LogoTicker images={logos || []} />
      <LogoTicker images={logos || []} direction="right" />

      <Grid container spacing="24px">
        <Grid item mobile={12} tablet={6}>
          <Link target="_parent_blank" to={formatRedirectLink()}>
            <Button variant="contained" size="large" fullWidth sx={buttonStyles}>
              {buttonText}
            </Button>
          </Link>
        </Grid>

        <Grid
          item
          container
          mobile={12}
          tablet={6}
          justifyContent={{ mobile: 'center', tablet: 'flex-end' }}
          alignItems={'center'}
        >
          <Stack direction="row" gap="8px" alignItems="center" justifyContent="center">
            <Typography variant="paragraphXS" color={disabledColor}>
              Powered by
            </Typography>
            <LogoGrey height="19.5" width="39" />
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};

export default Widget1;
