import { Box, DialogContent, IconButton, Stack, useMediaQuery, type Theme } from '@mui/material';
import { useContext } from 'react';
import LogoDark from '../../assets/LogoDark.svg';

import { Link } from 'react-router-dom';
import { CloseIcon } from '../../assets/icons';
import { DonationFlowContext } from '../../providers/donation-flow-provider';
import {
  CheckExistingBundleStep,
  FrequencyAndAmountStep,
  RegistrationAndLoginStep,
  SelectCharitiesStep,
  SummaryAndPaymentStep,
} from './steps';
import { DonationSteps } from './DonationFlow.utils';
import { config } from '../../api/config';

interface DonationFlowProps {
  handleClose: () => void;
}

export const DonationFlowSteps = ({ handleClose }: DonationFlowProps) => {
  const { currentStep, stepContainerRef, resetDonationFlow } = useContext(DonationFlowContext);
  const isTabletOrMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('laptop'));

  const renderStep = () => {
    switch (currentStep) {
      case DonationSteps.SelectCharities:
        return <SelectCharitiesStep />;
      case DonationSteps.FrequencyAndAmount:
        return <FrequencyAndAmountStep />;
      case DonationSteps.Authentication:
        return <RegistrationAndLoginStep />;
      case DonationSteps.SummaryAndPayment:
        return <SummaryAndPaymentStep />;
      case DonationSteps.CheckExistingBundleStep:
        return <CheckExistingBundleStep />;

      default: {
        return <SelectCharitiesStep />;
      }
    }
  };

  const onCloseDonationFlow = () => {
    resetDonationFlow();
    handleClose();
  };
  return (
    <DialogContent
      ref={stepContainerRef}
      sx={{
        overflowY: 'auto',
        padding: '0 0 32px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems={'center'}
        py={{ mobile: 2, tablet: '20px', laptop: 4 }}
        width={{ mobile: '100%', laptop: '70%' }}
        maxWidth={'1216px'}
        mx={{ mobile: '0', laptop: '32px' }}
        px={{ mobile: '20px', tablet: '40px', laptop: 0 }}
      >
        <Box data-testid="GiftShiftLogo">
          <Link to={config.FE_APP_URL}>
            <img
              src={LogoDark}
              alt="logo-light"
              width={isTabletOrMobile ? '80px' : '98px'}
              height={isTabletOrMobile ? '40px' : '49px'}
            />
          </Link>
        </Box>
        <IconButton onClick={onCloseDonationFlow} aria-label="close">
          <CloseIcon />
        </IconButton>
      </Stack>
      {renderStep()}
    </DialogContent>
  );
};

export default DonationFlowSteps;
