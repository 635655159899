import { Grid, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AuthenticationContext } from '../../providers/authentication-provider';
import { RegisterWithEmailAddress } from './components/RegisterWithEmailAddress';
import SocialLogin from './components/SocialLogin';

export const RegistrationContent = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { resetFields } = useContext(AuthenticationContext);

  return (
    <Grid
      item
      mobile={12}
      laptop={6}
      alignItems={'center'}
      justifyContent={'center'}
      width={'100%'}
      sx={{
        overflowY: { mobile: 'auto', laptop: 'auto' },
      }}
      height={'100%'}
      display={'flex'}
      flexDirection={'column'}
      gap={3}
      px={{ mobile: 2, tablet: '220px', laptop: '147px' }}
      py={{ mobile: '52px', tablet: '144px', laptop: '112px' }}
    >
      <Stack width={'100%'} maxWidth={'430px'}>
        <Typography variant="h2">{t('register')}</Typography>
        <Grid container gap={0.5} alignItems={'center'}>
          <Typography variant="paragraphM" color={'textSecondary'}>
            {t('alreadyHaveAnAccount')}
          </Typography>
          <Typography
            variant="paragraphM"
            color="primary.main"
            onClick={() => {
              resetFields();
              navigate('/login');
            }}
            sx={{ cursor: 'pointer' }}
          >
            {t('login')}
          </Typography>
        </Grid>
      </Stack>

      <Stack gap={2} width={'100%'} maxWidth={'430px'}>
        <SocialLogin />
        <RegisterWithEmailAddress />
      </Stack>

      <Typography
        variant="paragraphXS"
        textAlign={'center'}
        color="textSecondary"
        maxWidth={'430px'}
      >
        {t('registerDisclaimer')}
      </Typography>
    </Grid>
  );
};

export default RegistrationContent;
