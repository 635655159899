import { Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import {
  removeCausesDuplicates,
  renderCharitiesSelectionInfo,
} from '../../pages/DonationFlowPage/DonationFlow.utils';
import { DonationFlowContext } from '../../providers/donation-flow-provider';

export const SelectCharitiesInfo = () => {
  const { t } = useTranslation();
  const { selectedCharities } = useContext(DonationFlowContext);

  return (
    <Stack flexDirection={'row'} gap={0.5} flex="2">
      <Typography variant="paragraphS" color="textSecondary">
        {t('youHaveSelected')}{' '}
        {renderCharitiesSelectionInfo(
          removeCausesDuplicates(selectedCharities),
          t('and'),
          t('charities')
        )}{' '}
        {t('selected')}
      </Typography>
    </Stack>
  );
};

export default SelectCharitiesInfo;
