// colors
export const primaryColor = '#000CFF';
export const secondaryColor = '#FFBCBC';
export const darkBlue = '#000347';

export const textColor = '#5E6083';
export const backgroundColor = '#E7E8F1';
export const white = '#FFFFFF';

export const successColor = '#71DE82';
export const errorColor = '#FF4545';
export const warningColor = '#F60';

export const cartBgColor = '#FAF2DE';
export const disabledColor = '#BBBCCD';

// shadows

export const shadowXS = '0px 1px 3px 0px #1C2C4033';
export const shadowSM = '0px 2px 6px 0px #1C2C4033';
export const shadowMD = '0px 3px 12px 0px #1C2C4033';
export const shadowLG = '0px 4px 16px 0px #1C2C4033';
export const shadowXL = '1px 5px 20px 0px #1C2C4026';
export const shadow2XL = '3px 6px 24px 0px #1C2C4026';
export const sliderThumbShadow = '2px 4px 12px 0px rgba(0, 0, 0, 0.35)';
export const lightNavbarShadow = '0px -1px 0px 0px rgba(0, 0, 0, 0.10) inset';

// new shadows
export const mobileXS = '0px 1px 3px 0px rgba(28, 44, 64, 0.15)';
export const mobileS = '0px 1px 3px 0px rgba(28, 44, 64, 0.15)';
export const mobileM = '0px 2px 6px 0px rgba(28, 44, 64, 0.15);';
export const mobileL = '0px 2px 6px 0px rgba(28, 44, 64, 0.15)';
export const mobileXL = '0px 3px 12px 0px rgba(28, 44, 64, 0.15)';
export const mobileXXL = '0px 4px 16px 0px rgba(28, 44, 64, 0.15)';

export const tabletXS = '0px 1px 3px 0px rgba(28, 44, 64, 0.15)';
export const tabletS = '0px 1px 3px 0px rgba(28, 44, 64, 0.15)';
export const tabletM = '0px 2px 6px 0px rgba(28, 44, 64, 0.15)';
export const tabletL = '0px 3px 12px 0px rgba(28, 44, 64, 0.15)';
export const tabletXL = '0px 4px 16px 0px rgba(28, 44, 64, 0.15)';
export const tabletXXL = '1px 5px 20px 0px rgba(28, 44, 64, 0.15)';

export const desktopXS = '0px 1px 3px 0px rgba(28, 44, 64, 0.20)';
export const desktopS = '0px 2px 6px 0px rgba(28, 44, 64, 0.20)';
export const desktopM = '0px 3px 12px 0px rgba(28, 44, 64, 0.20)';
export const desktopL = '0px 4px 16px 0px rgba(28, 44, 64, 0.20)';
export const desktopXL = '1px 5px 20px 0px rgba(28, 44, 64, 0.15)';
export const desktopXXL = '3px 6px 24px 0px rgba(28, 44, 64, 0.15)';
