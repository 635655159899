import { type Theme } from '@mui/material/styles';
import { font } from '../custom-theme';

export const MuiMenuItemStyles = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      padding: '12px 8px',
      borderRadius: '6px',
      fontFamily: font,
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '21px',
      '&:hover': {
        backgroundColor: theme.palette.grey[400],
      },
      '&.Mui-selected': {
        backgroundColor: theme.palette.grey[400],
        '&:hover': {
          backgroundColor: theme.palette.grey[400],
        },
        '&.Mui-focusVisible': {
          backgroundColor: theme.palette.grey[400],
        },
      },
      '&.Mui-focused': {
        backgroundColor: theme.palette.grey[400],
      },

      '&.Mui-focusVisible': {
        backgroundColor: theme.palette.grey[400],
      },
      [theme.breakpoints.up('tablet')]: {
        fontSize: '16px',
        lineHeight: '24px',
      },
      [theme.breakpoints.up('laptop')]: {
        fontSize: '18px',
        lineHeight: '27px',
      },
    }),
  },
};
