import {
  Alert,
  Box,
  Dialog,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  type Theme,
} from '@mui/material';

import { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { backgroundColor, shadowLG, errorColor } from '../../styles/variables';
import InputWithLabel from '../../components/inputWithLabel/InputWithLabel';
import { CloseIcon, AlertTriangleIcon } from '../../assets/icons';
import CheckEmailCard from './CheckEmailCard';
import { Form, Formik } from 'formik';
import { forgotPasswordSchema } from '../MyGiftShiftPage/MyGiftShift.utils';
import LoadingButton from '../../components/loading-button/LoadingButton';
import { AuthenticationContext } from '../../providers/authentication-provider';

interface ForgotPasswordProps {
  open: boolean;
  onClose: () => void;
}

const ForgotPasswordPage = ({ open, onClose }: ForgotPasswordProps) => {
  const {
    showResetLinkModal,
    loadingSubmitForgotPassword,
    setShowResetLinkModal,
    handleResetPassword,
    emailError,
    socialAccountExists,
    unconfirmedEmail,
    resetErrors,
  } = useContext(AuthenticationContext);

  const { t } = useTranslation();

  const isLandscape = useMediaQuery(
    (theme: Theme) => `${theme.breakpoints.down('laptop')} and (orientation: landscape)`
  );

  const handleClose = () => {
    setShowResetLinkModal(false);
    onClose();
  };

  const loginBoxStyles = {
    position: 'absolute',
    top: { mobile: 'unset', tablet: '50%' },
    left: '50%',
    bottom: { mobile: '0', tablet: 'unset' },
    transform: { mobile: 'translate(-50%, 0)', tablet: 'translate(-50%, -50%)' },
    bgcolor: 'background.paper',
    borderRadius: '16px',
    boxShadow: shadowLG,
    padding: {
      mobile: isLandscape ? '20px' : '76px 20px 20px',
      tablet: isLandscape ? '20px 50px' : '48px 128px',
      laptop: '75px 202px',
    },
    width: { mobile: '100%', tablet: '624px', laptop: '784px' },
    outline: 0,
    height: { mobile: '90vh', tablet: 'initial' },
  };

  const closeBtnStyles = {
    position: 'absolute',
    top: { mobile: '16px', tablet: '32px' },
    right: { mobile: '16px', tablet: '32px' },
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
      fullScreen
      hideBackdrop
      PaperProps={{
        sx: {
          bgcolor: backgroundColor,
        },
      }}
    >
      <Box sx={loginBoxStyles}>
        {!showResetLinkModal ? (
          <Box>
            <IconButton sx={closeBtnStyles} onClick={handleClose}>
              <CloseIcon />
            </IconButton>

            <Formik
              initialValues={{ email: '' }}
              validationSchema={forgotPasswordSchema(t)}
              onSubmit={handleResetPassword}
              validateOnChange={false}
              validateOnBlur={true}
            >
              {({ handleChange, errors, values, setErrors, handleBlur }) => (
                <Form>
                  <Stack gap={'24px'}>
                    <Typography variant="h2" mb={'4px'}>
                      {t('forgotYourPassword')}
                    </Typography>

                    <InputWithLabel
                      fullWidth
                      name="email"
                      labelName={t('emailAddress')}
                      value={values.email}
                      onBlur={handleBlur}
                      onChange={(e) => {
                        setErrors({});
                        handleChange(e);
                        resetErrors();
                      }}
                      error={!!errors.email}
                      errorMessage={errors.email}
                    />

                    <Box>
                      <LoadingButton
                        size="large"
                        variant="contained"
                        data-testid="ForgotPasswordButton"
                        type="submit"
                        fullWidth
                        loading={loadingSubmitForgotPassword}
                        disabled={
                          !(Object.keys(errors).length === 0) ||
                          loadingSubmitForgotPassword ||
                          !values.email
                        }
                        color="primary"
                      >
                        {t('resetPassword')}
                      </LoadingButton>
                    </Box>

                    {emailError && (
                      <Alert icon={<AlertTriangleIcon color={errorColor} />} severity={'error'}>
                        {t('invalidEmailAddress')}
                      </Alert>
                    )}

                    {socialAccountExists && (
                      <Alert icon={<AlertTriangleIcon color={errorColor} />} severity={'error'}>
                        {t('invalidEmailAddress')} {t('socialAccountExists')}
                      </Alert>
                    )}

                    {unconfirmedEmail && (
                      <Alert icon={<AlertTriangleIcon color={errorColor} />} severity={'error'}>
                        {t('unconfirmedEmail')}
                      </Alert>
                    )}
                  </Stack>
                </Form>
              )}
            </Formik>
          </Box>
        ) : (
          <CheckEmailCard handleClose={handleClose} closeBtnStyles={closeBtnStyles} />
        )}
      </Box>
    </Dialog>
  );
};

export default ForgotPasswordPage;
