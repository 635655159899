import type { FC, ReactNode } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { getPartnerById } from '../api/partners';
import PartnerDialog from '../components/partner-dialog/PartnerDialog';
import type { TDonationFrequency } from '../models/Donation';
import { type TPartner } from '../models/Partners';
import { DonationSteps } from '../pages/DonationFlowPage/DonationFlow.utils';
import { NotificationsContext } from './notifications-provider';

type WidgetData = {
  partnerName?: string;
  type?: string;
} | null;

export interface IDonationFromWidgetContext {
  loadingData: boolean;
  setLoadingData: (val: boolean) => void;
  preselectedCharitiesFromWidget: string[] | undefined;
  donationsAmountsFromWidget: string[] | undefined;
  preselectedDonationFrequency: TDonationFrequency;
  verticalImage: string | undefined;
  widgetData: WidgetData;
  partnerId: string | null;
}

export const DonationFromWidgetContext = createContext<IDonationFromWidgetContext>({
  loadingData: true,
  setLoadingData: () => {},
  preselectedCharitiesFromWidget: undefined,
  donationsAmountsFromWidget: undefined,
  preselectedDonationFrequency: 'one-time',
  verticalImage: undefined,
  widgetData: null,
  partnerId: null,
});

export const DonationFromWidgetProvider: FC<{ children?: ReactNode }> = (props) => {
  const [searchParams] = useSearchParams();

  // state

  const [isPartnerModalOpen, setIsPartnerModalOpen] = useState(false);
  const [partnerData, setPartnerData] = useState<TPartner | undefined>(undefined);
  const [preselectedCharitiesFromWidget, setPreselectedCharities] = useState<string[] | undefined>(
    undefined
  );

  const [donationsAmountsFromWidget, setDonationsAmountsFromWidget] = useState<
    string[] | undefined
  >(undefined);
  const [preselectedDonationFrequency, setPreselectedDonationFrequency] =
    useState<TDonationFrequency>(undefined);

  const [loadingData, setLoadingData] = useState(true);
  const { setNotification } = useContext(NotificationsContext);
  const { t } = useTranslation();

  const partnerId = searchParams.get('pid') || sessionStorage.getItem('pid');
  const widgetFromLocalStorage = localStorage.getItem('widgetData') as WidgetData;
  const widgetData: WidgetData = {
    partnerName: widgetFromLocalStorage?.partnerName,
    type: searchParams.get('w') || widgetFromLocalStorage?.type,
  };

  const charitiesString = searchParams.get('charities');
  const donationFrequencyFromWidget = searchParams.get('frequency');
  const donationsAmounts = searchParams.get('amounts');
  const step = searchParams.get('step');

  // fetching data

  const getPartnerData = async (partnerId: string) =>
    getPartnerById(partnerId)
      .then((res) => {
        setPartnerData(res.data);

        if (step === DonationSteps.SelectCharities.toString()) {
          setIsPartnerModalOpen(true);
        }

        return res.data;
      })
      .catch(() => {
        setNotification({
          type: 'error',
          message: t('somethingWentWrong', { interpolation: { skipOnVariables: false } }),
        });
      });

  const getPreselectedCharitiesFromURL = () => {
    const preselectedCharitiesIds = charitiesString?.split(',').map((id) => id) || [];
    setPreselectedCharities(preselectedCharitiesIds);
  };

  const getPreselectedDonationFrequency = () => {
    if (donationFrequencyFromWidget === 'one-time' || donationFrequencyFromWidget === 'monthly') {
      setPreselectedDonationFrequency(donationFrequencyFromWidget);
      localStorage.setItem('donationFrequency', donationFrequencyFromWidget);
    }
  };

  const getDonationsAmountsFromURL = () => {
    const preselectedCharitiesAmpounts = donationsAmounts?.split(',').map((amount) => amount) || [];
    setDonationsAmountsFromWidget(preselectedCharitiesAmpounts);
  };

  const handleWidgetData = async () => {
    let widgetObj: WidgetData = {};
    try {
      if (partnerId) {
        sessionStorage.setItem('pid', partnerId);
        const data = await getPartnerData(partnerId);
        if (data) {
          widgetObj = { ...widgetObj, partnerName: data.name };
        }
      }
    } finally {
      if (widgetData.type) {
        widgetObj = { ...widgetObj, type: widgetData.type };
      }

      getPreselectedCharitiesFromURL();
      getPreselectedDonationFrequency();
      getDonationsAmountsFromURL();

      if (Object.keys(widgetObj).length) {
        localStorage.setItem('widgetData', JSON.stringify(widgetObj));
      }
    }
  };

  useEffect(() => {
    handleWidgetData();
  }, []);

  return (
    <DonationFromWidgetContext.Provider
      value={{
        loadingData,
        setLoadingData,
        preselectedCharitiesFromWidget,
        preselectedDonationFrequency,
        donationsAmountsFromWidget,
        verticalImage: partnerData?.verticalImage,
        widgetData,
        partnerId,
      }}
    >
      {!!preselectedCharitiesFromWidget && props.children}
      {!!partnerData && (
        <PartnerDialog
          isOpen={isPartnerModalOpen}
          onContinue={() => {
            setIsPartnerModalOpen(false);
          }}
          partnerLogo={partnerData.image}
        />
      )}
    </DonationFromWidgetContext.Provider>
  );
};
