import { Grid, Skeleton, Stack } from '@mui/material';

export const AllWidgetsPageSkeleton = () => {
  return (
    <Grid container gap={8} flexWrap={{ mobile: 'wrap', laptop: 'nowrap' }}>
      <Grid item mobile={12} tablet={4}>
        <Stack gap={1}>
          <Skeleton variant="rounded" width={'100%'} height="24px" />
          <Skeleton variant="rounded" width={'100%'} height={'400px'} />
        </Stack>
      </Grid>

      <Grid item mobile={12} tablet={4}>
        <Stack gap={1}>
          <Skeleton variant="rounded" width={'100%'} height="24px" />
          <Skeleton variant="rounded" width={'100%'} height={'400px'} />
        </Stack>
      </Grid>
    </Grid>
  );
};

export default AllWidgetsPageSkeleton;
