import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import LoadingComponent from '../../components/loading/LoadingComponent';
import { LanguageContext } from '../../providers/language-selector-provider';

const BasenameRedirectPage = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { allPaths } = useContext(LanguageContext);

  useEffect(() => {
    if (allPaths.length) {
      const definedRoute = allPaths.find((route) =>
        route?.includes(location.pathname.split('/').pop() || '')
      );

      navigate(definedRoute ? `/${definedRoute}${location.search}` : '/');
    }
  }, [allPaths]);

  return <LoadingComponent />;
};

export default BasenameRedirectPage;
