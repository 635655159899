import { Box, IconButton, type Theme, Typography, useMediaQuery } from '@mui/material';
import { t } from 'i18next';
import EmailIllustration from '../../assets/EmailIllustration';
import { CloseIcon } from '../../assets/icons';

interface CheckEmailCardProps {
  closeBtnStyles: object;
  handleClose: () => void;
}

const CheckEmailCard = ({ closeBtnStyles, handleClose }: CheckEmailCardProps) => {
  const isLandscape = useMediaQuery(
    (theme: Theme) => `${theme.breakpoints.down('laptop')} and (orientation: landscape)`
  );

  const isLandscapeSmallHeight = useMediaQuery('(max-height:350px)');

  return (
    <Box textAlign="center">
      <IconButton sx={closeBtnStyles} onClick={handleClose}>
        <CloseIcon />
      </IconButton>
      <Box mb={'24px'}>
        <EmailIllustration
          height={isLandscape ? (isLandscapeSmallHeight ? '130px' : '190px') : 'undefined'}
        />
      </Box>
      <Typography variant="h2" mb={'10px'} mx={{ laptop: '15px' }}>
        {t('youHaveReceivedAnEmail')}
      </Typography>
      <Typography fontSize={'16px'} color="textSecondary">
        {t('useLinkToResetPassword')}
      </Typography>
    </Box>
  );
};

export default CheckEmailCard;
