import {
  Alert,
  Box,
  Button,
  Card,
  Grid,
  OutlinedInput,
  RadioGroup,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { config } from '../../api/config';
import { LogoGrey } from '../../assets/LogoGrey';
import { AlertTriangleIcon } from '../../assets/icons';
import { DonationSteps } from '../../pages/DonationFlowPage/DonationFlow.utils';
import {
  desktopL,
  disabledColor,
  errorColor,
  mobileM,
  primaryColor,
  textColor,
  white,
} from '../../styles/variables';
import ImageMolecule from '../molecules/ImageMolecule';
import RadioButtonLabeled from '../radio-button-labeled/RadioButtonLabeled';

//types
import type { Theme } from '@mui/material';
import type { TSelectedCharity } from '../../models/Charities';
import type { TDonationFrequency } from '../../models/Donation';
import type { WidgetProps } from './types';

const currency = '€';

export const Widget3 = ({
  charities = [],
  image,
  previewImage,
  headingColor,
  subHeadingColor,
  buttonBgColor,
  buttonBorderRadius = 8,
  buttonTextColor,
  partnerId,
  language,
  headingText,
  subHeadingText,
  buttonText,
  selectedCountry,
  selectedLanguage,
}: WidgetProps) => {
  const { t } = useTranslation(undefined, { lng: language });
  const [frequency, setFrequency] = useState<TDonationFrequency>('one-time');
  const [selectedCharities, setSelectedCharities] = useState<TSelectedCharity[]>(charities);
  const [disableButton, setDisableButton] = useState(true);
  const [showMinValueError, setShowMinValueError] = useState(false);
  const [charityWithoutAmount, setCharityWithoutAmount] = useState(false);

  useEffect(() => {
    const charityWithNoAmount = selectedCharities.find((charity) => {
      if (!charity.donationAmount) {
        return true;
      }

      return parseInt(charity.donationAmount) <= 0;
    });

    setCharityWithoutAmount(!!charityWithNoAmount);

    const totalAmount = selectedCharities.reduce(
      (prev, acc) => prev + (acc?.donationAmount ? parseInt(acc.donationAmount) : 0),
      0
    );

    setShowMinValueError(totalAmount < 5);

    if (charityWithNoAmount || totalAmount < 5) {
      setDisableButton(true);
    } else {
      setDisableButton(false);
    }
  }, [selectedCharities]);

  const onAmountChange = (charityId: string, newAmount: string) => {
    setSelectedCharities(
      selectedCharities.map((charity) => ({
        ...charity,
        donationAmount: charityId === charity.id ? newAmount : charity.donationAmount,
      }))
    );
  };

  const onFrequencyChange = (frequency: TDonationFrequency) => {
    setFrequency(frequency);
  };

  const isNotMobile = useMediaQuery((theme: Theme) => theme.breakpoints.up(768));

  const formatRedirectLink = () => {
    const chartiesIds = selectedCharities.map((el) => el.id).join(',');
    const amounts = selectedCharities.map((el) => el.donationAmount).join(',');

    return `${config.FE_APP_URL}/${selectedCountry}/${selectedLanguage}/${
      language === 'en' ? 'donate' : 'doneren'
    }?w=3&pid=${partnerId}&step=${DonationSteps.Authentication}&charities=${chartiesIds}&frequency=${frequency}&amounts=${amounts}`;
  };

  const cardStyles = {
    borderRadius: '12px',
    margin: '4px auto',
    padding: '24px 20px',
    alignSelf: 'stretch',
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
    backgroundColor: white,
    boxShadow: desktopL,
    maxWidth: '800px',
  };

  const buttonStyles = {
    width: { mobile: '100%', tablet: 'auto' },
    backgroundColor: buttonBgColor || primaryColor,
    color: buttonTextColor || white,
    border: `1px solid ${buttonBgColor}`,
    '&:hover': { backgroundColor: buttonBgColor || primaryColor },
    '&:focus': {
      border: '2px solid #FFF',
      boxShadow: `0px 0px 0px 2px ${buttonBgColor}`,
    },
    '&.MuiButton-root': {
      borderRadius: `${buttonBorderRadius}px`,
    },
  };

  return (
    <Card sx={cardStyles}>
      <Grid container direction="column">
        <Typography variant="h3" sx={{ color: headingColor }}>
          {headingText}
        </Typography>
        <Typography variant="paragraphM" sx={{ color: subHeadingColor }}>
          {subHeadingText}
        </Typography>
      </Grid>
      {image && (
        <ImageMolecule image={previewImage || image} proportions="2/1" backgroundSize="contain" />
      )}
      <Box
        display="grid"
        gridTemplateColumns={{ mobile: '1fr', tablet: 'repeat(3, 1fr)' }}
        gap={{ mobile: '10px', tablet: '16px' }}
      >
        {charities?.map((charity) => (
          <Stack key={charity.id} direction="row" alignItems="center" gap="16px">
            <Box
              display="flex"
              justifyContent="center"
              boxShadow={mobileM}
              borderRadius="6px"
              maxWidth="112px"
              flex={{ mobile: '0 0 112px', tablet: '0 0 calc(50% - 8px)' }}
            >
              <img
                src={charity.logo}
                alt="logo"
                style={{ width: '100%', objectFit: 'contain', borderRadius: 'inherit' }}
              />
            </Box>

            <OutlinedInput
              sx={{
                width: { mobile: '100%', tablet: 'initial' },
                flex: { mobile: 'auto', tablet: '0 0 calc(50% - 8px)' },
                maxWidth: { mobile: '100%', tablet: '0 0 calc(50% - 8px)' },
                height: '49px !important',
                'input[type="number"]': {
                  padding: '11px 14px 12px',
                  fontSize: '16px',
                },
              }}
              startAdornment={
                <Typography fontSize="16px" fontWeight={600}>
                  {currency}
                </Typography>
              }
              onKeyDown={(e) => {
                const disabledKeys = ['e', '-', '.'];
                if (disabledKeys.includes(e.key)) {
                  e.preventDefault();
                }
              }}
              onChange={(e) => {
                onAmountChange(charity.id, e.target.value);
              }}
              placeholder="0"
              type="number"
              inputProps={{ min: 0, pattern: '[0-9]*' }}
            />
          </Stack>
        ))}
      </Box>

      <RadioGroup
        aria-label="donation-frequency"
        aria-labelledby="donation-frequency"
        name="donation-frequency"
        value={frequency}
        onChange={(e) => {
          onFrequencyChange(e.target.value as TDonationFrequency);
        }}
      >
        <Stack
          gap={{ mobile: 0, tablet: '32px' }}
          flexDirection={{ mobile: 'column', tablet: 'row' }}
        >
          <RadioButtonLabeled
            label={<Typography fontSize="13px">{t('oneTime')}</Typography>}
            value="one-time"
            sx={{
              marginRight: '3px',
            }}
            flex={isNotMobile ? 'initial' : '0 0 40%'}
            marginRight="0"
          />
          <RadioButtonLabeled
            label={
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Stack
                  gap={1}
                  sx={{
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <Typography fontSize="13px">{t('monthly')}</Typography>
                  <Typography fontSize="9px" color={textColor}>
                    ({t('cancellableAnyTime')})
                  </Typography>
                </Stack>
              </Box>
            }
            value="monthly"
            sx={{
              marginRight: '3px',
            }}
            flex={isNotMobile ? 'initial' : '0 0 60%'}
            marginRight="0"
          />
        </Stack>
      </RadioGroup>
      {showMinValueError && !charityWithoutAmount ? (
        <Alert icon={<AlertTriangleIcon color={errorColor} />} severity={'error'}>
          {t('minDonationAmountError')}
        </Alert>
      ) : null}
      <Grid container spacing="24px">
        <Grid item mobile={12} tablet={6}>
          <Link
            target="_parent_blank"
            to={formatRedirectLink()}
            style={{ pointerEvents: disableButton ? 'none' : 'auto' }}
          >
            <Button
              variant="contained"
              size="large"
              color="primary"
              disabled={disableButton}
              fullWidth
              sx={buttonStyles}
            >
              {buttonText}
            </Button>
          </Link>
        </Grid>

        <Grid
          item
          container
          mobile={12}
          tablet={6}
          justifyContent={{ mobile: 'center', tablet: 'flex-end' }}
          alignItems={'center'}
        >
          <Stack direction="row" gap="8px" alignItems="center" justifyContent="center">
            <Typography variant="paragraphXS" color={disabledColor}>
              Powered by
            </Typography>
            <LogoGrey width="39" height="19.5" />
          </Stack>
        </Grid>
      </Grid>
    </Card>
  );
};

export default Widget3;
