import { IdealLogo } from '../../../../assets/IdealLogo';
import { MastercardLogo } from '../../../../assets/MastercardLogo';
import { SepaLogo } from '../../../../assets/SepaLogo';
import IdealDropdown from './IdealDropdown';

// types
import type { CSSProperties } from 'react';
import type { TPaymentMethod } from '../../../../models/Donation';

type PaymentComponent = {
  props: { paymentMethod: string; donationFrequency?: string; paymentProvider: string };
  component: JSX.Element;
  extraStyles?: CSSProperties;
};

export const PAYMENT_COMPONENTS = (
  paymentMethod: TPaymentMethod['paymentMethod'],
  handleChoosePaymentMethod: (paymentMethod: TPaymentMethod) => void
): PaymentComponent[] => [
  {
    props: { paymentMethod: 'ideal', paymentProvider: 'Stripe', donationFrequency: 'one-time' },
    component: (
      <>
        <IdealLogo />
        {paymentMethod === 'ideal' && (
          <IdealDropdown
            onChange={(isSelected) =>
              handleChoosePaymentMethod({
                paymentMethod: 'ideal',
                isSubElementSelected: isSelected,
              })
            }
          />
        )}
      </>
    ),
    extraStyles: {
      overflow: 'visible',
      flexWrap: 'wrap',
      justifyContent: 'flex-end',
    },
  },
  {
    props: { paymentMethod: 'sepa_debit', paymentProvider: 'Stripe', donationFrequency: 'monthly' },
    component: <SepaLogo />,
  },
  {
    props: { paymentMethod: 'card', paymentProvider: 'Stripe' },
    component: <MastercardLogo />,
  },
];
