import { type Theme } from '@mui/material/styles';

export const MuiDividerStyles = {
  styleOverrides: {
    root: ({ theme }: { theme: Theme }) => ({
      color: theme.palette.grey[700],
      '&::before': {
        borderTop: `thin solid ${theme.palette.grey[700]}`,
      },
      '&::after': {
        borderTop: `thin solid ${theme.palette.grey[700]}`,
      },
    }),
  },
};
