import { useNavigate } from 'react-router-dom';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import BundleCardGridItem from '../BundleCardGridItem';
import CurrentBundleData from './CurrentBundleData';
import { MyGiftShiftContext } from '../../../../../providers/my-giftshift-provider';
import { LanguageContext } from '../../../../../providers/language-selector-provider';

export const BundleSection = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { bundleInfo } = useContext(MyGiftShiftContext);
  const { isEnglish } = useContext(LanguageContext);

  const handleAdjustBundle = () => {
    localStorage.setItem('donationFrequency', 'monthly');
    localStorage.setItem('monthlyDonor', 'yes');
    navigate(isEnglish ? '/donate' : '/doneren');
  };
  return (
    <BundleCardGridItem
      title={t('bundle')}
      content={<CurrentBundleData />}
      actionLabel={bundleInfo.status !== 'inactive' ? t('adjust') : undefined}
      handleAction={handleAdjustBundle}
    />
  );
};

export default BundleSection;
