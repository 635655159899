import { type Theme } from '@mui/material/styles';

export const TypographyStyles = (theme: Theme) => ({
  h1: {
    fontSize: '28px',
    fontWeight: 700,
    lineHeight: 'normal',
    fontStyle: 'normal',
    [theme.breakpoints.up('tablet')]: {
      fontSize: '33px',
    },
    [theme.breakpoints.up('laptop')]: {
      fontSize: '38px',
    },
  },
  h2: {
    fontSize: '22px',
    fontWeight: 700,
    lineHeight: 'normal',
    fontStyle: 'normal',
    [theme.breakpoints.up('tablet')]: {
      fontSize: '26px',
    },
    [theme.breakpoints.up('laptop')]: {
      fontSize: '30px',
    },
  },
  h3: {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: 'normal',
    fontStyle: 'normal',
    [theme.breakpoints.up('tablet')]: {
      fontSize: '21px',
    },
    [theme.breakpoints.up('laptop')]: {
      fontSize: '24px',
    },
  },
  h4: {
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: 'normal',
    fontStyle: 'normal',
    [theme.breakpoints.up('tablet')]: {
      fontSize: '18px',
    },
    [theme.breakpoints.up('laptop')]: {
      fontSize: '20px',
    },
  },
  h5: {
    fontSize: '14px',
    fontWeight: 700,
    lineHeight: 'normal',
    fontStyle: 'normal',
    [theme.breakpoints.up('tablet')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.up('laptop')]: {
      fontSize: '18px',
    },
  },
  h6: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: 'normal',
    fontStyle: 'normal',
    [theme.breakpoints.up('tablet')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.up('laptop')]: {
      fontSize: '16px',
    },
  },
  subtitle: {
    fontSize: '10px',
    fontWeight: 600,
    lineHeight: 'normal',
    fontStyle: 'normal',
    textTransform: 'uppercase',
    letterSpacing: '0.8px',
    [theme.breakpoints.up('tablet')]: {
      fontSize: '11px',
      letterSpacing: '0.88px',
    },
    [theme.breakpoints.up('laptop')]: {
      fontSize: '12px',
      letterSpacing: '0.96px',
    },
  },
  paragraphL: {
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '150%',
    fontStyle: 'normal',
    [theme.breakpoints.up('tablet')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.up('laptop')]: {
      fontSize: '18px',
    },
  },
  paragraphM: {
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '150%',
    fontStyle: 'normal',
    [theme.breakpoints.up('tablet')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.up('laptop')]: {
      fontSize: '16px',
    },
  },
  paragraphS: {
    fontSize: '11px',
    fontWeight: 400,
    lineHeight: '150%',
    fontStyle: 'normal',
    [theme.breakpoints.up('tablet')]: {
      fontSize: '12px',
    },
    [theme.breakpoints.up('laptop')]: {
      fontSize: '14px',
    },
  },
  paragraphXS: {
    fontSize: '9px',
    fontWeight: 400,
    lineHeight: '150%',
    fontStyle: 'normal',
    [theme.breakpoints.up('tablet')]: {
      fontSize: '10px',
    },
    [theme.breakpoints.up('laptop')]: {
      fontSize: '12px',
    },
  },
  menuItem: {
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: 'normal',
    fontStyle: 'normal',
    [theme.breakpoints.up('tablet')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.up('laptop')]: {
      fontSize: '15px',
    },
  },
  buttonL: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: 'normal',
    fontStyle: 'normal',
    [theme.breakpoints.up('tablet')]: {
      fontSize: '17px',
    },
    [theme.breakpoints.up('laptop')]: {
      fontSize: '18px',
    },
  },
  buttonM: {
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: 'normal',
    fontStyle: 'normal',
    [theme.breakpoints.up('tablet')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.up('laptop')]: {
      fontSize: '15px',
    },
  },
  buttonS: {
    fontSize: '10px',
    fontWeight: 600,
    lineHeight: 'normal',
    fontStyle: 'normal',
    [theme.breakpoints.up('tablet')]: {
      fontSize: '11px',
    },
    [theme.breakpoints.up('laptop')]: {
      fontSize: '12px',
    },
  },
  quote: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '150%',
    fontStyle: 'normal',
    letterSpacing: '-0.304px',
    fontFamily: 'Kalam',
  },
});
