import { axiosPrivate } from '../common/axiosPrivate';
import { axiosPublic } from '../common/axiosPublic';
import type { WidgetConfiguratorProps } from '../components/widget-configurator/types';
import { type TPartner } from '../models/Partners';

export const getPartners = async () => await axiosPrivate.get('/partners/');

export const getPartnerById = async (partnerId: string) =>
  await axiosPublic.get<TPartner>(`/partner/${partnerId}/`);

export const getAllWidgets = async () => await axiosPrivate.get('/widget/');

export const getWidgetByPartnerId = async (partnerId: string) =>
  await axiosPublic.get(`/widget/?partnerId=${partnerId}`);

export const saveWidgetData = async (
  widgetData: Parameters<Pick<WidgetConfiguratorProps, 'onSaveWidgetData'>['onSaveWidgetData']>['0']
) => await axiosPrivate.post('/widget/', { ...widgetData });

export const saveWidgetImage = async (parterId: string, image: FormData | Blob | string) =>
  await axiosPrivate.patch(`/widget/?partnerId=${parterId}`, image, {
    headers: { 'Content-Type': 'multipart/form-data' },
  });
