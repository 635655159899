import { Stack, Tab, Tabs, Typography } from '@mui/material';
import { useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { AuthenticationContext } from '../../../../providers/authentication-provider';
import { DonationFlowContext } from '../../../../providers/donation-flow-provider';
import CheckExistingBundleSkeleton from '../check-existing-bundle-step/CheckExistingBundleSkeleton';
import AnonymousTabContent from './AnonymousTabContent';
import CheckEmailForActivation from './CheckEmailForActivation';
import RegistrationAndLoginTabContent from './RegistrationAndLoginTabContent';

export const RegistrationAndLoginStep = () => {
  const [tabValue, setTabValue] = useState('register');
  const { checkYourEmailState } = useContext(AuthenticationContext);
  const { donationFrequency, loadingData } = useContext(DonationFlowContext);
  const { t } = useTranslation();

  if (loadingData) {
    return <CheckExistingBundleSkeleton />;
  }

  return (
    <Stack
      gap={4}
      maxWidth={'510px'}
      mx="auto"
      p={{ mobile: '20px', tablet: '20px 0', laptop: '0' }}
      width="100%"
    >
      {checkYourEmailState ? (
        <CheckEmailForActivation />
      ) : donationFrequency === 'one-time' ? (
        <>
          <Tabs
            variant="fullWidth"
            aria-label="registrationTabs"
            value={tabValue}
            onChange={(_, newVal) => {
              setTabValue(newVal);
            }}
            sx={{
              typography: {
                fontVariant: 'subtitle',
              },
            }}
          >
            <Tab value="register" label={t('register')} data-testid={'RegisterTab'} />
            <Tab value="anonymous" label={t('noRegistration')} data-testid={'NoRegistrationTab'} />
          </Tabs>
          {tabValue === 'register' ? <RegistrationAndLoginTabContent /> : <AnonymousTabContent />}
        </>
      ) : (
        <Stack>
          <Typography variant="h2">{t('register')}</Typography>
          <RegistrationAndLoginTabContent />
        </Stack>
      )}
    </Stack>
  );
};

export default RegistrationAndLoginStep;
