import { useTranslation } from 'react-i18next';
import { InputAdornment, OutlinedInput } from '@mui/material';
import SearchIcon from '../../../../assets/icons/SearchIcon';
import { backgroundColor } from '../../../../styles/variables';

type CharitiesSearchbarProps = {
  searchTerm: string;
  setSearchTerm: (value: string) => void;
};

const CharitiesSearchbar = ({ searchTerm, setSearchTerm }: CharitiesSearchbarProps) => {
  const { t } = useTranslation();

  const onCharitySearch = (value: string) => {
    setSearchTerm(value);
  };

  const CharitiesSearchbarStyles = {
    marginBottom: { mobile: 3, tablet: 4 },
    maxHeight: { mobile: '36px', tablet: '43px' },
    padding: {
      mobile: '11px 12px 12px 12px',
      tablet: '13px 14px 14px 14px',
    },
    '.MuiOutlinedInput-notchedOutline': { borderWidth: '2px' },
    '&:hover': {
      '.MuiOutlinedInput-notchedOutline': { borderWidth: '2px', borderColor: backgroundColor },
    },
    '&::placeholder': { color: 'red' },
  };

  return (
    <OutlinedInput
      fullWidth
      name="searchCharities"
      type="text"
      value={searchTerm}
      onChange={(e) => onCharitySearch(e.target.value)}
      placeholder={t('search')}
      sx={CharitiesSearchbarStyles}
      startAdornment={
        <InputAdornment position="start">
          <SearchIcon />
        </InputAdornment>
      }
    />
  );
};

export default CharitiesSearchbar;
