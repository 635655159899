import { type TSelectedCharity } from '../../models/Charities';
import { type TColorPickerOpen } from './types';

const hasEqualValues = (prevArray: string[], currentArray: string[]) => {
  const prevArraySorted = [...prevArray].sort();
  const currentArraySorted = [...currentArray].sort();

  return JSON.stringify(prevArraySorted) === JSON.stringify(currentArraySorted);
};

export const hasChangedCharities = (
  prevArray: TSelectedCharity[] | undefined,
  currentArray: TSelectedCharity[]
) => {
  if (prevArray) {
    const prevArrayIds = prevArray.map((value) => value.id);
    const currentArrayIds = currentArray.map((value) => value.id);

    return !hasEqualValues(prevArrayIds, currentArrayIds);
  }

  return !(currentArray.length === 0);
};

export const isFieldChanged = (
  prevValue: string | number | undefined,
  currentValue: string | number
) => !(prevValue === currentValue);

export const openColorPickerDefaultValues: TColorPickerOpen = {
  bgButton: false,
  textButton: false,
  heading: false,
  subHeading: false,
};

export const tranformImageForPreview = (image: Blob, setPreview: (image: string) => void) => {
  const reader = new FileReader();

  reader.readAsDataURL(image);

  reader.onloadend = (e) => {
    setPreview(e.target?.result as string);
  };
};
