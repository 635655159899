import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface CalloutProps {
  content: string;
  customStyles?: object;
}

export const Callout = ({ content, customStyles }: CalloutProps) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        p: { mobile: 2, tablet: 2.5, laptop: 3 },
        backgroundColor: theme.palette.grey[500],
        borderRadius: '12px',
        ...customStyles,
      }}
    >
      <Typography variant="paragraphM">{content}</Typography>
    </Box>
  );
};

export default Callout;
