import { Button, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MyGiftShiftContext } from '../../../../providers/my-giftshift-provider';
import { textColor } from '../../../../styles/variables';
import { AuthenticationContext } from '../../../../providers/authentication-provider';

export const RenderNewsletterField = ({
  updateRenderStates,
}: {
  updateRenderStates: () => void;
}) => {
  const { handleUpdateNewsletter } = useContext(MyGiftShiftContext);
  const {
    userDetails: { newsletter },
  } = useContext(AuthenticationContext);
  const { t } = useTranslation();

  const onSuccess = () => {
    updateRenderStates();
  };
  return (
    <Stack gap="8px">
      <Typography variant="paragraphM" color={textColor}>
        {t(newsletter ? 'youAreCurrentlySubscribed' : 'youAreCurrentlyUnsubscribed')}
      </Typography>
      <Button
        variant="contained"
        color={newsletter ? 'error' : 'primary'}
        sx={{ width: 'fit-content' }}
        onClick={() => {
          handleUpdateNewsletter(!newsletter, onSuccess);
        }}
      >
        {t(newsletter ? 'unsubscribe' : 'subscribe')}
      </Button>
    </Stack>
  );
};
