export const CheckCircleIcon = ({
  width = '24',
  height = '24',
}: {
  width?: string;
  height?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.333374" width={width} height={height} rx="12" fill="white" />
    <path
      d="M0.333374 12C0.333374 5.39062 5.67712 0 12.3334 0C18.9427 0 24.3334 5.39062 24.3334 12C24.3334 18.6562 18.9427 24 12.3334 24C5.67712 24 0.333374 18.6562 0.333374 12ZM17.724 9.9375C18.2396 9.42188 18.2396 8.625 17.724 8.10938C17.2084 7.59375 16.4115 7.59375 15.8959 8.10938L10.8334 13.1719L8.724 11.1094C8.20837 10.5938 7.4115 10.5938 6.89587 11.1094C6.38025 11.625 6.38025 12.4219 6.89587 12.9375L9.89587 15.9375C10.4115 16.4531 11.2084 16.4531 11.724 15.9375L17.724 9.9375Z"
      fill="#71DE82"
    />
  </svg>
);

export default CheckCircleIcon;
