import { Drawer, Box, Typography, Button, Stack, IconButton, Badge } from '@mui/material';
import { useContext } from 'react';
import { CloseIcon } from '../../assets/icons';
import { Link, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { white } from '../../styles/variables';
import { AuthenticationContext } from '../../providers/authentication-provider';
import { PublicContext } from '../../providers/public-provider';
import { LanguageContext } from '../../providers/language-selector-provider';
import CountrySelector from '../country-selector/CountrySelector';

interface NavbarMenuDrawerProps {
  open: boolean;
  onClose: () => void;
  menuItems: {
    key: number;
    text: string;
    linkEn: string;
    linkNl: string;
  }[];
}

export const NavbarMenuDrawer = ({ open, onClose, menuItems }: NavbarMenuDrawerProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { authenticatedUser } = useContext(AuthenticationContext);
  const { currentBundle } = useContext(PublicContext);
  const { isEnglish } = useContext(LanguageContext);

  return (
    <Drawer
      anchor="left"
      open={open}
      onClose={onClose}
      PaperProps={{
        sx: {
          width: '100%',
          backgroundColor: 'primary.main',
          padding: { mobile: '92px 36px 52px', tablet: '92px 130px 52px' },
        },
      }}
    >
      <IconButton sx={{ position: 'absolute', top: '32px', right: '28px' }} onClick={onClose}>
        <CloseIcon color={white} />
      </IconButton>
      <Box sx={{ width: '100%', margin: 'auto' }}>
        <Stack gap={7} justifyItems={'center'}>
          <Stack gap={4}>
            {menuItems.map((menuItem) => (
              <Link
                key={menuItem.key}
                to={`${isEnglish ? menuItem.linkEn : menuItem.linkNl}`}
                style={{ textDecoration: 'none' }}
              >
                <Typography variant="h3" color="white">
                  {`${menuItem.text.slice(0, 1).toUpperCase()}${menuItem.text.slice(1)}`}
                </Typography>
              </Link>
            ))}
          </Stack>
          <Stack gap={2}>
            {authenticatedUser ? (
              <Button
                variant="outlined"
                size="large"
                color="light"
                onClick={() => {
                  navigate(isEnglish ? '/my-giftshift' : '/mijn-giftshift');
                  onClose();
                }}
                data-testid="MyGiftShiftButton"
              >
                {t('myGiftShift')}
              </Button>
            ) : (
              <Button
                variant="outlined"
                size="large"
                color="light"
                onClick={() => {
                  navigate('/login');
                }}
                data-testid="LoginButton"
              >
                {t('login')}
              </Button>
            )}
            <Badge badgeContent={currentBundle.length || undefined} color="error">
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                size="large"
                onClick={() => {
                  navigate(isEnglish ? '/donate' : '/doneren');
                }}
                data-testid="DonateButton"
              >
                {t('donate')}
              </Button>
            </Badge>
          </Stack>
          <Stack width="fit-content">
            <CountrySelector />
          </Stack>
        </Stack>
      </Box>
    </Drawer>
  );
};

export default NavbarMenuDrawer;
