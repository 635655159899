import { Button, Dialog, DialogActions, IconButton, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DeleteAccountIllustration } from '../../../../assets/DeleteAccountIllustration';
import { CloseIcon } from '../../../../assets/icons';
import LoadingButton from '../../../../components/loading-button/LoadingButton';
import { AuthenticationContext } from '../../../../providers/authentication-provider';
import { MyGiftShiftContext } from '../../../../providers/my-giftshift-provider';
import { textColor } from '../../../../styles/variables';

interface ConfirmDeleteDialogProps {
  open: boolean;
  onClose: () => void;
}

export const AccountDeletionDialog = ({ open, onClose }: ConfirmDeleteDialogProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { handleDeleteAccount } = useContext(MyGiftShiftContext);
  const { handleLogout } = useContext(AuthenticationContext);

  const onDeleteAccount = async () => {
    setLoading(true);
    await handleDeleteAccount().then(() => {
      handleLogout();
      setLoading(false);
      onClose();
    });
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-delete-dialog-title"
      aria-describedby="confirm-delete-dialog-description"
      maxWidth="tablet"
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          padding: { mobile: '76px 20px', tablet: 10 },
          borderRadius: { mobile: '20px 20px 0 0 ', tablet: '16px' },
          gap: 4,
          margin: { mobile: 0, tablet: 9 },
          width: '100%',
          alignItems: 'center',
        },
        '& .MuiDialog-container': {
          alignItems: { mobile: 'flex-end', tablet: 'center' },
        },
      }}
    >
      <DeleteAccountIllustration />

      <Stack gap="8px">
        <Typography variant="h3" id="confirm-delete-dialog-title" textAlign="center">
          {t('areYouSure')}
        </Typography>

        <Typography variant="paragraphL" color={textColor} textAlign="center">
          {t('ifYouDeleteYourAccount')}
        </Typography>
      </Stack>

      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          padding: 2,
          right: 16,
          top: 16,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogActions sx={{ flexDirection: 'column', padding: 0, gap: 4 }}>
        <LoadingButton
          onClick={onDeleteAccount}
          size="large"
          variant="contained"
          color="error"
          loading={loading}
          disabled={loading}
        >
          {t('deleteAccount')}
        </LoadingButton>
        <Button size="large" variant="text" onClick={onClose}>
          {t('keepAccount')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
