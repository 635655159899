import { Outlet, ScrollRestoration } from 'react-router-dom';
import Navbar from '../../components/navbar/Navbar';

export const AppWrapper = ({ lightMode = false }: { lightMode?: boolean }) => {
  return (
    <>
      <Navbar lightMode={lightMode} />
      <ScrollRestoration />
      <Outlet />
    </>
  );
};

export default AppWrapper;
