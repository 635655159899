import { Container, Grid, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getAllWidgets, getPartnerById } from '../../api/partners';
import Widget from '../../components/widgets/Widget';
import { type AllWidgetsProps } from '../../components/widgets/types';
import { NotificationsContext } from '../../providers/notifications-provider';
import { PublicContext } from '../../providers/public-provider';
import AllWidgetsPageSkeleton from './AllWidgetsPageSkeleton';

export const AllWidgetsPage = () => {
  const [widgets, setWidgets] = useState<AllWidgetsProps[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { setNotification } = useContext(NotificationsContext);
  const { t } = useTranslation();
  const { logos } = useContext(PublicContext);

  const matchWidgetsWithIds = async (widgets: AllWidgetsProps[]) => {
    await Promise.all(widgets.map(async (widget) => await getPartnerById(widget.partnerId)))
      .then((res) => {
        const widgetsWithNames = widgets.map((widget) => ({
          ...widget,
          name: res.find((resData) => widget.partnerId === resData.data.id)?.data.name,
          charities: widget.charities?.map((charity) => ({ ...charity, logo: charity.s3Url })),
        }));

        setWidgets(widgetsWithNames);
      })
      .catch(() => {
        setNotification({
          type: 'error',
          message: t('somethingWentWrong', { message: t('pleaseTryAgain') }),
        });
      });
  };

  useEffect(() => {
    setIsLoading(true);
    getAllWidgets()
      .then(async (res) => {
        await matchWidgetsWithIds(res.data);
      })
      .catch(() => {
        setNotification({
          type: 'error',
          message: t('somethingWentWrong', { message: t('pleaseTryAgain') }),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, []);

  return (
    <Container maxWidth="desktop" sx={{ marginTop: '20px', marginBottom: '20px' }}>
      {isLoading ? (
        <AllWidgetsPageSkeleton />
      ) : (
        <Grid container spacing={8} flexWrap="wrap">
          {widgets?.map((widget: AllWidgetsProps) => {
            return (
              <Grid item mobile={12} tablet={6} key={widget.name}>
                <Stack gap={1}>
                  <Typography variant="h3">{widget.name}</Typography>
                  <Widget
                    variant={widget.variant}
                    image={widget.image}
                    buttonBgColor={widget.buttonBgColor}
                    buttonTextColor={widget.buttonTextColor}
                    buttonBorderRadius={widget.buttonBorderRadius}
                    headingColor={widget.headingColor}
                    subHeadingColor={widget.subHeadingColor}
                    charities={widget.charities}
                    logos={logos}
                    language={widget.language}
                    headingText={widget.headingText}
                    buttonText={widget.buttonText}
                    subHeadingText={widget.subHeadingText}
                  />
                </Stack>
              </Grid>
            );
          })}
        </Grid>
      )}
    </Container>
  );
};

export default AllWidgetsPage;
