import { Checkbox, FormControlLabel, type CheckboxProps } from '@mui/material';
import { type ReactNode } from 'react';
import { CheckboxCheckedIcon, CheckboxOutlinedIcon } from '../../assets/icons';

export const CheckboxLabeled = (
  props: CheckboxProps & { label: ReactNode | string | undefined }
) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          {...props}
          sx={{ marginRight: '12px' }}
          checkedIcon={<CheckboxCheckedIcon />}
          icon={<CheckboxOutlinedIcon />}
        />
      }
      sx={{ marginLeft: 0 }}
      label={props.label}
      componentsProps={{ typography: { variant: 'paragraphM' } }}
    />
  );
};

export default CheckboxLabeled;
