import { darkBlue } from '../variables';

export const MuiSelectStyles = {
  styleOverrides: {
    icon: () => ({
      color: darkBlue,
      top: 'calc(50% - 0.7em)',
    }),
  },
};
