import { Box, Grid, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';
import InactiveCircleSvg from '../../../../../assets/svg/InactiveCircleSvg';
import SuccessCircleSvg from '../../../../../assets/svg/SuccessCircleSvg';
import LoadingButton from '../../../../../components/loading-button/LoadingButton';
import { MyGiftShiftContext } from '../../../../../providers/my-giftshift-provider';
import EditDonationPaymentDate from '../../../components/EditDonationPaymentDate';
import PendingCircleSvg from '../../../../../assets/svg/PendingCircleSvg';

interface StatusPausedEditStateProps {
  updateEditState: () => void;
}

export const StatusPausedEditState = ({ updateEditState }: StatusPausedEditStateProps) => {
  const { t } = useTranslation();
  const { bundleInfo, handleReactivateBundle } = useContext(MyGiftShiftContext);
  const [loadingResume, setLoadingResume] = useState(false);
  const [newPaymentDateValue, setNewPaymentDateValue] = useState<string>(
    bundleInfo.nextDonationDate
  );

  const today = new Date();
  const [min] = today.toISOString().split('T');

  const handleResumeSubscription = async () => {
    setLoadingResume(true);
    await handleReactivateBundle(newPaymentDateValue);
    updateEditState();
    setLoadingResume(false);
  };

  return (
    <Stack gap={{ mobile: 1, laptop: 2 }}>
      <Grid container alignItems={'center'} gap={1}>
        {bundleInfo.status === 'active' ? (
          <SuccessCircleSvg />
        ) : bundleInfo.status === 'pending' ? (
          <PendingCircleSvg />
        ) : (
          <InactiveCircleSvg />
        )}
        <Typography variant="paragraphM">
          {bundleInfo.status === 'active'
            ? t('active')
            : bundleInfo.status === 'pending'
              ? t('pending')
              : t('paused')}
        </Typography>
      </Grid>
      <Stack gap={2}>
        <Typography variant="h4">{t('whenContinueDonating')}</Typography>
        <Box>
          <EditDonationPaymentDate
            value={newPaymentDateValue}
            handleChange={(newValue) => {
              setNewPaymentDateValue(newValue);
            }}
            min={min}
          />
        </Box>
        <Box width={'fit-content'}>
          <LoadingButton
            onClick={handleResumeSubscription}
            variant="contained"
            color="primary"
            loading={loadingResume}
            disabled={loadingResume || newPaymentDateValue < min}
          >
            {t('resume')}
          </LoadingButton>
        </Box>
      </Stack>
    </Stack>
  );
};

export default StatusPausedEditState;
