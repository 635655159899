import { type Theme } from '@mui/material/styles';
import { shadow2XL, shadowXL, sliderThumbShadow } from '../variables';

export const MuiSliderStyles = {
  styleOverrides: {
    root: () => ({
      borderRadius: '23px',
      height: '8px',
    }),
    rail: ({ theme }: { theme: Theme }) => ({
      color: theme.palette.grey[500],
    }),
    thumb: ({ theme }: { theme: Theme }) => ({
      color: theme.palette.common.white,
      width: '24px',
      height: '24px',
      boxShadow: sliderThumbShadow,
      '&:hover': {
        boxShadow: shadowXL,
        '&.Mui-active': {
          boxShadow: shadow2XL,
        },
      },
      '&.Mui-active': {
        boxShadow: shadow2XL,
      },
      '&.Mui-focusVisible': {
        boxShadow: shadow2XL,
      },
    }),
  },
};
