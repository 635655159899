import { Grid, MenuItem, Select, Stack, Typography, type SelectProps } from '@mui/material';
import { CheckIcon, ChevronDownIcon } from '../../assets/icons';
import { darkBlue, shadowSM } from '../../styles/variables';
import type { TLanguage } from '../../models/Basename';

type LanguageSelectorItem = {
  lightMode: boolean;
  onChange: SelectProps['onChange'];
  selectedLanguage: string;
  languageOptions: (TLanguage | undefined)[];
  matchLanguageLogo: (value: string) => string | undefined;
  labelColor?: string;
  showArrowIcon?: boolean;
};

const LanguageSelectorItem = ({
  lightMode,
  onChange,
  selectedLanguage,
  languageOptions,
  matchLanguageLogo,
  labelColor,
}: LanguageSelectorItem) => (
  <Stack gap="4px">
    <Select
      fullWidth
      sx={{
        bgcolor: 'transparent',
        height: { mobile: '43px', tablet: '43px', laptop: '43px' },
        '.MuiOutlinedInput-notchedOutline': { border: '0px' },
        '.MuiSelect-icon path': { stroke: lightMode ? darkBlue : 'white' },
        '.MuiSelect-select': { p: '12px 0px' },
      }}
      MenuProps={{
        sx: {
          '& .MuiMenu-paper': {
            borderRadius: '8px',
            boxShadow: shadowSM,
          },
          '& .MuiList-root': {
            padding: '16px 8px',
          },
          '.MuiOutlinedInput-notchedOutline': { border: '0px' },
        },
      }}
      IconComponent={(props) => <ChevronDownIcon {...props} />}
      onChange={onChange}
      value={selectedLanguage}
      data-testid="LanguageSelector"
      renderValue={(value: string) => (
        <Stack direction="row" alignItems="center" spacing={2} padding="0">
          {labelColor && (
            <Typography color={labelColor} variant="buttonM">
              {languageOptions?.find((lng) => lng?.id === value)?.label || ''}
            </Typography>
          )}
          <img
            src={matchLanguageLogo(value)}
            alt={`lng-${value}`}
            width="38px"
            style={{ marginRight: '8px' }}
          />
        </Stack>
      )}
    >
      {languageOptions.map((option) => {
        if (option) {
          return (
            <MenuItem
              key={option.id}
              value={option.id}
              sx={{
                padding: '6px 8px',
                justifyContent: 'space-between',
              }}
            >
              <Grid
                container
                alignItems={'center'}
                sx={{ '& > img': { mr: 1, flexShrink: 0 } }}
                minWidth={{ mobile: '140px', laptop: '160px' }}
              >
                <img loading="lazy" width="38px" src={option.logo} alt={`${option.id}-logo`} />
                <Typography variant="paragraphM">{option.label}</Typography>
              </Grid>

              {option.id === selectedLanguage && <CheckIcon />}
            </MenuItem>
          );
        }
      })}
    </Select>
  </Stack>
);

export default LanguageSelectorItem;
