export const RadioOutlinedIcon = ({
  width = '24',
  height = '24',
}: {
  width?: string;
  height?: string;
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="1.5" y="1.5" width="21" height="21" rx="10.5" fill="white" />
    <rect x="1.5" y="1.5" width="21" height="21" rx="10.5" stroke="#E7E8F1" strokeWidth="3" />
  </svg>
);

export default RadioOutlinedIcon;
