import { useContext, useEffect, useState } from 'react';
import { Button, Dialog, DialogActions, Grid, IconButton, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { getFiltersByLanguage } from '../../../../api/filters';
import { LanguageContext } from '../../../../providers/language-selector-provider';
import { CloseIcon } from '../../../../assets/icons';
import LoadingButton from '../../../../components/loading-button/LoadingButton';
import CheckboxLabeled from '../../../../components/checkbox-labeled/CheckboxLabeled';
import type { CharityCategory } from '../../../../models/Charities';

type CharitiesFilterDialogProps = {
  open: boolean;
  onClose: () => void;
  selectedFilters: string[];
  setSelectedFilters: (value: string[]) => void;
  setActiveFiltersNumber: (value: number) => void;
};

type GroupedFilter = {
  category_group: string;
  filterNames: string[];
};

const CharitiesFilterDialog = ({
  open,
  onClose,
  selectedFilters,
  setSelectedFilters,
  setActiveFiltersNumber,
}: CharitiesFilterDialogProps) => {
  const { selectedCountry } = useContext(LanguageContext);
  const [charityFilters, setCharityFilters] = useState<GroupedFilter[]>([]);
  const [localFilters, setLocalFilters] = useState<string[]>([]);
  const [loading, setLoading] = useState(false);

  const onFilterCharities = () => {
    setLoading(true);
    setSelectedFilters(localFilters);
    setLoading(false);
    onClose();
  };

  const onClearFilters = () => {
    setSelectedFilters([]);
    setLocalFilters([]);
    onClose();
  };

  const onFilterSelect = (value: string) => {
    if (!localFilters.includes(value)) {
      setLocalFilters([...localFilters, value]);
    } else {
      setLocalFilters(localFilters.filter((localFilter) => localFilter !== value));
    }
  };

  const groupFilters = (filters: CharityCategory[]): GroupedFilter[] => {
    const categoryGroups: { [key: string]: string[] } = {};

    filters.forEach(({ category_group, name }) => {
      categoryGroups[category_group] = categoryGroups[category_group] || [];
      categoryGroups[category_group].push(name);
    });

    return Object.entries(categoryGroups).map(([category_group, filterNames]) => ({
      category_group,
      filterNames,
    }));
  };

  const getFilters = () => {
    getFiltersByLanguage(selectedCountry)
      .then((res) => {
        const groupedFilters = groupFilters(res.data);
        setCharityFilters(groupedFilters);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    getFilters();
  }, [open]);

  useEffect(() => {
    setActiveFiltersNumber(selectedFilters.length);
  }, [selectedFilters]);

  const dialogStyles = {
    '& .MuiDialog-paper': {
      padding: { mobile: '50px 20px 20px', tablet: 4 },
      borderRadius: { mobile: '20px 20px 0 0 ', tablet: '16px' },
      gap: 4,
      margin: { mobile: 0, tablet: 9 },
      width: '100%',
    },
    '& .MuiDialog-container': {
      alignItems: { mobile: 'flex-end', tablet: 'center' },
    },
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="filter-dialog-title"
      aria-describedby="filter-dialog-description"
      maxWidth="tablet"
      fullWidth
      sx={dialogStyles}
    >
      <Stack gap="8px">
        <Typography variant="h2" id="filter-dialog-title">
          {t('filter')}
        </Typography>
      </Stack>

      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          padding: 2,
          right: 16,
          top: 16,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <Grid
        container
        gap={{ mobile: 5, tablet: 2 }}
        flexWrap={{ mobile: 'wrap', laptop: 'nowrap' }}
      >
        {charityFilters.map(({ category_group, filterNames }) => (
          <Grid item key={category_group} mobile={12} tablet={4}>
            <Stack gap={2}>
              <Typography variant="h6">{category_group}</Typography>
              {filterNames.map((filterName) => (
                <CheckboxLabeled
                  value={filterName}
                  label={filterName}
                  key={filterName}
                  name={filterName}
                  onChange={(e) => {
                    onFilterSelect(e.target.value);
                  }}
                  checked={localFilters.includes(filterName)}
                />
              ))}
            </Stack>
          </Grid>
        ))}
      </Grid>

      <DialogActions sx={{ padding: 0, gap: 4, justifyContent: 'space-between' }}>
        <Button
          variant="text"
          size="large"
          onClick={() => onClearFilters()}
          color="info"
          sx={{ '&:focus': { borderColor: 'transparent' } }}
        >
          {t('clear')}
        </Button>
        <LoadingButton
          onClick={onFilterCharities}
          size="large"
          variant="contained"
          color="primary"
          loading={loading}
          disabled={loading}
        >
          {t('filter')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default CharitiesFilterDialog;
