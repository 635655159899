import {
  Badge,
  Button,
  Card,
  CardActions,
  CardContent,
  IconButton,
  useMediaQuery,
  type Theme,
} from '@mui/material';
import { type TCharity } from '../../models/Charities';
import {
  backgroundColor,
  errorColor,
  shadowSM,
  successColor,
  textColor,
  white,
} from '../../styles/variables';
import { CheckCircleIcon, ShareCornerIcon } from '../../assets/icons';

interface CharitySelectorItemProps {
  charity: TCharity;
  isSelected: boolean;
  handleSelectCharity: (charity: TCharity) => void;
  handleRemoveFromSelection: (charity: TCharity) => void;
  selectionText: string;
  removeSelectionText: string;
  handleShowDetails?: (charity: TCharity) => void;
  logoOnly: boolean;
}

export const CharitySelectorItem = ({
  charity,
  isSelected,
  selectionText,
  removeSelectionText,
  handleSelectCharity,
  handleRemoveFromSelection,
  logoOnly,
}: CharitySelectorItemProps) => {
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('tablet'));
  const isSmallMobile = useMediaQuery('(max-width: 300px)');

  return (
    <Badge
      badgeContent={
        isSelected ? (
          <CheckCircleIcon
            width={isMobile || logoOnly ? '20' : '25'}
            height={isMobile || logoOnly ? '20' : '24'}
          />
        ) : undefined
      }
      componentsProps={{
        badge: {
          style: {
            top: logoOnly ? 6 : 10,
            right: logoOnly ? 6 : 8,
            transform: 'none',
            padding: 0,
          },
        },
      }}
      sx={{ height: '100%', width: '100%' }}
    >
      <Card
        sx={{
          boxShadow: shadowSM,
          borderRadius: logoOnly ? '6px' : '14px',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          width: '100%',
          border: isSelected ? `2px solid ${successColor}` : `2px solid ${white}`,
          cursor: 'pointer',
        }}
        onClick={() => {
          isSelected ? handleRemoveFromSelection(charity) : handleSelectCharity(charity);
        }}
      >
        <CardContent
          sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '&.MuiCardContent-root:last-child': {
              padding: '0',
            },
          }}
        >
          <img
            src={charity.logo}
            alt={'logo'}
            width={'100%'}
            style={{
              objectFit: 'contain',
            }}
          />
        </CardContent>
        {!logoOnly ? (
          <CardActions sx={{ gap: 1, justifyContent: 'space-between' }}>
            {isSelected ? (
              <Button
                variant="contained"
                fullWidth
                color="error"
                size="medium"
                onClick={() => {
                  handleRemoveFromSelection(charity);
                }}
                sx={{
                  backgroundColor,
                  border: `1px solid ${backgroundColor}`,
                  color: errorColor,
                  '&:hover': {
                    color: white,
                    borderColor: 'transparent',
                  },
                }}
              >
                {removeSelectionText}
              </Button>
            ) : (
              <Button
                fullWidth
                variant="contained"
                size="medium"
                onClick={() => {
                  handleSelectCharity(charity);
                }}
              >
                {selectionText}
              </Button>
            )}

            <IconButton
              component="a"
              href={charity.webpageUrl || ''}
              target="_blank"
              rel="noopener noreferrer"
              aria-label={`${charity.name}-details`}
              sx={(theme) => ({
                [theme.breakpoints.down('mobileS')]: {
                  padding: 0,
                },
              })}
            >
              <ShareCornerIcon
                color={textColor}
                width={isSmallMobile ? '14' : '24'}
                height={isSmallMobile ? '14' : '24'}
              />
            </IconButton>
          </CardActions>
        ) : null}
      </Card>
    </Badge>
  );
};

export default CharitySelectorItem;
