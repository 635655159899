import { type Theme } from '@mui/material/styles';
import { font } from '../custom-theme';
import { darkBlue, white } from '../variables';

export const MuiChipStyles = {
  styleOverrides: {
    root: () => ({
      fontFamily: font,
      borderRadius: '24px',
      padding: '8px 10px',
      backgroundColor: darkBlue,
      color: white,
    }),
    label: ({ theme }: { theme: Theme }) => ({
      padding: 0,
      fontSize: '10px',
      fontWeight: 600,
      lineHeight: 'normal',
      fontStyle: 'normal',
      textTransform: 'uppercase',
      letterSpacing: '0.8px',
      [theme.breakpoints.up('tablet')]: {
        fontSize: '11px',
        letterSpacing: '0.88px',
      },
      [theme.breakpoints.up('laptop')]: {
        fontSize: '12px',
        letterSpacing: '0.96px',
      },
    }),
  },
};
