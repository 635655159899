import { Alert, Slide, Snackbar } from '@mui/material';
import type { FC, ReactNode, SyntheticEvent } from 'react';
import { createContext, useState } from 'react';
import { AlertTriangleIcon } from '../assets/icons';
import { errorColor } from '../styles/variables';

interface TNotification {
  message: string | undefined;
  type: 'success' | 'error' | 'info';
}

export interface INotificationsContext {
  setNotification: (notification: TNotification | undefined) => void;
  notificationData: TNotification | undefined;
}

export const NotificationsContext = createContext<INotificationsContext>({
  setNotification: () => {},
  notificationData: undefined,
});

export const NotificationsProvider: FC<{ children?: ReactNode }> = (props) => {
  const [notificationData, setNotificationData] = useState<TNotification | undefined>(undefined);

  const setNotification = (notification: TNotification | undefined) => {
    setNotificationData(notification);
  };

  const handleClose = (event?: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setNotificationData(undefined);
  };

  return (
    <NotificationsContext.Provider
      value={{
        setNotification,
        notificationData,
      }}
    >
      {props.children}
      <Snackbar
        open={!!notificationData}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        autoHideDuration={6000}
        onClose={handleClose}
        TransitionComponent={(props: any) => <Slide {...props} direction="down" />}
        sx={{ width: 'fit-content', margin: '0 auto' }}
      >
        <Alert
          icon={
            notificationData?.type === 'error' ? (
              <AlertTriangleIcon color={errorColor} />
            ) : undefined
          }
          severity={notificationData?.type}
        >
          {notificationData?.message}
        </Alert>
      </Snackbar>
    </NotificationsContext.Provider>
  );
};
