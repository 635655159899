import { Card, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { shareDonorInformation } from '../../../../../api/user-details';
import LoadingButton from '../../../../../components/loading-button/LoadingButton';
import { NotificationsContext } from '../../../../../providers/notifications-provider';
import { desktopXXL, white } from '../../../../../styles/variables';

const ShareDonorInformation = () => {
  const { t } = useTranslation();
  const { setNotification } = useContext(NotificationsContext);
  const [isLoading, setIsLoading] = useState(false);

  const handleShareDonorInformation = async () => {
    setIsLoading(true);
    try {
      await shareDonorInformation();

      setNotification({ message: t('successfullySent', { data: t('data') }), type: 'success' });
    } catch {
      setNotification({
        message: t('somethingWentWrong', { interpolation: { skipOnVariables: false } }),
        type: 'error',
      });
    } finally {
      setIsLoading(false);
    }
  };

  const cardStyles = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: { mobile: 'column', tablet: 'row' },
    gap: 3,
    backgroundColor: white,
    padding: '16px',
    boxShadow: desktopXXL,
    borderRadius: '8px',
  };

  return (
    <Card role="tabpanel" sx={cardStyles}>
      <Stack flex={2} gap="4px">
        <Typography variant="h6">{t('shareYourEmailAddress')}</Typography>
        <Typography variant="paragraphS">{t('stayInformedShareBundle')}</Typography>
      </Stack>
      <LoadingButton
        variant="contained"
        onClick={handleShareDonorInformation}
        loading={isLoading}
        disabled={isLoading}
      >
        {t('forward', { value: t('emailAddress') })}
      </LoadingButton>
    </Card>
  );
};

export default ShareDonorInformation;
