import { CircularProgress, Stack, Typography } from '@mui/material';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { AuthenticationContext } from '../../providers/authentication-provider';

export const ActivateAccountPage = () => {
  const navigate = useNavigate();
  const { handleActivateAccount } = useContext(AuthenticationContext);
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const code = searchParams.get('code');

  useEffect(() => {
    if (!code) {
      navigate('/');
    } else {
      handleActivateAccount(
        code,
        () => {
          window.location.href = 'login';
        },
        () => {
          navigate('/');
        }
      );
    }
  }, [code]);

  return (
    <Stack width={'100%'} height="100vh" justifyContent={'center'} alignItems={'center'} gap={2}>
      <>
        <CircularProgress />
        <Typography>{`${t('verifyingLink')}...`}</Typography>
      </>
    </Stack>
  );
};

export default ActivateAccountPage;
