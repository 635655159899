import { Card, FormControl, RadioGroup, Stack, Typography } from '@mui/material';
import { useContext, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import { MergeIcon, PlusIcon, RefreshIcon } from '../../../../assets/icons';
import RadioButtonLabeled from '../../../../components/radio-button-labeled/RadioButtonLabeled';
import type { TMonthlyDonorOption } from '../../../../models/Donation';
import { DonationFlowContext } from '../../../../providers/donation-flow-provider';
import { DonationFromWidgetContext } from '../../../../providers/donation-from-widget-provider';
import { shadowSM } from '../../../../styles/variables';
import { renderAllCharitiesNames } from '../../DonationFlow.utils';
import CheckExistingBundleSkeleton from './CheckExistingBundleSkeleton';
import OptionLabel from './OptionLabel';

export const CheckExistingBundleStep = () => {
  const { t } = useTranslation();
  const [loadingCurrentBundle, setLoadingCurrentBundle] = useState(true);
  const {
    selectedCharities,
    monthlyDonorOption,
    currentBundle,
    handleChooseMonthlyDonorOption,
    handleNextStep,
    loadingData,
  } = useContext(DonationFlowContext);
  const { preselectedCharitiesFromWidget, widgetData } = useContext(DonationFromWidgetContext);

  useEffect(() => {
    if (currentBundle.length === 0 && !loadingData) {
      handleNextStep();
    } else {
      if (!loadingData) {
        localStorage.setItem('monthlyDonor', 'yes');
        setLoadingCurrentBundle(false);
      }
    }
  }, [currentBundle, loadingCurrentBundle]);

  return loadingCurrentBundle ? (
    <CheckExistingBundleSkeleton />
  ) : (
    <Stack
      px={{ mobile: '20px', tablet: '40px', laptop: 0 }}
      mx={{ mobile: '0', laptop: '32px' }}
      width={{ mobile: '100%', laptop: '70%' }}
      maxWidth={'1216px'}
      pt={2}
      gap={{ mobile: 3, tablet: 4 }}
    >
      <Typography variant="h3">{t('youAlreadyHaveBundle')}</Typography>
      <FormControl>
        <RadioGroup
          aria-labelledby="payment-method"
          name="payment-method"
          value={monthlyDonorOption}
          onChange={(event) => {
            handleChooseMonthlyDonorOption(
              (event.target as HTMLInputElement).value as TMonthlyDonorOption
            );
          }}
        >
          <Stack gap={2}>
            {preselectedCharitiesFromWidget?.length && widgetData?.type !== '3' ? (
              <Card
                onClick={() => handleChooseMonthlyDonorOption('one-time')}
                sx={{
                  py: 2,
                  px: 3,
                  justifyContent: 'space-between',
                  display: 'flex',
                  alignItems: 'center',
                  boxShadow: shadowSM,
                  borderRadius: '8px',
                  cursor: 'pointer',
                  gap: 3,
                }}
                data-testid={'ExtraOneTimeDonationOption'}
              >
                <RadioButtonLabeled
                  value="one-time"
                  label={
                    <OptionLabel
                      title={t('additionalOneTimeDonation')}
                      subtitle={
                        <>
                          {t('additionToMonthlyDonation')}
                          {renderAllCharitiesNames(
                            selectedCharities.filter((charity) => !charity.isFromCurrentBundle),
                            t('and')
                          )}
                          .
                        </>
                      }
                    />
                  }
                  checked={monthlyDonorOption === 'one-time'}
                />
                <PlusIcon width="32" height="32" />
              </Card>
            ) : null}
            <Card
              onClick={() => {
                handleChooseMonthlyDonorOption('merge');
              }}
              sx={{
                py: 2,
                px: 3,
                justifyContent: 'space-between',
                display: 'flex',
                alignItems: 'center',
                boxShadow: shadowSM,
                borderRadius: '8px',
                gap: 3,
              }}
              data-testid={'mastercardPaymentMethod'}
            >
              <RadioButtonLabeled
                value="merge"
                label={
                  <OptionLabel
                    title={t('mergeBundles')}
                    subtitle={
                      <>
                        {t('yourCurrentBundle')}
                        {renderAllCharitiesNames(currentBundle, t('and'))}{' '}
                        {t('willBeMergedWithSelection')}
                        {renderAllCharitiesNames(
                          selectedCharities.filter((charity) => !charity.isFromCurrentBundle),
                          t('and')
                        )}
                        .
                      </>
                    }
                  />
                }
                checked={monthlyDonorOption === 'merge'}
              />
              <MergeIcon width="32" height="32" />
            </Card>
            <Card
              onClick={() => {
                handleChooseMonthlyDonorOption('overwrite');
              }}
              sx={{
                py: 2,
                px: 3,
                justifyContent: 'space-between',
                display: 'flex',
                alignItems: 'center',
                boxShadow: shadowSM,
                borderRadius: '8px',
                gap: 3,
              }}
              data-testid={'mastercardPaymentMethod'}
            >
              <RadioButtonLabeled
                value="overwrite"
                label={
                  <OptionLabel
                    title={t('overwriteBundle')}
                    subtitle={
                      <>
                        {t('changeBundleWithNewSelection')}{' '}
                        {renderAllCharitiesNames(
                          selectedCharities.filter((charity) => !charity.isFromCurrentBundle),
                          t('and')
                        )}
                      </>
                    }
                  />
                }
                checked={monthlyDonorOption === 'overwrite'}
              />
              <RefreshIcon width="32" height="32" />
            </Card>
          </Stack>
        </RadioGroup>
      </FormControl>
    </Stack>
  );
};

export default CheckExistingBundleStep;
