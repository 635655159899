import { type Theme } from '@mui/material/styles';
import { font } from '../custom-theme';
import { shadowSM } from '../variables';

export const MuiAutocompleteStyles = {
  styleOverrides: {
    root: () => ({
      '& .MuiOutlinedInput-root': {
        padding: '9px 14px',
        '& .MuiAutocomplete-input': {
          padding: '0px',
        },
      },
    }),
    listbox: () => ({
      padding: '8px',
      borderRadius: '8px',
      boxShadow: shadowSM,
      display: 'flex',
      flexDirection: 'column',
      gap: '8px',

      '& .MuiAutocomplete-option': {
        padding: '12px 8px',
      },
    }),
    option: ({ theme }: { theme: Theme }) => ({
      padding: '12px 8px',
      borderRadius: '6px',
      fontFamily: font,
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '21px',
      '&:hover': {
        backgroundColor: theme.palette.grey[400],
      },
      "&[aria-selected='true']": {
        backgroundColor: theme.palette.grey[400],
      },
      "&[aria-selected='true'].Mui-focused": {
        backgroundColor: theme.palette.grey[400],
      },
      '&.Mui-focusVisible': {
        backgroundColor: theme.palette.grey[400],
      },
      [theme.breakpoints.up('tablet')]: {
        fontSize: '16px',
        lineHeight: '24px',
      },
      [theme.breakpoints.up('laptop')]: {
        fontSize: '18px',
        lineHeight: '27px',
      },
    }),
  },
};
