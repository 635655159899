import { type ReactNode } from 'react';

import { FormControlLabel, Radio, type RadioProps } from '@mui/material';
import { RadioCheckedIcon, RadioOutlinedIcon } from '../../assets/icons';

interface RadioButtonLabeledProps extends RadioProps {
  label: ReactNode | string | undefined;
  typographyVariant?:
    | 'h1'
    | 'h2'
    | 'h3'
    | 'h4'
    | 'h5'
    | 'h6'
    | 'paragraphL'
    | 'paragraphM'
    | 'paragraphS'
    | 'paragraphXS'
    | 'subtitle'
    | 'menuItem';
  flex?: string;
  marginRight?: string;
}

export const RadioButtonLabeled = (props: RadioButtonLabeledProps) => {
  return (
    <FormControlLabel
      control={
        <Radio
          sx={{ marginRight: '15px' }}
          checkedIcon={<RadioCheckedIcon />}
          icon={<RadioOutlinedIcon />}
          inputProps={{ 'aria-label': `${props.value}-label` }}
          {...props}
        />
      }
      label={props.label}
      componentsProps={{
        typography: {
          variant: props.typographyVariant || 'paragraphM',
          fontWeight: 400,
        },
      }}
      id={`${props.value}-label`}
      sx={{
        width: 'fit-content',
        flex: props.flex || 'auto',
        marginRight: props.marginRight || '16px',
      }}
    />
  );
};

export default RadioButtonLabeled;
