import { Card, Grid, Typography } from '@mui/material';
import { Form, Formik } from 'formik';
import { desktopL, white } from '../../styles/variables';
import InputWithLabel from '../inputWithLabel/InputWithLabel';
import LoadingButton from '../loading-button/LoadingButton';
import { couponsSchema } from './Coupons.utils';

// types
import type { CreateCoupons } from '../../api/coupons';

type CouponsGenerator = {
  handleGenerateCoupons: (couponsData: CreateCoupons, resetFunction: () => void) => Promise<void>;
};

const currentDate = new Date().toISOString().slice(0, 10);

const couponGeneratorStyles = {
  margin: '4px auto 0px',
  borderRadius: '12px',
  width: '100%',
  maxWidth: { mobile: '100%', laptop: '500px' },
  padding: '24px 20px',
  alignSelf: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  gap: 3,
  backgroundColor: white,
  boxShadow: desktopL,
  overflow: 'unset',
};

const formInitialValues = {
  startDate: currentDate,
  endDate: currentDate,
  amount: 1,
  numberCoupons: 1,
  prefix: '',
  client: '',
  campaign: '',
};

const CouponsGenerator = ({ handleGenerateCoupons }: CouponsGenerator) => {
  return (
    <Card sx={couponGeneratorStyles}>
      <Typography variant="h3" textAlign="left">
        Coupons Generator
      </Typography>
      <Formik
        initialValues={formInitialValues}
        onSubmit={async (data, helpers) => {
          await handleGenerateCoupons(data, helpers.resetForm);
        }}
        validationSchema={couponsSchema(currentDate)}
        validateOnChange={false}
      >
        {({ handleChange, values, isSubmitting, errors, setErrors }) => {
          return (
            <Form>
              <Grid container spacing={2}>
                <Grid
                  container
                  item
                  gap={1}
                  justifyContent="center"
                  sx={{ flexWrap: { tablet: 'nowrap' } }}
                  mobile={12}
                  tablet={6}
                  laptop={12}
                >
                  <Grid item mobile={12} tablet={6} laptop={6}>
                    <InputWithLabel
                      labelName="Start Date"
                      fullWidth
                      name="startDate"
                      type="date"
                      value={values.startDate}
                      onChange={(e) => {
                        handleChange(e);
                        setErrors({});
                      }}
                      inputProps={{
                        min: currentDate,
                      }}
                      sx={{
                        '& .MuiInputBase-input': {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        },
                      }}
                      error={!!errors.startDate}
                      errorMessage={errors.startDate}
                    />
                  </Grid>
                  <Grid item mobile={12} tablet={6} laptop={6}>
                    <InputWithLabel
                      labelName="End Date"
                      fullWidth
                      name="endDate"
                      type="date"
                      value={values.endDate}
                      onChange={(e) => {
                        handleChange(e);
                        setErrors({});
                      }}
                      inputProps={{ min: currentDate }}
                      sx={{
                        '& .MuiInputBase-input': {
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                        },
                      }}
                      error={!!errors.endDate}
                      errorMessage={errors.endDate}
                    />
                  </Grid>
                </Grid>
                <Grid container item spacing={2} mobile={12} tablet={6} laptop={12}>
                  <Grid item mobile={12} tablet={6} laptop={6}>
                    <InputWithLabel
                      name="amount"
                      type="number"
                      labelName="Amount"
                      value={values.amount}
                      onChange={(e) => {
                        handleChange(e);
                        setErrors({});
                      }}
                      fullWidth
                      error={!!errors.amount}
                      errorMessage={errors.amount}
                      startAdornment={<Typography fontWeight="700">€</Typography>}
                    />
                  </Grid>
                  <Grid item mobile={12} tablet={6} laptop={6}>
                    <InputWithLabel
                      name="numberCoupons"
                      type="number"
                      labelName="Number of coupons"
                      value={values.numberCoupons}
                      onChange={(e) => {
                        handleChange(e);
                        setErrors({});
                      }}
                      fullWidth
                      error={!!errors.numberCoupons}
                      errorMessage={errors.numberCoupons}
                    />
                  </Grid>
                </Grid>
                <Grid container item spacing={2}>
                  <Grid item mobile={12} tablet={3} laptop={6}>
                    <InputWithLabel
                      name="prefix"
                      type="text"
                      labelName="Prefix"
                      value={values.prefix}
                      onChange={(e) => {
                        handleChange(e);
                        setErrors({});
                      }}
                      fullWidth
                      inputProps={{ maxLength: 4 }}
                      error={!!errors.prefix}
                      errorMessage={errors.prefix}
                    />
                  </Grid>
                  <Grid item mobile={12} tablet={3} laptop={6}>
                    <InputWithLabel
                      name="client"
                      type="text"
                      labelName="Client"
                      value={values.client}
                      onChange={(e) => {
                        handleChange(e);
                        setErrors({});
                      }}
                      fullWidth
                      error={!!errors.client}
                      errorMessage={errors.client}
                    />
                  </Grid>
                  <Grid item mobile={12} tablet={6} laptop={12}>
                    <InputWithLabel
                      name="campaign"
                      type="text"
                      labelName="Campaign"
                      value={values.campaign}
                      onChange={(e) => {
                        handleChange(e);
                        setErrors({});
                      }}
                      fullWidth
                      error={!!errors.campaign}
                      errorMessage={errors.campaign}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                loading={isSubmitting}
                disabled={
                  Object.values(errors).length > 0 ||
                  Object.values(values).some((el) => !el) ||
                  isSubmitting
                }
                sx={{ marginTop: 2 }}
              >
                Send
              </LoadingButton>
            </Form>
          );
        }}
      </Formik>
    </Card>
  );
};

export default CouponsGenerator;
