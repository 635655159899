import { Box, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import LoadingButton from '../../../../../components/loading-button/LoadingButton';
import { MyGiftShiftContext } from '../../../../../providers/my-giftshift-provider';

import { Trans, useTranslation } from 'react-i18next';

export const IBANNumberEditState = () => {
  const { t } = useTranslation();
  const [loadingVerification, setLoadingVerification] = useState(false);
  const { bundleInfo, updatePaymentMethod } = useContext(MyGiftShiftContext);

  const makeVerificationPayment = async () => {
    setLoadingVerification(true);
    const response = await updatePaymentMethod();
    if (response) {
      setLoadingVerification(false);
    }
  };

  return (
    <Stack gap={2}>
      <Typography variant="paragraphM">
        <Trans i18nKey={'toAdjustIbanNumber'} values={{ ibanNumber: `*${bundleInfo.ibanLast4}` }} />
      </Typography>
      <Box width="fit-content">
        <LoadingButton
          variant="contained"
          onClick={makeVerificationPayment}
          loading={loadingVerification}
          disabled={loadingVerification}
        >
          {t('makeVerificationPayment')}
        </LoadingButton>
      </Box>
    </Stack>
  );
};

export default IBANNumberEditState;
