import { Button, Dialog, DialogActions, IconButton, Stack, Typography } from '@mui/material';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EmptyBundleIllustration from '../../../../../assets/EmptyBundleIllustration';
import { CloseIcon } from '../../../../../assets/icons';
import LoadingButton from '../../../../../components/loading-button/LoadingButton';
import { MyGiftShiftContext } from '../../../../../providers/my-giftshift-provider';
import { textColor } from '../../../../../styles/variables';

interface ConfirmPauseDialogProps {
  open: boolean;
  onClose: () => void;
}

export const ConfirmRemoveBundleDialog = ({ open, onClose }: ConfirmPauseDialogProps) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const { handleRemoveBundle } = useContext(MyGiftShiftContext);

  const onRemoveBundle = async () => {
    setLoading(true);
    const response = await handleRemoveBundle();
    if (response) {
      setLoading(false);
      onClose();
    }
  };
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="confirm-remove-bundle-dialog-title"
      aria-describedby="confirm-remove-bundle-dialog-description"
      maxWidth="tablet"
      fullWidth
      sx={{
        '& .MuiDialog-paper': {
          padding: { mobile: '76px 20px', tablet: 10 },
          borderRadius: { mobile: '20px 20px 0 0 ', tablet: '16px' },
          gap: 4,
          margin: { mobile: 0, tablet: 9 },
          width: '100%',
          alignItems: 'center',
        },
        '& .MuiDialog-container': {
          alignItems: { mobile: 'flex-end', tablet: 'center' },
        },
      }}
    >
      <EmptyBundleIllustration />
      <Stack gap={1}>
        <Typography variant="h3" id="confirm-remove-bundle-dialog-title" textAlign={'center'}>
          {t('areYouSure')}
        </Typography>
        <Typography
          variant="paragraphL"
          color={textColor}
          id="confirm-remove-bundle-dialog-description"
          textAlign={'center'}
        >
          {t('pauseOrAdjust')}
        </Typography>
      </Stack>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          padding: 2,
          right: 16,
          top: 16,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogActions sx={{ padding: 0, flexDirection: 'column', gap: 4 }}>
        <LoadingButton
          onClick={onRemoveBundle}
          size="large"
          variant="contained"
          color="error"
          loading={loading}
          disabled={loading}
        >
          {t('removeBundle')}
        </LoadingButton>
        <Button size="large" onClick={onClose} variant="text" color="primary">
          {t('keepBundle')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmRemoveBundleDialog;
